import {useTranslation} from "react-i18next";

import AvailabilityStatusCIrcule from "../../../../../utilities/AvailabilityStatusCIrcule";
import {DateInput, TextInput} from "../../../../Inputs";

const PaymentDates = ({labelPlan, labelFact, ttType, name}) => {
  const {t} = useTranslation();

  return (
    <div className="payment-dates">
      <div className="on-payment">
        <AvailabilityStatusCIrcule />
        <div>{t("Date")}</div>
        <DateInput label={labelPlan} name={`${name}Plan`} readOnly />
      </div>
      <div className="on-payment">
        <div>{t("Date")}</div>
        <DateInput label={labelFact} question={ttType} name={`${name}Fact`} readOnly />
      </div>
    </div>
  );
};

export default PaymentDates;
