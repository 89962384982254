import {Component} from "react";

class ErrorBoundary extends Component {
  state = {
    error: false,
  };

  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo);
    this.setState({
      error: true,
    });
  }

  render() {
    if (this.state.error) {
      console.log("this.state.error", this.state.error);
      return (
        <div
          style={{
            position: "relative",
            height: "90vh",
            width: "100vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <label style={{position: "absolute", margin: "0 auto"}}>{this.state.error} Цей розділ ще в розробці</label>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
