import {useDispatch, useSelector} from "react-redux";

import {selectDoc} from "../../redux/PaymentOperationsSlice";
import {getCurrentDocument} from "../../redux/PaymentSelectors";

const usePaymentsDoc = number => {
  const dispatch = useDispatch();

  const currentDoc = useSelector(getCurrentDocument);

  const current = currentDoc?.number === number ? currentDoc.doc.docId : null;

  const onDocSelect = id => {
    if (id === current) {
      dispatch(selectDoc({number: null, docId: null}));
      return;
    }
    dispatch(selectDoc({number, docId: id}));
  };

  return {onDocSelect, current};
};

export default usePaymentsDoc;
