import {useCallback, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import PropTypes from "prop-types";

import {apiUrl} from "../../../../api/baseURL";
import {getKpInfoForKp, kpDocAdded} from "../../../../slices/KPSlice";
import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import {generatePdf} from "../../../pdfGenerate/pdfMaker";
import {deselectSku} from "../../../SKU/SkuSlice";
import {addSkuPlanDoc, changeSequenceNumber, getPlanSkuList, getSkuPlanInfo} from "../KpFinSlice";

import useKpFinDataService from "./KpFinDataService";
import useKpFinTableService from "./KpFinTableService";

import "./kpFinTable.scss";

const KpFinTable = ({currentKpF, kpFin}) => {
  const {t} = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  const {currentKpId} = location.state || {currentRequestId: ""};

  const planSkuList = useSelector(getPlanSkuList);

  const {getKpFinTableData} = useKpFinDataService();

  const kpFinTableData = useMemo(() => {
    return planSkuList?.map(getKpFinTableData) || [];
  }, [planSkuList]);

  // for numeration change, when user moves the lines in table
  const onReorder = useCallback(
    reorderedTableData => {
      if (reorderedTableData) {
        var sequenceObject = reorderedTableData.map(plan => {
          return {
            skuPlanId: plan.id,
            sequenceNumber: plan.number,
          };
        });

        dispatch(changeSequenceNumber({sequenceObject}));
      }
    },
    [currentKpF],
  );

  const selectSkuPlan = id => {
    if (kpFin) {
      dispatch(getSkuPlanInfo(id));

      dispatch(deselectSku());
    }
  };

  const exportToPdf = () => {
    const data = generateDataForExport(`${[t("CO(f)")]}_${currentKpF.cofNumber}`);
    var pdf = generatePdf(data);
    pdf.download(`kpf_${currentKpF.cofNumber}_doc`);
    pdf.getBlob(file => {
      var formData = new FormData();
      var fileName = `kpf_${currentKpF.cofNumber}_doc.pdf`;
      formData.append("doc", file, fileName);

      dispatch(addSkuPlanDoc({cofId: currentKpF.cofId, formData})).then(res => {
        if (res.meta.requestStatus === "fulfilled") {
          dispatch(kpDocAdded(res.payload.docs));
        }
      });
    });
  };

  const columns = useMemo(() => {
    const imgcellRender = value => {
      var imgs = value.getValue();
      if (imgs) {
        return (
          <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
            <div key={imgs} style={{position: "relative"}}>
              <img src={apiUrl + "/" + imgs} alt={imgs} style={{width: "5em", height: "5em", marginRight: "2px"}} />
            </div>
          </div>
        );
      }
    };
    return [
      {
        header: () => "№",
        accessorKey: "number",
        size: 15,
      },
      {
        header: () => "SKU",
        accessorKey: "article",
        toggled: true,
        size: 200,
      },
      {
        header: () => [t("Brand w*")],
        accessorKey: "brandName",
      },
      {
        header: () => [t("Nomenclature")],
        accessorKey: "skuName",
      },
      {
        header: () => [t("Characteristics")],
        accessorKey: "characteristics",
        size: 200,
        toggled: true,
      },
      {
        header: () => [t("VWC")],
        accessorKey: "ovh",
      },
      {
        header: () => [t("Image")],
        accessorKey: "image1",
        cell: imgcellRender,
      },
      {
        header: () => [t("Image")],
        accessorKey: "image2",
        cell: imgcellRender,
      },
      {
        header: () => [t("Set description")],
        accessorKey: "complectDesc",
        toggled: true,
      },
      {
        header: () => [t("Quantity")],
        accessorKey: "amount",
      },
      {
        header: () => [t("Price pcs")],
        accessorKey: "price",
        numberFormat: true,
      },
      {
        header: () => [t("Sum €")],
        accessorKey: "summ",
        numberFormat: true,
      },
      {
        header: () => [t("Discount %")],
        accessorKey: "discount",
        toggled: true,
      },
      {
        header: () => [t("Total €")],
        accessorKey: "totalSumm",
        numberFormat: true,
      },
      {
        header: () => [t("Total discount %")],
        accessorKey: "topDiscount",
        toggled: true,
      },
      {
        header: () => [t("Payment €")],
        accessorKey: "paymentSumm",
        numberFormat: true,
      },
    ];
  }, []);

  const {KpFinTableRender, generateDataForExport} = useKpFinTableService(
    columns,
    kpFinTableData,
    onReorder,
    selectSkuPlan,
  );
  const KpFinTable = KpFinTableRender();

  return (
    <>
      {KpFinTable}

      <div
        className="fc jcsb"
        style={{
          marginBottom: "10px",
          // position: "absolute",
          bottom: "-0.23em",
          width: "100%",
        }}
      >
        <div className="fc">
          <FunctionButton name={t("Export")} onClick={exportToPdf} disabled={planSkuList?.length === 0} />
          <FunctionButton name={t("Dublicate")} style={{marginLeft: "10px"}} disabled={true} />
        </div>
        <div className="fc">
          <FunctionButton name={t("Template")} style={{marginLeft: "10px"}} autoWidth disabled={true} />
          <FunctionButton name={t("Postings")} style={{marginLeft: "10px"}} disabled={true} />
        </div>
        <div>
          <FunctionButton
            name={t("return to kp")}
            style={{marginLeft: "10px"}}
            // disabled={}
            onClick={() => {
              dispatch(getKpInfoForKp(currentKpId));
              navigate("/ba3a/kp");
            }}
          />
        </div>
      </div>
    </>
  );
};

KpFinTable.propTypes = {
  onUpdate: PropTypes.func,
  currentKpF: PropTypes.object,
  setCurrSkuPlan: PropTypes.func,
  active: PropTypes.object,
  setActive: PropTypes.func,
};

export default KpFinTable;
