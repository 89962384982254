import {createAsyncThunk, createSlice, isAnyOf} from "@reduxjs/toolkit";
import moment from "moment";

import {fetchData, fetchList} from "../../hook/axios.hook";
import i18n from "../../i18n";
import {getInfo} from "../../utilities/toasts";

import {getForReadinessService} from "./RequestsInProgress/utils/getForReadinessService";

// import { fetchList } from "../hook/axios.hook";

const initialState = {
  requestsLoading: false,
  statuses: [],
  error: [],
  requests: [],
  firstStatusRequests: [],
  appointRequests: [],
  thirdStatusRequests: [],
  fourthStatusRequests: [],
  filters: null,
  identicalSkus: [],
  current: {
    currentRequestId: null, /// 3 status
    currentRequestSKUs: [],
    currentNote: null,
    currentFourthStatusRequestId: null, /// 4
    checkedRequestIds: [],
  },
  splitedOrder: {first: null, second: null},
  currentRequestSku: null,
  currentRequestInfo: null,
  requestFactoryData: [],
  //request dates
  requestDates: {
    datesWithPostponed: [],
    // allDatesByRequest: null,
    sheduledReadinessDate: [],
    confirmedDate: null,
  },
  invoices: {
    current: null,
    added: null,
  },
  packingLists: {
    current: null,
    added: null,
  },
  packingSpaces: [],
};

export const fetchStatuses = createAsyncThunk("requests/fetchStatuses", async () => {
  const response = fetchList("/requests/getStatuses", "get");
  return response;
});

export const getRequest = createAsyncThunk("requests/getRequest", async requestId => {
  const response = fetchList(`/requests/get/${requestId}`, "get");
  return response;
});

//!
export const fetchMixedRequests = createAsyncThunk("requests/fetchMixedRequests", async params => {
  const response = fetchList(`/requests/getAll/`, "get", {params});

  return response;
});

export const fetchRequests = createAsyncThunk("requests/fetchRequests", async params => {
  const {showidenticalSku, ...restParams} = params;
  const response = fetchList(`/requests/getAll/`, "get", {params: {...restParams}});
  return response;
});

export const fetchFilters = createAsyncThunk("requests/fetchFilters", async () => {
  const response = fetchData(`requests/getFilters`, "get");
  return response;
});

export const updateRequest = createAsyncThunk("requests/updateRequest", async ({requestId, data}) => {
  const response = fetchData(`/requests/update/${requestId}`, "patch", {data});
  return response;
});

export const appointRequests = createAsyncThunk("requests/appointRequests", async ({managerFeaId, data}) => {
  const response = fetchData(`/requests/appoint/${managerFeaId}`, "put", data);
  return response;
});

export const cancelAppointmentRequests = createAsyncThunk("requests/cancelAppointmentRequests", async requestIds => {
  const response = fetchData(`/requests/cancelAppointment`, "put", {requestIds});
  return response;
});

export const cancelAllAppointmentRequests = createAsyncThunk("requests/cancelAllAppointmentRequests", async () => {
  const response = fetchData(`/requests/cancelAllAppointment`, "put");
  return response;
});

export const distributeRequests = createAsyncThunk("requests/distributeRequests", async () => {
  const response = fetchData(`/requests/distribute`, "put");
  return response;
});

export const turnIntoRequest = createAsyncThunk("requests/turnIntoRequest", async ({requestIds, note}) => {
  const response = fetchData(`/requests/turnIntoRequest/`, "put", {requestIds, note});
  return response;
});

export const mergeRequests = createAsyncThunk("requests/mergeRequests", async requestIds => {
  const response = fetchData(`/requests/merge/`, "put", {requestIds});
  return response;
});

export const splitRequests = createAsyncThunk("requests/splitRequests", async requestId => {
  const response = fetchData(`/requests/split/${requestId}`, "patch");
  return response;
});

export const deleteRequest = createAsyncThunk("requests/deleteRequest", async requestId => {
  const response = fetchData(`/requests/delete/${requestId}`, "delete");
  return response;
});

export const uploadPdf = createAsyncThunk("requests/uploadPdf", async ({requestId, formData}) => {
  const response = fetchData(`/requests/uploadPdf/${requestId}`, "put", formData);
  return response;
});

export const sendToFactory = createAsyncThunk("requests/sendToFactory", async ({requestId, data}) => {
  const response = fetchData(`/requests/sendToFactory/${requestId}`, "put", data);
  return response;
});
// addReadiness;

//SKU requests
export const fetchRequestSKUs = createAsyncThunk("requests/getRequestSKUs", async requestId => {
  const response = fetchList(`/skuRequest/getForRequest/${requestId}`, "get");
  return response;
});

export const transferSkuToRequest = createAsyncThunk("requests/transferSkuToRequest", async ({requestId, data}) => {
  const response = fetchList(`/skuRequest/transferToRequest/${requestId}`, "put", data);
  return response;
});

export const getSkuRequestInfo = createAsyncThunk("requests/getSkuRequestInfo", async skuRequestId => {
  const response = fetchList(`/skuRequest/get/${skuRequestId}`, "get");
  return response;
});

export const updateRequestSku = createAsyncThunk("requests/updateRequestSku", async ({skuRequestId, data}) => {
  const response = fetchData(`/skuRequest/update/${skuRequestId}`, "patch", {...data});
  return response;
});

export const reorderRequestSku = createAsyncThunk("requests/reorderRequestSku", async ({requestId, data}) => {
  const response = fetchData(`/skuRequest/reorder/${requestId}`, "patch", data);
  return response;
});

//// files
export const requestsAddFile = createAsyncThunk("requests/addFile", async ({requestId, formData}) => {
  const response = fetchData(`/requests/files/add/${requestId}`, "post", formData);
  return response;
});

//*** Request dates ***
//get
export const getAllRequestDates = createAsyncThunk("requests/getAllRequestDates", async requestId =>
  fetchList(`/requests/dates/getForRequest/${requestId}`, "get"),
);
export const getForReadiness = createAsyncThunk("requests/getForReadiness", async requestId =>
  fetchList(`/requests/getForReadiness/${requestId}`, "get"),
);

//post
export const addReadinessDate = createAsyncThunk("requests/addReadinessDate", async ({requestId, data}) =>
  fetchData(`/requests/dates/addReadiness/${requestId}`, "post", data),
);
//patch
export const confirmReadiness = createAsyncThunk("requests/confirmReadiness", async requestId =>
  fetchData(`/requests/dates/confirmReadiness/${requestId}`, "patch"),
);

//*** Invoices ***
//get
export const fetchInvoice = createAsyncThunk("requests/fetchInvoice", async invoiceId =>
  fetchData(`requests/invoices/get/${invoiceId}`, "get"),
);
//post
export const addInvoice = createAsyncThunk("requests/addInvoice", async ({requestId, formData}) =>
  fetchData(`requests/invoices/add/${requestId}`, "post", formData),
);

//*** Packing Lists ***
//get
export const fetchPackingList = createAsyncThunk("requests/fetchPackingList", async listId =>
  fetchData(`requests/packingLists/get/${listId}`, "get"),
);
//post
export const addPackingList = createAsyncThunk("requests/addPackingList", async ({requestId, formData}) =>
  fetchData(`requests/packingLists/add/${requestId}`, "post", formData),
);

//*** Packing Spaces ***
//get
export const fetchPackingSpace = createAsyncThunk("requests/fetchPackingSpace", async spaceId =>
  fetchData(`requests/packingSpaces/get/${spaceId}`, "get"),
);
//post
export const addPackingSpace = createAsyncThunk("requests/addPackingSpace", async ({requestId, data}) =>
  fetchData(`requests/packingSpaces/add/${requestId}`, "post", data),
);
//patch
export const updatePackingSpace = createAsyncThunk("requests/updatePackingSpace", async ({spaceId, data}) =>
  fetchData(`requests/packingSpaces/update/${spaceId}`, "patch", data),
);

const extraActions = [
  fetchStatuses,
  fetchRequests,
  fetchMixedRequests,
  fetchFilters,
  updateRequest,
  appointRequests,
  cancelAppointmentRequests,
  cancelAllAppointmentRequests,
  distributeRequests,
  turnIntoRequest,
  mergeRequests,
  splitRequests,
  deleteRequest,
  fetchRequestSKUs,
  transferSkuToRequest,
  getAllRequestDates,
  addReadinessDate,
  confirmReadiness,
  fetchInvoice,
  addInvoice,
  fetchPackingList,
  addPackingList,
  fetchPackingSpace,
  addPackingSpace,
  updatePackingSpace,
];
const getItems = type => extraActions.map(el => el[type]);

const namingLangSpecify = obj => {
  const lang = i18n.language;
  console.log();
  return (lang === "en" && obj ? obj?.persona?.engFirstName + " " + obj?.persona?.engLastName : obj?.name) || "";
};

const pullManagerCo = requestsRaw => {
  return requestsRaw.map(req => {
    const {cos, managerFea} = req;
    const managerCo = cos[0]?.managerCo;

    return {
      ...req,
      managerCo: {...managerCo, name: namingLangSpecify(managerCo)},
      managerFea: {...managerFea, name: namingLangSpecify(managerFea)},
    };
  });
};

const RequestSlice = createSlice({
  name: "requests",
  initialState,
  reducers: {
    setRequests: (state, action) => {
      state.requests = action.payload;
    },
    setFirstStatusRequests: (state, action) => {
      state.firstStatusRequests = action.payload;
    },
    setCurrent: (state, {payload}) => {
      state.current.currentRequestId = payload;
    },
    setCurrentNote: (state, {payload}) => {
      state.current.currentNote = payload;
    },
    clearNote: state => {
      state.current.currentNote = null;
    },
    setCurrentFourthStatusRequestId: (state, {payload}) => {
      state.current.currentFourthStatusRequestId = payload;
    },
    deselectCurrentOrder: state => {
      state.current.currentRequestId = null;
      state.current.currentRequestSKUs = [];
    },
    setCheckedRequestIds: (state, {payload}) => {
      state.current.checkedRequestIds = payload;
      if (payload?.length === 0) {
        state.current.currentNote = null;
      }
    },
    deselectCurrentRequestSku: (state, {payload}) => {
      state.currentRequestSku = null;
    },
    deselectCurrentRequest: (state, {payload}) => {
      state.currentRequestInfo = null;
      state.current.currentRequestSKUs = [];
    },

    // decelectCurrentRequestSku: (state, {payload}) => {
    //   state.currentRequestSku = null;
    // }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchStatuses.fulfilled, (state, action) => {
        state.orgStructures = action.payload;
      })

      .addCase(getRequest.fulfilled, (state, action) => {
        state.currentRequestInfo = action.payload;
        state.current.currentRequestSKUs = action.payload.skuByRequest;
      })

      .addCase(fetchMixedRequests.fulfilled, (state, {payload}) => {
        const {requests: requestsRaw} = payload;
        const requests = pullManagerCo(requestsRaw);
        state.requests = requests;
      })
      .addCase(fetchRequests.fulfilled, (state, action) => {
        state.requestsLoading = false;
        const {meta, payload} = action;
        const {identicalSku, requests: requestsRaw} = payload;
        const requests = pullManagerCo(requestsRaw);
        switch (meta.arg?.statusId) {
          case 1:
            state.firstStatusRequests = requests;
            break;
          case 2:
            state.appointRequests = requests;
            break;
          case 3:
            state.thirdStatusRequests = requests;
            break;
          case 4:
            state.fourthStatusRequests = requests;
            break;
          default:
            state.requests = requests;
        }
        if (meta.arg.showidenticalSku) {
          state.identicalSkus = identicalSku;
        }
      })
      .addCase(turnIntoRequest.fulfilled, (state, {payload}) => {
        state.fourthStatusRequests = pullManagerCo(payload);
        state.current.currentRequestId = null;
        state.current.currentRequestSKUs = [];
        state.current.currentNote = null;
        state.current.currentFourthStatusRequestId = null;
      })
      .addCase(mergeRequests.fulfilled, (state, {payload}) => {
        //TODO

        console.log("mergeRequests payload", payload);
      })
      .addCase(splitRequests.fulfilled, (state, action) => {
        //TODO
        const {payload, meta} = action;
        console.log("splitRequests payload", payload);
        state.splitedOrder.second = payload;
        const firstOrderNumber = state.thirdStatusRequests.find(req => req.requestId === meta.arg)?.requestNumber;
        state.splitedOrder.first = firstOrderNumber;
      })
      .addCase(fetchFilters.fulfilled, (state, action) => {
        state.filters = action.payload;
      })
      .addCase(appointRequests.fulfilled, (state, action) => {
        state.appointRequests = pullManagerCo(action.payload);
        state.firstStatusRequests = state.firstStatusRequests.filter(
          req => !action.meta.arg.data.requestIds.includes(req.requestId),
        );
      })
      .addCase(cancelAppointmentRequests.fulfilled, (state, action) => {
        state.appointRequests = pullManagerCo(action.payload);
      })
      .addCase(cancelAllAppointmentRequests.fulfilled, (state, action) => {
        state.appointRequests = [];
      })
      .addCase(distributeRequests.fulfilled, (state, action) => {
        state.appointRequests = [];
        getInfo("Замовлення успішно додано в обробку");
      })
      .addCase(fetchRequestSKUs.fulfilled, (state, {payload}) => {
        state.current.currentRequestSKUs = payload;
      })
      .addCase(deleteRequest.fulfilled, (state, action) => {
        state.thirdStatusRequests = state.thirdStatusRequests.filter(req => req.requestId !== action.meta.arg);
      })
      .addCase(transferSkuToRequest.fulfilled, (state, action) => {})
      .addCase(getSkuRequestInfo.fulfilled, (state, action) => {
        state.currentRequestSku = action.payload;
      })
      .addCase(updateRequestSku.fulfilled, (state, {payload}) => {
        state.currentRequestSku = payload.skuRequest;

        state.current.currentRequestSKUs = state.current.currentRequestSKUs.map(sku => {
          if (sku.skuRequestId === payload.skuRequest.skuRequestId) {
            return payload.skuRequest;
          }
          return sku;
        });
      })

      .addCase(uploadPdf.fulfilled, (state, {payload}) => {
        state.currentRequestInfo = payload.request;
      })
      .addCase(requestsAddFile.fulfilled, (state, {payload}) => {
        state.currentRequestInfo = {...state.currentRequestInfo, requestFiles: payload.requestFiles};
      })

      //*** Request Dates ***
      .addCase(getAllRequestDates.fulfilled, (state, {payload}) => {
        state.requestDates.allDatesByRequest = payload;
      })
      .addCase(addReadinessDate.fulfilled, (state, {payload}) => {
        const {readinessDate, readinessControlDate} = payload;

        const newReadinessDate = {
          id: readinessDate.dateId + readinessDate.plannedDate,
          readinessDate: readinessDate.plannedDate,
          actualReadinessDate: readinessDate.actualDate,
          readinessControlDate: readinessControlDate.plannedDate,
          note: readinessDate.note,
        };
        state.requestDates.datesWithPostponed = [...state.requestDates.datesWithPostponed, newReadinessDate];
      })
      .addCase(confirmReadiness.fulfilled, (state, {payload}) => {
        state.requestDates.confirmedDate = payload;
      })
      .addCase(getForReadiness.fulfilled, (state, {payload}) => {
        state.requestDates.datesWithPostponed = getForReadinessService(payload);
        state.requestFactoryData = payload.brand.factoryContacts;
        state.invoices.current = payload.invoice;
        state.packingLists.current = payload.packingList;
        state.packingSpaces = payload.packingSpaces;
      })

      //*** Invoices ***
      //get
      .addCase(fetchInvoice.fulfilled, (state, {payload}) => {
        state.invoices.current = payload;
      })
      //post
      .addCase(addInvoice.fulfilled, (state, {payload}) => {
        state.invoices.added = payload;
      })
      //*** Packing Lists ***
      //get
      .addCase(fetchPackingList.fulfilled, (state, {payload}) => {
        state.packingLists.current = payload;
      })
      //post
      .addCase(addPackingList.fulfilled, (state, {payload}) => {
        state.packingLists.added = payload;
      })
      //*** Packing Spaces ***
      //get
      .addCase(fetchPackingSpace.fulfilled, (state, {payload}) => {
        state.packingSpaces.current = payload;
      })
      //post
      .addCase(addPackingSpace.fulfilled, (state, {payload}) => {
        state.packingSpaces.push(payload);
      })
      //patch
      .addCase(updatePackingSpace.fulfilled, (state, {payload}) => {
        state.packingSpaces = state.packingSpaces.map(space => {
          if (space.spaceId === payload.spaceId) {
            return payload;
          }
          return space;
        });
      })

      //pending matcher
      .addMatcher(isAnyOf(...getItems("fulfilled")), state => handleFulfilled(state))
      //pending matcher
      .addMatcher(isAnyOf(...getItems("pending")), state => handlePending(state))
      //rejected matcher
      .addMatcher(isAnyOf(...getItems("rejected")), (state, {error}) => handleRejected(state, error))
      //default
      .addDefaultCase(() => {});
  },
});

function handleFulfilled(state) {
  state.requestsLoading = false;
  state.error = [];
}

function handlePending(state) {
  state.requestsLoading = true;
  state.error = [];
}

function handleRejected(state, error) {
  state.requestsLoading = false;
  state.error = error;
}

const {actions, reducer} = RequestSlice;

export const {
  setRequests,
  setFirstStatusRequests,
  setCurrent,
  setCurrentFourthStatusRequestId,
  deselectCurrentOrder,
  deselectCurrentRequestSku,
  setCurrentNote,
  clearNote,
  setCheckedRequestIds,
  deselectCurrentRequest,
} = actions;

export default reducer;

export const getRequestInfo = state => state.requests.currentRequestInfo;
export const getRequestSkuInfo = state => state.requests.currentRequestSku;
export const getRequestRegistrFilters = state => state.requests.filters;
export const getFirstStatusRequest = state => state.requests.firstStatusRequests;
export const getIdenticalSkus = state => state.requests.identicalSkus;
export const getThirdStatusRequests = state => state.requests.thirdStatusRequests;
export const getFourthStatusRequests = state => state.requests.fourthStatusRequests;
export const getAppointRequests = state => state.requests.appointRequests;
export const getAllStatusRequest = state => state.requests.requests;
export const getLoadingRequest = state => state.requests.requestsLoading;
export const getCurrentRequestId = state => state.requests.current.currentRequestId;
export const getCurrentRequestSKUs = state => state.requests.current.currentRequestSKUs;
export const getCurrentFourthStatusRequestId = state => state.requests.current.currentFourthStatusRequestId;
export const getCurrentNote = state => state.requests.current.currentNote;
export const getCheckedRequestIds = state => state.requests.current.checkedRequestIds;

export const getSplitedOrder = state => state.requests.splitedOrder;

//*** Request Dates ***
// export const selectAllRequestDates = state => state.requests.requestDates.allDatesByRequest;
export const selectReadinessDate = state => state.requests.requestDates.sheduledReadinessDate;
// export const selectConfirmedDate = state => state.requests.requestDates.confirmedDate;
export const selectdatesWithPostponed = state => state.requests.requestDates.datesWithPostponed;
export const selectFactoryData = state => state.requests.requestFactoryData;

//*** Invoices ***
export const selectCurrentInvoice = state => state.requests.invoices.current;
export const selectAddedInvoice = state => state.requests.invoices.added;
//*** Packing Lists ***
export const selectCurrentPackingList = state => state.requests.packingLists.current;
export const selectAddedPackingList = state => state.requests.packingLists.added;
//*** Packing Spaces ***
export const selectPackingSpace = state => state.requests.packingSpaces;
