import React from "react";
import {useTranslation} from "react-i18next";

import {InputWithMeasureUnits, NumberInput, TextInput} from "../../Inputs";
import {Select} from "../../Inputs/SelectField";
import useSkuCatalogsService from "../SkuCatalogsService";

import PalletSelect from "./PalletSelect";

const useVWCharInputsService = () => {
  const {
    measurementUnitsList,
    measurementObjectsList,
    lengthMeasurementQuantites,
    areaMeasurementQuantites,
    volumeMeasurementQuantites,
    weightMeasurementQuantites,
    palletsList,
  } = useSkuCatalogsService();

  const {t} = useTranslation();

  const dimension = (
    <InputWithMeasureUnits
      name={"overallSizeUnitId"}
      measureUnitsList={lengthMeasurementQuantites}
      coef={"dimensionCoef"}
      label={t("Dimension")}
    >
      <NumberInput label={t("Length")} name="length" />
      <NumberInput label={t("Width")} name="width" />
      <NumberInput label={t("Height")} name="height" />
    </InputWithMeasureUnits>
  );

  const area = (
    <InputWithMeasureUnits
      name="areaUnitId"
      label={t("Area")}
      measureUnitsList={areaMeasurementQuantites}
      coef={"areaCoef"}
    >
      <NumberInput name="area" width="15em" />
    </InputWithMeasureUnits>
  );

  const volume = (
    <InputWithMeasureUnits
      name="volumeUnitId"
      label={t("Volume")}
      measureUnitsList={volumeMeasurementQuantites}
      coef={"volumeCoef"}
    >
      <NumberInput name="volume" width="15em" />
    </InputWithMeasureUnits>
  );

  const weight = (
    <InputWithMeasureUnits
      name="weightUnitId"
      label={t("Weight")}
      measureUnitsList={weightMeasurementQuantites}
      coef={"volumeWeightCoef"}
    >
      <NumberInput name="weight" width="15em" />
    </InputWithMeasureUnits>
  );

  const skuMeasurementUnit = (
    <Select label={t("Measurement units")} name="unitId" options={measurementUnitsList} width="5em" />
  );

  const quantity = (
    <div className="fc jcfs autolabel aist" style={{gap: "5px"}}>
      <Select label={t("Quantity")} name="objectId" width="10em" options={measurementObjectsList} />
      <TextInput name="amount" width="5em" />
    </div>
  );

  const pallet = <PalletSelect pallets={palletsList} />;

  return {
    dimension,
    area,
    volume,
    weight,
    skuMeasurementUnit,
    quantity,
    pallet,
  };
};

export default useVWCharInputsService;
