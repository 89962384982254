export const REQ_AUTODISTRIBUTION_TABLE_COLUMNS = Object.freeze([
  {
    header: "№ nn",
    accessorKey: "number",
    size: 15,
  },
  {
    header: "Factory",
    accessorKey: "factory",
    size: 15,
  },
  {
    header: "Note",
    accessorKey: "note",
    size: 15,
  },
]);

export const FACTORY_LIST_TABLE_COLUMNS = Object.freeze([
  {
    header: "№ nn",
    accessorKey: "index",
    size: 15,
  },
  {
    header: "Factory",
    accessorKey: "name",
    size: 15,
  },
  {
    header: "Country",
    accessorKey: "country",
    size: 15,
  },
  {
    header: "Discount",
    accessorKey: "discount",
    size: 15,
  },
  {
    header: "Contact person",
    accessorKey: "contactPerson",
    size: 15,
  },
  {
    header: "e-mail",
    accessorKey: "email",
    size: 15,
  },
  {
    header: "Phone",
    accessorKey: "phone",
    size: 15,
  },
  {
    header: "Timeframe",
    accessorKey: "terms",
    size: 15,
  },
  {
    header: "Conditions",
    accessorKey: "conditions",
    size: 15,
  },
  {
    header: "Notes",
    accessorKey: "notes",
    size: 15,
  },
  {
    header: "ToDo",
    accessorKey: "toDo",
    size: 15,
  },
]);
