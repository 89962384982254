import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import PropTypes from "prop-types";

import {
  addCondition,
  addContact,
  addFactory,
  cancelFactoryCreation,
  cancelFactoryUpdate,
  deleteCondition,
  deleteContact,
  fetchAllConditions,
  fetchFactory,
  fetchFactoryConditions,
  fetchFilters,
  fetchReferences,
  saveFactory,
  updateCondition,
  updateContact,
  updateFactory,
} from "../../../../redux/operations/FactoryOperations";
import {
  selectAreReferencesFetched,
  selectCurrentCondition,
  selectCurrentConditionId,
  selectCurrentContact,
  selectCurrentContactId,
  selectCurrentFactory,
  selectCurrentFactoryId,
  selectFactories,
  selectFactoryContactConditions,
  selectNewCondition,
  selectNewContact,
} from "../../../../redux/selectors/FactorySelectors";
import {factoryCleanCurrent} from "../../../../redux/slices/FactorySlice";
import {FILTER_STORAGE_NAME} from "../../../conditions&contactsFactory/utils/constants";
import {
  checkIfConditionValuesChanged,
  checkIfFactoryAddressValuesChanged,
} from "../../../factoryModalParts/utils/functions/checkIfValuesChanged";
import {
  cleanFactoryIdSessionStorage,
  setFactoryIdSessionStorage,
} from "../../../factoryModalParts/utils/functions/factoryIdSessionStorage";
import formNotes from "../../../factoryModalParts/utils/functions/formNotes";
import useSwalDelete from "../../../factoryModalParts/utils/hooks/getSwalDeleteService";
import {FACTORY_MODAL_TYPES} from "../constants";

const useFactoryService = ({modalType, setModalActive = () => {}}) => {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const swalDelete = useSwalDelete();

  //state
  const [isAddFactoryBtnDisabled, setIsAddFactoryBtnDisabled] = useState(false);
  const [currentCommonCondition, setCurrentCommonCondition] = useState(null);

  //getting redux state
  const allFactories = useSelector(selectFactories);
  const newCondition = useSelector(selectNewCondition);
  const currentCondition = useSelector(selectCurrentCondition);
  const currentConditionId = useSelector(selectCurrentConditionId);
  const currentContact = useSelector(selectCurrentContact);

  const newContact = useSelector(selectNewContact);
  const currentContactId = useSelector(selectCurrentContactId);
  const currentFactory = useSelector(selectCurrentFactory);
  const currentFactoryId = useSelector(selectCurrentFactoryId);
  const users = useSelector(state => state.person.users);
  const factoryContactConditions = useSelector(selectFactoryContactConditions);

  const currentEmailContact = currentFactory?.contacts?.find(contact => contact.contactId === currentContactId) ?? null;

  useEffect(() => {
    if (modalType === FACTORY_MODAL_TYPES.CREATE_FACTORY) {
      dispatch(factoryCleanCurrent());
    }
    if (modalType !== FACTORY_MODAL_TYPES.CREATE_FACTORY) {
      dispatch(fetchFactory(currentFactoryId));
    }
  }, []);

  const areReferencesFetched = useSelector(selectAreReferencesFetched);

  useEffect(() => {
    if (!areReferencesFetched) {
      dispatch(fetchReferences());
    }
  }, [areReferencesFetched]);

  const isNoCurrentFactory = !currentFactory;
  const isNoCurrentContact = !newContact;
  const isNoCurrentCondition = !newCondition;

  const areFactoryContactsDisabled = isNoCurrentFactory;
  const areFactoryConditionsDisabled = isNoCurrentFactory || isNoCurrentContact;
  const isSaveFactoryDisabled = isNoCurrentFactory || isNoCurrentContact || isNoCurrentCondition;

  //initial values
  //factory address section
  const countryId = currentFactory ? currentFactory.countryId : "";
  const factoryName = currentFactory ? currentFactory.name : "";
  const address = currentFactory ? currentFactory.address : "";
  const location = currentFactory ? currentFactory.location : "";
  const webSite = currentFactory ? currentFactory.webSite : "";
  const addressNotes = currentFactory ? formNotes(currentFactory.notes) : "";
  //contacts section
  const email = currentEmailContact
    ? currentEmailContact.email
    : newContact
    ? newContact.email
    : currentContact
    ? currentContact.email
    : "";
  const contactName = currentEmailContact
    ? currentEmailContact.name
    : newContact
    ? newContact.name
    : currentContact
    ? currentContact.name
    : "";
  const contactTypeId = currentEmailContact
    ? currentEmailContact.contactTypeId
    : newContact
    ? newContact.contactTypeId
    : currentContact
    ? currentContact.contactTypeId
    : "";
  const phone = currentEmailContact
    ? currentEmailContact.phone
    : newContact
    ? newContact.phone
    : currentContact
    ? currentContact.phone
    : "";
  const contactNotes = newContact
    ? formNotes(newContact.notes)
    : currentEmailContact
    ? formNotes(currentEmailContact.notes)
    : currentContact
    ? formNotes(currentContact.notes)
    : "";
  //conditions section
  const discount = currentCondition ? currentCondition.discount : newCondition ? newCondition.discount : "";
  const discountNote = currentCondition ? currentCondition.discountNote : newCondition ? newCondition.discountNote : "";
  const termsNote = currentCondition ? currentCondition.termsNote : newCondition ? newCondition.termsNote : "";
  const termsCondition = currentCondition
    ? currentCondition.termsCondition
    : newCondition
    ? newCondition.termsCondition
    : "";
  const unitId = currentCondition ? currentCondition.unitId : newCondition ? newCondition.unitId : "";
  const termsFrom = currentCondition ? currentCondition.termsFrom : newCondition ? newCondition.termsFrom : "";
  const termsTo = currentCondition ? currentCondition.termsTo : newCondition ? newCondition.termsTo : "";

  let initialValues = {
    countryId,
    factoryName,
    address,
    location,
    webSite,
    newAddressNotes: "",
    addressNotes,
    email,
    contactName,
    contactTypeId,
    phone,
    newContactNotes: "",
    contactNotes,
    discount,
    discountNote,
    termsNote,
    termsCondition,
    unitId,
    termsFrom,
    termsTo,
  };

  const creator = currentCommonCondition
    ? users?.find(user => user.userId === currentCommonCondition.creatorId)?.name
    : "";
  const modifier = currentCommonCondition
    ? users?.find(user => user.userId === currentCommonCondition.modifierId)?.name
    : "";

  if (modalType === FACTORY_MODAL_TYPES.EDIT_FACTORY) {
    initialValues = {
      ...initialValues,
      createdAt: currentCommonCondition ? currentCommonCondition.createdAt : "",
      updatedAt: currentCommonCondition ? currentCommonCondition.updatedAt : "",
      creator,
      modifier,
    };
  }

  const isFactoryNew = name => !allFactories?.find(factory => factory.name === name);

  function validate(values) {
    const errors = {};
    if (!isFactoryNew(values.factoryName)) {
      errors.factoryName = t("The factory already exists") + "!";
      setIsAddFactoryBtnDisabled(true);
    }
    if (isFactoryNew(values.factoryName)) {
      setIsAddFactoryBtnDisabled(false);
    }
    return errors;
  }

  function onAddFactoryBtnClick({countryId, factoryName: name, address, location, webSite}) {
    const data = {
      countryId,
      name,
      address,
      location,
      webSite,
    };
    dispatch(addFactory(data)).then(response => {
      if (response.meta.requestStatus === "fulfilled") {
        toast.success(t("Factory added successfully!"));
        setFactoryIdSessionStorage(currentFactoryId, modalType);
      }
    });
  }

  function onAddContactBtnClick({contactTypeId, email, contactName: name, phone}) {
    const data = {
      contactTypeId,
      email,
      name,
      phone,
    };
    if (isNoCurrentFactory) return;

    dispatch(addContact(data)).then(response => {
      if (response.meta.requestStatus === "fulfilled") {
        toast.success(t("Contact added successfully!"));
        setFactoryIdSessionStorage(currentFactoryId, modalType);
      }
    });
  }

  function checkIfConditionChanged(formikCondition, stateCondition) {
    const formikConditionKeys = Object.keys(formikCondition);
    for (let i = 0; i < formikConditionKeys.length; i++) {
      const key = formikConditionKeys[i];
      if (formikCondition[key] != stateCondition[key]) return true;
    }
    return false;
  }

  function onAddConditionBtnClick({discount, discountNote, termsNote, termsCondition}) {
    const data = {
      discount,
      discountNote,
      termsFrom,
      termsTo,
      unitId: unitId ? unitId : 19,
      termsNote,
      termsCondition,
    };

    if (!checkIfConditionChanged({discount, discountNote, termsNote, termsCondition}, currentCondition)) {
      toast.error(t("Such condition already exists!"));
      return;
    }

    if (
      modalType === FACTORY_MODAL_TYPES.EDIT_FACTORY &&
      !checkIfConditionChanged({discount, discountNote, termsNote, termsCondition}, currentCondition)
    ) {
      toast.error(t("Such condition already exists!"));
      return;
    }

    if (
      !currentFactory ||
      (modalType === FACTORY_MODAL_TYPES.CREATE_FACTORY && !newContact) ||
      (modalType === FACTORY_MODAL_TYPES.CREATE_CONDITIONS && !currentContactId)
    )
      return;
    dispatch(
      addCondition({factoryId: currentFactory.factoryId, contactId: newContact?.contactId ?? currentContactId, data}),
    ).then(response => {
      if (response.meta.requestStatus === "fulfilled") {
        toast.success(t("Condition added successfully!"));
        setFactoryIdSessionStorage(currentFactoryId, modalType);
      }
    });
  }

  function onSaveFactoryBtnClick() {
    const savedFilters = JSON.parse(sessionStorage.getItem(FILTER_STORAGE_NAME));
    if (!currentFactory || !currentContactId || !currentConditionId) return;
    dispatch(saveFactory(currentFactory.factoryId)).then(response => {
      if (response.meta.requestStatus === "fulfilled") {
        toast.success(t("Factory") + ` ${response.payload.name} ` + t("created"));
        dispatch(fetchAllConditions(savedFilters));
        dispatch(fetchFilters());
        cleanFactoryIdSessionStorage();
      }
    });
    setModalActive(false);
  }

  const modalCancelActions = {
    [FACTORY_MODAL_TYPES.CREATE_FACTORY]: currentFactoryId => cancelFactoryCreation(currentFactoryId),
    [FACTORY_MODAL_TYPES.CREATE_CONDITIONS]: currentFactoryId => cancelFactoryUpdate(currentFactoryId),
    [FACTORY_MODAL_TYPES.EDIT_FACTORY]: () => {},
  };

  function onCancelBtnClick() {
    const savedFilters = JSON.parse(sessionStorage.getItem(FILTER_STORAGE_NAME));
    setModalActive(false);
    dispatch(fetchAllConditions(savedFilters));
    if (!currentFactoryId || modalType === FACTORY_MODAL_TYPES.EDIT_FACTORY) return;

    dispatch(modalCancelActions[modalType](currentFactoryId)).then(response => {
      if (response.meta.requestStatus === "fulfilled") {
        toast.success("Factory changed canceled");
        cleanFactoryIdSessionStorage();
      }
    });
  }

  //***edit factory conditions modal***
  const areConditionsButtonsDisabled = !currentConditionId;

  function calcIfUpdateConditionsButtonDisabled(values) {
    return areConditionsButtonsDisabled || !checkIfConditionValuesChanged(values, currentCondition);
  }

  function calcIfUpdateFactoryAddressBtnDisabled(values) {
    return !currentFactoryId || !checkIfFactoryAddressValuesChanged(values, currentFactory);
  }

  function onUpdateFactoryBtnClick({address, location, webSite}) {
    dispatch(updateFactory({factoryId: currentFactoryId, data: {address, location, webSite}})).then(response => {
      if (response.meta.requestStatus === "fulfilled") {
        toast.success(t("Factory updated"));
      }
    });
  }

  function onUpdateContactBtnClick({contactTypeId, email, contactName, phone}) {
    dispatch(updateContact({contactId: currentContactId, data: {contactTypeId, email, name: contactName, phone}})).then(
      response => {
        if (response.meta.requestStatus === "fulfilled") {
          toast.success(t("Factory Contact updated"));
        }
      },
    );
  }

  function onDeleteContactBtnClick() {
    if (factoryContactConditions && factoryContactConditions.length > 0) {
      toast.error(t("contact can`t be deleted"));
      return;
    }
    swalDelete(() => {
      dispatch(deleteContact(currentContactId)).then(response => {
        if (response.meta.requestStatus === "fulfilled") {
          toast.success(t("Factory Contact deleted"));
          dispatch(fetchFactoryConditions(currentFactoryId));
        }
      });
    });
  }

  function onUpdateConditionBtnClick({discount, discountNote, termsFrom, termsTo, unitId, termsNote, termsCondition}) {
    dispatch(
      updateCondition({
        conditionId: currentConditionId,
        data: {discount, discountNote, termsFrom, termsTo, unitId, termsNote, termsCondition},
      }),
    ).then(response => {
      if (response.meta.requestStatus === "fulfilled") {
        toast.success(t("Factory Condition updated"));
      }
    });
  }

  function onDeleteConditionBtnClick() {
    swalDelete(() => {
      dispatch(deleteCondition(currentConditionId)).then(response => {
        if (response.meta.requestStatus === "fulfilled") {
          toast.success(t("Factory Condition deleted"));
          dispatch(fetchFactoryConditions(currentFactoryId));
        }
      });
    });
  }

  function onUpdateFactoryModalChangesBtnClick() {
    toast.success(t("Factory updates saved"));
    const savedFilters = JSON.parse(sessionStorage.getItem(FILTER_STORAGE_NAME));
    dispatch(fetchAllConditions(savedFilters));
    cleanFactoryIdSessionStorage();
    setModalActive(false);
  }

  return {
    onAddFactoryBtnClick,
    onAddContactBtnClick,
    onAddConditionBtnClick,
    onSaveFactoryBtnClick,
    areFactoryContactsDisabled,
    areFactoryConditionsDisabled,
    isSaveFactoryDisabled,
    initialValues,
    validate,
    isAddFactoryBtnDisabled,
    currentCommonCondition,
    setCurrentCommonCondition,
    onCancelBtnClick,
    onUpdateFactoryBtnClick,
    onUpdateContactBtnClick,
    onDeleteContactBtnClick,
    onUpdateConditionBtnClick,
    onDeleteConditionBtnClick,
    onUpdateFactoryModalChangesBtnClick,
    calcIfUpdateConditionsButtonDisabled,
    areConditionsButtonsDisabled,
    calcIfUpdateFactoryAddressBtnDisabled,
  };
};

useFactoryService.propTypes = {
  modalType: PropTypes.oneOf(Object.values(FACTORY_MODAL_TYPES)),
  setModalActive: PropTypes.func,
};

export default useFactoryService;
