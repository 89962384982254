import React from "react";
import PropTypes from "prop-types";

import downIcon from "../../images/icons/Caret_Circle_Down.png";
import upIcon from "../../images/icons/Caret_Circle_Up.png";

import ImageButton from "./ImageBtn";

const UpDownButtons = ({onUpClick, onDownClick}) => {
  return (
    <div className="fc nowrap" style={{width: "6em"}}>
      <ImageButton src={downIcon} tooltipMessage="distrib to mansgers" onClick={onDownClick} size={2} />
      <ImageButton src={upIcon} onClick={onUpClick} size={2} />
    </div>
  );
};

UpDownButtons.propTypes = {
  onUpClick: PropTypes.func,
  onDownClick: PropTypes.func,
};

export default UpDownButtons;
