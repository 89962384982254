import {useDispatch, useSelector} from "react-redux";

import useColumns from "../../../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {selectCurrentContactId, selectCurrentFactoryContacts} from "../../../../redux/selectors/FactorySelectors";
import {cleanFactoryContactConditions, setCurrentContactId} from "../../../../redux/slices/FactorySlice";
import {NEW_CONTACTS_COLUMNS} from "../../../conditions&contactsFactory/utils/constants";

import useContactTypesOptionsService from "./getContactTypesService";

const useContactsAddService = () => {
  const dispatch = useDispatch();

  const currentFactoryContacts = useSelector(selectCurrentFactoryContacts);
  const currentContactId = useSelector(selectCurrentContactId);

  const columns = useColumns(NEW_CONTACTS_COLUMNS);
  const contactTypeOptions = useContactTypesOptionsService();
  const current = currentContactId;

  const contactsTableData = currentFactoryContacts.map(({contactId, phone, email}, index) => ({
    id: contactId,
    index: ++index,
    phone,
    email,
  }));

  function onContactsTableRowSelect(id) {
    if (id === currentContactId) {
      dispatch(setCurrentContactId(null));
      dispatch(cleanFactoryContactConditions());

      return;
    }

    dispatch(setCurrentContactId(id));
  }

  return {columns, contactTypeOptions, contactsTableData, onContactsTableRowSelect, current};
};

export default useContactsAddService;
