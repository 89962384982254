// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.imgBox {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.imgBox__container {
  position: relative;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.imgBox__container .carousel img {
  width: 100%;
}
.imgBox__control-bar {
  position: absolute;
  bottom: 2px;
  width: 100%;
}
.imgBox__label {
  width: 100%;
  text-align: center;
  line-height: 1.6em;
  text-decoration: underline;
}

.img-control-bar {
  display: flex;
  justify-content: center;
}
.img-control-bar img:hover {
  border-radius: 50%;
  box-sizing: border-box;
  box-shadow: inset 0 -3em 3em rgba(255, 255, 255, 0.499), 0 0 0 1px rgb(255, 255, 255), 0.3em 0.3em 1em rgba(255, 255, 255, 0.3);
}

.carousel img {
  object-fit: cover;
}
.carousel__img_container {
  width: 230px;
}

#next-page {
  margin-left: 10px;
}`, "",{"version":3,"sources":["webpack://./src/Components/Inputs/fileSelect/ImgBox/ImgBox.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,qBAAA;EACA,eAAA;EACA,WAAA;EACA,YAAA;EACA,gBAAA;AACF;AAAE;EACE,kBAAA;EACA,uBAAA;EACA,aAAA;EACA,sBAAA;EACA,8BAAA;EACA,WAAA;EACA,YAAA;EACA,gBAAA;AAEJ;AAGM;EACE,WAAA;AADR;AAKE;EACE,kBAAA;EACA,WAAA;EACA,WAAA;AAHJ;AAKE;EACE,WAAA;EACA,kBAAA;EACA,kBAAA;EACA,0BAAA;AAHJ;;AAMA;EACE,aAAA;EACA,uBAAA;AAHF;AAIE;EACE,kBAAA;EACA,sBAAA;EACA,+HAAA;AAFJ;;AAOE;EAGE,iBAAA;AANJ;AAUE;EACE,YAAA;AARJ;;AAYA;EACE,iBAAA;AATF","sourcesContent":[".imgBox {\n  display: flex;\n  align-items: flex-end;\n  flex-wrap: wrap;\n  width: 100%;\n  height: 100%;\n  overflow: hidden;\n  &__container {\n    position: relative;\n    background-color: white;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    width: 100%;\n    height: 100%;\n    overflow: hidden;\n\n    // height: 100%;\n    // width: auto;\n    .carousel {\n      img {\n        width: 100%;\n      }\n    }\n  }\n  &__control-bar {\n    position: absolute;\n    bottom: 2px;\n    width: 100%;\n  }\n  &__label {\n    width: 100%;\n    text-align: center;\n    line-height: 1.6em;\n    text-decoration: underline;\n  }\n}\n.img-control-bar {\n  display: flex;\n  justify-content: center;\n  img:hover {\n    border-radius: 50%;\n    box-sizing: border-box;\n    box-shadow: inset 0 -3em 3em rgba(255, 255, 255, 0.499), 0 0 0 1px rgb(255, 255, 255),\n      0.3em 0.3em 1em rgba(255, 255, 255, 0.3);\n  }\n}\n.carousel {\n  img {\n    // width: auto;\n    // height: 100%;\n    object-fit: cover;\n    // width: AUTO;\n    // height: 100%;\n  }\n  &__img_container {\n    width: 230px;\n  }\n}\n\n#next-page {\n  margin-left: 10px;\n}\n\n@media screen and (min-width: 1905px) {\n  .imgBox__container {\n    // width: 15%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
