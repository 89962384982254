import {useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {Form, Formik} from "formik";

import {apiUrl} from "../../../../../api/baseURL";
import FunctionButton from "../../../../../utilities/Buttons/FunctionButton";
import DateStatusCircle from "../../../../../utilities/DateStatusCircle";
import QuestionItem from "../../../../../utilities/QuestionItem";
import {getInfo} from "../../../../../utilities/toasts";
import ToolTip from "../../../../../utilities/ToolTip";
import ReactTable from "../../../../conteiners/ReactTable";
import {TextAreaInput, TextInput} from "../../../../Inputs";
import SingleFilePicker from "../../../../Inputs/SingleFilePicker";
import NewPdfViewer from "../../../../Project/Documents/DocumentView/NewPdfViewer";
import useColumns from "../../../OrderProcessing/utils/hooks/useColumns";
import {
  approveProform,
  declineProform,
  fetchForRequestById,
  sendProformForControl,
  sendProformToFactory,
} from "../../../Proform/redux/operations/ProformOperations";
import {getRequestInfo} from "../../../RequestsSlice";

import {
  CONTROL_DETAILS_TABLE_COLUMNS,
  CONTROL_TABLE_COLUMNS,
  HISTORY_PF_TABLE_COLUMNS,
  STATUS_CONTROL_TABLE_COLUMNS,
} from "./utils/constants";
import getApprovalDates from "./utils/helpers/getApprovalDates";
import useControlDetailsPfTableData from "./utils/hooks/getControlDetailsPfTableDataService";
import useControlPfTableData from "./utils/hooks/getControlPfTableDataService";
import useForRequestService from "./utils/hooks/getForRequestService";
import useHistoryPfTableData from "./utils/hooks/getHistoryPfTableDataService";
import usePfOperationsModalFilePickerFunctions from "./utils/hooks/getPfOperationsModalFilePickerService";
import getStatusControlTableData from "./utils/hooks/getStatusControlTableData";

import styles from "./PfOperationsModal.module.scss";

const PfOperationsModal = ({setModalActive}) => {
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const navigate = useNavigate();

  const fetchProforma = () => {
    dispatch(fetchForRequestById(currentRequest.requestId));
  };

  // useEffect(() => {
  //   return () => {
  //     dispatch(getRequest(currentRequest.requestId));
  //   };
  // }, []);

  //REDUX STATE
  const currentRequest = useSelector(getRequestInfo);

  //DATA
  const forRequest = useForRequestService();

  const forRequestProforma = forRequest?.request?.proforms[0];

  const declinedProforms = forRequest?.declinedProforms ?? [];

  const statusControlTableData = getStatusControlTableData(forRequestProforma);

  const [currentApprovalId, setCurrentApprovalId] = useState(null);

  const currentApproval = forRequestProforma?.approvals?.find(apr => apr.approvalId === currentApprovalId);

  const currentApprovalReasons = useMemo(() => {
    if (!currentApprovalId) return [];

    return forRequestProforma?.approvals?.find(apr => apr.approvalId === currentApprovalId)?.disapprovalReasons || [];
  }, [currentApprovalId]);

  const pdfFileUrlArr =
    forRequestProforma && forRequestProforma.pdfFileUrl?.length > 0 ? [forRequestProforma.pdfFileUrl] : [];
  ``;
  const requestDates = forRequest?.request.requestDates ?? [];
  const initReceiptDates = requestDates?.find(date => date.dateType.nameEng === "Getting a proforma");

  const {controlPlannedPfDate, controlActualPfDate} = getApprovalDates(forRequestProforma, currentApprovalId);

  //controlPfTable data
  const controlPfTableColumns = useColumns(CONTROL_TABLE_COLUMNS);
  const controlPfTableData = useControlPfTableData(forRequestProforma) ?? [];

  //controlDetailsPfTable data
  const controlDetailsPfTableColumns = useColumns(CONTROL_DETAILS_TABLE_COLUMNS);
  const statusControlPfTableColumns = useColumns(STATUS_CONTROL_TABLE_COLUMNS);

  const controlDetailsPfTableData = useControlDetailsPfTableData(currentApprovalReasons) ?? [];

  //historyPfTable data
  const historyPfTableColumns = useColumns(HISTORY_PF_TABLE_COLUMNS);
  const historyPfTableData = useHistoryPfTableData(declinedProforms, forRequestProforma?.proformaId) ?? [];

  //FilePreview functions
  const {onFileAdding} = usePfOperationsModalFilePickerFunctions();

  //EVENT HANDLERS
  const onPfOperationsFormSubmit = () => {};
  //add proforma to control
  const onAddPfToControlBtnClick = noteForManagerKP => {
    dispatch(sendProformForControl({proformaId: forRequestProforma?.proformaId, data: noteForManagerKP})).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        fetchProforma();
      }
    });
  };
  //PF not agreed btn
  const onPfNotAgreedBtnClick = () => {
    dispatch(declineProform(forRequestProforma?.proformaId)).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        fetchProforma();
        getInfo("Проформу не погоджено");
      }
    });
  };
  //PF agreed btn
  const onPfAgreedBtnClick = () => {
    dispatch(approveProform(forRequestProforma?.proformaId)).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        fetchProforma();
        getInfo("Проформу погоджено");
      }
    });
  };

  // const declineBtnDisabled = true;

  const agreedBtnDisabled = forRequestProforma?.approvals?.reduce((accumulator, approval) => {
    return accumulator && (approval?.isDeclined || approval?.isDeclined === null);
  }, true);

  const downloadFile = fileUrl => {
    const url = `${apiUrl}/${fileUrl}`,
      filename = forRequestProforma?.proformaNumber + "_pf";

    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        link.click();
      })
      .catch(console.error);
  };

  const onSendToFactory = () => {
    dispatch(sendProformToFactory(forRequestProforma.proformaId)).then(resp => {
      if (resp.meta.requestStatus === "fulfilled") {
        downloadFile(resp.payload);
        getInfo("Проформу выдправлено на фабрику");
        setTimeout(() => {
          setModalActive(false);
        }, 1000);
      }
    });
  };

  const onAprovSelect = id => {
    if (currentApprovalId === id) {
      setCurrentApprovalId(null);
      return;
    }
    setCurrentApprovalId(id);
  };

  //initiial values
  const initialValues = {
    receiptPlannedPfDate: initReceiptDates?.plannedDate ?? "",
    receiptActualPfDate: initReceiptDates?.actualDate ?? "",
    pfNumber: forRequestProforma?.proformaNumber ?? "",
    noteForManagerKP: "",
    controlPlannedPfDate,
    controlActualPfDate,
    managerFeaNote: currentApproval?.note ?? t("No note from manager FEA"),
  };

  return (
    <Formik initialValues={initialValues} enableReinitialize onSubmit={onPfOperationsFormSubmit}>
      {({values}) => (
        <Form className={`${styles.modalContainer} autolabel`}>
          <div className={styles.leftColumn}>
            <section className={styles.acquiringSection}>
              <h2 className={styles.sectionHeader}>
                1. <span className={styles.underLined}>{t("Pf Acquiring") + ":"}</span>
              </h2>
              <div className={styles.acquiringSectionFirstRow}>
                <div className={styles.sectionFlexStartRow}>
                  <DateStatusCircle plannedDate={values.receiptPlannedPfDate} />
                  <TextInput name="receiptPlannedPfDate" label={t("Date: Pf receipt (p):")} readOnly />
                </div>

                <TextInput name="receiptActualPfDate" question="upld pf" label={t("Date: Pf receipt (f):")} readOnly />
              </div>

              <div className={styles.acquiringSectionSecondRow}>
                <TextInput
                  label={t("Pf №") + ":"}
                  width="15em"
                  name="pfNumber"
                  question="num pf"
                  disabled={forRequestProforma?.proformaNumber.length > 0}
                />
                <ToolTip title={t("scan upload")}>
                  <SingleFilePicker
                    disabled={forRequestProforma || values.pfNumber?.length === 0}
                    addFunc={file => onFileAdding(file, values)}
                    accept={".pdf"}
                  />
                </ToolTip>
              </div>
              <ReactTable
                // className={styles.controlPFTableWrapper}
                // tableClassName={styles.controlDetailsPFTable}
                style={{height: "7em"}}
                columns={statusControlPfTableColumns}
                defaultData={statusControlTableData}
                current={null}
              />
              <TextAreaInput
                name="noteForManagerKP"
                className={styles.noteForManagerKP}
                width="101%"
                height="50px"
                placeholder={t("Enter your note for manager CO")}
              />
              <FunctionButton
                type="button"
                className={styles.addPfToControlBtn}
                name="Add Pf to control"
                onClick={() => onAddPfToControlBtnClick(values.noteForManagerKP)}
                autoWidth
                disabled={!forRequestProforma}
              />
            </section>
            <section className={styles.controlSection}>
              <h2 className={styles.sectionHeader}>
                2. <span className={styles.underLined}>{t("Pf Control") + ":"}</span>
              </h2>
              <div className={styles.sectionRow}>
                <div className={styles.sectionFlexStartRow}>
                  <DateStatusCircle plannedDate={values.controlPlannedPfDate} />
                  <TextInput name="controlPlannedPfDate" label={t("Date: Pf control (p):")} readOnly />
                </div>

                <TextInput name="controlActualPfDate" question="approval" label={t("Date: Pf control (f):")} readOnly />
              </div>
              <h3 className={styles.sectionHeader}>
                <QuestionItem title="info unapproved" />
                {t("Pf approval") + ":"}
              </h3>
              <ReactTable
                className={styles.controlPFTableWrapper}
                tableClassName={styles.controlPFTable}
                columns={controlPfTableColumns}
                defaultData={controlPfTableData}
                current={null}
                onSelect={onAprovSelect}
              />
              <ReactTable
                className={styles.controlPFTableWrapper}
                tableClassName={styles.controlDetailsPFTable}
                columns={controlDetailsPfTableColumns}
                defaultData={controlDetailsPfTableData}
                current={null}
              />
              <div className={styles.sectionRow}>
                <FunctionButton name="Pf not agreed" width="12em" onClick={onPfNotAgreedBtnClick} />
                <FunctionButton
                  name="Pf agreed"
                  width="12em"
                  disabled={agreedBtnDisabled}
                  onClick={onPfAgreedBtnClick}
                />
              </div>
            </section>
            <section className={styles.historySection}>
              <h2 className={styles.sectionHeader}>
                3. <span className={styles.underLined}>{t("History of Pf NOT consent") + ":"}</span>
              </h2>
              <ReactTable
                className={styles.historyTableWrapper}
                tableClassName={styles.historyTable}
                columns={historyPfTableColumns}
                defaultData={historyPfTableData}
                // current={null}
              />
            </section>
          </div>
          <div className={styles.rightColumn}>
            <TextAreaInput className={styles.noteArea} name="managerFeaNote" width="100%" height="50px" disabled />
            {/* <div className={styles.pdfWrapper}> */}
            <div className="kpPreview__box" style={{height: "100%"}}>
              {forRequestProforma && <NewPdfViewer pfShow={true} fileArray={pdfFileUrlArr} />}
            </div>
            <div className={styles.sectionRow}>
              <FunctionButton
                name="open pf wind"
                autoWidth
                onClick={() => {
                  navigate("/ba3a/kp/control");
                }}
                disabled={!forRequestProforma}
              />
              <FunctionButton
                className={styles.createPDFBtn}
                type="submit"
                tooltipMessage="select folder"
                name="Create PDF to Factory"
                width={"15em"}
                onClick={onSendToFactory}
                disabled={!forRequestProforma || !forRequestProforma.isApproved}
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default PfOperationsModal;
