import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import QuestionItem from "../../../../../utilities/QuestionItem";
import ReactTable from "../../../../conteiners/ReactTable";
import useColumns from "../../../OrderProcessing/utils/hooks/useColumns";

import {getCurrentDocument} from "./redux/PaymentSelectors";
import usePaymentsData from "./utils/hook/PaymentDataService";
import usePaymentsDoc from "./utils/hook/paymentDocService";
import {onPaymentColumns} from "./columnsForPaymentTables";
import PaymentDates from "./PaymentDates";

const OnPaymentPart = () => {
  const {t} = useTranslation();

  const columns = useColumns(onPaymentColumns);

  const {docsByPayment} = usePaymentsData();

  const {onDocSelect, current} = usePaymentsDoc(3);

  return (
    <div>
      <div className="header-blocks header-blocks_last">2.{t("On payment")} </div>
      <PaymentDates
        labelPlan={t("on payment (p)")}
        labelFact={t("on payment (f)")}
        ttType="fact payment"
        name={"onPaymentConfirm"}
      />

      <div className="header-blocks q_wrapper">
        <QuestionItem title="list confirm" />
        {t("Document list on payment")}
      </div>
      <ReactTable
        defaultData={docsByPayment}
        columns={columns}
        onSelect={onDocSelect}
        current={current}
        className={"project_table"}
        style={{height: "5em"}}
      />
    </div>
  );
};

export default OnPaymentPart;
