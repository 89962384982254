import React from "react";
import {useTranslation} from "react-i18next";
import {Formik} from "formik";

import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import {NumberInput, TextInput} from "../../../Inputs";
import LeftRightBtnGroup from "../common/components/LeftRightBtnGroup";

import useControlTermsService from "./useControlTermsService";

import styles from "../common/styles/VedSettingsGeneral.module.scss";

const ControlTermsSettings = () => {
  const {t} = useTranslation();

  const textInputWidth = "200px";
  const textInputLabelWidth = "200px";
  const afterNumberInputText = t("work. days");
  const tooltipL = t("Add control terms");
  const tooltipR = t("Delete control terms");

  const {
    initialValues,
    onSaveControlTermsBtnClick,
    onAddControlTermsBtnClick,
    onRemoveControlTermsBtnClick,
    isAddControlTermsDisabled,
    areUpdateAndDeleteControlTermsDisabled,
  } = useControlTermsService();

  return (
    <Formik initialValues={initialValues} enableReinitialize>
      {({values}) => (
        <section className={styles.controlTermsSection}>
          <h2 className={styles.sectionHeader}>
            3. <span className={styles.underLined}>{t("Control Terms Adjustment")}</span>
          </h2>
          <div className={styles.sectionRow}>
            <div>
              <TextInput label={t("Factory") + ":"} name="factoryName" disabled />
              <div>
                <NumberInput
                  label={t("Date: “to the Factory (f)” -> Date: “Pf receipt (p)”") + ":"}
                  name="toFactoryDate"
                  labelStyles={{width: textInputLabelWidth, wordWrap: "nowrap"}}
                  width={textInputWidth}
                />
                <span>{afterNumberInputText}</span>
              </div>
              <div>
                <NumberInput
                  label={t("Date: “Pf receiving (f)” -> Date: “Pf control (n)”") + ":"}
                  name="pfReceivingDate"
                  labelStyles={{width: textInputLabelWidth, wordWrap: "nowrap"}}
                  width={textInputWidth}
                />
                <span>{afterNumberInputText}</span>
              </div>
              <div>
                <NumberInput
                  label={t("Date: “Pf control (f)” -> Date: “for payment (p)”") + ":"}
                  name="pfControlDate"
                  labelStyles={{width: textInputLabelWidth, wordWrap: "nowrap"}}
                  width={textInputWidth}
                />
                <span>{afterNumberInputText}</span>
              </div>
              <div>
                <NumberInput
                  label={t("Date: “for payment (f)“ -> Date: upon payment (p)“") + ":"}
                  name="paymentDate"
                  labelStyles={{width: textInputLabelWidth, wordWrap: "nowrap"}}
                  width={textInputWidth}
                />
                <span>{afterNumberInputText}</span>
              </div>
              <div>
                <NumberInput
                  label={t("Date: “upon payment (f)“ -> Date: “Ready (n)“") + ":"}
                  name="paymentReadyDate"
                  labelStyles={{width: textInputLabelWidth, wordWrap: "nowrap"}}
                  width={textInputWidth}
                />
                <span>{afterNumberInputText}</span>
              </div>
              <div>
                <NumberInput
                  label={t("Date: “Goods readiness control“ by") + ":"}
                  name="readyControlDate"
                  labelStyles={{width: textInputLabelWidth, wordWrap: "nowrap"}}
                />
                <span>{afterNumberInputText + t('before Date: "Readiness(f)"')}</span>
              </div>
              <div>
                <NumberInput
                  label={t("Date: “Logist (in)“ -> Date: “Logist (o)“") + ":"}
                  name="logistDate"
                  labelStyles={{width: textInputLabelWidth, wordWrap: "nowrap"}}
                  width={textInputWidth}
                />
                <span>{afterNumberInputText}</span>
              </div>
            </div>
            <div className={styles.controlTermsRightColumn}>
              <LeftRightBtnGroup
                onAddBtnClick={onAddControlTermsBtnClick}
                onRemoveBtnClick={onRemoveControlTermsBtnClick}
                tooltipL={tooltipL}
                tooltipR={tooltipR}
                disabledL={isAddControlTermsDisabled}
                disabledR={areUpdateAndDeleteControlTermsDisabled}
              />
              <FunctionButton
                name="Save"
                disabled={areUpdateAndDeleteControlTermsDisabled}
                className={styles.controlTermsSaveBtn}
                onClick={() => onSaveControlTermsBtnClick(values)}
                autoWidth
              />
            </div>
          </div>
        </section>
      )}
    </Formik>
  );
};

export default ControlTermsSettings;
