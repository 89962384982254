import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useField, useFormikContext} from "formik";
import {debounce} from "lodash";
import PropTypes from "prop-types";

import QuestionItem from "../../utilities/QuestionItem";

import useInputsTooltip from "./hook/InputsTooltipService";

export const Select = ({
  label,
  width = "70%",
  // styles = null,
  id,
  labelWrap,
  options, ///// должен быть вида [{value: ###, title: ### }, ....]
  makeEffect, /// функция (не обязательная) которая должна сработать когда происходит выбор из выпадающего списка
  question, ///// пояснение к полю
  // nomination = "",
  // multi = false, ///если передаём в массив выбора объекты с несколькими dependentField например {orgId, orgName, orgCode}
  minFilterLength = 3, //// минимальная длина строки для фильтрации поля
  errorField,
  widthLabel = "",
  disabled = false,
  selectOnly = false,
  inputClassName,
  ...props
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const [filter, setFilter] = useState("");
  const [filteredOptions, setFilteredOptions] = useState([]);

  const [field, meta, helpers] = useField(props);
  const {value} = field;
  const {
    // setFieldValue, handleChange,
    initialValues,
    errors,
  } = useFormikContext();

  const [textValue, setTextValue] = useState("");
  const {t, i18n} = useTranslation();

  const {tooltipShown, onMouseEnter, onMouseLeave} = useInputsTooltip(field);
  //////////фильтрация

  const updateFilterQuery = filterStr => {
    if (filterStr && filterStr.length >= minFilterLength) {
      setFilter(filterStr);
    } else setFilter("");
  };

  const delayedFilter = debounce(updateFilterQuery, 500);
  const namingType = useMemo(() => (i18n.language === "en" ? "engName" : "name"), [i18n.language]);

  useEffect(() => {
    var filtered = options.filter(
      option =>
        option &&
        option.title &&
        option.title !== null &&
        option.title !== "" &&
        option.title?.toLowerCase().includes(filter.toLowerCase()),
    );
    setFilteredOptions(filtered);

    if (filter !== "" && !showOptions && filtered.length > 0 && filtered.length !== options.length) {
      openOptions();
    }
  }, [filter, options]);

  /////////////////////////
  const timeout = useRef();

  const handleSelectOption = option => {
    clearTimeout(timeout.current);
    setShowOptions(false);
    setFilter("");

    helpers.setValue(option.value);
    // setTextValue(option.title)
  };

  const optionList = useMemo(() => {
    return filteredOptions.map((option, i) => {
      var {title} = option;

      return (
        <li
          className="select_option"
          key={i}
          onClick={() => {
            handleSelectOption(option);
          }}
        >
          {title.length > 32 ? title.slice(0, 32) + "..." : title}
        </li>
      );
    });
  }, [filteredOptions]);

  useEffect(() => {
    setShowOptions(false);
    setFilter("");
    valueChange();
  }, [options]);

  const openOptions = () => {
    if (filteredOptions.length > 0 && !props.readOnly) setShowOptions(true);
  };

  useEffect(() => {
    showAllOptions();
  }, [initialValues]);

  const showAllOptions = useCallback(() => {
    setFilter("");
  }, []);

  //////// findId ////////

  const updateIdQuery = value => {
    if (value && value !== "") {
      var item = options.find(option => option.title === value);
      if (item) helpers.setValue(item.value);
      else helpers.setValue(null);
    } else {
      helpers.setValue(null);
    }
  };

  const valueChange = () => {
    if (value && value !== "") {
      if (value?.title) {
        setTextValue(value.title);
      } else {
        const item = options.find(option => option.value === value);

        if (item) {
          setTextValue(item.title);
        }
      }
      // title: item[namingType], value: item.colorId
      // hasOwnProperty
    }
    if (value === "") {
      setTextValue("");
    }
  };

  useEffect(() => {
    valueChange();
  }, [value]);

  const findId = debounce(updateIdQuery, 500);

  //////////

  return (
    <div className={`project_input select ${labelWrap ? "" : "labelWrap"}`} id={id}>
      {label && (
        <label htmlFor={props.name} style={{width: widthLabel}} className={props.autolabel ? "autolabel" : ""}>
          {/* {  typeof label === "string" ? t(label) : label  } */}
          {label}
        </label>
      )}
      <div
        style={{display: "flex", position: "relative", width: width, maxHeight: "100px"}}
        onMouseLeave={() => setTimeout(() => setShowOptions(false), 100)}
      >
        <input
          className={inputClassName}
          readOnly={props.readOnly || selectOnly}
          value={textValue}
          autoComplete="off"
          style={{width: "100%"}}
          onClick={openOptions}
          onChange={e => {
            console.log("e.target.value", e.target.value);
            setTextValue(e.target.value);
            findId(e.target.value);
            delayedFilter(e.target.value);
            // makeEffect(e.target.value);
          }}
          disabled={disabled}
          onMouseEnter={question ? onMouseEnter : null}
          onMouseLeave={question ? onMouseLeave : null}
        />
        {meta.error ? <div className="error">{meta.error}</div> : null}
        {errors[errorField] && meta.touched ? <div className="error">{errors[errorField]}</div> : null}
        {!props.readOnly && (
          <div
            className={`select__list_button ${disabled ? "disable" : ""}`}
            onClick={() => {
              if (disabled) return;
              if (!props.readOnly) {
                showAllOptions();
                setShowOptions(i => (i = !i));
              }
            }}
          >
            ▼
          </div>
        )}

        {showOptions && (
          <div className="select__list disable">
            <ul>{optionList}</ul>
          </div>
        )}
        {question ? <QuestionItem title={question} tooltipShown={tooltipShown} /> : null}
      </div>
    </div>
  );
};

Select.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  width: PropTypes.string,
  question: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  props: PropTypes.array,
  id: PropTypes.string,
  options: PropTypes.array,
  minFilterLength: PropTypes.number,
  errorField: PropTypes.string,
  disabled: PropTypes.bool,
};
