import React from "react";
import {Route, Routes} from "react-router-dom";

import Accounts from "../Components/accounts/Accounts";
import AccountsList from "../Components/finance/AccountsList";

import FinAccounting from "./Finance/FinAccounting";
import FinBlock from "./Finance/FinBlock";

const FinancePage = () => {
  return (
    <Routes>
      <Route path="/fin-block/*" element={<FinBlock />} />
      <Route path="/fin-accounting/*" element={<FinAccounting />} />
    </Routes>
  );
};

export default FinancePage;
