import React from "react";

import ErrorBoundary from "../Components/errorBoundary/ErrorBoundary";

import TemporarilyUnavailable from "../Components/errorBoundary/TemporarilyUnavailable";

import DemoForm from "../Components/Inputs/DemoForm";

import SKU from "../Components/SKU/SKU";

const SKUPage = () => {
  return (
    <ErrorBoundary>
      <SKU />
      {/* <TemporarilyUnavailable /> */}
      {/* <DemoForm /> */}
    </ErrorBoundary>
  );
};

export default SKUPage;
