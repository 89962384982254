import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useFormikContext} from "formik";
import PropTypes from "prop-types";

import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import {getInfo, getSuccess} from "../../../../utilities/toasts";
import ReactTable from "../../../conteiners/ReactTable";
import {NoteInput, Select} from "../../../Inputs";
import UserSelect from "../../../Inputs/preparedInputs/UserSelect";
import useColumns from "../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {
  factoryAppointManager,
  factoryDisappointManager,
  fetchAllFactories,
  fetchFactory,
} from "../../redux/operations/FactoryOperations";
import {selectCurrentConditionId, selectCurrentFactoryId} from "../../redux/selectors/FactorySelectors";
import LeftRightBtnGroup from "../common/components/LeftRightBtnGroup";
import {REQ_AUTODISTRIBUTION_TABLE_COLUMNS} from "../common/utils/constants";

import useFactoryAppointTableData from "./utils/factoryAppointTableData";

import styles from "../common/styles/VedSettingsGeneral.module.scss";

const FactoryDistribution = () => {
  const {t} = useTranslation();

  const {
    values: {userId},
  } = useFormikContext();

  const [currFactory, cetCurrFactory] = useState(null);

  const dispatch = useDispatch();

  const currentFactoryId = useSelector(selectCurrentFactoryId);
  const currentCondition = useSelector(selectCurrentConditionId);

  const updateTable = () => {
    dispatch(fetchAllFactories({managerFeaId: userId}));
  };

  //data
  const factoryAppoinColumns = useColumns(REQ_AUTODISTRIBUTION_TABLE_COLUMNS);
  const factoryAppoinData = useFactoryAppointTableData();

  //event handlers
  const onReqAutodestributionsTableSelect = () => {};
  const onSaveReqAutodistributionClick = () => {};

  useEffect(() => {
    if (userId && userId !== "") {
      updateTable();
    }
  }, [userId]);

  const onApointManager = () => {
    dispatch(factoryAppointManager({factoryId: currentFactoryId, managerFeaId: userId})).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        getSuccess(t("factory autodistribution success"));
        updateTable();
      }
    });
  };

  const onDisApointManager = () => {
    dispatch(factoryDisappointManager(currFactory)).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        updateTable();
      }
    });
  };

  return (
    <section className={styles.reqAutodistrSection}>
      <h2 className={styles.sectionHeader}>
        1. <span className={styles.underLined}>{t("Request Autodistribution")}</span>
      </h2>
      <UserSelect width="300px" label={"Manager FEA"} className={styles.noTextWrap} widthLabel="120px" />
      <div className={styles.sectionRow}>
        <ReactTable
          className={styles.reqAutodistrTableWrapper}
          tableClassName={styles.reqAutodistrTable}
          columns={factoryAppoinColumns}
          defaultData={factoryAppoinData}
          onSelect={cetCurrFactory}
          current={currFactory}
        />
        <LeftRightBtnGroup
          onAddBtnClick={onApointManager}
          onRemoveBtnClick={onDisApointManager}
          tooltipL={"Appoint factory"}
          tooltipR={"Delete factory appoint"}
          disabledL={!currentCondition || !userId || userId === ""}
          disabledR={!currFactory}
        />
      </div>
      <FunctionButton
        name="Save"
        disabled
        className={styles.saveReqAutoBtn}
        onClick={onSaveReqAutodistributionClick}
        autoWidth
      />
    </section>
  );
};

export default FactoryDistribution;
