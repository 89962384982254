import {useTranslation} from "react-i18next";
import {Form, Formik} from "formik";
import PropTypes from "prop-types";

import {TextInput} from "../../../Inputs";
import PhoneInput from "../../../Inputs/PhoneInput";
import {Select} from "../../../Inputs/SelectField";
import {FilterButton} from "../../../SKU/VED/Filters/SkuFilters";

import useFactoryFiltersService from "./utils/hooks/getFactoryFiltersService";

const FactoryFilters = ({filters, setFilters}) => {
  const {t} = useTranslation();
  const {initialValues, onFilter, delFilters, termsOptions, countryIdOptions, factoryIdOptions} =
    useFactoryFiltersService({filters, setFilters});

  return (
    <Formik
      initialValues={initialValues}
      // validationSchema={Yup.object().shape({})}
      validateOnChange={true}
      validateOnBlur={true}
      enableReinitialize
    >
      {({values}) => {
        return (
          <Form className="fcc g15">
            <FilterButton
              onFilter={() => onFilter(values)}
              delFilter={() => delFilters(values)}
              questionTitle={t("Filter")}
            />

            <div className="filter_block">
              <Select label={t("Factory")} name="factoryId" options={factoryIdOptions} width="80%" />
              <Select label={t("Country")} name="countryId" options={countryIdOptions} width="80%" />
              <TextInput
                label={t("cont pers CP")}
                name="contactName"
                type="text"
                generalClassName="filter_item"
                width="80%"
              />
              <PhoneInput label={t("P#")} name="phone" inputProps={{name: "phone"}} type="text" width="80%" />
              <Select
                label={t("Conditions status")}
                name="conditionsStatus"
                width="80%"
                options={termsOptions}
                generalClassName="filter_item"
              />
              <TextInput label={t("cond")} name="condition" type="text" width="80%" generalClassName="filter_item" />
              <TextInput label={t("Notes")} name="note" type="text" generalClassName="filter_item" width="80%" />
              <TextInput label="e-mail" name="email" type="text" generalClassName="filter_item" width="80%" />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

FactoryFilters.propTypes = {setFilters: PropTypes.func.isRequired};

export default FactoryFilters;
