import {useMemo} from "react";

import useTermsStatusOptionsService from "../../../factoryModalParts/utils/hooks/getTermsStatusOptionsService";
import {CLEAN_FILTERS, FILTER_STORAGE_NAME} from "../constants";

import useCountryIdOptionsService from "./getCountryIdOptionsService";
import useFactoryIdOptionsService from "./getFactoryIdOptionsService";

const useFactoryFiltersService = ({filters, setFilters}) => {
  const storedFilters = JSON.parse(sessionStorage.getItem(FILTER_STORAGE_NAME));

  let initialValues = useMemo(
    () => ({
      factoryId: storedFilters ? storedFilters.factoryId : filters.factoryId,
      countryId: storedFilters ? storedFilters.countryId : filters.countryId,
      contactName: storedFilters ? storedFilters.contactName : filters.contactName,
      phone: storedFilters ? storedFilters.phone : filters.phone,
      email: storedFilters ? storedFilters.email : filters.email,
      condition: storedFilters ? storedFilters.condition : filters.condition,
      note: storedFilters ? storedFilters.note : filters.note,
      conditionsStatus: storedFilters ? storedFilters.conditionsStatus : filters.conditionsStatus,
    }),
    [filters, storedFilters],
  );

  const termsOptions = useTermsStatusOptionsService();
  const countryIdOptions = useCountryIdOptionsService();
  const factoryIdOptions = useFactoryIdOptionsService();

  function onFilter(values) {
    setFilters(values);
    sessionStorage.setItem(FILTER_STORAGE_NAME, JSON.stringify(values));
  }

  function delFilters(values) {
    setFilters(CLEAN_FILTERS);
    clearValues(values);
    sessionStorage.setItem(FILTER_STORAGE_NAME, null);
  }

  function clearValues(values) {
    Object.keys(values).forEach(key => (values[key] = ""));
  }

  return {initialValues, onFilter, delFilters, termsOptions, countryIdOptions, factoryIdOptions};
};

export default useFactoryFiltersService;
