import {useEffect, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";

import plusIcon from "../../images/icons/plus.png";
import saveIcon from "../../images/icons/save.png";
import {BUTTON_TYPES} from "../../utilities/Buttons/constants";
import ImageButton from "../../utilities/Buttons/ImageBtn";
import {formatStatus} from "../../utilities/CirculeStatus";
import ControlFieldsChanges from "../../utilities/ControllChanges";
import {dateFormat} from "../../utilities/dateFormat";
import ToolTip from "../../utilities/ToolTip";
import TableWithSelect from "../conteiners/TableWithSelect";
import {DateInput, TextInput} from "../Inputs";

import {remindCreated, remindUpdated, resetReminds, selectRemind} from "./RemindsSlice";

import "../../Styles/Reminder.scss";

const ReminderHoc = props => {
  const {t} = useTranslation();

  const {forcedUpdate, height, setGlobalSave, create, update, loading, disabled} = props;

  const {reminds, currentRemind} = useSelector(state => state.reminds);

  const dispatch = useDispatch();

  const ths = useMemo(() => {
    return {
      "№": 5,
      [t("Date")]: 10,
      [t("Notes")]: 70,
      "D-day": 10,
      [t("Status")]: 5,
    };
  }, []);

  useEffect(() => {
    dispatch(resetReminds());
  }, []);

  const remindsTableField = useMemo(() => {
    return reminds.map((item, i) => {
      const date1 = item.createdAt ? new Date(item.createdAt).toLocaleDateString() : "";
      const date2 = item.dueDate ? new Date(item.dueDate).toLocaleDateString() : "";
      return {
        todoNumber: i + 1,
        createdAt: item.createdAt,
        note: item.note,
        dueDate: item.dueDate,
        status: "",
        id: item.todoId,
      };
    });
  }, [reminds]);

  const onSaveNote = newnote => {
    const {note, dueDate} = newnote;
    if (!currentRemind) {
      create({
        note,
        dueDate,
      }).then(res => {
        dispatch(remindCreated(res));
      });
    } else {
      update({
        todoId: currentRemind.todoId,
        data: {
          note,
          dueDate,
        },
      }).then(res => {
        dispatch(remindUpdated(res));
      });
    }
  };

  const onPlus = () => {
    dispatch(selectRemind(null));
    create({
      note: "",
      dueDate: dateFormat(new Date()),
    }).then(res => {
      dispatch(remindCreated(res));
    });
  };

  const onNewSelect = currentNote => {
    if (currentRemind && currentNote === currentRemind?.todoId) {
      dispatch(selectRemind(null));
      return;
    }
    dispatch(selectRemind(currentNote));
  };

  const initialValues = useMemo(() => {
    return {
      number: reminds != null ? reminds.length + 1 : 1,
      todoId: "",
      note: "",
      dueDate: new Date().toJSON(),
      status: "",
    };
  }, [reminds]);

  return (
    <div className="reminder" style={{height: height, overflowY: "hidden"}}>
      <div className="form_wrapper">
        <Formik
          initialValues={currentRemind || initialValues}
          enableReinitialize
          validateOnChange
          validationSchema={Yup.object({
            dueDate: Yup.date().required([t("Deadline")]),
          })}
          onSubmit={values => {
            onSaveNote({
              ...values,
              dueDate: values.dueDate,
            });
          }}
        >
          <Form className="reminder_form fc nowrap">
            <ControlFieldsChanges editFunc={setGlobalSave} forcedUpdate={forcedUpdate} updFunc={onSaveNote} />

            <div style={{width: "80%"}}>
              <TextInput
                autolabel
                label={t("TD notes")}
                name="note"
                className="reminder_note"
                autoComplete="off"
                width={"100%"}
              />
            </div>
            <DateInput name="dueDate" min={dateFormat(new Date())} />
            {/* <ImageButton
              src={plusIcon}
              alt="add remind"
              onClick={onPlus}
              tooltipMessage="New entry"
              width={1.5}
              height={1.5}
              disabled={disabled}
            /> */}
            <ImageButton
              src={saveIcon}
              alt="save"
              type={BUTTON_TYPES.SUBMIT}
              width={2.2}
              height={1.5}
              style={{zIndex: "1000"}}
              disabled={disabled}
            />
          </Form>
        </Formik>
        {/* /////////список///////// */}
        <div className="reminder_table project_list" style={{height: "100%"}}>
          <TableWithSelect
            thsName={ths}
            items={remindsTableField}
            current={currentRemind?.todoId || null}
            onSelect={onNewSelect}
            loadingStatus={loading}
            formatStatus={formatStatus}
          />
        </div>
      </div>
    </div>
  );
};

export default ReminderHoc;
