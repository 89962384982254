import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import {getCurrentProject} from "../../selectors/generalSelectors";
import PageControlContainer from "../conteiners/PageControlContainer";

import MainDocComponent from "./Documents/MainDocComponent";
import KpContainer from "./projectContainer/KpContainer";
import ProjectContainer from "./projectContainer/ProjectContainer";
import ProjectReminder from "./projectReminds/ProjectReminder";

const Project = () => {
  const {t} = useTranslation();

  const currentProject = useSelector(getCurrentProject);
  const catalogsLoading = useSelector(state => state.catalogs.catalogsLoading);

  return (
    <PageControlContainer
      title={currentProject ? `${[t("Project")]} ${currentProject.projectNumber}` : [t("Project")]}
      loading={catalogsLoading}
    >
      <div className="two-column-page__container">
        <div className="left-side">
          <div className="form_wrapper">
            <div
              style={{
                height: "50%",
                width: "100%",
                borderBottom: "1px solid #426ca681",
              }}
            >
              <ProjectContainer />
            </div>

            <div style={{height: "50%", width: "100%"}}>
              <KpContainer />
            </div>
          </div>
        </div>

        <div className="right-side">
          <div className="form_wrapper">
            <MainDocComponent />
            <ProjectReminder />
          </div>
        </div>
      </div>
    </PageControlContainer>
  );
};

export default Project;
