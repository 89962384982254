import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import PropTypes from "prop-types";
import Swal from "sweetalert2";

import {deselectKp, setKpList} from "../../../slices/KPSlice";
import {remindsFetched} from "../../reminder/RemindsSlice";
import ProjectAddForm from "../ProjectAddForm/ProjectAddForm";
import ProjectList from "../ProjectList/ProjectList";
import {deselectProject, fetchProjectById} from "../ProjectSlice";

const ProjectContainer = ({setGlobalSave, forcedUpdate}) => {
  const {t} = useTranslation();

  const [isEdited, setIsEdited] = useState(false);
  const currentProject = useSelector(state => state.projects.currentProject);
  const projects = useSelector(state => state.projects.projects);

  const [filteredProjectList, setFilteredProjectList] = useState([]);

  const [initialUpd, setInitialUpd] = useState(forcedUpdate);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isEdited) setGlobalSave(true);
  }, [isEdited]);

  useEffect(() => {
    setInitialUpd(forcedUpdate);
  }, [forcedUpdate]);

  useEffect(() => {
    return () => {
      dispatch(deselectProject());
      dispatch(setKpList([]));
    };
  }, []);

  const select = value => {
    if (currentProject && currentProject.projectId === value) {
      dispatch(deselectProject());
      dispatch(setKpList([]));
      dispatch(deselectKp());
      dispatch(remindsFetched([]));
    } else {
      dispatch(fetchProjectById(value)).then(res => {
        dispatch(deselectKp());
        dispatch(setKpList(res.payload.project.cos));
        dispatch(remindsFetched(res.payload.project.todo));
      });
    }
  };

  const filterProjectsList = clientId => {
    const filtered = projects.filter(el => el.clientId !== clientId);
    setFilteredProjectList(filtered);
  };

  const onMakeEffect = value => {
    if (isEdited && currentProject) {
      Swal.fire({
        title: "",
        text: `${[t("Project changes")]} ${currentProject.projectNumber}?`,
        icon: "question",
        confirmButtonText: [t("Yes")],
        showCancelButton: true,
        cancelButtonText: [t("No")],
      })
        .then(answ => {
          if (answ.isConfirmed) setInitialUpd(i => (i = !i));
        })
        .then(() => {
          select(value);
        });
    } else {
      select(value);
    }
  };

  return (
    <div className="project_form_wrapper">
      {/* <div className="project_list" style={{height: "100%"}}> */}
      <ProjectList onMakeEffect={onMakeEffect} />
      {/* </div> */}
      <ProjectAddForm
        updates={initialUpd}
        onMakeEffect={onMakeEffect}
        setIsEdited={setIsEdited}
        isEdited={isEdited}
        filterProjectsList={filterProjectsList}
      />
    </div>
  );
};

ProjectContainer.propTypes = {setGlobalSave: PropTypes.func, forcedUpdate: PropTypes.bool};

export default ProjectContainer;
