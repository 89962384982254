import {useCallback, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Formik} from "formik";
import * as Yup from "yup";

import i18n from "../../../i18n";
import ToolTip from "../../../utilities/ToolTip";
import PageContainer from "../../conteiners/PageContainer";
import ModalForm from "../../Modal/FormModal";
import {
  cancelAllAppointmentRequests,
  fetchMixedRequests,
  fetchRequests,
  getAllStatusRequest,
  getAppointRequests,
  getFirstStatusRequest,
  getLoadingRequest,
  getThirdStatusRequest,
  setFirstStatusRequests,
  setRequests,
} from "../RequestsSlice";

import OrderingModal from "./ordering/Ordering";
import RequestTable from "./RequestTable/RequestTable";
import {STATUS_COLORS} from "./colorConstants";
import RequestFilter from "./RequestFilter";

import "./requestRegister.scss";

const RequestRegister = () => {
  const {t, i18n} = useTranslation();
  const dispatch = useDispatch();
  const [orderingModalActive, setOrderingModalActive] = useState(false);

  const firstStatusReq = useSelector(getFirstStatusRequest);
  const requests = useSelector(getAllStatusRequest),
    requestLoading = useSelector(getLoadingRequest),
    appointRequests = useSelector(getAppointRequests),
    firstSatusRequests = useSelector(getFirstStatusRequest);

  const [buttonColor, setButtonColor] = useState(STATUS_COLORS.GREEN);

  useEffect(() => {
    if (!orderingModalActive) {
      dispatch(fetchRequests({statusId: 1, showidenticalSku: true})).then(req => {
        // if(req.payload.requests.length > 0){
        //   setButtonColor(STATUS_COLORS.RED)
        // }
        dispatch(fetchMixedRequests());
      });
    }
  }, [orderingModalActive]);

  useEffect(() => {
    if (firstSatusRequests.length > 0) {
      setButtonColor(STATUS_COLORS.RED);
    }
  }, [firstSatusRequests]);

  const transformForTable = (item, i) => {
    const {
      requestId,
      status,
      brand,
      brand: {factoryContacts},
      managerFea,
      cos,
      managerCo,
      ...rest
    } = item;
    const statusName = status?.[i18n.language === "en" ? "engName" : "ukrName"] || "";
    // const managerCo = cos[0]?.managerCo;
    return {
      ...rest,
      number: i + 1,
      requestStatus: statusName,
      managerCo: managerCo?.name || "",
      managerFea: managerFea?.name || "",
      factory: (factoryContacts && factoryContacts[0]?.name) || brand?.name || "",
      id: requestId,
      noname: "",
    };
  };

  const defaultData = useMemo(() => {
    return requests ? requests.map(transformForTable) : [];
  }, [requests, i18n.language]);

  const initialValues = useMemo(() => {
    return {
      requestStatus: "",
      requestNumber: "",
      managerFea: "",
      factory: "",
      managerCo: "",
      dateStart: "",
      dateEnd: "",
      note: "",
    };
  }, []);

  const onModalClose = useCallback(() => {
    if (appointRequests.length > 0)
      dispatch(cancelAllAppointmentRequests()).then(() => {
        dispatch(fetchRequests({statusId: 1}));
      });
  }, [appointRequests]);

  return (
    <PageContainer title={t("Request register")}>
      <div
        className="form_wrapper"
        style={{
          // padding: "0.5em 1em",
          paddingBottom: "1em",
        }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object().shape({})}
          validateOnChange={true}
          validateOnBlur={true}
          enableReinitialize
          onSubmit={values => {}}
        >
          <div className="request-header-wrapper">
            <RequestFilter>
              <StatusButton
                onClick={() => {
                  setOrderingModalActive(true);
                }}
                color={buttonColor}
              />
            </RequestFilter>
          </div>
        </Formik>

        <RequestTable
          defaultData={defaultData}
          orderingModalActive={orderingModalActive}
          requestLoading={requestLoading}
        />
      </div>

      <ModalForm
        closable={true}
        Component={OrderingModal}
        label={t("Order distribution")}
        active={orderingModalActive}
        setModalActive={setOrderingModalActive}
        onModalClose={onModalClose}
      />
    </PageContainer>
  );
};

export const StatusButton = ({color, ...props}) => {
  return (
    <ToolTip title={"indicator"}>
      <button type="button" className="status-button" {...props}>
        <div className="status-circle-external">
          <div className="status-circle-internal " style={{background: color}}></div>
        </div>
      </button>
    </ToolTip>
  );
};

export default RequestRegister;
