import {AVAILABILITY_CIRCLE_COLORS} from "../../../../../utilities/AvailabilityStatusCIrcule";

const usePaymentsData = paymentsRaw => {
  return paymentsRaw.map((payment, index) => {
    const {paymentId, paimentSum, request, docsForPayment, createdAt, isPaid} = payment;

    const circleColor = isPaid === null ? AVAILABILITY_CIRCLE_COLORS.RED : AVAILABILITY_CIRCLE_COLORS.GREEN;

    return {
      id: paymentId,
      number: index + 1,
      date: createdAt,
      dt: "",
      kt: "",
      summ: paimentSum,
      isPaid: circleColor,
      paymentPurpose: "?",
      paymentDocument: docsForPayment[0]?.docNumber,
      proforma: request?.proforms[0].proformaNumber,
      factory: request?.brand?.name,
    };
  });
};

export default usePaymentsData;
