import {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Form, Formik} from "formik";
import PropTypes from "prop-types";
import * as Yup from "yup";

import UpDownButtons from "../../../../utilities/Buttons/UpDownButtons";
import {dateFormat} from "../../../../utilities/dateFormat";
import QuestionItem from "../../../../utilities/QuestionItem";
import {DateInput, TextInput} from "../../../Inputs";
import {Select} from "../../../Inputs/SelectField";
import {appointRequests, cancelAppointmentRequests, fetchRequests, getRequestRegistrFilters} from "../../RequestsSlice";
import SimilarSkuBlock from "../../SimilarSkuBlock/SimilarSkuBlock";

const OrderingForm = ({selectedReq, selectedForCancel}) => {
  const {t, i18n} = useTranslation();

  const dispatch = useDispatch();
  const filters = useSelector(getRequestRegistrFilters);

  const onAppoint = (values, {resetForm, setFieldValues}) => {
    const {managerFea, note, date} = values;
    dispatch(
      appointRequests({
        managerFeaId: managerFea,
        data: {
          requestIds: selectedReq,
          note,
          date: date,
        },
      }),
    ).then(() => {
      // setFieldValues(initialValues)
      resetForm();
    });
  };
  const onCancel = () => {
    dispatch(cancelAppointmentRequests(selectedForCancel)).then(() => {
      dispatch(fetchRequests({statusId: 1}));
    });
  };

  const coManagersOptions = useMemo(() => {
    return (
      filters?.coManagers.map(item => {
        const name =
          i18n.language === "en"
            ? item.persona.engFirstName + " " + item.persona.engLastName
            : item.persona.firstName + " " + item.persona.lastName;

        return {
          title: name,
          value: item.managerCoId,
        };
      }) || []
    );
  }, [filters, i18n.language]);

  const initialValues = useMemo(() => {
    return {
      note: "",
      date: "",
      managerFea: "",
    };
  }, []);

  return (
    <div style={{margin: "1em 0 "}}>
      <Formik
        initialValues={initialValues}
        validateOnChange
        validationSchema={Yup.object().shape({
          managerFea: Yup.string().required(),
          date: Yup.date().required([t("Enter date")]),
        })}
        onSubmit={onAppoint}
      >
        {({values, submitForm}) => (
          <Form className="fc jcsb nowrap">
            <div className="fc jcfs autolabel g15">
              <Select
                name="managerFea"
                label={t("FEA Manager")}
                question="FeaM assign"
                options={coManagersOptions}
                width="15em"
              />

              <DateInput name="date" question="dd to fact" label={t("DD per Factory(p):")} />

              <TextInput name="note" placeholder={t("Add a Note to the Application ")} width="25em" />
            </div>

            <UpDownButtons
              onUpClick={onCancel}
              onDownClick={() => {
                submitForm();
              }}
            />

            <div style={{height: "57px"}}>
              <SimilarSkuBlock />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

OrderingForm.propTypes = {
  selectedReq: PropTypes.array,
};

export default OrderingForm;
