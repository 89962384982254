import {createSelector} from "@reduxjs/toolkit";

//*** FACTORIES ***
export const selectFactories = state => state.factories.factories;
export const selectCurrentFactory = state => state.factories.currentFactory;
export const selectCurrentFactoryId = state => state.factories.currentFactoryId;
export const selectCurrentFactoryName = state => state.factories.currentFactoryName;

//** REFERENCES ***
export const selectReferences = state => state.factories.references;
export const selectAreReferencesFetched = createSelector([selectReferences], references => references !== null);
export const selectContactTypeNames = createSelector([selectReferences], references =>
  references?.contactTypes?.reduce(
    (acc, contactType) => ({
      ...acc,
      [contactType.contactTypeId]: {ukrName: contactType.ukrName, engName: contactType.engName},
    }),
    {},
  ),
);

//*** CONDITIONS ***
export const selectConditions = state => state.factories.conditions;
export const selectFactoryContactConditions = state => state.factories.factoryContactConditions;
export const selectCurrentFactoryConditions = state => state.factories.currentFactoryConditions;
export const selectCurrentConditionId = state => state.factories.currentConditionId;
const getCurrentCondition = state =>
  state.factories.factoryContactConditions?.find(
    condition => condition.conditionId === state.factories.currentConditionId,
  ) ?? null;
export const selectCurrentCondition = createSelector([getCurrentCondition], condition => condition);
export const selectNewCondition = state => state.factories.newCondition;
export const selectFilters = state => state.factories.refs;
export const selectFactoriesRefs = state => state.factories.refs.factories;
export const selectCountries = state => state.factories.refs.countries;

//*** CONTACTS ***
export const selectContacts = state => state.factories.contacts;
export const selectCurrentContactId = state => state.factories.currentContactId;
export const selectNewContact = state => state.factories.newContact;
export const selectCurrentContact = createSelector(
  [selectCurrentFactory, selectCurrentContactId],
  (currentFactory, currentContactId) =>
    currentFactory?.contacts?.find(contact => contact.contactId === currentContactId) ?? null,
);
export const selectCurrentEmailContact = createSelector(
  [selectContacts, selectCurrentContactId],
  (contacts, currentContactId) => contacts?.find(contact => contact.contactId === currentContactId) ?? null,
);
export const selectCurrentFactoryContacts = state => state.factories.currentFactory?.contacts ?? [];

//*** EMAIL TEMPLATES ***
export const selectEmailTamplates = state => state.factories.emailTemplates;

//*** CONTROL TERMS ***
export const selectControlTerms = state => state.factories.controlTerms;
