import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

import {deleteReq, fetchData, fetchList} from "../../hook/axios.hook";
import {checkEmptyFields} from "../../utilities/checkEmptyFields";
import {getError} from "../../utilities/toasts";

const initialState = {
  skuList: [],
  skuPage: 1,
  totalPages: 0,
  filterParams: null,
  currentSku: null,

  images: [],
  colorImages: [],
  decorImages: [],
  drawings: [],

  skuLoadingStatus: "idle",

  brands: [],
  countries: [],
  colors: [],
  measurementUnits: [],
  dimensions: [],
  materials: [],
  pallets: [],
  producrSeries: [],
  productGroups: [],
  productTypes: [],
  measurementObjects: [],

  volumeMeasurementQuantites: [],
  weightMeasurementQuantites: [],
  lengthMeasurementQuantites: [],
  areaMeasurementQuantites: [],
  measurementQuantites: {
    area: [],
    volume: [],
    weight: [],
  },
};

export const fetchSkuCatalogs = createAsyncThunk("sku/fetchSkuCatalogs", async () => {
  const responce = fetchList("references/forSku", "get");
  return responce;
});

export const fetchSkuMeasureUnits = createAsyncThunk("sku/fetchSkuMeasureUnits", async () => {
  const responce = fetchList("/references/measurementUnits", "get");
  return responce;
});

export const fetchMeasurementQuantities = createAsyncThunk("sku/fetchMeasurementQuantities", async params => {
  const responce = fetchList("/references/measurementQuantities", "get", {params});
  return responce;
});

export const fetchSkuBrands = createAsyncThunk("sku/fetchSkuBrands", async params => {
  const responce = fetchList("/references/brands", "get", {params});
  return responce;
});

export const fetchSku = createAsyncThunk("sku/fetchSku", async params => {
  const responce = fetchData("/sku/getAll", "get", {params});

  return responce;
});

export const getSkuInfo = createAsyncThunk("sku/getSkuInfo", async skuId => {
  const responce = fetchData(`/sku/get/${skuId}`, "get");
  return responce;
});

export const dublicateSku = createAsyncThunk("sku/dublicateSku", async skuId => {
  const responce = fetchData(`/sku/duplicate/${skuId}`, "post");
  return responce;
});

export const createSkuNumder = createAsyncThunk("sku/createSkuNumder", async skuId => {
  const responce = fetchData(`/sku/add`, "post");
  return responce;
});

export const createSku = createAsyncThunk("sku/createSku", async ({sku, skuId}) => {
  const responce = fetchData(`/sku/create/${skuId}`, "post", checkEmptyFields(sku));
  return responce;
});

export const updateSku = createAsyncThunk("sku/updateSku", async ({sku, skuId}) => {
  const responce = fetchData(`/sku/update/${skuId}`, "patch", checkEmptyFields(sku));
  return responce;
});

export const deleteSku = createAsyncThunk("sku/deleteSku", async skuId => {
  const responce = fetchData(`/sku/delete/${skuId}`, "delete");
  return responce;
});

export const addSkuImages = createAsyncThunk("sku/addSkuImages", async ({skuId, formdata}) => {
  const responce = fetchData(`/sku/images/update/${skuId}`, "put", formdata);
  return responce;
});
export const delSkuImages = createAsyncThunk("sku/delSkuImages", async ({imgUrl, skuId}) => {
  const responce = deleteReq(`/sku/images/delete/${skuId}`, {imgUrl});
  return responce;
});
export const addDecorImages = createAsyncThunk("sku/addDecorImages", async ({skuId, formdata}) => {
  const responce = fetchData(`/sku/decorImages/update/${skuId}`, "put", formdata);
  return responce;
});
export const delDecorImages = createAsyncThunk("sku/delDecorImages", async ({imgUrl, skuId}) => {
  const responce = deleteReq(`/sku/decorImages/delete/${skuId}`, {imgUrl});
  return responce;
});

export const addColorImages = createAsyncThunk("sku/addColorImages", async ({skuId, formdata}) => {
  const responce = fetchData(`/sku/colorImages/update/${skuId}`, "put", formdata);
  return responce;
});
export const delColorImages = createAsyncThunk("sku/delColorImages", async ({imgUrl, skuId}) => {
  const responce = deleteReq(`/sku/colorImages/delete/${skuId}`, {imgUrl});
  return responce;
});

export const addDrawingImages = createAsyncThunk("sku/addDrawingImages", async ({skuId, formdata}) => {
  const responce = fetchData(`/sku/drawings/update/${skuId}`, "put", formdata);
  return responce;
});
export const delDrawingImages = createAsyncThunk("sku/delDrawingImages", async ({imgUrl, skuId}) => {
  const responce = deleteReq(`/sku/drawings/delete/${skuId}`, {imgUrl});
  return responce;
});
const skuSlice = createSlice({
  name: "sku",
  initialState,
  reducers: {
    deselectSku: state => {
      state.currentSku = null;
      state.images = [];
      state.colorImages = [];
      state.decorImages = [];
      state.drawings = [];
    },
    changeSkuPage: (state, action) => {
      state.skuPage = action.payload;
    },
    setCurrentSku: (state, action) => {
      state.currentSku = action.payload;
    },
    setFilters: (state, action) => {
      state.filterParams = action.payload;
    },
    // selectSku: (state, action) => {},
  },
  extraReducers: builder => {
    builder
      .addCase(fetchSkuCatalogs.pending, state => {
        state.skuLoadingStatus = "loading";
      })
      .addCase(fetchSkuCatalogs.fulfilled, (state, {payload}) => {
        state.skuLoadingStatus = "complete";
        Object.assign(state, payload);
        // const {
        //   brands,
        //   colors,
        //   countries,
        //   materials,
        //   measurementUnits,
        //   measurementObjects,
        //   productGroups,
        //   productTypes,
        //   pallets,
        // } = payload;

        // // state = {...state, ...payload}

        // state.brands = brands;
        // state.colors = colors;
        // state.countries = countries;
        // state.materials = materials;
        // state.pallets = pallets;
        // state.measurementUnits = measurementUnits;
        // state.productGroups = productGroups;
        // state.productTypes = productTypes;
        // state.measurementObjects = measurementObjects;

        //     state.brandList = brands.map(item=>{ return {title:item.name, value:item.brandId}})
        // state.colorsList = colors.map(item=>{ return {title:item.name, value:item.colorId}});
        //     state.countriesList = countries.map(item=>{ return {title:item.shortName.replace(/ +/g, ' ').trim(), value:item.countryId}});
        // state.materialsList = materials.map(item=>{ return {value:item.name, materialId:item.materialId}});
        // state.measurementUnitsList = measurementUnits.map(item=>{ return {value:item.name, unitId:item.unitId}});
        //     state.productGroups =poductGroups.map(item=>{ return {title:item.name, value:item.productGroupId}});
        //     state.productTypes =productTypes.length === 0 ? [] :productTypes.map(item=>{ return {title:item.name, value:item.productTypeId}});
        // state.producrSeriesList = allProductSeries.map(item=>{ return {value:item.name, productSeriesId:item.productSeriesId}})
      })
      .addCase(fetchSkuCatalogs.rejected, state => {
        state.skuLoadingStatus = "error";
      })
      //
      .addCase(fetchSkuBrands.fulfilled, (state, {payload}) => {
        state.brands = payload.map(item => {
          return {title: item.name, value: item.brandId};
        });
      })
      .addCase(fetchSkuBrands.rejected, state => {
        state.brands = [];
      })
      //
      .addCase(fetchSkuMeasureUnits.fulfilled, (state, {payload}) => {
        state.measurementUnits = payload.map(item => {
          return {value: item.name, unitId: item.unitId};
        });
      })
      .addCase(fetchSku.pending, state => {
        state.skuLoadingStatus = "loading";
      })
      .addCase(fetchSku.fulfilled, (state, {payload}) => {
        state.skuLoadingStatus = "complete";
        const {sku, totalPages, currentPage} = payload;
        state.skuList = +currentPage === 1 ? sku : [...state.skuList, ...sku];
        state.totalPages = totalPages;
        state.skuPage = +currentPage;
      })
      .addCase(fetchSku.rejected, state => {
        state.skuLoadingStatus = "error";
      })

      .addCase(updateSku.fulfilled, (state, {payload}) => {
        const {sku} = payload;
        state.skuList = state.skuList.map(item => {
          if (item.skuId === sku.skuId) {
            return sku;
          } else return item;
        });
        const {brand, color, country, material, measurementUnit, pallet, productGroup, productType, ...rest} = sku;
        state.currentSku = {
          ...rest,
          brandId: brand.brandId,
          colorId: color.colorId,
          materialId: material.materialId,
          unitId: measurementUnit?.unitId || "",
          palletId: pallet?.palletId || "",
          productGroupId: productGroup.productGroupId,
          productTypeId: productType.productTypeId,
        };
      })
      .addCase(updateSku.rejected, (state, {error}) => {
        getError(error);
      })
      .addCase(dublicateSku.fulfilled, (state, action) => {
        const {payload, meta} = action;

        const sku = state.skuList.find(s => s.skuId === meta.arg);
        state.skuList.unshift({...sku, skuId: payload.sku.skuId});
        state.currentSku = payload.sku;
      })
      .addCase(createSkuNumder.fulfilled, (state, action) => {
        const {payload, meta} = action;
        state.currentSku = payload.id;
      })
      .addCase(createSku.fulfilled, (state, {payload}) => {
        const {sku} = payload;
        state.skuList.unshift(sku);
        const {brand, color, country, material, measurementUnit, pallet, productGroup, productType, ...rest} = sku;
        state.currentSku = {
          ...rest,
          brandId: brand.brandId,
          colorId: color.colorId,
          materialId: material.materialId,
          unitId: measurementUnit?.unitId || "",
          palletId: pallet?.palletId || "",
          productGroupId: productGroup.productGroupId,
          productTypeId: productType.productTypeId,
        };
      })
      .addCase(deleteSku.fulfilled, (state, action) => {
        const {meta} = action;
        state.currentSku = null;
        state.skuList = state.skuList.filter(sku => sku.skuId !== meta.arg);
        state.images = [];
        state.colorImages = [];
        state.decorImages = [];
        state.drawings = [];
      })
      .addCase(getSkuInfo.fulfilled, (state, {payload}) => {
        const {images, colorImages, decorImages, drawings, ...skuInfo} = payload;
        state.currentSku = skuInfo;

        state.images = images;
        state.colorImages = colorImages;
        state.decorImages = decorImages;
        state.drawings = drawings;

        // state.currentSku = payload;
      })
      .addCase(addSkuImages.fulfilled, (state, {payload}) => {
        // state.currentSku = { ...state.currentSku, images: payload.images };
        state.images = payload.images;
      })
      .addCase(delSkuImages.fulfilled, (state, {payload}) => {
        // state.currentSku = { ...state.currentSku, images: payload.images };
        state.images = payload.images;
      })

      .addCase(addDecorImages.fulfilled, (state, {payload}) => {
        // state.currentSku = { ...state.currentSku, decorImages: payload.images };
        state.decorImages = payload.decorImages;
      })
      .addCase(delDecorImages.fulfilled, (state, {payload}) => {
        // state.currentSku = { ...state.currentSku, decorImages: payload.images };
        state.decorImages = payload.decorImages;
      })

      .addCase(addColorImages.fulfilled, (state, {payload}) => {
        // state.currentSku = { ...state.currentSku, colorImages: payload.images };
        state.colorImages = payload.colorImages;
      })
      .addCase(delColorImages.fulfilled, (state, {payload}) => {
        // state.currentSku = { ...state.currentSku, colorImages: payload.images };
        state.colorImages = payload.colorImages;
      })

      .addCase(addDrawingImages.fulfilled, (state, {payload}) => {
        // state.currentSku = { ...state.currentSku, drawings: payload.drawings };
        state.drawings = payload.drawings;
      })
      .addCase(delDrawingImages.fulfilled, (state, {payload}) => {
        // state.currentSku = { ...state.currentSku, drawings: payload.drawings };
        state.drawings = payload.drawings;
      })

      .addCase(fetchMeasurementQuantities.fulfilled, (state, {meta, payload}) => {
        // state.measurementQuantites[meta.arg.field] = payload;
        state[meta.arg.field + "MeasurementQuantites"] = payload;
      })

      .addDefaultCase(() => {});
  },
});

const {actions, reducer} = skuSlice;

export default reducer;
export const {deselectSku, changeSkuPage, setFilters, setCurrentSku} = actions;

export const getCurrentSku = state => state.sku.currentSku;
export const getSkuLoading = state => state.sku.skuLoadingStatus;
export const getSkuList = state => state.sku.skuList;

export const getCurrentPage = state => state.sku.skuPage;
export const getTotalPages = state => state.sku.totalPages;
export const getFilterParams = state => state.sku.filterParams;

export const getColorImages = state => state.sku.colorImages;
export const getDecorImages = state => state.sku.decorImages;
export const getSkuImages = state => state.sku.images;
export const getDrawings = state => state.sku.drawings;
