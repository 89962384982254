import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux/es/exports";
import Swal from "sweetalert2";

import FunctionButton from "../../utilities/Buttons/FunctionButton";
import PageControlContainer from "../conteiners/PageControlContainer";

import SkuForm from "./skuForm/SkuForm";
import SkuList from "./SkuList";
import {deselectSku, fetchSkuCatalogs, getCurrentSku, getSkuInfo, getSkuLoading} from "./SkuSlice";

import "./sku.scss";

const SKU = () => {
  const {t} = useTranslation();

  const dispatch = useDispatch();
  const skuLoadingStatus = useSelector(getSkuLoading);
  const currentSku = useSelector(getCurrentSku);
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    dispatch(fetchSkuCatalogs());
  }, []);

  const selectSku = id => {
    if (currentSku && currentSku.skuId === id) {
      dispatch(deselectSku());
    } else {
      dispatch(getSkuInfo(id));
    }
  };

  const onSelect = id => {
    if (!isDirty) {
      selectSku(id);
    } else {
      Swal.fire({
        title: "",
        text: `${[t("Save changes in")]} ${currentSku.article}?`,
        icon: "question",
        confirmButtonText: [t("Yes")],
        showCancelButton: true,
        cancelButtonText: [t("No")],
      })
        .then(answ => {
          if (answ.isConfirmed) {
            // console.log(answ);
          }
        })
        .then(() => {
          selectSku(id);
        });
    }
  };

  return (
    <PageControlContainer title={`SKU `} loading={!skuLoadingStatus === "complete"}>
      <div className="sku">
        <div className="project_form_wrapper">
          <div className="sku__top">
            <SkuForm onSelect={selectSku} setIsDirty={setIsDirty} />
          </div>
          <SkuList onSelect={selectSku} />
        </div>
        <div className="sku_buttons flex_container space-between">
          <FunctionButton name={t("Go to Warehouse")} style={{width: "12em"}} disabled={true} />

          <FunctionButton name={t("Dublicate SKU")} style={{marginLeft: "10px"}} disabled={true} />
        </div>
      </div>
    </PageControlContainer>
  );
};

export default SKU;
