// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sku-filters_container {
  gap: 1em;
  border-bottom: 1px solid rgba(66, 108, 166, 0.368627451);
  padding-bottom: 1em;
  width: 100%;
}
.sku-filters_container .project_input {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/Components/SKU/VED/Filters/src/skuFilters.scss"],"names":[],"mappings":"AAAA;EACE,QAAA;EACA,wDAAA;EACA,mBAAA;EACA,WAAA;AACF;AAAE;EACE,WAAA;AAEJ","sourcesContent":[".sku-filters_container {\n  gap: 1em;\n  border-bottom: 1px solid #426ca65e;\n  padding-bottom: 1em;\n  width: 100%;\n  .project_input {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
