import {createSlice, isAnyOf} from "@reduxjs/toolkit";

import {
  addDisapprovementReason,
  addProform,
  addProformApproval,
  approveProform,
  declineProform,
  declineProformApproval,
  fetchForCurrentApproval,
  fetchForRequestById,
  fetchForUserApprovals,
  fetchProformById,
  fetchProforms,
  sendProformForControl,
  sendProformToFactory,
} from "../operations/ProformOperations";

//extraactions from operations
const extraActions = [
  fetchProformById,
  fetchForRequestById,
  fetchProforms,
  addProform,
  sendProformForControl,
  approveProform,
  declineProform,
  sendProformToFactory,
  fetchForCurrentApproval,
  fetchForUserApprovals,
  addDisapprovementReason,
  addProformApproval,
  declineProformApproval,
];
const getItems = type => extraActions.map(action => action[type]);

const initialState = {
  proforms: null,
  currentProform: null,
  currentForRequest: null,

  currentApproval: null,
  approvals: null,
  proformsLoading: false,
  userApprovals: null,
  error: [],
};

const ProformSlice = createSlice({
  name: "proforms",
  initialState,
  reducers: {
    setCurrentApproval: (state, {payload}) => {
      state.currentApproval = payload;
    },
  },
  extraReducers: builder => {
    builder
      //for get requests
      .addCase(fetchProformById.fulfilled, (state, {payload}) => {
        state.currentProform = payload;
      })
      .addCase(fetchForRequestById.fulfilled, (state, {payload}) => {
        state.currentForRequest = payload;
      })
      .addCase(fetchProforms.fulfilled, (state, {payload}) => {
        state.proforms = payload;
      })
      //get requests from proform approvals
      .addCase(fetchForCurrentApproval.fulfilled, (state, {payload}) => {
        state.approvals = payload;
      })
      .addCase(fetchForUserApprovals.fulfilled, (state, {payload}) => {
        state.userApprovals = payload;
      })

      //for post, put, patch requests
      .addCase(addProform.fulfilled, (state, {payload}) => {
        state.currentForRequest.request.proforms.unshift(payload);
      })
      .addCase(addDisapprovementReason.fulfilled, (state, {payload}) => {
        state.currentApproval.disapprovalReasons = [...state.currentApproval.disapprovalReasons, payload];
      })
      .addCase(declineProformApproval.fulfilled, (state, {payload}) => {
        state.approvals = state.approvals.map(approval => {
          if (approval.approvalId === payload.approvalId) return {...approval, isDeclined: true};
          return approval;
        });
      })
      .addCase(sendProformForControl.fulfilled, (state, {payload}) => {
        // state.currentForRequest.request.proforms.unshift(payload);
      })

      //matchers
      .addMatcher(isAnyOf(...getItems("fulfilled")), state => handleFulfilled(state))
      .addMatcher(isAnyOf(...getItems("pending")), state => handlePending(state))
      .addMatcher(isAnyOf(...getItems("rejected")), (state, {error}) => handleRejected(state, error));
  },
});

//matcher functions
function handleFulfilled(state) {
  state.proformsLoading = false;
  state.error = [];
}

function handlePending(state) {
  state.proformsLoading = true;
  state.error = [];
}

function handleRejected(state, error) {
  state.proformsLoading = false;
  state.error = error;
}

//actions, reducer
const {actions, reducer} = ProformSlice;

export const {setCurrentApproval} = actions;

export default reducer;
