import React from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import Swal from "sweetalert2";

import {
  getCurrentKp,
  getCurrentSubKp,
  getCurrentSubKpDocs,
  getKpInfoForKp,
  makeOrder,
  saveKpfAsMain,
} from "../../../../slices/KPSlice";
import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import {getSuccess} from "../../../../utilities/toasts";
import NewPdfViewer from "../../../Project/Documents/DocumentView/NewPdfViewer";

import "./KpPreview.scss";

const KpPreview = () => {
  const {t} = useTranslation();

  const currentSubKp = useSelector(getCurrentSubKp),
    docsArray = useSelector(getCurrentSubKpDocs),
    currentKp = useSelector(getCurrentKp);

  const dispatch = useDispatch();

  const onSaveAsMain = () => {
    Swal.fire({
      title: "",
      text: `${[t("CO will be saved as the main one", {cofNumber: currentSubKp.cofNumber})]}`,
      icon: "info",
      confirmButtonText: [t("Continue")],
      showCancelButton: true,
      cancelButtonText: [t("Cancel")],
    }).then(answ => {
      if (answ.isConfirmed) {
        dispatch(saveKpfAsMain(currentSubKp.cofId)).then(({meta}) => {
          if (meta.requestStatus === "fulfilled") {
            dispatch(getKpInfoForKp(currentKp.coId));
            getSuccess(t("CO was successfully saved as the main one", {cofId: currentSubKp.cofId}));
          }
        });
      }
    });
  };

  const onMakeOrder = () => {
    Swal.fire({
      title: "",
      text: `${[t("Will be created an order for FEA")]}`,
      icon: "info",
      confirmButtonText: [t("Continue")],
      showCancelButton: true,
      cancelButtonText: [t("Cancel")],
    }).then(answ => {
      if (answ.isConfirmed) {
        dispatch(makeOrder(currentSubKp.cofId)).then(({meta}) => {
          if (meta.requestStatus === "fulfilled") {
            getSuccess(t("An order for FEA was successfully created"));
          }
        });
      }
    });
  };

  return (
    <div className="kpPreview__container">
      <div style={{display: "flex", justifyContent: "space-between"}}>
        <div>
          <label className="label">
            {t("CO preview")}
            {currentSubKp?.cofNumber || currentSubKp?.coaNumber}
          </label>
          <p></p>
        </div>
      </div>

      <div className="kpPreview__box">
        <NewPdfViewer fileArray={docsArray || []} />
      </div>
      <div style={{gap: "1.5em", justifyContent: "space-between"}} className="fc">
        <FunctionButton
          name={t("Save as main")}
          style={{width: "200px"}}
          disabled={currentSubKp?.isMain || !currentSubKp}
          onClick={onSaveAsMain}
        />
        <FunctionButton
          name={t("Create an Order for FEA")}
          autoWidth
          disabled={!currentSubKp?.cofId}
          onClick={onMakeOrder}
        />
      </div>
    </div>
  );
};

export default KpPreview;
