export const READINESS_TABLE_COLUMNS = Object.freeze([
  {
    header: "Date Control",
    accessorKey: "readinessControlDate",
    size: 15,
  },
  {
    header: "Date Readiness",
    accessorKey: "readinessDate",
    size: 15,
  },
  {
    header: "Product readiness info",
    accessorKey: "note",
    size: 15,
  },
]);

export const PROPORTIONS_TABLE_COLUMNS = Object.freeze([
  {
    header: "place №",
    accessorKey: "placeNumber",
    size: 10,
  },
  {
    header: "L.W.H(s).",
    accessorKey: "proportions",
    size: 10,
  },
  {
    header: "volume",
    accessorKey: "volume",
    size: 10,
  },
  {
    header: "weight (kg)",
    accessorKey: "weight",
    size: 10,
  },
  {
    header: "note",
    accessorKey: "note",
    size: 55,
  },
]);
