import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import PropTypes from "prop-types";
import translit from "ua-en-translit";

import {getColors, getMaterials} from "../../../../selectors/skuSelectors";
import {TextAreaInput} from "../../../Inputs";
import ImgBox from "../../../Inputs/fileSelect/ImgBox/ImgBox";
import FilePreview from "../../../Inputs/fileSelect/preview/FilePreview";
import {Select} from "../../../Inputs/SelectField";
import {
  addColorImages,
  addDecorImages,
  addSkuImages,
  delColorImages,
  delDecorImages,
  delSkuImages,
  getColorImages,
  getCurrentSku,
  getDecorImages,
  getDrawings,
  getSkuImages,
} from "../../SkuSlice";

const Decoration = ({imagerefs}) => {
  const {t, i18n} = useTranslation();

  const colors = useSelector(getColors),
    materials = useSelector(getMaterials);
  const currentSku = useSelector(getCurrentSku);
  const skuImages = useSelector(getSkuImages),
    colorImages = useSelector(getColorImages),
    decorImages = useSelector(getDecorImages),
    drawings = useSelector(getDrawings);

  const namingType = useMemo(() => (i18n.language === "en" ? "engName" : "name"), [i18n.language]);
  const colorsList = useMemo(
      () =>
        colors.map(item => {
          return {title: item[namingType], value: item.colorId};
        }),
      [colors, namingType],
    ),
    materialsList = useMemo(
      () =>
        materials.map(item => {
          return {title: item[namingType], value: item.materialId};
        }),
      [materials, namingType],
    );

  const dispatch = useDispatch();

  const addImg = (arrFiles, type) => {
    var formdata = new FormData();
    var i = 0,
      file,
      filename;

    for (; i < arrFiles.length; i++) {
      file = arrFiles[i];
      filename = translit(file.name);
      formdata.append("image", file, filename);
    }

    const dataObj = {skuId: currentSku.skuId, formdata};

    switch (type) {
      case "sku":
        dispatch(addSkuImages(dataObj));
        break;
      case "decor":
        dispatch(addDecorImages(dataObj));
        break;
      case "color":
        dispatch(addColorImages(dataObj));
        break;
      default:
        break;
    }
  };

  function onDell(imgUrl, type) {
    const dataObj = {skuId: currentSku.skuId, imgUrl};
    switch (type) {
      case "sku":
        dispatch(delSkuImages(dataObj));
        break;
      case "decor":
        dispatch(delDecorImages(dataObj));
        break;
      case "color":
        dispatch(delColorImages(dataObj));
        break;
      default:
        break;
    }
  }

  return (
    <div className="middle_sku__decoration-color" style={{}}>
      <div>
        <ImgBox classNames="sku_item_image" imgs={skuImages || []} width={"300px"} height={"280px"}></ImgBox>
        <FilePreview
          imgs={skuImages || []}
          delFunc={file => {
            onDell(file, "sku");
          }}
          accept={"image/*"}
          addFunc={file => {
            addImg(file, "sku");
          }}
          disable={!currentSku}
          cropNeed={true}
        />
      </div>

      <div
        className="project_form_wrapper"
        style={{
          width: "90%",
          height: "90%",
          maxWidth: "300px",
          marginLeft: "1em",
        }}
      >
        <Select label={t("Finishing*")} name="material" options={materialsList} multi={true} readOnly={true} />
        <div className="flex_container nowrap " style={{alignItems: "flex-start", gap: "5px"}}>
          <ImgBox classNames="smaller-image-box" imgs={decorImages || []} width={"140"} height={"120px"} />

          <FilePreview
            imgs={decorImages || []}
            delFunc={file => {
              onDell(file, "decor");
            }}
            accept={"image/*"}
            addFunc={file => {
              addImg(file, "decor");
            }}
            disable={!currentSku}
            cropNeed={true}
          />
        </div>
        <Select label={t("Color*")} name="color" width="100%" options={colorsList} multi={true} readOnly={true} />
        <div className="flex_container nowrap " style={{alignItems: "flex-start", gap: "5px"}}>
          <ImgBox classNames="smaller-image-box" imgs={colorImages || []} width={"140"} height={"120px"} />

          <FilePreview
            imgs={colorImages || []}
            delFunc={file => {
              onDell(file, "color");
            }}
            accept={"image/*"}
            addFunc={file => {
              addImg(file, "color");
            }}
            disable={!currentSku}
            cropNeed={true}
          />
        </div>
      </div>
      <div className="autolabel" style={{marginLeft: "15px", width: "100%", height: "98%"}}>
        <div style={{height: "50%"}}>
          <TextAreaInput label={t("Finishing type")} name="materialType" width="95%" height="80%" readOnly={true} />
        </div>
        <div style={{height: "50%"}}>
          <TextAreaInput
            label={t("Color type")}
            id="colorType"
            name="colorType"
            width="95%"
            height="80%"
            readOnly={true}
          />
        </div>
      </div>
    </div>
  );
};

Decoration.propTypes = {imagerefs: PropTypes.array};

export default Decoration;
