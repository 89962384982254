import {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {Formik} from "formik";
import * as Yup from "yup";

import ModalForm from "../../Modal/FormModal";
import {STATUS_COLORS} from "../RequestRegister/colorConstants";
import {fetchRequests, getForReadiness, getRequest, getRequestInfo} from "../RequestsSlice";

import ModalPaymentDocuments from "./modalsInProgress/modalPaymentDocParts/ModalPaymentDocuments";
import PfOperationsModal from "./modalsInProgress/PfOperationsModal/PfOperationsModal";
import ReceiptGoodsModal from "./ReceiptGoodsModal/ReceiptGoodsModal";
import FactoryEditRqsts from "./toFactory/ToFactoryModal";
import {PROGRESS_CARD_TYPES, PROGRESS_CARDS} from "./utils/constants";
import InProgressCard from "./InProgressCard";

const InProgressSector = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [currentType, setCurrentType] = useState(null);
  const [currentLabel, setCurrentLabel] = useState("");
  const [modalActive, setModalActive] = useState(false);

  const currentRequest = useSelector(getRequestInfo);

  useEffect(() => {
    if (modalActive === false && currentType) {
      dispatch(fetchRequests({currentUser: true}));
      dispatch(getRequest(currentRequest.requestId));
    }
  }, [modalActive]);

  const onCardClick = (type, label) => {
    if (type === PROGRESS_CARD_TYPES.FACTORY) {
      navigate("/ba3a/ved/requestToFactoryMod", {state: {currentRequestId: currentRequest.requestId}});
      return;
    }
    setCurrentType(type);
    setCurrentLabel(label);
    setModalActive(true);
  };

  const currentComponent = useMemo(() => {
    switch (currentType) {
      case PROGRESS_CARD_TYPES.PF:
        return PfOperationsModal;

      case PROGRESS_CARD_TYPES.PAYMENT:
        return ModalPaymentDocuments;

      case PROGRESS_CARD_TYPES.RECEIPT:
        return ReceiptGoodsModal;

      case PROGRESS_CARD_TYPES.FACTORY:
        return FactoryEditRqsts;

      default:
        return null;
    }
  }, [currentType]);

  //initial values
  const initialValues = useMemo(() => {
    return {
      sendToFactoryDateS: currentRequest?.requestDates[2]?.plannedDate || "",
      sendToFactoryDateE: currentRequest?.requestDates[2]?.actualDate || "",

      gettingProformaDateS: currentRequest?.requestDates[3]?.plannedDate || "",
      gettingProformaDateE: currentRequest?.requestDates[3]?.actualDate || "",

      proformaControlDateS: currentRequest?.requestDates[4]?.plannedDate || "",
      proformaControlDateE: currentRequest?.requestDates[4]?.actualDate || "",

      forPaymentDateS: currentRequest?.requestDates[5]?.plannedDate || "",
      forPaymentDateE: currentRequest?.requestDates[5]?.actualDate || "",

      onPaymentDateS: currentRequest?.requestDates[6]?.plannedDate || "",
      onPaymentDateE: currentRequest?.requestDates[6]?.actualDate || "",

      forPaymentDate: "",
      onPaymentDate: "",

      plannedReadinessDate: currentRequest?.requestDates[7]?.plannedDate || "",
      actualReadinessDate: currentRequest?.requestDates[7]?.actualDate || "",
      readinessControlDate: currentRequest?.requestDates[8]?.plannedDate || "",

      logist: "",
    };
  }, [currentRequest]);
  // requestStatus: "",
  // requestNumber: "",
  // managerFea: "",
  // factory: "",
  // managerCo: "",
  // dateStart: "",
  // dateEnd: "",
  // note: "",

  //event handlers
  const onInProgressSectorFormSubmit = () => {};
  return (
    <div className="main">
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object().shape({})}
        validateOnChange={true}
        validateOnBlur={true}
        enableReinitialize
        onSubmit={onInProgressSectorFormSubmit}
      >
        <div style={{width: "100%", paddingTop: "2em"}}>
          <div className="list-card">
            {PROGRESS_CARDS.map(
              ({
                statusButton,
                header = "",
                dateStartLabel = "plan",
                dateEndLabel = "fact",
                dateStartName = "",
                dateEndName = "",
                buttonName,
                frame = STATUS_COLORS.TRANSPARENT,
                type,
                label = "",
                descText,
              }) => (
                <InProgressCard
                  key={buttonName}
                  statusButton={statusButton}
                  header={t(header)}
                  dateStartLabel={t(dateStartLabel)}
                  dateEndLabel={t(dateEndLabel)}
                  dateStartName={dateStartName} //value should be replaced to data you need
                  dateEndName={dateEndName} //value should be replaced to data you need
                  // dateStartName={currentRequest?.requestDates[i + 1]?.plannedDate || null}
                  // dateEndName={currentRequest?.requestDates[i + 1]?.actualDate || null}
                  buttonName={t(buttonName)}
                  frame={frame}
                  // disabled={}
                  onClick={() => {
                    onCardClick(type, t(label));
                  }}
                  descText={descText}
                  type={type}
                />
              ),
            )}
          </div>

          {/* <div className="payment">
            <div className="payment-input">
              <Select label={t('Payment scheme')} options={[]} name="payment" width="15em" widthLabel={'9em'} />
              <TextAreaInput
                name="notes"
                label=""
                width={"40em"}
                height="1.4em"
                placeholder={t("Financial payment scheme info")}
              />
            </div>
            <FunctionButton name={t('Save')} />
          </div> */}
        </div>
      </Formik>
      <ModalForm
        closable={true}
        Component={currentComponent}
        label={currentLabel + " " + currentRequest?.requestNumber}
        active={modalActive}
        setModalActive={setModalActive}
        className="inProgressModal"
      />
    </div>
  );
};

export default InProgressSector;
