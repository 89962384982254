import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import FunctionButton from "../../../../../utilities/Buttons/FunctionButton";
import ReactTable from "../../../../conteiners/ReactTable";
import {TextAreaInput} from "../../../../Inputs";
import useColumns from "../../../OrderProcessing/utils/hooks/useColumns";

import {attachFromRequest, removePaymentDocs, selectDoc} from "./redux/PaymentOperationsSlice";
import {getCurrentDocument, getPayment} from "./redux/PaymentSelectors";
import usePaymentsData from "./utils/hook/PaymentDataService";
import usePaymentsDoc from "./utils/hook/paymentDocService";
import {columnsForPayment} from "./columnsForPaymentTables";

const DocumentsForPayment = () => {
  const {t} = useTranslation();

  const columns = useColumns(columnsForPayment);
  const dispatch = useDispatch();

  const {paymentDocs} = usePaymentsData();

  const {onDocSelect, current} = usePaymentsDoc(1);

  return (
    <>
      <div className="header-blocks">{t("Document list for payment")}</div>
      <ReactTable
        defaultData={paymentDocs}
        columns={columns}
        onSelect={onDocSelect}
        current={current}
        className={"project_table"}
        style={{height: "5em"}}
      />
      <TextAreaInput
        name="docNote"
        width={"100%"}
        placeholder={t("Note for factory manager")}
        style={{marginTop: "10px"}}
      />
    </>
  );
};

export default DocumentsForPayment;
