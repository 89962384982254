import {useDispatch, useSelector} from "react-redux";
import {useFormikContext} from "formik";

import useColumns from "../../../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {
  selectCurrentContactId,
  selectCurrentFactory,
  selectCurrentFactoryContacts,
} from "../../../../redux/selectors/FactorySelectors";
import {cleanFactoryContactConditions, setCurrentContactId} from "../../../../redux/slices/FactorySlice";
import {CONTACTS_EDIT_COLUMNS} from "../../../conditions&contactsFactory/utils/constants";
import {checkIfContactValuesChanged} from "../functions/checkIfValuesChanged";
import formNotes from "../functions/formNotes";

import useContactTypeByIdService from "./getContactTypeById";
import useContactTypesOptionsService from "./getContactTypesService";

const useFactoryContactsEditService = () => {
  const dispatch = useDispatch();

  const currentContactId = useSelector(selectCurrentContactId);
  const currentFactoryContacts = useSelector(selectCurrentFactoryContacts);
  const currentFactory = useSelector(selectCurrentFactory);

  const {values} = useFormikContext();
  const currentEmailContact = currentFactory?.contacts?.find(contact => contact.contactId === currentContactId) ?? null;

  const isContactsDeleteBtnDisabled = !currentContactId;
  const isContactsUpdateBtnDisabled = !currentContactId || !checkIfContactValuesChanged(values, currentEmailContact);
  const columns = useColumns(CONTACTS_EDIT_COLUMNS);

  const contactTypeOptions = useContactTypesOptionsService();
  const contactTypesById = useContactTypeByIdService();

  const factoryContactTableData = currentFactoryContacts.map(
    ({contactId, phone, name, notes, contactTypeId}, index) => ({
      id: contactId,
      index: ++index,
      name,
      phone,
      contactType: contactTypesById[contactTypeId],
      cpNotes: formNotes(notes),
    }),
  );

  function onContactsTableRowSelect(id) {
    if (id === currentContactId) {
      dispatch(setCurrentContactId(null));
      dispatch(cleanFactoryContactConditions());
      return;
    }

    dispatch(setCurrentContactId(id));
  }

  return {
    columns,
    contactTypeOptions,
    factoryContactTableData,
    onContactsTableRowSelect,
    isContactsUpdateBtnDisabled,
    isContactsDeleteBtnDisabled,
  };
};

export default useFactoryContactsEditService;
