import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useFormikContext} from "formik";

import {getSuccess} from "../../../../utilities/toasts";
import {TextInput} from "../../../Inputs";
import SingleFilePicker from "../../../Inputs/SingleFilePicker";
import {
  addInvoice,
  addPackingList,
  getRequestInfo,
  selectAddedInvoice,
  selectAddedPackingList,
} from "../../RequestsSlice";

import styles from "./ReceiptGoodsModal.module.scss";

const InvoiceAndPackBlock = ({currentPackingList, currentInvoice, setData, data}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const currentRequest = useSelector(getRequestInfo),
    addedPackingList = useSelector(selectAddedPackingList),
    addedInvoice = useSelector(selectAddedInvoice);

  const {values, setFieldValue} = useFormikContext(),
    {packingList, invoice, PLDate, invDate} = values;

  const onInvoiceAdding = file => {
    const formdata = new FormData();
    let filename = file.name;
    formdata.append("doc", file, filename);
    formdata.append("invoiceNumber", values.invoice);

    if (formdata.has("doc")) {
      dispatch(addInvoice({requestId: currentRequest.requestId, formData: formdata})).then(resp => {
        if (resp.meta.requestStatus === "fulfilled") {
          getSuccess(t("invoice Success"));

          setData([...data, resp.payload.fileUrl]);
        }
      });
    }
  };
  const onPackingAdding = file => {
    const formdata = new FormData();
    let filename = file.name;
    formdata.append("doc", file, filename);
    formdata.append("listNumber", values.packingList);
    if (formdata.has("doc")) {
      dispatch(addPackingList({requestId: currentRequest.requestId, formData: formdata})).then(resp => {
        if (resp.meta.requestStatus === "fulfilled") {
          getSuccess(t("packing Success"));
          setData([...data, resp.payload.fileUrl]);
        }
      });
    }
  };

  useEffect(() => {
    currentPackingList !== null
      ? setFieldValue("packingList", currentPackingList.listNumber)
      : setFieldValue("packingList", "");

    currentInvoice !== null ? setFieldValue("invoice", currentInvoice.invoiceNumber) : setFieldValue("invoice", "");
  }, [currentPackingList, currentInvoice]);

  return (
    <section className={styles.documentsSection}>
      <h2 className={styles.sectionHeader}>
        2. <span className={styles.underLined}>{t("Documentation") + ":"}</span>
      </h2>
      <div className={styles.sectionRow}>
        <TextInput label={t("Download Packing List №") + ":"} name="packingList" question="d numb" width="13em" />
        <SingleFilePicker
          disabled={
            packingList === "" || currentPackingList?.listNumber || addedPackingList?.listNumber || PLDate === ""
          }
          addFunc={onPackingAdding}
          accept={".pdf"}
        />

        <TextInput name="PLDate" label={t("Date PL") + ":"} question="last upload doc" />
      </div>
      <div className={styles.sectionRow}>
        <TextInput label={t("Download Invoice №") + ":"} width="15em" name="invoice" question="inv nim" />
        <SingleFilePicker
          disabled={invoice === "" || currentInvoice?.invoiceNumber || addedInvoice?.invoiceNumber || invDate === ""}
          addFunc={onInvoiceAdding}
          accept={".pdf"}
        />

        <TextInput name="invDate" label={t("Date Inv") + ":"} question="last upload inv" />
      </div>
    </section>
  );
};

export default InvoiceAndPackBlock;
