import {createSlice, isAnyOf} from "@reduxjs/toolkit";

import {
  addCondition,
  addContact,
  addContactNote,
  addControlTerms,
  addEmailTemplates,
  addFactory,
  addFactoryNote,
  cancelFactoryCreation,
  cancelFactoryUpdate,
  deleteCondition,
  deleteContact,
  deleteControlTerms,
  deleteEmailTemplates,
  fetchAllConditions,
  fetchAllContacts,
  fetchAllFactories,
  fetchContact,
  fetchControlTerms,
  fetchEmailTemplates,
  fetchFactory,
  fetchFactoryConditions,
  fetchFactoryContactConditions,
  fetchFilters,
  fetchReferences,
  fetchSupplyCondition,
  saveFactory,
  updateCondition,
  updateContact,
  updateFactory,
  updateTermsForCondition,
} from "../operations/FactoryOperations";

//extraactions from operations
const extraActions = [
  fetchAllFactories,
  fetchFactory,
  fetchReferences,
  addFactory,
  saveFactory,
  cancelFactoryCreation,
  fetchAllConditions,
  addCondition,
  fetchAllContacts,
  fetchContact,
  addContact,
  addFactoryNote,
  addContactNote,
  cancelFactoryUpdate,
  deleteCondition,
  fetchFilters,
  fetchSupplyCondition,
  updateCondition,
  updateContact,
  updateFactory,
  fetchFactoryContactConditions,
  fetchFactoryConditions,
  deleteContact,
  fetchControlTerms,
  addControlTerms,
  updateTermsForCondition,
  deleteControlTerms,
];
const getItems = type => extraActions.map(action => action[type]);

const initialState = {
  factoriesLoading: false,
  error: [],
  factories: null,
  currentFactory: null,
  currentFactoryId: null,
  references: null,
  conditions: null,
  newCondition: null,
  currentFactoryConditions: null,
  factoryContactConditions: null,
  currentConditionId: null,
  contacts: null,
  newContact: null,
  currentContactId: null,
  emailTemplates: [],
  currentFactoryName: null,
  refs: {
    countries: null,
    factories: null,
  },
  controlTerms: null,
};

const FactorySlice = createSlice({
  name: "factories",
  initialState,
  reducers: {
    setCurrentFactoryId: (state, {payload}) => {
      state.currentFactoryId = payload;
    },
    setCurrentContactId: (state, {payload}) => {
      state.currentContactId = payload;
      state.newContact = null;
    },
    setCurrentConditionId: (state, {payload}) => {
      state.currentConditionId = payload;
      state.newCondition = null;
    },
    factoryCleanCurrent: state => {
      state.currentFactory = null;
      state.newContact = null;
      state.currentConditionId = null;
      state.currentContactId = null;
      state.newCondition = null;
      state.newContact = null;
      state.currentFactoryConditions = null;
      state.factoryContactConditions = null;
    },
    cleanFactoryContactConditions: state => {
      state.factoryContactConditions = null;
    },
    setCurrentFactoryName: (state, {payload}) => {
      state.currentFactoryName = payload || null;
    },
    setEmailTemplates: (state, {payload}) => {
      state.emailTemplates = payload || null;
    },
    cleanControlTerms: state => {
      state.controlTerms = null;
    },
  },
  extraReducers: builder => {
    builder
      //*** FACTORIES ***
      //for get requests
      .addCase(fetchAllFactories.fulfilled, (state, {payload}) => {
        state.factories = payload;
      })
      .addCase(fetchFactory.fulfilled, (state, {payload}) => {
        state.currentFactory = payload;
      })
      .addCase(fetchReferences.fulfilled, (state, {payload}) => {
        state.references = payload;
      })
      //for post requests
      .addCase(addFactory.fulfilled, (state, {payload}) => {
        state.currentFactory = payload;
        state.currentFactoryId = payload.factoryId;
      })
      //for patch requests
      .addCase(saveFactory.fulfilled, (state, {payload}) => {
        state.conditions = [...(state.conditions ?? []), payload];
        state.currentFactoryId = null;
        state.currentContactId = null;
        state.currentFactory = null;
        state.newContact = null;
        state.currentConditionId = null;
        state.newCondition = null;
        state.newContact = null;
        state.currentFactoryConditions = null;
        state.factoryContactConditions = null;
      })
      .addCase(updateFactory.fulfilled, (state, {payload}) => {
        state.currentFactory = {...state.currentFactory, payload};
        state.conditions = state.conditions.map(condition => {
          if (condition.factory.factoryId === payload.factoryId) {
            const {factoryId, address, location, webSite} = payload;
            condition.factory = {...condition.factory, factoryId, address, location, webSite};
          }
          return condition;
        });
      })

      //for delete requests
      .addCase(cancelFactoryCreation.fulfilled, state => {
        state.currentFactory = null;
        state.newContact = null;
        state.newCondition = null;
        state.currentConditionId = null;
        state.currentContactId = null;
        state.currentFactoryConditions = null;
        state.factoryContactConditions = null;
        state.currentFactoryId = null;
      })
      .addCase(cancelFactoryUpdate.fulfilled, state => {
        state.currentFactory = null;
        state.currentContactId = null;
        state.currentConditionId = null;
      })

      //*** SUPPLY CONDITIONS ***
      //for get requests
      .addCase(fetchAllConditions.fulfilled, (state, {payload}) => {
        state.conditions = payload;
      })
      .addCase(fetchFactoryContactConditions.fulfilled, (state, {payload}) => {
        state.factoryContactConditions = payload;
      })
      .addCase(fetchFactoryConditions.fulfilled, (state, {payload}) => {
        state.currentFactoryConditions = payload;
      })
      .addCase(fetchFilters.fulfilled, (state, {payload}) => {
        state.refs.countries = payload.countries;
        state.refs.factories = payload.factories;
      })
      .addCase(fetchSupplyCondition.fulfilled, (state, {payload}) => {
        state.currentConditionId = payload.conditionId;
        // state.currentFactoryConditions
      })
      //for post requests
      .addCase(addCondition.fulfilled, (state, {payload}) => {
        state.newCondition = payload;
        state.currentConditionId = payload.conditionId;
        state.factoryContactConditions = [...(state.factoryContactConditions ?? []), payload];
        state.currentFactory.supplyConditions = [...(state.currentFactory.supplyConditions ?? []), payload];
      })
      //for patch requests
      .addCase(updateCondition.fulfilled, (state, {payload}) => {
        state.factoryContactConditions = state.factoryContactConditions.map(condition => {
          if (condition.conditionId === state.currentConditionId) {
            condition = payload;
          }
          return condition;
        });
        state.currentFactoryConditions = state.currentFactoryConditions.map(condition => {
          if (condition.conditionId === state.currentConditionId) {
            condition = payload;
          }
          return condition;
        });
      })
      //for delete requests
      .addCase(deleteCondition.fulfilled, (state, {meta}) => {
        state.currentConditionId = null;
        const conditionId = meta.arg;
        state.conditions = state.conditions.filter(condition => condition.conditionId !== conditionId);
        state.factoryContactConditions = state.factoryContactConditions.filter(
          condition => condition.conditionId !== conditionId,
        );
      })

      //*** REPRESENTATIVE CONTACTS ***
      //for get requests
      .addCase(fetchAllContacts.fulfilled, (state, {payload}) => {
        state.contacts = payload;
      })
      .addCase(fetchContact.fulfilled, (state, {payload}) => {
        state.newContact = payload;
      })
      //for post requests
      .addCase(addContact.fulfilled, (state, {payload}) => {
        state.currentFactory.contacts = [...(state.currentFactory.contacts ?? []), payload];
        state.newContact = payload;
        state.currentContactId = payload.contactId;
      })
      //for patch requests
      .addCase(updateContact.fulfilled, (state, {payload}) => {
        state.currentFactory.contacts = state.currentFactory.contacts.map(contact =>
          contact.contactId === payload.contactId ? payload : contact,
        );
        state.conditions = state.conditions.map(condition => {
          if (condition.contactId === payload.contactId) {
            const {contactId, email, name, phone} = payload;
            condition.representativeContact = {...condition.representativeContact, contactId, email, name, phone};
          }
          return condition;
        });
        state.currentFactoryConditions = state.currentFactoryConditions.map(condition => {
          if (condition.contactId === payload.contactId) {
            condition.representativeContact = {...condition.representativeContact, ...payload};
          }
          return condition;
        });
      })
      //for delete requests
      .addCase(deleteContact.fulfilled, (state, {meta}) => {
        state.currentContactId = null;
        const contactId = meta.arg;
        state.newContact = null;
        state.contacts = state.contacts.filter(contact => contact.contactId !== contactId);
        state.currentFactory.contacts = state.currentFactory.contacts.filter(
          contact => contact.contactId !== contactId,
        );
      })

      //*** EMAIL TEMPLATES ***
      .addCase(fetchEmailTemplates.fulfilled, (state, {payload}) => {
        state.emailTemplates = payload;
      })
      .addCase(addEmailTemplates.fulfilled, (state, {payload}) => {
        state.emailTemplates.push(payload);
      })
      .addCase(deleteEmailTemplates.fulfilled, (state, {meta}) => {
        state.emailTemplates = state.emailTemplates.filter(templ => templ.templateId !== meta.arg);
      })

      //*** CONTROL TERMS ***
      .addCase(fetchControlTerms.fulfilled, (state, {payload}) => {
        state.controlTerms = payload;
      })
      .addCase(addControlTerms.fulfilled, (state, {payload}) => {
        state.controlTerms = payload;
      })
      .addCase(updateTermsForCondition.fulfilled, (state, {payload}) => {
        state.controlTerms = payload;
      })
      .addCase(deleteControlTerms.fulfilled, state => {
        state.controlTerms = [];
      })
      //matchers
      .addMatcher(isAnyOf(...getItems("fulfilled"), state => handleFulfilled(state)))
      .addMatcher(isAnyOf(...getItems("pending"), state => handlePending(state)))
      .addMatcher(isAnyOf(...getItems("rejected"), state => handleRejected(state)));
  },
});

//matcher functions
function handleFulfilled(state) {
  state.factoriesLoading = false;
  state.error = [];
}
function handlePending(state) {
  state.factoriesLoading = true;
  state.error = [];
}
function handleRejected(state, error) {
  state.factoriesLoading = false;
  state.error = error;
}

//actions, reducer
const {actions, reducer} = FactorySlice;
export const {
  setCurrentFactoryId,
  setCurrentContactId,
  setCurrentConditionId,
  factoryCleanCurrent,
  setCurrentFactoryContacts,
  cleanFactoryContactConditions,
  setCurrentFactoryName,
  setEmailTemplates,
  cleanControlTerms,
} = actions;
export default reducer;
