import {useEffect, useMemo, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Form, Formik} from "formik";

import {TextAreaInput, TextInput} from "../../../../Inputs";
import NewPdfViewer from "../../../../Project/Documents/DocumentView/NewPdfViewer";
import {getRequestInfo} from "../../../RequestsSlice";

import {getPaymentsForRequest} from "./redux/PaymentOperationsSlice";
import {getCurrentDocument, getPayment} from "./redux/PaymentSelectors";
import usePaymentsData from "./utils/hook/PaymentDataService";
import ForPaymentPart from "./ForPaymentPart";
import OnPaymentPart from "./OnPaymentPart";

const ModalPaymentDocuments = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const currentRequest = useSelector(getRequestInfo);
  const payment = useSelector(getPayment);
  const currentDoc = useSelector(getCurrentDocument);

  const initialValues = useMemo(
    () => ({
      onPaymentPlan: currentRequest?.requestDates?.[5]?.plannedDate || "",
      onPaymentFact: currentRequest?.requestDates?.[5]?.actualDate || "",
      doc: "",
      documentType: "",
      docNote: "",
      requestNote: currentRequest?.note || "",
      onPaymentConfirmPlan: currentRequest?.requestDates?.[6]?.plannedDate || "",
      onPaymentConfirmFact: currentRequest?.requestDates?.[6]?.actualDate || "",
    }),
    [payment],
  );
  const onPaymentDocsSubmit = () => {};

  useEffect(() => {
    dispatch(getPaymentsForRequest(currentRequest.requestId));
  }, []);

  return (
    <Formik initialValues={initialValues} enableReinitialize onSubmit={onPaymentDocsSubmit}>
      <Form>
        <div className="paynent-main">
          <div className="left-part">
            <ForPaymentPart />
            <OnPaymentPart />
          </div>

          <div className="right-part">
            <TextAreaInput
              name="requestNote"
              width={"100%"}
              placeholder={t("Note for factory manager")}
              readOnly={true}
            />
            <div className="kpPreview__box" style={{height: "100%"}}>
              <NewPdfViewer fileArray={currentDoc?.doc ? [currentDoc?.doc.fileUrl] : []} />
            </div>
          </div>
        </div>
      </Form>
    </Formik>
  );
};

export default ModalPaymentDocuments;
