import {useEffect, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Form, useFormikContext} from "formik";
import PropTypes from "prop-types";

import {useDebouncedCallback} from "../../../hook/debounced";
import {BUTTON_TYPES} from "../../../utilities/Buttons/constants";
import {checkEmptyFields} from "../../../utilities/checkEmptyFields";
import {dateFormat} from "../../../utilities/dateFormat";
import QuestionItem from "../../../utilities/QuestionItem";
import {DateInput, TextInput} from "../../Inputs";
import {Select} from "../../Inputs/SelectField";
import {FilterButton} from "../../SKU/VED/Filters/SkuFilters";
import {fetchFilters, fetchMixedRequests, getAllStatusRequest, getRequestRegistrFilters} from "../RequestsSlice";

import useRequestFilters from "./../Filters/filters.hook";

const RequestFilter = ({currentUserTrue, children}) => {
  const {t, i18n} = useTranslation();

  const {values, setValues, initialValues} = useFormikContext();

  const {brandsOptions, feaManagersOptions, coManagersOptions, requestStatusOptions} = useRequestFilters();

  const allReq = useSelector(getAllStatusRequest);

  const AppNumberOptions = useMemo(() => {
    return allReq.length > 0
      ? allReq.map(item => {
          return {title: item.requestNumber, value: item.number};
        })
      : [];
  }, [allReq]);

  const onFilter = () => {
    const {requestStatus, requestNumber, managerCo, managerFea, factory, notes, dateStart, dateEnd} = values;
    var params = checkEmptyFields({
      requestNumber: requestNumber,
      statusId: requestStatus,
      managerFeaId: managerFea,
      managerCoId: managerCo,
      brandId: factory,
      note: notes,
      dateStart: new Date(dateStart).toJSON() || "",
      dateEnd:  new Date(dateEnd).toJSON() || "",
    });
    delayedFilter(params);
  };

  const updateFilterQuery = params => {
    if (currentUserTrue) {
      params.currentUser = true;
    }
    dispatch(fetchMixedRequests(params));
  };

  const dispatch = useDispatch();

  const delayedFilter = useDebouncedCallback(updateFilterQuery, 500);
  const delayedFilterArticle = useDebouncedCallback(updateFilterQuery, 2000);

  const selectRequest = id => {};

  // useEffect(() => {
  //   dispatch(fetchRequestCatalogs());
  //   dispatch(fetchRequests({ page: 1 }));
  // }, []);

  const delFilters = () => {
    if (currentUserTrue) {
      updateFilterQuery({currentUser: true});
    } else {
      updateFilterQuery();
    }

    setValues(initialValues);
  };

  return (
    // <div className="filter-wrapper">

    <Form className="" style={{width: "100%"}}>
      <div
        style={{
          gap: "1em",
          alignItems: "flex-start",
          width: "100%",
        }}
        className="fc jcfs autolabel nowrap"
      >
        <div className="flex_container" style={{flexDirection: "column"}}>
          <FilterButton
            onFilter={onFilter}
            delFilter={delFilters}
            questionTitle={t("Filter")}
            type1={BUTTON_TYPES.SUBMIT}
          />
          {children}
        </div>
        <div className="grid2_5 fGrow">
          <Select
            label={t("Request status")}
            name="requestStatus"
            options={requestStatusOptions}
            multi={true}
            width="100%"
          />
          <Select label={t("Request №")} name="requestNumber" options={AppNumberOptions} multi={true} width="100%" />
          <Select label={t("CO Manager")} name="managerCo" options={coManagersOptions} width="100%" multi={true} />
          <Select label={t("Factory")} name="factory" options={brandsOptions} width="100%" multi={true} />
          <div className="period-wrapper">
            <div className="fc gap5">
              <QuestionItem title={"Select date"} />
              {t("Period")}
            </div>
            <div className="request-date-wrapper">
              <DateInput label={t("From")} name="dateStart" />
              <DateInput label={t("To")} name="dateEnd" />
            </div>
          </div>
          <TextInput
            label="Pf №"
            name="pf"
            width="100%"
            // readOnly={true}
            // style={{width: "15.5em", marginRight: "4em"}}
          />
          <TextInput
            label={t("Invoice №")}
            name="invoice"
            width="100%"
            // readOnly={true}
            // style={{width: "14em", marginRight: "5.7em"}}
          />

          <Select label={t("FEA Manager")} name="managerFea" options={feaManagersOptions} multi={true} width="100%" />
          <TextInput label={t("Notes")} name="notes" type="text" width="100%" />
        </div>
      </div>
    </Form>
  );
};

// const FilterButton = props => {
//   const { t } = useTranslation();
//   return (
//     <div className="fc nowrap">
//       <ToolTip title={t("Filter")}>
//         <ImageButton src={filterImg} alt="filter" onClick={props.onFilter} width={2} height={2} />
//       </ToolTip>

//       <ImageButton src={filterOffImg} alt="filter off" onClick={props.delFilter} width={2} height={2} />
//     </div>
//   );
// };

RequestFilter.propTypes = {currentUserTrue: PropTypes.bool, children: PropTypes.node};

export default RequestFilter;
