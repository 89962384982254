import {useEffect, useMemo, useRef} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Field, Form, Formik, useFormikContext} from "formik";
import PropTypes from "prop-types";
import Swal from "sweetalert2";
import * as Yup from "yup";

import {designersCatalogSelector, managerWithOfficeSelector} from "../../../selectors/CatalogSelectors";
import {getCurrentProject} from "../../../selectors/generalSelectors";
import {createKp, deleteKp, kpCreated, updateKp} from "../../../slices/KPSlice";
import FunctionButton from "../../../utilities/Buttons/FunctionButton";
import ControlFieldsChanges from "../../../utilities/ControllChanges";
import {dateFormat} from "../../../utilities/dateFormat";
import {DateInput, NoteInput, Select, TextInput} from "../../Inputs";

import remindImg from "./src/Data_Time_yellow.png";

import "./src/AddKPForm.scss";

const ProjectAddKPForm = ({updates, onMakeEffect, setIsEdited}) => {
  const {t, i18n} = useTranslation();

  const dispatch = useDispatch();
  const {currentKp, kpIdList} = useSelector(state => state.kp);
  const currentProject = useSelector(getCurrentProject);

  const kpCatalog = kpIdList.map(kp => {
    return {value: kp};
  });
  const designersCatalog = useSelector(designersCatalogSelector);
  const managersCatalog = useSelector(managerWithOfficeSelector);

  const formikRef = useRef();

  const onKpDelete = () => {
    if (currentKp) {
      Swal.fire({
        title: [t("Sure")],
        text: [t("Irrevocably")],
        icon: "question",
        confirmButtonText: [t("Yes")],
        showCancelButton: true,
        cancelButtonText: [t("No")],
      }).then(value => {
        if (value.isConfirmed) {
          dispatch(deleteKp(currentKp.coId));
        }
      });
    } else {
      Swal("Спочатку оберіть кп");
    }
  };

  // функция срабатывает когда выбор kp происходит из инпута в форме. Принимает айди проекта и устанавливает айди текущего проекта
  const makeEffect = id => {
    if (kpIdList.includes(id)) {
      onMakeEffect(id);
    }
  };

  ////  for table
  const today = useMemo(() => {
    var t = new Date();
    t.setHours(0);
    t.setMinutes(0);
    t.setSeconds(0);
    t.setMilliseconds(0);
    return t;
  }, []);

  const initialValues = useMemo(() => {
    return {
      coId: "",
      managerCoName: currentProject ? currentProject.userName : "",
      managerCoId: currentProject ? currentProject.userId : "",
      note: "",
      startDate: "",
      finalDate: "",
      remind: "",
      designerName: currentProject ? currentProject.designerName : "",
      designerId: currentProject ? currentProject.designerId : null,
      designerBonus: currentKp?.bonus || currentKp?.designerBonus,
      status: "",
      orgName: currentProject?.orgStructure[i18n.language === "en" ? "fullNameEng" : "fullNameUkr"] || "",
      orgStructureId: currentProject ? currentProject.orgStructure.orgStructureId : "",
    };
  }, [currentProject]);

  const onNoteRed = note => {
    if (currentKp) {
      dispatch(updateKp({newKp: {note: note}, coId: currentKp.coId})).then(res => {
        setIsEdited(false);
      });
    }
  };

  const onUpdate = values => {
    if (new Date(values.startDate) > new Date(values.finalDate)) {
      console.log("no no ");
      formikRef.current.setErrors({finalDate: "Later than"});
      return;
    }
    const kp = {
      managerCoId: values.managerCoId,
      orgStructureId: values.orgStructureId,
      designerId: !values.designerId || values.designerId === "" ? null : values.designerId,
      designerBonus: values.designerBonus,
      startDate: values.startDate,
      finalDate: values.finalDate,
      note: values.note,
      designerName: values.designer?.name,
      managerCoName: values.managerCo?.name,
    };
    // console.log(new Date(values.startDate));

    dispatch(updateKp({newKp: kp, coId: currentKp.coId}));
  };
  const validatingObj = Yup.object().shape({
    startDate: currentKp
      ? Yup.date().required([t("Enter date")])
      : Yup.date()
          .min(today, `${[t("Not earlier")]} ${today.toLocaleDateString()}`)
          .required([t("Enter date")]),
    finalDate: Yup.date()
      .required([t("Enter date")])
      .min(Yup.ref("startDate"), ({min}) => `${[t("Later than")]} ${min.toLocaleDateString()}!`),
    managerCoId: Yup.number().required([t("Existing M")]),
  });

  const currentFormState = useMemo(() => {
    if (!currentKp) {
      return initialValues;
    }
    return {
      ...currentKp,
      orgName: currentKp.orgStructure[i18n.language === "en" ? "fullNameEng" : "fullNameUkr"],
      designerBonus: currentKp?.bonus || currentKp?.designerBonus,
      designerName: currentKp?.designer?.name,
      managerCoName: currentKp?.managerCo?.name,
    };
  }, [currentKp, currentProject]);

  const onCreateKp = values => {
    dispatch(
      createKp({
        projectId: currentProject.projectId,
        newCo: {
          managerCoId: values.managerCoId,
          designerId: values.designerId,
          orgStructureId: values.orgStructureId,
          bonus: values.designerBonus,
          startDate: values.startDate,
          finalDate: values.finalDate,
          note: values.note,
        },
      }),
    ).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        const {
          payload: {co},
        } = res;

        dispatch(
          kpCreated({
            ...co,
            managerCoId: co.managerCoId,
            managerCoName: co.managerCo.name,
            designerName: co.designer?.name || "",
            designerId: co.designerId || null,
            designerBonus: co.designerBonus || null,
          }),
        );
      }
    });
  };

  const onSubmitForm = values => {
    if (currentKp) {
      onUpdate(values);
      return;
    } else {
      onCreateKp(values);
    }
  };

  const onForcedUpd = () => {
    if (formikRef.current) {
      formikRef.current.submitForm(); // Вызываем метод submitForm() через реф
    }
  };

  return (
    <Formik
      initialValues={currentFormState}
      enableReinitialize
      validateOnChange={true}
      validateOnBlur={true}
      validationSchema={validatingObj}
      onSubmit={onSubmitForm}
      innerRef={formikRef}
    >
      {({values, errors}) => (
        <Form className="add-kp-form">
          <ControlFieldsChanges editFunc={setIsEdited} forcedUpdate={updates} updFunc={onForcedUpd} />
          <Strange />
          <div className="add-kp-form__container">
            <Select
              label={t("CO №")}
              name="coNumber"
              options={kpCatalog}
              width="69%"
              makeEffect={option => {
                makeEffect(option.value);
              }}
            />
            <div style={{position: "relative"}}>
              <Select label={t("CO Manager")} name="managerCoName" width="69%" options={managersCatalog} multi={true} />
            </div>
            <Select label={t("Designer")} name="designerName" width="69%" options={designersCatalog} multi={true} />

            <TextInput
              label={t("Designer %")}
              name="designerBonus"
              type="number"
              width="66%"
              className="procent"
              min="0"
              max="100"
              step="any"
              question={t("Des fee")}
            />
          </div>

          <div className="add-kp-form__container">
            <div
              className=" flex_container "
              style={{width: "100%", justifyContent: "space-between", marginBottom: "10px"}}
            >
              <DateInput label={t("From")} name="startDate" readOnly={!!currentKp} />
              <DateInput label={t("To")} name="finalDate" />

              {/* <div className="" style={{display: "flex", flexWrap: "nowrap"}}>
              <img
                src={remindImg}
                alt="remind"
                style={{height: "16px", width: "16px", marginTop: "1px"}}
                draggable="false"
              />
              <Field label="" id="remind" name="remind" type="checkbox" />
            </div> */}
            </div>
            <TextInput
              label={t("Offic")}
              name="orgName"
              autolabel
              labelStyles={{width: "3em"}}
              width="88.5%"
              readOnly={true}
            />
            <div className="fixed_button__container" style={{marginTop: "5%"}}>
              <FunctionButton
                type="submit"
                name={t("Create CO")}
                disabled={currentKp || !currentProject ? true : false}
              />

              <FunctionButton
                name={t("Delete CO")}
                className="fixed_button fixed_button__delete "
                // disabled={!currentKp}
                disabled={true}
                onClick={onKpDelete}
              />
            </div>
          </div>
          <div className="" style={{width: "100%"}}>
            <NoteInput
              label={t("About")}
              action={onNoteRed}
              labelStyles={{width: "8.3em"}}
              name="note"
              type="text"
              width="100%"
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

const Strange = () => {
  const {values, validateForm} = useFormikContext();
  useEffect(() => {
    validateForm();
  }, [values.managerKpId]);
};

ProjectAddKPForm.propTypes = {updates: PropTypes.bool, onMakeEffect: PropTypes.func, setIsEdited: PropTypes.func};

export default ProjectAddKPForm;
