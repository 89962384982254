import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {Form, Formik} from "formik";
import Swal from "sweetalert2";
import * as Yup from "yup";

import {getOrgStructures} from "../../../selectors/CatalogSelectors";
import {registrClient} from "../../../slices/PersonSlice";
import FunctionButton from "../../../utilities/Buttons/FunctionButton";
import {InvisibleInput, Select, TextInput} from "../../Inputs";

const AddClient = () => {
  const {t, i18n} = useTranslation();

  const persons = useSelector(state => state.person.persons);
  const orgStructures = useSelector(getOrgStructures);
  // const orgStructuresList = useSelector(getOrgStructuresList);

  const orgStructuresList = useMemo(() => {
    if (orgStructures && orgStructures.length > 0) {
      return orgStructures.map(item => {
        return {
          value: item[i18n.language === "en" ? "fullNameEng" : "fullNameUkr"],
          orgShortName: item.shortName,
          orgStructureId: item.orgStructureId,
        };
      });
    } else {
      return [];
    }
  }, [orgStructures]);

  const orgStructuresCodeList = useMemo(() => {
    if (orgStructures && orgStructures.length > 0) {
      return orgStructures.map(org => {
        return {
          orgFullName: org[i18n.language === "en" ? "fullNameEng" : "fullNameUkr"],
          value: org.shortName,
          orgStructureId: org.orgStructureId,
        };
      });
    } else {
      return [];
    }
  }, [orgStructures]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const persList = persons.map(item => {
    return {
      value: item.fullName,
      personaId: item.personaId,
    };
  });

  const initialValues = useMemo(() => {
    return {
      fullName: "",
      personaId: "",
      orgFullName: "",
      orgStructureId: "",
      orgShortName: "",
      notes: "",
    };
  }, []);

  const findPersonFullName = id => {
    var persona_ = persons.find(per => per.personaId === +id);
    return persona_ ? persona_.fullName : "";
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          fullName: Yup.string().required(),
          // orgStructureId:Yup.string().required('Такого офісу не існує')
        })}
        validateOnChange={false}
        validateOnBlur={false}
        enableReinitialize
        onSubmit={(values, {resetForm}) => {
          var persona = persons.find(persona => persona.fullName === values.fullName);

          if (persona) {
            // console.log("person find");
            dispatch(
              registrClient({
                personaId: +persona.personaId,
                orgStructureId: +values.orgStructureId,
              }),
            ).then(() => {
              resetForm();
            });
          } else {
            Swal.fire({
              title: [t("Doesnt exist")],
              text: [t("Go to creation")],
              icon: "question",
              confirmButtonText: [t("Yes")],
              showCancelButton: true,
              cancelButtonText: [t("No")],
            }).then(value => {
              if (value.isConfirmed) {
                navigate(`/ba3a/admin/addPerson/${value.fullName.split(" ").join("&")}`);
              }
            });
          }
        }}
      >
        {({
          // values, errors, meta, isSubmitting, resetForm, handleChange,
          setFieldValue,
        }) => (
          <Form className="project_form">
            <TextInput
              label={t("Personal id")}
              name="personaId"
              func={e => {
                setFieldValue("fullName", findPersonFullName(e));
              }}
            />
            <Select
              label={t("Full Name")}
              name="fullName"
              type="text"
              width="67%"
              multi={true}
              minFilterLength={1}
              options={persList}
            />

            <InvisibleInput name="orgStructureId" />
            <div className="flex_container nowrap autolabel">
              <Select
                label={t("Office")}
                name="orgFullName"
                type="text"
                width="60%"
                multi={true}
                options={orgStructuresList}
              />

              <Select
                label={t("Office code")}
                name="orgShortName"
                type="text"
                width="60%"
                multi={true}
                minFilterLength={1}
                options={orgStructuresCodeList}
              />
            </div>

            {/* <TextAreaInput
                                label="Примітки по Персоні:" 
                                name="notes"
                                width="40%"
                            /> */}
            <div className="flex_container space-between">
              <FunctionButton
                name={t("Add person")}
                style={{left: "2em"}}
                onClick={() => {
                  navigate("/ba3a/admin/addPerson");
                }}
              />

              <FunctionButton type="submit" name={t("Register")} />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AddClient;
