import {useTranslation} from "react-i18next";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import PropTypes from "prop-types";

import {apiUrl} from "../../api/baseURL";
import FunctionButton from "../../utilities/Buttons/FunctionButton";

// Загрузка шрифтов для pdfmake
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const generatePdf = tableData => {
  // Определение стилей для таблицы
  const styles = {
    tableHeader: {
      bold: true,
      fontSize: 8,
      color: "black",
      alignment: "center",
    },
    cell: {
      fontSize: 7,
      color: "black",
      alignment: "center",
    },
    justy: {
      alignment: "center",
    },
    nestedTable: {
      margin: [0, 0, 0, 0],
      alignment: "left", // Убираем отступы внутри ячейки с вложенной таблицей
    },
  };
  console.log(tableData);
  // Определение содержимого таблицы
  const tableContent = [];

  var imagesDef = {}; /// перечень картинок которые будут во всём пдф

  var header = [];
  header.push({text: "№", style: "tableHeader", fillColor: "#ADD8E6"});
  tableData.headerRow.forEach((th, i) => {
    header.push({text: th, style: "tableHeader", fillColor: "#ADD8E6"});
  });
  tableContent.push(header);

  var imgCount = 1;
  var decorimgCount = 1,
    colorimgCount = 1,
    overalVimgCount = 1;

  const decorimgCountPlus = () => {
    decorimgCount++;
  };
  const colorimgCountPlus = () => {
    colorimgCount++;
  };
  const overalVimgCountPlus = () => {
    overalVimgCount++;
  };

  const makeImageGroup = (images, count, onImgPlus, naming, colSpan, centered) => {
    var cell = [];
    var rowImgCount = count;
    if (images && images?.length !== 0) {
      images.forEach((img, j) => {
        if (img) {
          var imgWidth = 50;
          if (centered) {
            var alignment = "center";
            var width = "*";
            if (images.length > 1) {
              if (j === Math.trunc(images.length / 2) && images.length !== 2) {
                alignment = "center";
              } else if (j + 1 <= images.length / 2) {
                alignment = "right";
              } else if (j + 1 > images.length / 2) {
                alignment = "left";
              }
            }

            if (j !== 0 && j !== images.length - 1) {
              width = imgWidth;
            }

            cell.push({
              image: `${naming}${rowImgCount}`,
              fit: [imgWidth, imgWidth],
              width: width,
              alignment: alignment,
            });
          } else {
            cell.push({
              image: `${naming}${rowImgCount}`,
              fit: [imgWidth, imgWidth],
              width: imgWidth,
              alignment: "left",
            });
          }
          imagesDef[`${naming}${rowImgCount}`] = `${apiUrl}/${img}`;
          onImgPlus();
          rowImgCount++;
        }
      });

      return {
        colSpan: colSpan, // Об'єднання всіх стовпців у рядку
        columns: cell,
        columnGap: 10,
        pageBreak: "avoid",
        alignment: "left",
        border: [false, true, true, false],
      };
    } else return null;
  };

  const charTypeCellsEnabled = !!tableData.typeCells;
  const colspan = charTypeCellsEnabled
    ? tableData.dataRows[0].length - tableData.collSpanBeforeCharCell - 1
    : tableData.dataRows[0].length;

  tableData.dataRows.forEach((rowData, i) => {
    const {colorImages, overallView, decorImages} = tableData.rowImages[i];
    const nestedTableContent = [];
    const decorImgCell = makeImageGroup(
      decorImages,
      decorimgCount,
      decorimgCountPlus,
      "decorImg",
      colspan,
      !charTypeCellsEnabled,
    );

    const overallViewImgCell = makeImageGroup(
      overallView,
      overalVimgCount,
      overalVimgCountPlus,
      "ovImg",
      tableData.dataRows[0].length,
      true,
    );
    const colorImgCell = makeImageGroup(
      colorImages,
      colorimgCount,
      colorimgCountPlus,
      "colorImg",
      colspan,
      !charTypeCellsEnabled,
    );
    var header = null;
    var rowSpan = overallViewImgCell ? 2 : 1;

    if (tableData.headerStringData[i]) {
      rowSpan++;
      header = {
        colSpan: rowData.length, // Об'єднання всіх стовпців у рядку
        alignment: "center",
        stack: [{text: tableData.headerStringData[i]}],
        pageBreak: "avoid",
        fillColor: "#ADD8E6",
      };
    }

    const tableRow = [];
    tableRow.push({
      rowSpan: rowSpan,
      text: i + 1,
      style: "cell",
      border: [true, true, true, true],
      pageBreak: "avoid",
      alignment: "center",
    });
    if (header || overallViewImgCell) tableRow.push(header || overallViewImgCell);

    /// рядок вкладеноЇ таблиці з данними
    const nestedTableRow = [];

    rowData.forEach(cell => {
      if (typeof cell === "object") {
        var imagesCell = [];
        var imgWidth = cell.length === 1 ? tableData.widths[6] : tableData.widths[6] / 2;
        cell.forEach(img => {
          if (img) {
            imagesCell.push({image: `image${imgCount}`, fit: [imgWidth, imgWidth]});
            imagesDef[`image${imgCount}`] = `${apiUrl}/${img}`;
            imgCount++;
          }
        });

        nestedTableRow.push({
          style: "justy",
          alignment: "center",
          columns: imagesCell,
          pageBreak: "avoid",
          border: [false, true, true, false],
        });
      } else
        nestedTableRow.push({
          text: cell,
          style: "cell",
          pageBreak: "avoid",
          alignment: "center",
          border: [false, true, true, false],
        });
    });

    nestedTableContent.push(nestedTableRow);

    const formImgRowWithTypeCell = (type, imgCell) => {
      const row = [];

      rowData.forEach((cell, j) => {
        if (j === 0) {
          row.push({text: "", colSpan: tableData.collSpanBeforeCharCell, border: [false, true, true, false]});
        } else if (j === tableData.collSpanBeforeCharCell) {
          //
          row.push({text: tableData.typeCells[i][type] || "", style: "cell", border: [false, true, true, false]});
        } else if (j === tableData.collSpanBeforeCharCell + 1) {
          imgCell ? row.push(imgCell) : row.push({text: "", colSpan: colspan, border: [false, true, true, false]});
        } else {
          row.push("");
        }
      });
      return row;
    };

    if (decorImgCell || (tableData?.typeCells && tableData?.typeCells[i]?.decorType)) {
      if (!charTypeCellsEnabled) {
        nestedTableContent.push([decorImgCell]);
      } else {
        const decorRow = formImgRowWithTypeCell("decorType", decorImgCell);
        nestedTableContent.push(decorRow);
      }
    }

    if (colorImgCell || (tableData?.typeCells && tableData?.typeCells[i]?.colorType)) {
      if (!charTypeCellsEnabled) {
        nestedTableContent.push([colorImgCell]);
      } else {
        const colorRow = formImgRowWithTypeCell("colorType", colorImgCell);
        nestedTableContent.push(colorRow);
      }
    }

    var tableCell = {
      /// ячейка с вложеной таблицей
      colSpan: rowData.length,
      table: {
        headerRows: 1,
        widths: tableData.widths,
        body: nestedTableContent,
        margin: [0, 0, 0, 0],
        dontBreakRows: true,
        layout: {
          dontBreakRows: true,
          noWrap: true, // запретить разрыв ячеек
        },
        border: null,
        style: "nestedTable",
      },
      pageBreak: "avoid",
      layout: {
        defaultBorder: false,
        hLineWidth: function () {
          return 0.01;
        },
        vLineWidth: function () {
          return 0.01;
        },
      },
      border: [false, false, false, true],
      style: "nestedTable",
      margin: [0, 0, 0, 0],
    };

    if (rowSpan > 1) {
      tableContent.push(tableRow);
      if (rowSpan > 2) {
        tableContent.push(["", overallViewImgCell]);
      }
      tableContent.push(["", tableCell]);
    } else {
      tableRow.push(tableCell);
      tableContent.push(tableRow);
    }
  });

  var containerTableWidth = [...tableData.widths];
  containerTableWidth.splice(0, 1, tableData.widths[0] + 4);

  // Определение документа PDF
  const documentDefinition = {
    pageSize: "A4",
    // pageSize: {
    //     width: 'auto',
    //     height: 595.28
    //   },
    pageOrientation: "landscape",
    pageMargins: [10, 10, 0, 0],
    content: [
      {text: tableData.text, style: "header", margin: [2, 0, 2, 2]},
      {
        table: {
          widths: [10, ...containerTableWidth],
          headerRows: 0,
          style: "nestedTable",
          body: tableContent,
          dontBreakRows: true,
          layout: {
            dontBreakRows: true,
            noWrap: true,
          },
          pageBreak: "avoid",
          margin: [0, 0, 2, 2],
        },
        layout: {
          defaultBorder: true,
          hLineWidth: function () {
            return 0.01;
          },
          vLineWidth: function () {
            return 0.01;
          },
        },
      },
      {text: tableData.summRow, margin: [2, 0, 2, 2], alignment: "right"},
    ],
    images: {...imagesDef},
    styles: styles,
  };

  // Генерация и экспорт PDF-файла
  var pdf = pdfMake.createPdf(documentDefinition);
  // pdf.download(tableData.name);
  // pdf.open();
  return pdf;
};

const TableExport = ({tableData}) => {
  const {t, i18n} = useTranslation();

  const handleExport = () => {
    const lang = i18n.language;
    generatePdf(tableData, lang);
  };

  return (
    <div>
      <FunctionButton name={t("Export")} style={{width: "12em"}} onClick={handleExport} />
    </div>
  );
};

TableExport.propTypes = {tableData: PropTypes.array};

export default TableExport;
