import {useTransition} from "react";
import {useSelector} from "react-redux";

import {AVAILABILITY_CIRCLE_COLORS} from "../../../../../../../utilities/AvailabilityStatusCIrcule";

const useControlPfTableData = forRequest => {
  const {t} = useTransition();
  const users = useSelector(state => state.person.users);

  return (
    forRequest?.approvals?.map(({approvalId, managerCoId, isApproved}, i) => {
      const circleColor =
        isApproved === null
          ? AVAILABILITY_CIRCLE_COLORS.GREY
          : isApproved
          ? AVAILABILITY_CIRCLE_COLORS.GREEN
          : AVAILABILITY_CIRCLE_COLORS.RED;

      const managerCo = users?.find(user => user.userId === managerCoId)?.name ?? t("No name");

      return {
        number: i + 1,
        id: approvalId,
        pfNumber: forRequest.proformaNumber,
        managerCo,
        accepted: circleColor,
      };
    }) || []
  );
};

export default useControlPfTableData;
