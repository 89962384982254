import React from "react";

// function addPropToReactComponents(children) {
//     return React.Children.map(children, (child) => {
//         if (React.isValidElement(child)) {
//             if (typeof child.type === 'function') {
//                 const props = { ...child.props, readOnly:true};
//                 const newChild = React.cloneElement(child, props);

//                 return newChild;
//             }
//         }
//         return child;
//     });
// }
function addPropToReactComponents(children) {
  return React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      if (typeof child.type === "function") {
        const props = {...child.props, readOnly: true};
        const newChild = React.cloneElement(child, props);
        const nestedChildren = addPropToReactComponents(child.props.children);
        if (nestedChildren?.length) {
          return React.cloneElement(newChild, {}, nestedChildren);
        }
        return newChild;
      }
      const nestedChildren = addPropToReactComponents(child.props.children);
      if (nestedChildren?.length) {
        return React.cloneElement(child, {}, nestedChildren);
      }
    }
    return child;
  });
}

const ReadOnlyFields = ({children}) => {
  const components = addPropToReactComponents(children);
  return components;
};

export default ReadOnlyFields;
