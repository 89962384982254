import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import {useFormikContext} from "formik";

import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import PageContainer from "../../../conteiners/PageContainer";
import ModalForm from "../../../Modal/FormModal";
import {getSkuInfo} from "../../../SKU/SkuSlice";
import FactoryRqstsModal from "../../OrderProcessing/FabricRequestsModal/FactoryRqstsModal";
import {
  deselectCurrentOrder,
  fetchRequestSKUs,
  getRequest,
  getRequestInfo,
  getRequestSkuInfo,
} from "../../RequestsSlice";
import RequestPlanSkuTable from "../RequestPlanSkuTable";
import ZedUpdateForm from "../ZedUpdateForm";

const FactoryRequestModificate = () => {
  const currentRequest = useSelector(getRequestInfo);
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  const {currentRequestId} = location.state || {currentRequestId: ""};

  useEffect(() => {
    if (currentRequestId) {
      // dispatch(fetchRequestSKUs(currentRequestId));
      // dispatch(getRequest(currentRequestId));
    }
  }, [currentRequestId]);

  const currentRequestSku = useSelector(getRequestSkuInfo);
  const [showUpdateSkuModal, setShowUpdateSkuModal] = useState(false);
  const onEditRequestSku = currentRequestSku => {
    dispatch(getSkuInfo(currentRequestSku.skuId));
    setShowUpdateSkuModal(true);
  };

  useEffect(() => {
    if (currentRequest?.requestId) {
      dispatch(fetchRequestSKUs(currentRequest?.requestId));
    }
  }, []);
  return (
    <PageContainer style={{height: "100%"}} title={t("Edit the Application for the Factory")}>
      <div style={{width: "100%", height: "100%", paddingBottom: "2em "}}>
        <FactoryRqstsModal>
          <Child />
        </FactoryRqstsModal>
        <div className="button-wrapper">
          <FunctionButton
            name={t("Edit SKU in request")}
            autoWidth
            onClick={() => {
              onEditRequestSku(currentRequestSku);
            }}
            disabled={!currentRequestSku}
          />
          <div>
            <FunctionButton
              name={t("Close and return")}
              autoWidth
              onClick={() => {
                navigate("/ba3a/ved/reqInProgress");
              }}
            />
          </div>
        </div>

        <ModalForm
          active={showUpdateSkuModal}
          Component={ZedUpdateForm}
          setModalActive={setShowUpdateSkuModal}
          label={`${t("Edit SKU")} ${t("In request")} ${currentRequest?.requestNumber}`}
        />
      </div>
    </PageContainer>
  );
};

const Child = () => {
  const {submitForm} = useFormikContext();

  const onSubmit = () => {
    submitForm();
    // setTimeout(
    // setModalActive()
  };

  return <RequestPlanSkuTable onSubmit={onSubmit} submitBtnLabel={"Create and Save Request and PDF to Factory"} />;
};
export default FactoryRequestModificate;
