import {useEffect, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import ReactTable from "../../../conteiners/ReactTableUniSelect";
import {fetchRequests, getFirstStatusRequest} from "../../RequestsSlice";

import styles from "./ordering.module.scss";

const ManagerKpRequestTable = ({onRequestSelect}) => {
  const {t, i18n} = useTranslation();

  const dispatch = useDispatch();
  const requests = useSelector(getFirstStatusRequest);

  useEffect(() => {
    console.log(requests);
  }, [requests]);

  useEffect(() => {
    dispatch(fetchRequests({statusId: 1}));
  }, []);

  const transformForTable = (item, i) => {
    const {requestId, status, brand, managerFea, managerCo, cos, ...rest} = item;
    const statusName = status?.engName ? status[i18n.language === "en" ? "engName" : "ukrName"] : "";
    return {
      ...rest,
      number: i + 1,
      status: statusName,
      managerCo: managerCo?.name || "",
      managerFea: managerFea?.name || "",
      factory: brand?.name || "",
      coId: cos[0]?.coId || "",
      id: requestId,
      noname: "",
    };
  };
  const defaultData = useMemo(() => {
    return requests ? requests.map(transformForTable) : [];
  }, [requests, i18n.language]);

  const columns = useMemo(
    () => [
      {
        header: () => "№",
        accessorKey: "number",
        size: 15,
      },
      // {
      //   header: () => (
      //     <>
      //       <input type="checkbox" checked={true} readOnly={true} />
      //     </>
      //   ),
      //   accessorKey: "checkbox",
      //   // size: 100,
      // },
      {
        header: () => [t("CO №")],
        accessorKey: "coId",
        // size: 100,
      },
      {
        header: () => [t("Order")],
        accessorKey: "requestNumber",
        // size: 100,
      },
      {
        header: () => [t("CO Manager")],
        accessorKey: "managerCo",
        // size: 100,
      },
      {
        header: () => [t("Factory")],
        accessorKey: "factory",
        // size: 100,
      },
      {
        header: () => [t("Request amount")],
        accessorKey: "requestSum",
        // size: 100,
      },
      {
        header: () => [t("Request date")],
        accessorKey: "createdAt",
        // size: 100,
      },
      {
        header: () => [t("Notes")],
        accessorKey: "note",
        // size: 100,
      },
      {
        header: () => [t("Status*")],
        accessorKey: "status",
        // size: 100,
      },
      // {
      //   header: () => [t("DD to factory")],
      //   accessorKey: "article",
      //   // size: 100,
      // },
      // {
      //   header: () => [t("DD proform")],
      //   accessorKey: "article",
      //   // size: 100,
      // },
      // {
      //   header: () => [t("DD control")],
      //   accessorKey: "article",
      //   // size: 100,
      // },
      // {
      //   header: () => [t("DD for payment")],
      //   accessorKey: "article",
      //   // size: 100,
      // },
      // {
      //   header: () => [t("DD paid")],
      //   accessorKey: "article",
      //   // size: 100,
      // },
      // {
      //   header: () => [t("DD preReady")],
      //   accessorKey: "article",
      //   // size: 100,
      // },
      // {
      //   header: () => [t("DD ReadyProduct")],
      //   accessorKey: "article",
      //   // size: 100,
      // },
    ],
    [],
  );
  return (
    <div className={styles.table + " table_xscroll__container "}>
      <ReactTable
        defaultData={defaultData}
        columns={columns}
        onSelect={() => {}}
        current={null}
        setSelectedItems={onRequestSelect}
      />
    </div>
  );
};

export default ManagerKpRequestTable;
