import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

import {deleteReq, fetchData, fetchList} from "../../../hook/axios.hook";
import {checkEmptyFields} from "../../../utilities/checkEmptyFields";

const initialState = {
  planSkuList: null,
  // planSkuList: [],

  currentSkuPlan: null,

  kpFLoadingStatus: "idle",
};

export const fetchSkuPlanListForKpf = createAsyncThunk("sku/fetchSkuPlanListForKpf", async cofId => {
  const responce = fetchList(`/skuPlan/getForCof/${cofId}`, "get");

  return responce;
});
// export const fetchSkuPlanInfo = createAsyncThunk(
//     'sku/fetchSkuPlanInfo',
//     async (skuPlanId) => {
//         return fetchData(`/sku/get/${skuPlanId}`,'get')
//     }
// );

export const addSkuToKpF = createAsyncThunk("sku/addSkuToKpF", async ({cofId, idealSkuId, skuPlan}) => {
  var plan = checkEmptyFields(skuPlan);

  return fetchData(`/skuPlan/add/${idealSkuId}/${cofId}`, "post", plan);
});

export const getSkuPlanInfo = createAsyncThunk("sku/getSkuPlanInfo", async skuPlanId => {
  return fetchData(`/skuPlan/get/${skuPlanId}`, "get");
});

export const updateSkuPlan = createAsyncThunk("sku/updateSkuPlan", async ({skuPlanId, skuPlan}) => {
  // var plan = checkEmptyFields(skuPlan);

  return fetchData(`/skuPlan/update/${skuPlanId}`, "patch", skuPlan);
});

export const delSkuPlan = createAsyncThunk("sku/delSkuPlan", async skuPlanId => {
  return deleteReq(`/skuPlan/delete/${skuPlanId}`, "delete");
});

export const addSkuPlanDoc = createAsyncThunk("sku/addSkuPlanDoc", async ({cofId, formData}) => {
  return fetchData(`/kp/f/docs/add/${cofId}`, "put", formData);
});

//
export const changeSequenceNumber = createAsyncThunk("sku/changeSequenceNumber", async ({sequenceObject}) => {
  return fetchData(`/skuPlan/reorder`, "patch", sequenceObject);
});

export const uploadOverallImage = createAsyncThunk("sku/uploadOverallImage", async ({skuPlanId, formdata}) => {
  const responce = fetchData(`/skuPlan/overallView/upload/${skuPlanId}`, "put", formdata);
  return responce;
});
export const delOverallImage = createAsyncThunk("sku/delOverallImages", async ({imgUrl, skuPlanId}) => {
  const responce = deleteReq(`/skuPlan/overallView/delete/${skuPlanId}`, {imgUrl});
  return responce;
});
//

const kpfSlice = createSlice({
  name: "kpf",
  initialState,
  reducers: {
    deselect: state => {
      state.currentSkuPlan = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchSkuPlanListForKpf.pending, state => {
        state.kpFLoadingStatus = "loading";
      })
      .addCase(fetchSkuPlanListForKpf.fulfilled, (state, {payload}) => {
        state.kpFLoadingStatus = "complete";

        // state.planSkuList = payload.skuPlanList;
        state.planSkuList = payload;

        state.currentSkuPlan = null;
      })
      .addCase(fetchSkuPlanListForKpf.rejected, state => {
        state.kpFLoadingStatus = "error";
      })

      .addCase(addSkuToKpF.fulfilled, (state, {payload}) => {
        state.planSkuList.push(payload.skuPlan);

        state.currentSkuPlan = payload.skuPlan;
      })

      .addCase(getSkuPlanInfo.fulfilled, (state, {payload}) => {
        state.currentSkuPlan = {
          ...payload,
        };
      })
      .addCase(updateSkuPlan.fulfilled, (state, {payload}) => {
        state.currentSkuPlan = payload.skuPlan;
        state.planSkuList = state.planSkuList.map(plan => {
          if (plan.skuPlanId === payload.skuPlan.skuPlanId) {
            return payload.skuPlan;
          }
          return plan;
        });
      })
      .addCase(delSkuPlan.fulfilled, (state, {payload}) => {
        state.planSkuList = payload.skuPlanList;

        state.currentSkuPlan = null;
      })
      .addCase(changeSequenceNumber.fulfilled, (state, {payload}) => {
        console.log(payload);
      })
      .addCase(uploadOverallImage.fulfilled, (state, {payload}) => {
        state.currentSkuPlan = {...state.currentSkuPlan, uploadedOverallView: payload.uploadedOverallView};
      })
      .addCase(delOverallImage.fulfilled, (state, {payload}) => {
        state.currentSkuPlan = {...state.currentSkuPlan, uploadedOverallView: payload.uploadedOverallView};
      })

      .addDefaultCase(() => {});
  },
});

const {actions, reducer} = kpfSlice;

export default reducer;
export const {deselect, clearOverall} = actions;

export const getPlanSkuList = state => state.kpf.planSkuList;
export const getCurrentSkuPlan = state => state.kpf.currentSkuPlan;

export const getKpFLoading = state => state.kpf.kpFLoadingStatus;
