import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Field, useFormikContext} from "formik";

import ReadOnlyFields from "../../../hoc/ReadOnlyFields";
import {Select, TextAreaInput, TextInput} from "../../../Inputs";
import {getCurrentSku} from "../../SkuSlice";

const MainCharacteristics = () => {
  const {t} = useTranslation();

  const currentSku = useSelector(getCurrentSku);

  const {setValues, initialValues} = useFormikContext();

  useEffect(() => {
    if (currentSku) {
      const {country, brand, productGroup, productType, article} = currentSku;
      setValues({
        ...currentSku,
      });
    } else {
      setValues(initialValues);
    }
  }, [currentSku]);

  return (
    <div>
      <ReadOnlyFields>
        <div className=" mainCharacteristics">
          <div style={{paddingRight: "20px", width: "80%", marginTop: "1em"}}>
            <div className="flex_container nowrap">
              <TextInput label="UnKit" name="skuUnkit" type="text" />
              <Select label={t("UnKit type")} id="unKitType" name="unKitType" type="text" options={[]} />
              <TextInput label={t("Series")} name="productSeries" />
              <TextInput label={t("Batch")} name="skuBatch" />
            </div>

            <div
              className="fc jcsb"
              style={{
                alignItems: "flex-end",
                width: "100%",
              }}
            >
              <div
                className="fc jcfe"
                style={{
                  width: "15%",
                }}
              >
                <label htmlFor="autoDescription" className="label">
                  {t("Auto")}
                </label>
                <Field id="autoDescription" name="autoDescription" type="checkbox" disabled />
              </div>
              <TextAreaInput label={t("Nomenclature")} id="nameSku" name="skuName" width="40%" />
              <TextAreaInput label="SKU name (eng)" id="nameSkuEng" name="skuNameEng" width="40%" />
            </div>
          </div>
          <div style={{width: "40%"}}>
            <TextAreaInput
              label={t("Set description")}
              id="description"
              name="complectDesc"
              width="100%"
              height="100%"
              // action ={()=>{}}
            />
          </div>
        </div>
      </ReadOnlyFields>
    </div>
  );
};

export default MainCharacteristics;
