import {useEffect, useMemo, useState} from "react";
import {useFormikContext} from "formik";

// import {mappingObj} from "../../kpFinTable/utils/functionalFormatters";

const CharactericticsField = () => {
  const {values, setFieldValue} = useFormikContext();

  const [text, setText] = useState("");

  const {productSeriesCheck, materialCheck, colorCheck, batchCheck, productSeries, material, color, batch} = values;

  const mappingObj = useMemo(() => {
    return {
      productSeriesCheck: "productSeries",
      materialCheck: "material",
      colorCheck: "color",
      batchCheck: "batch",
    };
  }, []);

  useEffect(() => {
    var currList = [];
    for (var prop in mappingObj) {
      if (values[prop]) {
        var name = mappingObj[prop];
        if (values[name] && values[name] !== "") currList.push(values[name]);
      }
    }
    setText(currList.join(", "));
  }, [productSeriesCheck, materialCheck, colorCheck, batchCheck, setFieldValue]);

  return (
    <div className="fc nowrap" style={{width: "100%"}}>
      <textarea
        value={text}
        name="characteristicText"
        style={{width: "100%", height: "40px", marginLeft: "0.4em"}}
        readOnly={true}
      />
    </div>
  );
};

export default CharactericticsField;
