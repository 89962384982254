import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {fetchRequestSKUs, getCurrentRequestId, getCurrentRequestSKUs} from "../../../../RequestsSlice";

const useCurrentRequest = () => {
  const dispatch = useDispatch();
  const currentRequestId = useSelector(getCurrentRequestId);

  useEffect(() => {
    if (!currentRequestId) return;
    dispatch(fetchRequestSKUs(currentRequestId));
  }, [currentRequestId]);

  const currentRequestSKUs = useSelector(getCurrentRequestSKUs);

  return currentRequestSKUs;
};

export default useCurrentRequest;
