import {toast} from "react-toastify";

import {instance} from "../api/apiSetup";

const useAx = () => {
  const fetchData = async (url, method, body = null, headers = null) => {
    var response = await instance[method](url, body)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        toast.error(err.response.data.message);
        throw new Error(err.response.data.message);
      });
    return response;
  };

  return {fetchData};
};

export default useAx;
