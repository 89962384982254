import {useTranslation} from "react-i18next";

const useHistoryPfTableData = (declinedProforms, proformaId) => {
  const {
    i18n: {language},
  } = useTranslation();
  const nameType = language === "en" ? "engFirstName" : "firstName";
  const nameType2 = language === "en" ? "engLastName" : "lastName";

  return declinedProforms?.map(({deletedAt, approvals, proformaNumber, proformaId}, index) => ({
    number: index + 1,
    id: proformaId,
    pfRefusalDate: approvals[0]?.updatedAt,
    proformaId: proformaNumber,
    managerCo: approvals[0]?.managerCo.persona[nameType] + " " + approvals[0]?.managerCo.persona[nameType2],
    reason: approvals[0]?.note || "",
  }));
};

export default useHistoryPfTableData;
