import {useCallback, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Form, Formik, useFormikContext} from "formik";
import {isEqual} from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import Swal from "sweetalert2";
import translit from "ua-en-translit";
import * as Yup from "yup";

import plusIcon from "../../../images/icons/plus.png";
import saveImg from "../../../images/icons/save.png";
import {getCurrentProject} from "../../../selectors/generalSelectors";
import {BUTTON_TYPES} from "../../../utilities/Buttons/constants";
import ImageButton from "../../../utilities/Buttons/ImageBtn";
import {getInfo} from "../../../utilities/toasts";
import {DateInput, MoneyInput, TextInput} from "../../Inputs";
import FilePreview from "../../Inputs/fileSelect/preview/FilePreview";
import {Select} from "../../Inputs/SelectField";
// import {Select as SL} from "../../Inputs/SelectField";
import {
  addProjectContract,
  addProjectContractFile,
  delProjectContractFile,
  getDocsLoading,
  getProjectContracts,
  updateProjectContract,
} from "../ProjectSlice";

import {RegistrButton} from "./RegistrButton";

import "./AddProjectDocForm.scss";

const AddProjectDocumentForm = ({files, setFile, docRef, focusOnDoc}) => {
  const {t} = useTranslation();

  const dispatch = useDispatch();
  const [current, setCurrent] = useState(null);

  const today = useMemo(() => {
    return moment().format("YYYY-MM-DD");
  }, []);

  const initialValues = useMemo(() => {
    return {contractId: null, note: null, deadline: today, budget: null};
  }, []);

  const onUnpade = values => {
    const {note, deadline, budget} = values;

    return dispatch(
      updateProjectContract({
        contractId: current.contractId,
        data: {
          note,
          budget,
          deadline: deadline,
        },
      }),
    ).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        return res.payload.contract;
      }
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validateOnChange={false}
      validateOnBlur={false}
      // enableReinitialize
      validationSchema={Yup.object({
        deadline: Yup.string().required([t("Enter date")]),
        // contractNumber: Yup.string().required([t("Contract number")]),
      })}
      onSubmit={(values, actions) => {
        if (current) {
          const {docsArray, ...restCurrent} = current;
          const eq = isEqual(values, restCurrent);

          if (!eq) {
            onUnpade(values).then(contr => {
              var {budget, contractId, contractNumber, deadline, note} = contr;
              setCurrent({
                budget,
                contractId,
                contractNumber,
                deadline,
                note,
              });
              actions.setFieldValue("deadline", deadline);
              setFile(contr?.docsArray || []);
            });
          }
        }
      }}
    >
      <DocForm
        setFile={setFile}
        files={files}
        docRef={docRef}
        focusOnDoc={focusOnDoc}
        setCurrent={setCurrent}
        current={current}
        dispatch={dispatch}
        onUnpade={onUnpade}
      />
    </Formik>
  );
};

const DocForm = props => {
  const {t} = useTranslation();

  const {setFile, docRef, focusOnDoc, setCurrent, current, onUnpade, dispatch} = props;

  const {values, resetForm, submitForm, setValues, setFieldValue} = useFormikContext();

  const {contractId} = values;

  const contracts = useSelector(getProjectContracts),
    currentProject = useSelector(getCurrentProject),
    docsLoading = useSelector(getDocsLoading);

  const registrList = useMemo(() => {
    var notes = [];
    if (contracts)
      contracts.forEach(annex => {
        const {contractId, note, deadline} = annex;
        notes.push({id: contractId, note, deadline: moment(deadline).format("YYYY-MM-DD")});
      });
    return notes;
  }, [contracts]);

  const contractNumberList = useMemo(() => {
    return contracts?.map(contract => ({title: contract.contractNumber, value: contract.contractId})) || [];
  }, [contracts]);

  const changesCheck = () => {
    if (current && values.contractNumber) {
      const {docsArray, contractId: c1, ...restCurr} = current;
      const {contractId: c2, ...restValues} = values;

      const eq = isEqual(restValues, restCurr);
      if (!eq) {
        Swal.fire({
          title: "",
          text: `${[t("Save document")]} №${values.contractNumber}?`,
          icon: "question",
          confirmButtonText: [t("Yes")],
          showCancelButton: true,
          cancelButtonText: [t("No")],
        }).then(answ => {
          if (answ.isConfirmed) {
            onUnpade(restValues);
          }
        });
      }
    }
  };

  useEffect(() => {
    changesCheck();
    if (currentProject && contracts.length !== 0) {
      var contracts_ = [...contracts];
      var first = contracts_.pop();

      setFieldValue("contractId", first.contractId);
    } else {
      resetForm();
      setCurrent(null);
      setFieldValue("contractId", "");
    }
  }, [currentProject]);

  useEffect(() => {
    if (contractId && contractId !== "") {
      changesCheck();
      var contr = contracts.find(contract => contract.contractId === contractId);

      if (contr) {
        const {docsArray, ...rest} = contr;
        setFile(docsArray);
        setCurrent(contr);
        setValues({...rest});
      } else {
        setCurrent(null);
      }
    }
  }, [contractId]);

  const onFileAdding = arrFiles => {
    var formdata = new FormData();
    var i = 0,
      file;

    for (; i < arrFiles.length; i++) {
      file = arrFiles[i];
      if (current && current?.docsArray && current?.docsArray.length !== 0) {
        var exist = current?.docsArray.includes(`contracts/contract_doc_${contractId}_${file.name}`);
        if (exist) {
          Swal.fire({
            title: "",
            text: `${[t("File name")]} ${file.name} ${[t("Already exist")]}`,
            icon: "warning",
            confirmButtonText: "Ок",
            customClas: {
              popup: "zindex",
              container: "zindex",
              htmlContainer: "zindex",
            },
          });
          continue;
        }
      }
      const filename = translit(file.name);
      formdata.append("contract", file, filename);
    }

    if (formdata.has("contract")) {
      dispatch(addProjectContractFile({contractId: current.contractId, formdata})).then(res => {
        if (res.meta.requestStatus === "fulfilled") {
          setFile(res.payload.docsArray);
          setCurrent(item => {
            return {...item, docsArray: res.payload.docsArray};
          });
        }
      });
    }
  };

  const onFileDel = url => {
    Swal.fire({
      title: "",
      text: [t("Sure")],
      icon: "question",
      confirmButtonText: [t("Yes")],
      showCancelButton: true,
      cancelButtonText: [t("No")],
      customClas: {
        popup: "zindex",
        container: "zindex",
        htmlContainer: "zindex",
      },
    }).then(value => {
      if (value.isConfirmed) {
        dispatch(delProjectContractFile({contractId: current.contractId, url})).then(res => {
          if (res.meta.requestStatus === "fulfilled") {
            setFile(res.payload.docsArray);
            setCurrent(item => {
              return {...item, docsArray: res.payload.docsArray};
            });
          }
        });
      }
    });
  };

  const onPlusClick = () => {
    if (currentProject) {
      resetForm();

      dispatch(addProjectContract(currentProject.projectId)).then(res => {
        if (res.meta.requestStatus === "fulfilled") {
          const {contractNumber, deadline, ...rest} = res.payload.contract;
          setCurrent({
            deadline: moment().format("YYYY-MM-DD"),
            ...rest,
          });
          setFieldValue("contractId", res.payload.contract.contractId);
        }
      });
    } else {
      getInfo([t("Project at first")]);
    }
  };

  const onFocus = i => {
    focusOnDoc(i);
    setFile(current?.docsArray);
  };

  return (
    <Form
      className="add-kp-doc-form"
      ref={el => {
        docRef.current[1] = el;
      }}
      onFocus={() => {
        onFocus(1);
      }}
    >
      <div className="number_block">
        <div>1.</div>
        <div className="block_content">
          <Select
            label={t("Contract witn num")}
            name="contractId"
            options={contractNumberList}
            inputClassName="ref_input"
            selectOnly={true}
            widthLabel="10em"
            width="62%"
            question={t("Instructions")}
          />
          <TextInput label={t("Briefly about")} labelStyles={{width: "10em"}} name="note" width="55%" />
          <MoneyInput label={t("Contract Amount")} labelStyles={{width: "10em"}} name="budget" width="55%" />

          <div style={{display: "flex", justifyContent: "space-between"}}>
            <label className="label" style={{marginTop: "0.5em"}}>
              {t("Project term")}
            </label>
            <DateInput label="" name="deadline" />
          </div>

          <div className="flex_container space-between icon_mr">
            <div className="fc icon_ml">
              <ImageButton
                src={plusIcon}
                tooltipMessage="Clear form"
                alt="add remind"
                width={1.2}
                height={1.2}
                onClick={onPlusClick}
              />
              <ImageButton src={saveImg} alt="save" type={BUTTON_TYPES.SUBMIT} width={2} height={1.3} />
            </div>
            <RegistrButton label={[t("Contract")]} list={registrList} idFieldName="contractId" />
            <FilePreview
              imgs={current?.docsArray || []}
              delFunc={onFileDel}
              accept={".pdf"}
              addFunc={onFileAdding}
              disable={!current}
              loading={docsLoading}
            />
          </div>
        </div>
      </div>
    </Form>
  );
};

AddProjectDocumentForm.propTypes = {
  files: PropTypes.array,
  setFile: PropTypes.func,
  forcedUpdate: PropTypes.bool,
  docRef: PropTypes.any,
  focusOnDoc: PropTypes.func,
};

export default AddProjectDocumentForm;
