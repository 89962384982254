import {Children, useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";

const ScrollableComponent = ({children, onBottomScroll, scrollXEnable}) => {
  const scrollContainerRef = useRef(null);
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;

    const handleScroll = () => {
      // Проверяем, достигнут ли скроллом низ контейнера
      const isBottom =
        Math.ceil(scrollContainer.scrollTop + scrollContainer.clientHeight) === scrollContainer.scrollHeight;
      console.log(isBottom);
      setIsScrolledToBottom(isBottom);
    };

    scrollContainer.addEventListener("scroll", handleScroll);
    return () => {
      scrollContainer.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isScrolledToBottom) {
      // console.log("bottom");
      onBottomScroll();
    }
  }, [isScrolledToBottom]);

  return (
    <div
      ref={scrollContainerRef}
      className={`project_list sku_table table_with_bb ${scrollXEnable ? "scrollX" : null}`}
      style={{height: "100%", overflowX: "hidden"}}
    >
      {children}
    </div>
  );
};

ScrollableComponent.propTypes = {
  children: PropTypes.node,
  onBottomScroll: PropTypes.func,
  scrollXEnable: PropTypes.bool,
};

export default ScrollableComponent;
