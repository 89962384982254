import {useTranslation} from "react-i18next";
import {useFormikContext} from "formik";
import PropTypes from "prop-types";

import saveIcon from "../../../../images/icons/save.png";
import ImageButton from "../../../../utilities/Buttons/ImageBtn";
import {TextAreaInput, TextInput} from "../../../Inputs";
import {Select} from "../../../Inputs/SelectField";
import {FACTORY_MODAL_TYPES} from "../factoryModals/utils/constants";

import useFactoryAddressService from "./utils/hooks/getFactoryAddressService";

const FactoryAdress = ({modalType, children}) => {
  const {t} = useTranslation();
  const {values} = useFormikContext();
  const fieldDisabled = modalType === FACTORY_MODAL_TYPES.EDIT_FACTORY;

  const {
    onSaveFactoryNoteBtnClick,
    countryIdsOptions,
    factoryAddressNotesPlaceholder,
    isFactoryAddressNotesFieldDisabled,
  } = useFactoryAddressService();

  return (
    <div className="factory_line">
      <div>1. {t("F adress")}</div>
      <div style={{width: "100%"}} className="f_c_wrapper">
        <Select
          label={t("Country")}
          name="countryId"
          options={countryIdsOptions}
          multi={true}
          widthLabel="10em"
          width="100%"
          readOnly={fieldDisabled}
        />

        <TextInput
          label={t("Factory")}
          name="factoryName"
          type="text"
          labelStyles={{width: "10em"}}
          width="100%"
          readOnly={fieldDisabled}
        />

        <TextInput label={t("F adress")} name="address" type="text" labelStyles={{width: "10em"}} width="100%" />

        <TextInput label={t("location")} name="location" type="text" labelStyles={{width: "10em"}} width="100%" />

        <TextInput label={t("website")} name="webSite" type="text" labelStyles={{width: "10em"}} width="100%" />

        <div className="f_b_wrapper">
          <TextInput
            label={t("Notes")}
            name="newAddressNotes"
            type="text"
            labelStyles={{width: "8.5em"}}
            width="100%"
            generalClassName="w100"
            placeholder={factoryAddressNotesPlaceholder}
            disabled={isFactoryAddressNotesFieldDisabled}
          />
          <ImageButton
            src={saveIcon}
            alt="save"
            width={1.5}
            height={1}
            tooltipMessage={t("Save note")}
            onClick={() => onSaveFactoryNoteBtnClick(values.newAddressNotes)}
            disabled={isFactoryAddressNotesFieldDisabled}
          />
        </div>

        <TextAreaInput name="addressNotes" width="101%" height="6em" readonly />
      </div>

      {children}
    </div>
  );
};

FactoryAdress.propTypes = {
  modalType: PropTypes.oneOf(Object.values(FACTORY_MODAL_TYPES)),
};

export default FactoryAdress;
