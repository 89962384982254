import {useEffect, useState} from "react";
import CurrencyInput from "react-currency-input-field";
import {useField} from "formik";
import PropTypes from "prop-types";

import QuestionItem from "../../utilities/QuestionItem";

const MoneyInput = ({
  label,
  width = "5em",
  question = null,
  autolabel,
  labelStyles,
  // func = null,
  ...props
}) => {
  const [field, meta, helpers] = useField(props);
  const [value, setValue] = useState("");

  const {setValue: setRawValue, setError} = helpers;

  const [className, setClassName] = useState("moneyInp");

  const validateValue = value => {
    const rawValue = value === undefined ? null : value;
    setValue(rawValue);
    setRawValue(rawValue);

    if (Number.isNaN(Number(value))) {
      setError("не валідна сумма");
    }
  };

  useEffect(() => {
    validateValue(field.value);
  }, [field.value]);

  return (
    <div className={"project_input money_input"}>
      {label && (
        <label htmlFor={props.name} style={autolabel ? {width: "auto"} : labelStyles}>
          {/* { typeof label === "string" ? t(label) : label }  */}
          {label}
        </label>
      )}
      <div className="" style={{width: width, position: "relative"}}>
        <div className={className}>
          <CurrencyInput
            id={`validation-example-${props.id}-field`}
            placeholder="€"
            allowDecimals={true}
            readOnly={props.readOnly}
            className={`form-control ${className}`}
            onValueChange={validateValue}
            groupSeparator={"'"}
            decimalSeparator={"."}
            decimalsLimit={2}
            decimalScale={2}
            maxLength={10}
            allowNegativeValue={false}
            // prefix={'$'}
            step={10}
            autoComplete="off"
            value={value || ""}
            intlConfig={{
              locale: "en-US",
              currency: "EUR",
            }}
          />
        </div>
        {meta.touched && meta.error ? <div className="error">{meta.error}</div> : null}
      </div>

      {question ? <QuestionItem title={question} /> : null}
    </div>
  );
};
MoneyInput.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  width: PropTypes.string,
  question: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  props: PropTypes.array,
};
export default MoneyInput;
