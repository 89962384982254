import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import Swal from "sweetalert2";
import translit from "ua-en-translit";

import {getSuccess} from "../../../../../../../utilities/toasts";
import {addProform, fetchForRequestById} from "../../../../../Proform/redux/operations/ProformOperations";
import {getRequestInfo} from "../../../../../RequestsSlice";

const usePfOperationsModalFilePickerFunctions = () => {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const currentRequest = useSelector(getRequestInfo);

  //FilePreview functions
  const onFileAdding = (file, values) => {
    const formdata = new FormData();
    let filename = translit(file.name);
    formdata.append("doc", file, filename);
    formdata.append("proformaNumber", values.pfNumber);

    if (formdata.has("doc")) {
      dispatch(addProform({requestId: currentRequest.requestId, data: formdata})).then(res => {
        if (res.meta.requestStatus === "fulfilled") {
          getSuccess(t("The proforma has been created"));
        }
      });
    }
  };

  const onFileDel = url => {
    Swal.fire({
      title: "",
      text: [t("Sure")],
      icon: "question",
      confirmButtonText: [t("Yes")],
      showCancelButton: true,
      cancelButtonText: [t("No")],
      customClas: {
        popup: "zindex",
        container: "zindex",
        htmlContainer: "zindex",
      },
    }).then(value => {
      if (value.isConfirmed) {
        console.log(url);
        // dispatch(delProjectContractFile({ contractId: current.contractId, url })).then(res => {
        //   if (res.meta.requestStatus === "fulfilled") {
        //     setFile(res.payload.docsArray);
        //     setCurrent(item => {
        //       return { ...item, docsArray: res.payload.docsArray };
        //     });
        //   }
        // });
      }
    });
  };
  return {onFileAdding, onFileDel};
};

export default usePfOperationsModalFilePickerFunctions;
