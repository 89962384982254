import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import FunctionButton from "../../../../../utilities/Buttons/FunctionButton";
import QuestionItem from "../../../../../utilities/QuestionItem";
import ReactTable from "../../../../conteiners/ReactTable";
import useColumns from "../../../OrderProcessing/utils/hooks/useColumns";

import {attachFromRequest, removePaymentDocs, selectDoc} from "./redux/PaymentOperationsSlice";
import {getCurrentDocument, getPayment} from "./redux/PaymentSelectors";
import usePaymentsData from "./utils/hook/PaymentDataService";
import usePaymentsDoc from "./utils/hook/paymentDocService";
import {paymentColumns} from "./columnsForPaymentTables";

const PaymentDocs = () => {
  const {t} = useTranslation();

  const columns = useColumns(paymentColumns);
  const {docsForPayment} = usePaymentsData();

  const {onDocSelect, current} = usePaymentsDoc(2);

  return (
    <>
      <div className="header-blocks q_wrapper">
        <QuestionItem title="selected doc" />
        {t("Payment documents list")}
      </div>
      <ReactTable
        defaultData={docsForPayment}
        columns={columns}
        onSelect={onDocSelect}
        current={current}
        className={"project_table"}
        style={{height: "5em"}}
      />
    </>
  );
};

export default PaymentDocs;
