import {useCallback, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import {
  getDocsByPayment,
  getDocsForPayment,
  getDocTypes,
  getPaymentDocs,
  getPaymentSchemas,
} from "../../redux/PaymentSelectors";

const usePaymentsData = () => {
  const {
    i18n: {language},
  } = useTranslation();

  const docTypesRaw = useSelector(getDocTypes),
    paymentDocsRaw = useSelector(getPaymentDocs),
    docsForPaymentRaw = useSelector(getDocsForPayment),
    docsByPaymentRaw = useSelector(getDocsByPayment),
    paymentSchemasRaw = useSelector(getPaymentSchemas);

  const nameLang = language === "en" ? "Eng" : "Ukr";

  const getDocData = useCallback(
    docs => {
      return (
        docs?.map((doc, i) => ({
          id: doc.docId,
          number: i + 1,
          doc: doc.docNumber,
          docType: doc?.docType?.["name" + nameLang] || doc?.docTypeId,
          notes: doc.note,
        })) || []
      );
    },
    [nameLang],
  );

  const docTypes =
    docTypesRaw?.map((type, i) => ({
      title: type["name" + nameLang],
      value: type.docTypeId,
    })) || [];

  const paymentSchemas = useMemo(
    () =>
      paymentSchemasRaw?.map((schema, i) => ({
        id: schema.schemaId,
        number: i + 1,
        name: schema["name" + nameLang],
        desc: "",
      })) || [],
    [paymentSchemasRaw, nameLang],
  );

  const paymentDocs = getDocData(paymentDocsRaw);
  const docsForPayment = getDocData(docsForPaymentRaw);
  const docsByPayment = getDocData(docsByPaymentRaw);

  return {docTypes, paymentSchemas, paymentDocs, docsForPayment, docsByPayment};
};
export default usePaymentsData;
