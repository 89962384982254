import PropTypes from "prop-types";

import AvailabilityStatusCIrcule from "./AvailabilityStatusCIrcule";

export const DATE_STATUS_CIRCLE_COLORS = Object.freeze({
  RED: "#B72025",
  GREEN: "#69A042",
  GREY: "#C8C8C8",
  YELLOW: "#ffa31a",
});

function calcStatusColorViaPlannedDate(plannedDate) {
  const currentDate = new Date();
  const dateDiff = Math.floor((currentDate - new Date(plannedDate)) / (1000 * 60 * 60 * 24));
  if (dateDiff <= 2 && dateDiff >= -2) return DATE_STATUS_CIRCLE_COLORS.YELLOW;
  if (dateDiff < -2) return DATE_STATUS_CIRCLE_COLORS.GREEN;
  if (dateDiff > 2) return DATE_STATUS_CIRCLE_COLORS.RED;
  return DATE_STATUS_CIRCLE_COLORS.GREY;
}

const DateStatusCircle = ({plannedDate = "", defaultColor = DATE_STATUS_CIRCLE_COLORS.GREY, tooltipMessage = ""}) => {
  let statusColor = defaultColor;

  if (plannedDate) {
    statusColor = calcStatusColorViaPlannedDate(plannedDate);
  }
  return <AvailabilityStatusCIrcule color={statusColor} tooltipMessage={tooltipMessage} />;
};

DateStatusCircle.propTypes = {
  plannedDate: PropTypes.string,
  defaultColor: PropTypes.oneOf(Object.values(DATE_STATUS_CIRCLE_COLORS)),
  tooltipMessage: PropTypes.string,
};

export default DateStatusCircle;
