import {useEffect} from "react";
import {Route, Routes} from "react-router-dom";

import ErrorBoundary from "../Components/errorBoundary/ErrorBoundary";
import Kp from "../Components/KP/kp/Kp";
import KpPfControl from "../Components/KP/kp/KpPfControl/KpPfControl";
import KpFin from "../Components/KP/kp-f/KpFin";

const KpPage = () => {
  useEffect(() => {}, []);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <ErrorBoundary>
            <Kp />
          </ErrorBoundary>
        }
      />
      <Route path="/kpfin" element={<KpFin />} />
      <Route
        path="control"
        element={
          <KpPfControl />
          // <ErrorBoundary>
          //   <KpPfControl />
          // </ErrorBoundary>
        }
      />
      {/* <Route path="archive" element={<ErrorBoundary><KpFin /></ErrorBoundary>} /> */}
    </Routes>
  );
};

export default KpPage;
