import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Form, Formik} from "formik";

import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import NewPdfViewer from "../../../Project/Documents/DocumentView/NewPdfViewer";
import {getForReadiness, getRequestInfo, selectCurrentInvoice, selectCurrentPackingList} from "../../RequestsSlice";

import AdressBlock from "./AdressBlock";
import InvoiceAndPackBlock from "./InvoiceAndPackBlock";
import MeasurementBlock from "./MeasurementBlock";
import ReadinessDatesBlock from "./ReadinessDatesBlock";

import styles from "./ReceiptGoodsModal.module.scss";

const ReceiptGoodsModal = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const currentRequest = useSelector(getRequestInfo),
    currentInvoice = useSelector(selectCurrentInvoice),
    currentPackingList = useSelector(selectCurrentPackingList);

  const [invoiceAndPackingViewData, setInvoiceAndPackingViewData] = useState([]);

  useEffect(() => {
    let data = [];
    if (currentInvoice !== null) {
      data.push(currentInvoice.fileUrl);
    }
    if (currentPackingList !== null) {
      data.push(currentPackingList.fileUrl);
    }
    setInvoiceAndPackingViewData(data);
  }, [currentInvoice, currentPackingList]);

  useEffect(() => {
    if (currentRequest) {
      dispatch(getForReadiness(currentRequest?.requestId));
    }
  }, [currentRequest]);

  //initiial values
  const initialValues = useMemo(() => {
    return {
      readinessDate: "",
      readinessControlDate: "",
      readinessNote: "",
      readinessActualDate: currentRequest?.requestDates[7]?.actualDate
        ? currentRequest?.requestDates[7]?.actualDate
        : "",
      factoryName:
        currentRequest?.brand.factoryContacts.length !== 0 ? currentRequest?.brand.factoryContacts?.[0].contactId : "",
      factoryNote: "",
      packingList: currentPackingList?.listNumber || "",
      PLDate: currentRequest?.requestDates[7]?.actualDate ? currentRequest?.requestDates[7]?.actualDate : "",
      invoice: currentInvoice?.invoiceNumber || "",
      invDate: currentRequest?.requestDates[7]?.actualDate ? currentRequest?.requestDates[7]?.actualDate : "",
      note: "",
      quantity: "",
      place: "",
      length: "",
      width: "",
      height: "",
      selectedProportionOption: "",
      selectVolumeProportion: "",
      volume: "",
      selectWeightProportion: "",
      weight: "",
    };
  }, [currentRequest]);

  //event handlers
  const onReceiptGoodsFormSubmit = () => {};

  const onTransferToLogistBtnClick = () => {};

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validateOnChange={true}
      validateOnBlur={true}
      // validationSchema={validatingObj}
      onSubmit={onReceiptGoodsFormSubmit}
    >
      <Form className={`${styles.modalContainer} autolabel`}>
        <ReadinessDatesBlock />
        <section
          className={
            currentRequest?.requestDates[7]?.actualDate ? styles.detailsSection : styles.detailsSection_disabled
          }
        >
          <div className={styles.leftColumn}>
            <AdressBlock />
            {/* <ReadOnlyFields> */}
            <InvoiceAndPackBlock
              currentInvoice={currentInvoice}
              currentPackingList={currentPackingList}
              data={invoiceAndPackingViewData}
              setData={setInvoiceAndPackingViewData}
            />
            {/* </ReadOnlyFields> */}
            <section className={styles.proportionsSection}>
              <MeasurementBlock />
              <FunctionButton
                type="button"
                className={styles.transferToLogistBtn}
                name="Transfer to Logist"
                autoWidth
                disabled
                onClick={onTransferToLogistBtnClick}
              />
            </section>
          </div>
          <div className={styles.rightColumn}>
            <div className="kpPreview__box" style={{height: "100%"}}>
              <NewPdfViewer fileArray={invoiceAndPackingViewData} />
            </div>
          </div>
        </section>
      </Form>
    </Formik>
  );
};

export default ReceiptGoodsModal;
