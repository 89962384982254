import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {Route, Routes} from "react-router-dom";

import ErrorBoundary from "../Components/errorBoundary/ErrorBoundary";
import MainPage from "../Components/MainPage/MainPage";
import useAx from "../hook/ax.hook";
import {fetchDesigners, fetchOrgStructures} from "../slices/CatalogsSlice";
import {clientsFetched, fetchUsers, usersFetched} from "../slices/PersonSlice";
import {getUser} from "../slices/UserSlice";

// import SkuVed from "../Components/SKU/SkuVed";
import AdminPage from "./AdminPage";
import FinancePage from "./FinancePage";
import KpPage from "./KpPage";
import ProjectPage from "./ProjectPage";
import SKUPage from "./SKUPage";
import SkuVedPage from "./SkuVedPage";
import Translation from "./Translation";

// const Page = ({ title, children }) => {
//   useEffect(() => {
//     document.title = title;
//   }, [title]);

//   return <>{children}</>;
// };

const IndexPage = () => {
  const dispatch = useDispatch();

  const {fetchData} = useAx();

  useEffect(() => {
    dispatch(getUser());
    dispatch(fetchDesigners());
    dispatch(fetchOrgStructures());
    dispatch(fetchUsers());
    // .then(() => {
    //   fetchData("/users/getAll", "get").then(res => {
    //     dispatch(usersFetched(res));
    //   });
    // });
    fetchData("/clients/getAll", "get").then(res => {
      dispatch(clientsFetched(res));
    });
  }, [dispatch]);

  return (
    <Routes>
      <Route path="/admin/*" element={<AdminPage fetchData={fetchData} />} />

      <Route path="/main" element={<MainPage />} />

      <Route path="/project/*" element={<ProjectPage />} />
      <Route path="/kp/*" element={<KpPage />} />
      <Route path="/sku" element={<SKUPage />} />
      <Route path="/finance/*" element={<FinancePage />} />
      {/* <Route path="/fin-accounting/*" element={<FinancePage />} /> */}
      {/* <Route path="/ved/sku" element={<SkuVed />} /> */}
      <Route path="/ved/*" element={<SkuVedPage />} />

      <Route path="settings/translate/*" element={<Translation />} />
    </Routes>
  );
};

export default IndexPage;
