import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import Swal from "sweetalert2";
import translit from "ua-en-translit";

import {addFacsimile} from "../../../../../../../slices/UserSlice";

const useFacsimilePreview = currentApproval => {
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const onFileAdding = (arrFiles = [], values) => {
    console.log("arrFiles: ", arrFiles);
    const formdata = new FormData();
    let file, filename;

    for (let i = 0; i < arrFiles?.length; i++) {
      file = arrFiles[i];
      if (
        currentApproval &&
        currentApproval?.managerCo.facsimile.imageUrl &&
        currentApproval?.managerCo.facsimile.imageUrl.lenght > 0
      ) {
        const exist = currentApproval?.requestFiles.includes(
          `requests/6-1-2-NOK-16-ARC${currentApproval.requestNumber}/${file.name}`,
        );
        if (exist) {
          Swal.fire({
            title: "",
            text: `${[t("File name")]} ${file.name} ${[t("Already exist")]}`,
            icon: "warning",
            confirmButtonText: "Ок",
            customClas: {
              popup: "zindex",
              container: "zindex",
              htmlContainer: "zindex",
            },
          });
          continue;
        }
      }
      filename = translit(file.name);
      formdata.append("image", file, filename);
      //   formdata.append("proformaNumber", values.pfNumber);
    }
    if (formdata.has("image")) {
      dispatch(addFacsimile({imageUrl: filename, facsimileId: null}));
    }
  };

  const onFileDel = url => {
    Swal.fire({
      title: "",
      text: [t("Sure")],
      icon: "question",
      confirmButtonText: [t("Yes")],
      showCancelButton: true,
      cancelButtonText: [t("No")],
      customClas: {
        popup: "zindex",
        container: "zindex",
        htmlContainer: "zindex",
      },
    }).then(value => {
      if (value.isConfirmed) {
        console.log(url);
        // dispatch(delProjectContractFile({ contractId: current.contractId, url })).then(res => {
        //   if (res.meta.requestStatus === "fulfilled") {
        //     setFile(res.payload.docsArray);
        //     setCurrent(item => {
        //       return { ...item, docsArray: res.payload.docsArray };
        //     });
        //   }
        // });
      }
    });
  };
  return {onFileAdding, onFileDel};
};

export default useFacsimilePreview;
