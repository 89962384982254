import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import useColumns from "../../../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {fetchFactoryConditions} from "../../../../redux/operations/FactoryOperations";
import {
  selectCurrentFactory,
  selectCurrentFactoryConditions,
  selectCurrentFactoryId,
  selectNewCondition,
  selectNewContact,
} from "../../../../redux/selectors/FactorySelectors";
import {COMMON_FACTORY_TABLE_COLUMNS} from "../../../conditions&contactsFactory/utils/constants";
import {FACTORY_MODAL_TYPES} from "../../../factoryModals/utils/constants";

const useFactoryCommonTableService = ({setCurrentCommonCondition, modalType}) => {
  const dispatch = useDispatch();

  const currentFactoryId = useSelector(selectCurrentFactoryId);
  const currentFactoryConditions = useSelector(selectCurrentFactoryConditions);
  const newCondition = useSelector(selectNewCondition);
  const newContact = useSelector(selectNewContact);
  const newFactory = useSelector(selectCurrentFactory);

  useEffect(() => {
    if (modalType !== FACTORY_MODAL_TYPES.CREATE_FACTORY) {
      dispatch(fetchFactoryConditions(currentFactoryId));
    }
  }, [currentFactoryId]);

  const columns = useColumns(COMMON_FACTORY_TABLE_COLUMNS);

  const factoryCommonData =
    !currentFactoryConditions || currentFactoryConditions.length === 0
      ? [
          {
            id: newCondition ? newCondition.conditionId : "",
            index: 1,
            factoryName: newFactory ? newFactory.name : "",
            country: newFactory ? newFactory.countryId : "",
            contactPersonName: newContact ? newContact.name : "",
            discount: newCondition ? newCondition.discount : "",
            email: newContact ? newContact.email : "",
            phone: newContact ? newContact.phone : "",
            terms:
              newCondition && newCondition.termsFrom && newCondition.termsTo
                ? `from ${newCondition.termsFrom} to ${newCondition.termsTo}`
                : "",
            termsCondition: newCondition ? newCondition.termsCondition : "",
            termsNote: newCondition ? newCondition.termsNote : "",
            address: newFactory ? newFactory.address : "",
          },
        ]
      : currentFactoryId && currentFactoryConditions
      ? currentFactoryConditions.map(
          (
            {
              conditionId,
              discount,
              termsFrom,
              termsTo,
              termsNote,
              termsCondition,
              representativeContact: {email, name: contactPersonName, phone},
              factory: {name: factoryName, country, address},
            },
            index,
          ) => ({
            id: conditionId,
            index: ++index,
            factoryName,
            country: country?.shortName ?? "",
            contactPersonName,
            discount,
            email,
            phone,
            terms: termsFrom && termsTo ? `from ${termsFrom} to ${termsTo}` : "",
            termsCondition,
            termsNote,
            address,
          }),
        )
      : [];

  function onFactoryCommonTableRowSelect(conditionId) {
    if (modalType !== FACTORY_MODAL_TYPES.EDIT_FACTORY) return;

    setCurrentCommonCondition(prev => {
      if (prev && conditionId === prev.conditionId) {
        return null;
      }
      return currentFactoryConditions.find(condition => condition.conditionId === conditionId);
    });
  }

  return {columns, factoryCommonData, onFactoryCommonTableRowSelect};
};

export default useFactoryCommonTableService;
