import React, {useState} from "react";
import PropTypes from "prop-types";

import KpfControlSaveBtns from "../../utilities/Buttons/KpfControlButtons";
import Spinner from "../spinner/Spinner";

function addForcedPropToReactComponents(children, forcedUpdate, setGlobalSave) {
  return React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      if (typeof child.type === "function") {
        const props = {...child.props, forcedUpdate, setGlobalSave};
        const newChild = React.cloneElement(child, props);
        const nestedChildren = addForcedPropToReactComponents(child.props.children, forcedUpdate, setGlobalSave);
        if (nestedChildren?.length) {
          return React.cloneElement(newChild, {}, nestedChildren);
        }
        return newChild;
      }
      const nestedChildren = addForcedPropToReactComponents(child.props.children, forcedUpdate, setGlobalSave);
      if (nestedChildren?.length) {
        return React.cloneElement(child, {}, nestedChildren);
      }
    }
    return child;
  });
}

const KPfPageContainer = ({title, children, loading}) => {
  const [forcedUpdate, setForsedpdate] = useState(false); // по нажатию кнопки "зберегти"
  const [isEdited, setGlobalSave] = useState(false);

  const seveAll = () => {
    setForsedpdate(val => !val);
    setGlobalSave(false);
  };

  const components = addForcedPropToReactComponents(children, forcedUpdate, setGlobalSave);

  return (
    <>
      <label className="label limitedSize_label">{title}</label>
      {loading ? <Spinner /> : components}
      <div className="control__btn">
        <KpfControlSaveBtns
          isEdited={isEdited}
          seveAll={seveAll}
          setGlobalSave={setGlobalSave}
          forcedUpdate={forcedUpdate}
          disabled={true}
        />
      </div>
    </>
  );
};

KPfPageContainer.propTypes = {title: PropTypes.string, children: PropTypes.node, loading: PropTypes.bool};

export default KPfPageContainer;
