const useControlDetailsPfTableData = reasons =>
  reasons.map(({reasonId, skuNumber, reason, note}, i) => ({
    id: reasonId,
    number: i + 1,
    SKUNumber: skuNumber,
    reason: reason,
    description: note,
  }));

export default useControlDetailsPfTableData;
