import moment from "moment";

export const getForReadinessService = data => {
  const {requestDates, requestPostponedDates} = data;

  if (requestDates.length === 0) {
    return [];
  }

  if (requestPostponedDates.length === 0) {
    return [
      {
        id: requestDates[0].dateId,
        readinessDate: requestDates[0].plannedDate,
        actualReadinessDate: requestDates[0].actualDate,
        readinessControlDate: requestDates[1].plannedDate,
        note: requestDates[0].note,
      },
    ];
  } else if (requestPostponedDates.length === 2) {
    return [
      {
        id: requestDates[0].dateId,
        readinessDate: requestDates[0].plannedDate,
        actualReadinessDate: requestDates[0].actualDate,
        readinessControlDate: requestDates[1].plannedDate,
        note: requestDates[0].note,
      },
      {
        id: requestPostponedDates[0].dateId,
        readinessDate: moment(requestPostponedDates[0].plannedDate).format("YYYY-MM-DD"),
        readinessControlDate: moment(requestPostponedDates[1].plannedDate).format("YYYY-MM-DD"),
        note: requestPostponedDates[0].note,
      },
    ];
  } else {
    return [
      {
        id: requestDates[1].dateId,
        readinessControlDate: requestDates[1].plannedDate,
        actualReadinessDate: requestDates[0].actualDate,
        readinessDate: requestDates[0].plannedDate,
        note: requestDates[0].note,
      },
      {
        id: requestPostponedDates[1].dateId,
        readinessControlDate: moment(requestPostponedDates[1].plannedDate).format("YYYY-MM-DD"),
        readinessDate: moment(requestPostponedDates[0].plannedDate).format("YYYY-MM-DD"),
        note: requestPostponedDates[0].note,
      },
      {
        id: requestPostponedDates[3].dateId,
        readinessControlDate: moment(requestPostponedDates[3].plannedDate).format("YYYY-MM-DD"),
        readinessDate: moment(requestPostponedDates[2].plannedDate).format("YYYY-MM-DD"),
        note: requestPostponedDates[2].note,
      },
    ];
  }
};
