import AvailabilityStatusCIrcule from "../../../../../../utilities/AvailabilityStatusCIrcule";

export const CONTROL_TABLE_COLUMNS = Object.freeze([
  {
    header: "№ nn",
    accessorKey: "number",
    size: 15,
  },
  {
    header: "Pf №",
    accessorKey: "pfNumber",
    size: 15,
  },
  {
    header: "Manager CO",
    accessorKey: "managerCo",
    size: 15,
  },
  {
    header: "Accepted",
    accessorKey: "accepted",
    size: 15,
    cell: circleColor => <AvailabilityStatusCIrcule color={circleColor.getValue()} />,
  },
]);

export const STATUS_CONTROL_TABLE_COLUMNS = Object.freeze([
  {
    header: "№ nn",
    accessorKey: "number",
    size: 15,
  },
  {
    header: "Pf №",
    accessorKey: "pfNumb",
    // size: 15,
  },
  {
    header: "stat control",
    accessorKey: "statControl",
    cell: circleColor => (
      <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}>
        <AvailabilityStatusCIrcule color={circleColor.getValue()} />
      </div>
    ),
    // size: 15,
  },
]);

export const CONTROL_DETAILS_TABLE_COLUMNS = Object.freeze([
  {
    header: "№ nn",
    accessorKey: "number",
    size: 15,
  },
  {
    header: "SKU №",
    accessorKey: "SKUNumber",
    size: 15,
  },
  {
    header: "Reason",
    accessorKey: "reason",
    size: 15,
  },
  {
    header: "Description",
    accessorKey: "description",
    size: 15,
  },
]);

export const HISTORY_PF_TABLE_COLUMNS = Object.freeze([
  {
    header: "№ nn",
    accessorKey: "number",
    size: 15,
  },
  {
    header: "Refusal date",
    accessorKey: "pfRefusalDate",
    size: 15,
  },
  {
    header: "Pf №",
    accessorKey: "proformaId",
    size: 15,
  },
  {
    header: "Manager CO",
    accessorKey: "managerCo",
    size: 15,
  },
  {
    header: "Reason",
    accessorKey: "reason",
    size: 15,
  },
]);
