import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Formik} from "formik";
import * as Yup from "yup";

import PageContainer from "../../conteiners/PageContainer";
import RequestFilter from "../RequestRegister/RequestFilter";
import RequestTable from "../RequestRegister/RequestTable/RequestTable";
import {deselectCurrentRequest, getForReadiness, getRequest, getRequestInfo} from "../RequestsSlice";

import useInProgressRequests from "./utils/hooks/useInProgressRequests";
import InProgressSector from "./InProgressSector";

import "./InProgress.scss";

const RequestsInProgress = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const tableData = useInProgressRequests();

  const currentRequest = useSelector(getRequestInfo);

  useEffect(() => {
    if (currentRequest) {
      dispatch(getRequest(currentRequest.requestId));
    }
  }, []);
  // useEffect(() => {
  //   if (currentRequest?.requestDates.length > 7) {
  //     dispatch(getForReadiness(currentRequest?.requestId));
  //   }
  // }, [currentRequest]);

  //form initial values
  const initialValues = {
    requestStatus: "",
    requestNumber: "",
    managerFea: "",
    factory: "",
    managerCo: "",
    dateStart: "",
    dateEnd: "",
    note: "",
  };

  const onRequestSelect = id => {
    if (id === currentRequest?.requestId) {
      dispatch(deselectCurrentRequest());
      return;
    }
    dispatch(getRequest(id));
  };

  return (
    <PageContainer title={t("Requests in progress")}>
      <div className="form_wrapper">
        {/* <div className="form_wrapper" style={{paddingBottom: "2em"}}> */}
        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object().shape({})}
          validateOnChange={true}
          validateOnBlur={true}
          enableReinitialize
          onSubmit={() => {}}
        >
          <div className="request-header-wrapper">
            <RequestFilter currentUserTrue>
              <div style={{width: "6em"}}></div>
            </RequestFilter>
          </div>
        </Formik>
        <RequestTable defaultData={tableData} onSelect={onRequestSelect} />
        {/* </div> */}
        <InProgressSector />
      </div>
    </PageContainer>
  );
};

export default RequestsInProgress;
