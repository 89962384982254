import React from "react";
import {useTranslation} from "react-i18next";
import {Form, Formik} from "formik";
import * as Yup from "yup";

import saveIcon from "../../../images/icons/save.png";
import ImageButton from "../../../utilities/Buttons/ImageBtn";
import {Select} from "../../Inputs";

import pencinIcon from "./pencil.png";

import "./accountsForm.scss";

const AllAccountsForm = () => {
  return (
    <Formik
      initialValues={{}}
      validateOnChange
      // enableReinitialize
      validationSchema={Yup.object({})}
    >
      {formProps => (
        <Form className="all-acc-form">
          <BalanceAcc />
          <CommercialAcc />
          <OperationAcc />
        </Form>
      )}
    </Formik>
  );
};

const BalanceAcc = () => {
  const {t} = useTranslation();

  return (
    <div className="acc_forms_container">
      <p className="label">{t("Balance accounts")}</p>
      <div className="flex_container" style={{width: "100%", gap: "1em"}}>
        <Select label={t("Account №")} options={[]} name="acc" width="8em" />
        <Select label={t("Type*")} options={[]} name="acc" width="5em" />
        <Select label={t("Balance account nomenclature")} options={[]} name="acc" width="30em" />
        <div className="flex_container">
          <ImageButton src={pencinIcon} alt="edit" width={1.5} height={1.5} />
          <ImageButton src={saveIcon} alt="save" width={2} height={1.3} />
        </div>
      </div>
    </div>
  );
};

const CommercialAcc = () => {
  const {t} = useTranslation();

  return (
    <div className="acc_forms_container">
      <p className="label">{t("Commercial accounts")}</p>
      <div className="flex_container" style={{width: "100%", gap: "1em", alignItems: "flex-start"}}>
        <Select label={t("Account №")} options={[]} name="acc" width="8em" />
        <Select label={t("Type*")} options={[]} name="acc" width="5em" />
        <div className="">
          <Select label={t("Code of vertical")} options={[]} name="acc" width="15em" />
          <Select label={t("City code")} options={[]} name="acc" width="15em" />
        </div>
        <div className="">
          <Select label={t("Subdivision code")} options={[]} name="acc" width="15em" />
          <div className="flex_container" style={{gap: "1em"}}>
            <Select label={t("FRC code")} options={[]} name="acc" width="15em" />
            <Select label={t("Gross")} options={[]} name="acc" width="5em" />
          </div>
        </div>
      </div>
    </div>
  );
};

const OperationAcc = () => {
  const {t} = useTranslation();

  return (
    <div className="acc_forms_container">
      <p className="label">{t("Operating accounts")}</p>
    </div>
  );
};

export default AllAccountsForm;
