import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {BrowserRouter as Router, Navigate, Route, Routes} from "react-router-dom";
import {ToastContainer} from "react-toastify";

import IndexPage from "../../pages/IndexPage";
import LoginForm from "../authorization/LoginForm";
import PasswordForm from "../authorization/PasswordForm";
import ErrorBoundary from "../errorBoundary/ErrorBoundary";
import RequireAuth from "../hoc/RequireAuth";
import NavBar from "../NavBar/NavBar";

import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import "../../Styles/Main.scss";
import "../../Styles/List.scss";
import "../../Styles/Buttons.scss";
import "../Modal/modal.scss";

function App() {
  const {t} = useTranslation();
  const {i18n} = useTranslation();

  useEffect(() => {
    localStorage.setItem("language", i18n.language);
  }, [i18n.language]);

  // const appHeight = useCallback(() =>{
  //   const doc = document.documentElement
  //   doc.style.setProperty('--app-height', `${window.innerHeight-30}px`)
  // },[])

  // useEffect(() => {
  //   appHeight()
  //   window.addEventListener('resize', appHeight)

  //   return () => window.removeEventListener("resize", appHeight);
  // }, [appHeight]);

  const {token, user} = useSelector(state => state.user);

  return (
    <Router>
      <NavBar />
      <div className="App">
        <div className="App__container">
          <Routes>
            {/* <Route path='/admin/*' element={<RequireAuth><AdminPage/></RequireAuth>}/>  */}
            {/* <Route path='/admin/*' element={<AdminPage/>}/>  */}
            {/* //                <Route path='/*' element={<TemporarilyUnavailable/>}  />  */}
            <Route
              path="/ba3a/*"
              element={
                <RequireAuth>
                  <IndexPage />
                </RequireAuth>
              }
            />
            {/* <Route path='/ba3a/*' element={<IndexPage/>}/>  */}

            <Route
              path="/"
              element={
                <ErrorBoundary>
                  <Navigate to="/login" />
                </ErrorBoundary>
              }
            />
            <Route
              path="/login"
              element={
                user && !token ? (
                  <ErrorBoundary>
                    <Navigate to="/password" />
                  </ErrorBoundary>
                ) : (
                  <ErrorBoundary>
                    <LoginForm />
                  </ErrorBoundary>
                )
              }
            />
            <Route path="/password" element={user && token ? <Navigate to="/ba3a/main" /> : <PasswordForm />} />

            <Route
              path="/*"
              element={
                <div className="flex_container jcc aic" style={{width: "100%", height: "100%"}}>
                  <p> {t("Under development")}</p>
                </div>
              }
            />
          </Routes>
        </div>
        <ToastContainer position="top-center" hideProgressBar />
      </div>
      {/* </div> */}
    </Router>
  );
}

export default App;
