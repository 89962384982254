import {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Form, Formik} from "formik";

import {TextAreaInput} from "../../../../Inputs";
import NewPdfViewer from "../../../../Project/Documents/DocumentView/NewPdfViewer";
import {
  getCurrentDocument,
  getPayment,
} from "../../../../Requests/RequestsInProgress/modalsInProgress/modalPaymentDocParts/redux/PaymentSelectors";

import OnPaymentBlock from "./PaymentToFactoryParts/OnPaymentBlock";
import PaidBlock from "./PaymentToFactoryParts/PaidBlock";

const PaymentToFactoryModal = () => {
  const {
    t,
    i18n: {language},
  } = useTranslation();

  const onPaymentToFactory = () => {};

  const payment = useSelector(getPayment);
  const currentDoc = useSelector(getCurrentDocument);

  const nameLang = language === "en" ? "Eng" : "Ukr";

  const initialValues = useMemo(
    () => ({
      onPaymentPlan: payment?.request?.requestDates?.[5]?.plannedDate || "",
      onPaymentFact: payment?.request?.requestDates?.[5]?.actualDate || "",
      doc: currentDoc?.doc?.docNumber || "",
      documentType: currentDoc?.doc?.docType?.docTypeId || "",
      docNote: "",
      requestNote: payment?.note || "",
      payDocument: "",
    }),
    [payment, currentDoc],
  );
  const onPaymentDocsSubmit = () => {};

  return (
    <Formik initialValues={initialValues} enableReinitialize onSubmit={onPaymentToFactory}>
      <Form>
        <div className="paynent-main">
          <div className="left-part factory_line" style={{height: "95%"}}>
            <OnPaymentBlock />
            <PaidBlock />
          </div>

          <div className="right-part">
            <TextAreaInput name="requestNote" width={"100%"} placeholder={t("note zed factory")} readOnly={true} />
            <div className="kpPreview__box" style={{height: "80%"}}>
              <NewPdfViewer fileArray={currentDoc?.doc ? [currentDoc?.doc.fileUrl] : []} />
            </div>
          </div>
        </div>
      </Form>
    </Formik>
  );
};

export default PaymentToFactoryModal;
