import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {current} from "@reduxjs/toolkit";

import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import ReactTable from "../../../conteiners/ReactTable";
import ModalForm from "../../../Modal/FormModal";
import useColumns from "../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {
  getAllPayments,
  getPaymentInfo,
} from "../../../Requests/RequestsInProgress/modalsInProgress/modalPaymentDocParts/redux/PaymentOperationsSlice";
import {
  getPayment,
  getPayments,
} from "../../../Requests/RequestsInProgress/modalsInProgress/modalPaymentDocParts/redux/PaymentSelectors";

import PaymentToFactoryModal from "./PaymentToFactoryModal/PaymentToFactoryModal";
import {PAYMENT_TO_FACTORY_COLUMNS} from "./utils/constants";
import usePaymentsData from "./utils/getPaymentsData";

const PaymentDocumentsList = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const [paymentToFactoryModal, setPaymentToFactoryModal] = useState(false);
  const [current, setCurrent] = useState();

  const columns = useColumns(PAYMENT_TO_FACTORY_COLUMNS);
  const payments = useSelector(getPayments),
    payment = useSelector(getPayment);

  const defaultData = usePaymentsData(payments);

  useEffect(() => {
    dispatch(getAllPayments());
  }, []);

  const fetchPaymentInfo = paymentId => {
    dispatch(getPaymentInfo(paymentId));
  };

  const onSelect = id => {
    if (id === current) {
      setCurrent(null);
      return;
    }
    setCurrent(id);
  };

  return (
    <>
      <div style={{height: "100%", marginTop: "10px", width: "100%"}}>
        <ReactTable
          defaultData={defaultData}
          columns={columns}
          onSelect={onSelect}
          current={null}
          className={"scrollX"}
          tableClassName={"request-table"}
          loading={false}
        />
      </div>
      <div className="right-side-btn">
        <FunctionButton
          name={"Open"}
          onClick={() => {
            fetchPaymentInfo(current);
            setPaymentToFactoryModal(true);
          }}
          disabled={!current}
        />
      </div>
      <ModalForm
        closable={true}
        Component={PaymentToFactoryModal}
        label={t("P on Req") + " " + payment?.request?.requestNumber}
        active={paymentToFactoryModal}
        setModalActive={setPaymentToFactoryModal}
        // className="inProgressModal"
        // modalInnerWrapperClassName="over"
      />
    </>
  );
};

export default PaymentDocumentsList;
