import {useCallback, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Form, Formik, useFormikContext} from "formik";
import {isEqual} from "lodash";
import PropTypes from "prop-types";
import Swal from "sweetalert2";
import translit from "ua-en-translit";
import * as Yup from "yup";

import plusIcon from "../../../images/icons/plus.png";
import saveImg from "../../../images/icons/save.png";
import {getCurrentKp, getCurrentProject} from "../../../selectors/generalSelectors";
import {
  addAnnex,
  addAnnexFile,
  delAnnexFile,
  filteredAnnexSelector,
  getKpAnnexes,
  updateAnnex,
} from "../../../slices/KPSlice";
import {BUTTON_TYPES} from "../../../utilities/Buttons/constants";
import ImageButton from "../../../utilities/Buttons/ImageBtn";
import {getInfo} from "../../../utilities/toasts";
import {MoneyInput, Select, TextInput} from "../../Inputs";
import FilePreview from "../../Inputs/fileSelect/preview/FilePreview";
import {Select as SelectNew} from "../../Inputs/SelectField";
import {getDocsLoading} from "../ProjectSlice";

import {RegistrButton} from "./RegistrButton";

const AddKPDocumentForm = ({files, setFile, forcedUpdate, docRef, focusOnDoc}) => {
  const {t} = useTranslation();

  const dispatch = useDispatch();
  const currentKp = useSelector(state => state.kp.currentKp),
    docsLoading = useSelector(getDocsLoading);

  const [current, setCurrent] = useState(null);

  const initialValues = useMemo(() => {
    return {annexId: "", note: "", budget: null};
  }, []);

  const onUnpade = values => {
    const {note, budget} = values;
    return dispatch(
      updateAnnex({
        annexId: current.annexId,
        data: {
          note,
          budget,
        },
      }),
    ).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        return res.payload.annex;
      }
    });
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={Yup.object({
          // annexNumber: Yup.string().required([t("Contract number")]),
          note: Yup.string().required([t("Required")]),
        })}
        onSubmit={(values, actions) => {
          if (current) {
            onUnpade(values).then(annex => {
              var {annexId, annexNumber, note, budget} = annex;

              setCurrent({annexId, annexNumber, note, budget});

              setFile(annex?.docsArray || []);
            });
          }
        }}
      >
        <DocForm
          setFile={setFile}
          files={files}
          docRef={docRef}
          focusOnDoc={focusOnDoc}
          forcedUpdate={forcedUpdate}
          onUnpade={onUnpade}
          setCurrent={setCurrent}
          current={current}
          dispatch={dispatch}
        />
        {/* }}  */}
      </Formik>
    </>
  );
};

const DocForm = ({files, setFile, docRef, focusOnDoc, forcedUpdate, setCurrent, current, onUnpade, dispatch}) => {
  const {values, resetForm, submitForm, setValues, setFieldValue} = useFormikContext();

  const {t} = useTranslation();

  const {annexId, note, annexNote} = values;

  // const annexes = useSelector(filteredAnnexSelector),
  const annexes = useSelector(getKpAnnexes),
    currentProject = useSelector(getCurrentProject),
    currentKp = useSelector(getCurrentKp),
    docsLoading = useSelector(getDocsLoading);

  const registrList = useMemo(() => {
    var notes = [];
    annexes.forEach(annex => {
      const {annexId, note, annexNumber} = annex;
      notes.push({id: annexId, annexNumber, note});
    });
    return notes;
  }, [annexes]);

  const annexNumberList = useMemo(() => {
    return annexes?.map(annex => ({title: annex.annexNumber, value: annex.annexId})) || [];
  }, [annexes]);

  // const noteList = useMemo(() => {
  //   var notes = [];
  //   if (annexes.length > 0) {
  //     annexes.forEach(annex => {
  //       const {note: value, docsArray, ...rest} = annex;
  //       if (value) notes.push({value, ...rest});
  //     });
  //     return notes;
  //   } else {
  //     return [{}];
  //   }
  // }, [annexes]);

  const noteList = useMemo(() => {
    return annexes?.map(annex => ({value: annex.note, annexNote: annex.note, noteId: annex.annexId})) || [];
  }, [annexes]);

  useEffect(() => {
    if (annexNote?.length !== 0) {
      const currAn = annexes.find(ann => ann.note === annexNote && ann.annexId === values.noteId);
      setFieldValue("note", current?.note);

      setFieldValue("annexId", currAn?.annexId);
      // resetForm();
    }
  }, [annexNote]);

  const changesCheck = () => {
    if (current && values.annexNumber) {
      const {docsArray, annexId: c1, deadline: v1, noteId: m1, ...restCurr} = current;
      const {annexId: c2, annexNote, deadline: v2, noteId, ...restValues} = values;

      const eq = isEqual(restValues, restCurr);
      if (!eq) {
        Swal.fire({
          title: "",
          text: `${[t("Save document")]} №${values.annexNumber}?`,
          icon: "question",
          confirmButtonText: [t("Yes")],
          showCancelButton: true,
          cancelButtonText: [t("No")],
        }).then(answ => {
          if (answ.isConfirmed) {
            onUnpade(restValues);
          }
        });
      }
    }
  };

  useEffect(() => {
    if (currentProject && !currentKp) {
      resetForm();
      setCurrent(null);
    }
    if (currentKp && annexes.length !== 0) {
      changesCheck();
      var annx = [...annexes];

      var first = annx.pop();
      setFieldValue("annexId", first.annexId);
    } else {
      resetForm();
      setCurrent(null);
    }
  }, [currentKp, currentProject]);

  useEffect(() => {
    if (annexId && annexId !== "") {
      changesCheck();
      var annex_ = annexes.find(item => item.annexId === annexId);
      if (annex_) {
        const {docsArray, ...rest} = annex_;
        setFile(docsArray);
        setCurrent(annex_);

        setValues({...rest});
      } else {
        setCurrent(null);
      }
    }
  }, [annexId]);

  const onFileAdding = arrFiles => {
    var formdata = new FormData();
    var i = 0,
      file;

    for (; i < arrFiles.length; i++) {
      file = arrFiles[i];
      if (current && current?.docsArray && current?.docsArray.length !== 0) {
        var exist = current?.docsArray.includes(`annexes/annex_doc_${current.annexId}_${file.name}`);
        if (exist) {
          Swal.fire({
            title: "",
            text: `${[t("File name")]} ${file.name} ${[t("Already exist")]}`,
            icon: "warning",
            confirmButtonText: "Ок",
            customClas: {
              popup: "zindex",
              container: "zindex",
              htmlContainer: "zindex",
            },
          });
          continue;
        }
      }
      const filename = translit(file.name);
      formdata.append("annex", file, filename);
    }

    if (formdata.has("annex")) {
      dispatch(addAnnexFile({annexId: current.annexId, formdata})).then(res => {
        if (res.meta.requestStatus === "fulfilled") {
          setFile(res.payload.docsArray);
          setCurrent(item => {
            return {...item, docsArray: res.payload.docsArray};
          });
        }
      });
    }
  };

  const onFileDel = url => {
    Swal.fire({
      title: "",
      text: [t("Sure")],
      icon: "question",
      confirmButtonText: [t("Yes")],
      showCancelButton: true,
      cancelButtonText: [t("No")],
      customClas: {
        popup: "zindex",
        container: "zindex",
        htmlContainer: "zindex",
      },
    }).then(value => {
      if (value.isConfirmed) {
        dispatch(delAnnexFile({annexId: current.annexId, url})).then(res => {
          if (res.meta.requestStatus === "fulfilled") {
            setFile(res.payload.docsArray);
            setCurrent(item => {
              return {...item, docsArray: res.payload.docsArray};
            });
          }
        });
      }
    });
  };

  const onPlusClick = () => {
    if (currentKp) {
      resetForm();

      dispatch(addAnnex(currentKp.coId)).then(res => {
        if (res.meta.requestStatus === "fulfilled") {
          // setCurrent({...res.payload.annex});
          // setFieldValue("annexId", res.payload.annex.annexId);
          setFieldValue("annexId", res.payload.annex.annexId);
          // setFile(res.payload.annex.docsArray || []);
        }
      });
    } else {
      getInfo(t("CO at first"));
    }
  };
  const onFocus = i => {
    focusOnDoc(i);
    setFile(current?.docsArray);
  };

  return (
    <Form
      className="add-kp-doc-form"
      ref={el => {
        docRef.current[2] = el;
      }}
      onFocus={() => {
        onFocus(2);
      }}
    >
      <div className="number_block">
        <div>2.</div>
        <div className="block_content">
          <SelectNew
            label={t("Additional")}
            name="annexId"
            options={annexNumberList}
            // className="ref_input"
            inputClassName="ref_input"
            selectOnly={true}
            width="72%"
            question={t("Info")}
            // readOnly={true}
            // multi={true}
          />
          <Select label={t("Briefly about")} width="68.3%" name="note" options={noteList} disabled={!currentKp} />
          {/* <TextInput label={t("Briefly about")} width="68.3%" name="note" /> */}
          <MoneyInput label={t("CO amount")} name="budget" width="65%" readOnly={!currentKp} />
          <div className="flex_container space-between icon_mr">
            <div className="fc icon_ml">
              <ImageButton
                src={plusIcon}
                alt="add remind"
                width={1.2}
                height={1.2}
                tooltipMessage="New entry"
                onClick={onPlusClick}
              />
              <ImageButton src={saveImg} alt="save" type={BUTTON_TYPES.SUBMIT} width={2} height={1.3} />
            </div>
            <RegistrButton label={[t("CO")]} list={registrList} fieldName={"note"} idFieldName="annexId" />
            <FilePreview
              imgs={current?.docsArray || []}
              accept={".pdf"}
              delFunc={onFileDel}
              addFunc={onFileAdding}
              disable={!current}
              loading={docsLoading}
            />
          </div>
        </div>
      </div>
    </Form>
  );
};

AddKPDocumentForm.propTypes = {
  files: PropTypes.array,
  setFile: PropTypes.func,
  forcedUpdate: PropTypes.bool,
  docRef: PropTypes.any,
  focusOnDoc: PropTypes.func,
};

export default AddKPDocumentForm;
