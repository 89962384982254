export const REQUEST_TABLE_COLUMNS = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 25,
  },
  {
    header: "Request status",
    accessorKey: "requestStatus",
    // size: 100,
  },
  {
    header: "Request №",
    accessorKey: "requestNumber",
    // size: 100,
  },
  {
    header: "CO Manager",
    accessorKey: "managerCo",
    // size: 100,
  },
  {
    header: "FEA Manager",
    accessorKey: "managerFea",
    // size: 100,
  },
  {
    header: "Factory",
    accessorKey: "factory",
    // size: 100,
  },
  {
    header: "Request amount",
    accessorKey: "requestSum",
    // size: 100,
  },
  {
    header: "Request date",
    accessorKey: "createdAt",
    // size: 100,
  },
  {
    header: "Notes",
    accessorKey: "note",
    // size: 100,
  },
  // {
  //   header: () => [t("Status*")],
  //   accessorKey: "article",
  //   // size: 100,
  // },
  // {
  //   header: () => [t("DD to factory")],
  //   accessorKey: "noname",
  //   // size: 100,
  // },
  // {
  //   header: () => [t("DD proform")],
  //   accessorKey: "article",
  //   // size: 100,
  // },
  // {
  //   header: () => [t("DD control")],
  //   accessorKey: "article",
  //   // size: 100,
  // },
  // {
  //   header: () => [t("DD for payment")],
  //   accessorKey: "article",
  //   // size: 100,
  // },
  // {
  //   header: () => [t("DD paid")],
  //   accessorKey: "article",
  //   // size: 100,
  // },
  // {
  //   header: () => [t("DD preReady")],
  //   accessorKey: "article",
  //   // size: 100,
  // },
  // {
  //   header: () => [t("DD ReadyProduct")],
  //   accessorKey: "article",
  //   // size: 100,
  // },
  // {
  //   header: () => [t("Quantity (request)")],
  //   accessorKey: "article",
  //   // size: 100,
  // },
  // {
  //   header: () => [t("Quantity(stock)")],
  //   accessorKey: "article",
  //   // size: 100,
  // },
  // {
  //   header: () => [t("Price in request")],
  //   accessorKey: "article",
  //   // size: 100,
  // },
  // {
  //   header: () => [t("TP №")],
  //   accessorKey: "article",
  //   // size: 100,
  // },
  // {
  //   header: () => [t("Reservation")],
  //   accessorKey: "article",
  //   // size: 100,
  // },
  // {
  //   header: () => [t("CO reservation")],
  //   accessorKey: "article",
  //   // size: 100,
  // },
]);
