import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import Swal from "sweetalert2";

import {fetchData, fetchList} from "../../../../../../hook/axios.hook";

const initialState = {
  currentDocument: {
    doc: null,
    number: null,
  },
  payments: [],
  payment: null,
  paymentDocs: [],
  docsForPayment: [],
  docsByPayment: [],
  docTypes: [],
  paymentSchemas: [],
};

export const getPaymentsForRequest = createAsyncThunk("payment/getPaymentsForRequest", async requestId => {
  const responce = fetchData(`/payments/getForRequest/${requestId}`, "get");
  return responce;
});
export const getAllPayments = createAsyncThunk("payment/getAllPayments", async () => {
  const responce = fetchList(`/payments/getAll`);
  return responce;
});

export const getPaymentInfo = createAsyncThunk("payment/getPaymentInfo", async paymentId => {
  const responce = fetchData(`/payments/get/${paymentId}`, "get");
  return responce;
});

export const attachFromRequest = createAsyncThunk("payment/attachFromRequest", async ({paymentId, data}) => {
  const responce = fetchData(`payments/docs/attachFromRequest/${paymentId}`, "post", data);
  return responce;
});

export const addPaymentDocs = createAsyncThunk("payment/addPaymentDocs", async ({paymentId, formData}) => {
  const responce = fetchData(`/payments/docs/add/${paymentId}`, "post", formData);
  return responce;
});

export const addRequestPaymentDocs = createAsyncThunk(
  "payment/addRequestPaymentDocs",
  async ({requestId, formData}) => {
    const responce = fetchData(`/requests/paymentDocs/add/${requestId}`, "post", formData);
    return responce;
  },
);

export const removePaymentDocs = createAsyncThunk("payment/removePaymentDocs", async docId => {
  const responce = fetchData(`/payments/docs/remove/${docId}`, "delete");
  return responce;
});

export const sendForPayment = createAsyncThunk("payment/sendForPayment", async ({paymentId, data}) => {
  const responce = fetchData(`/payments/sendForPayment/${paymentId}`, "patch", data);
  return responce;
});

export const payUp = createAsyncThunk("payment/payUp", async ({paymentId, data}) => {
  const responce = fetchData(`/payments/payUp/${paymentId}`, "patch", data);
  return responce;
});

const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    selectDoc: (state, {payload}) => {
      const {number, docId} = payload;
      state.currentDocument.number = number;
      var doc;
      switch (number) {
        case 1: {
          doc = state.paymentDocs.find(doc => doc.docId === docId);
          break;
        }
        case 2:
          doc = state.docsForPayment.find(doc => doc.docId === docId);
          break;
        case 3:
          doc = state.docsByPayment.find(doc => doc.docId === docId);
          break;
        default:
          break;
      }
      state.currentDocument.doc = doc;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getPaymentsForRequest.fulfilled, (state, {payload}) => {
        const {request, docTypes, paymentSchemas} = payload;
        const {paymentDocs, payment} = request;
        state.payment = payment;
        state.paymentDocs = paymentDocs;
        state.docsForPayment = payment?.docsForPayment || [];
        state.docsByPayment = payment?.paymentConfirmingDocs || [];
        state.docTypes = docTypes;
        state.paymentSchemas = paymentSchemas;
      })
      .addCase(getAllPayments.fulfilled, (state, {payload}) => {
        state.payments = payload;
      })
      .addCase(attachFromRequest.fulfilled, (state, {payload}) => {
        const {docsForPayment} = payload;
        state.docsForPayment = docsForPayment;
      })
      .addCase(addPaymentDocs.fulfilled, (state, {payload}) => {
        ///////not active
        const {docsForPayment, paymentConfirmingDocs} = payload;
        // state.paymentDocs = docsForPayment;
        state.docsByPayment = paymentConfirmingDocs;
      })
      .addCase(addRequestPaymentDocs.fulfilled, (state, {payload}) => {
        const {doc} = payload;
        state.paymentDocs = [...state.paymentDocs, doc];
      })
      .addCase(removePaymentDocs.fulfilled, (state, {meta}) => {
        console.log(meta.arg);
        state.docsForPayment = state.docsForPayment.filter(doc => doc.docId !== meta.arg);
        state.currentDocument = {doc: null, number: null};
      })
      .addCase(sendForPayment.fulfilled, (state, {meta}) => {})
      .addCase(getPaymentInfo.fulfilled, (state, {payload}) => {
        const {payment, docTypes} = payload;
        const {schemas, docsForPayment, paymentConfirmingDocs, ...restInfo} = payment;
        state.payment = restInfo;
        state.docsForPayment = docsForPayment;
        state.paymentSchemas = schemas;
        state.docsByPayment = paymentConfirmingDocs;
        state.docTypes = docTypes;
      })
      .addDefaultCase(() => {});
  },
});

const {actions, reducer} = paymentSlice;

export const {selectDoc} = actions;

export default reducer;
