import {useEffect, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Form, Formik, useFormikContext} from "formik";
import PropTypes from "prop-types";
import * as Yup from "yup";

import {useDebouncedCallback} from "../../../../hook/debounced";
import {getCurrentSku, getSkuOptionsList} from "../../../../selectors/skuSelectors";
import {BUTTON_TYPES} from "../../../../utilities/Buttons/constants";
import ImageButton from "../../../../utilities/Buttons/ImageBtn";
import {checkEmptyFields} from "../../../../utilities/checkEmptyFields";
import ToolTip from "../../../../utilities/ToolTip";
import {Select as NS} from "../../../Inputs";
import {Select} from "../../../Inputs/SelectField";
import useSkuCatalogsService from "../../SkuCatalogsService";
import {changeSkuPage, deselectSku, fetchSku, fetchSkuCatalogs, getSkuInfo, setFilters} from "../../SkuSlice";

import filterOffImg from "./src/8679938_filter_off_line_icon.png";
import filterImg from "./src/8680055_filter_line_icon.png";

import "./src/skuFilters.scss";

const SkuFilters = () => {
  const currentSku = useSelector(getCurrentSku);

  const dispatch = useDispatch();

  const initialValues = useMemo(() => {
    return {
      article: "",
      // country:'',
      brand: "",
      productGroup: "",
      productType: "",
      material: "",
      color: "",
    };
  }, []);

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object().shape({})}
        validateOnChange={true}
        validateOnBlur={true}
        enableReinitialize
        onSubmit={values => {
          // console.log(values);
          // dispatch(createSku(values))
        }}
      >
        <InputsRender dispatch={dispatch} currentSku={currentSku} />
      </Formik>
    </div>
  );
};

const InputsRender = ({dispatch, currentSku}) => {
  const {t} = useTranslation();

  const {brandList, colorsList, materialsList, productGroups, productTypes} = useSkuCatalogsService();

  const skuOptions = useSelector(getSkuOptionsList);
  const {values, setValues, initialValues} = useFormikContext();
  const {brand, productGroup, productType, skuId, article, material, color} = values;

  const onFilter = () => {
    var params = checkEmptyFields({
      // countryId: country.value,
      brandId: brand,
      productGroupId: productGroup,
      productTypeId: productType,
      search: article === "" ? undefined : article,
      materialId: material,
      colorId: color,
      page: 1,
    });

    updateFilterQuery(params);
  };

  // useEffect(() => {
  //     if(article !== ''){
  //         var params = checkEmptyFields({
  //             // countryId:country.countryId,
  //             brandId:brand.brandId,
  //             productGroupId:productGroup.productGroupId,
  //             productTypeId:productType.productTypeId,
  //             search:article,
  //             page:1})
  //         delayedFilterArticle(params)
  //     }
  //     else{
  //         onFilter()
  //     }

  // }, [article]);

  // useEffect(() => {
  //   if (skuId && skuId !== null && skuId !== "") selectSku(skuId);
  //   else if (skuId === "") {
  //     dispatch(deselectSku());

  //     setValues({
  //       // countryName: '',
  //       ...values,
  //       brandName: "",
  //       productGroupName: "",
  //       productTypeName: "",
  //     });
  //   }
  // }, [skuId]);

  ////// страна -> не удалять
  // useEffect(() => {
  //     if(country?.value && country.value!=='')
  //         dispatch(fetchSkuBrands({country:country.value}))
  //         .then(res=>{
  //             if( !res.payload || res.payload?.length === 0 || !res.payload?.some(obj => obj.brandId === brand.value)){
  //                 setFieldValue('brand','')
  //             }
  //         })
  // }, [country]);

  const updateFilterQuery = params => {
    dispatch(fetchSku(params));
    dispatch(setFilters(params));
  };

  const delayedFilter = useDebouncedCallback(updateFilterQuery, 500);
  const delayedFilterArticle = useDebouncedCallback(updateFilterQuery, 2000);

  const selectSku = id => {
    if (currentSku && currentSku.skuId === id) {
      dispatch(deselectSku());
    } else {
      dispatch(getSkuInfo(id));
    }
  };

  useEffect(() => {
    dispatch(fetchSkuCatalogs());
    dispatch(fetchSku({page: 1}));
  }, []);

  const delFilters = () => {
    dispatch(fetchSku({page: 1}));
    setValues(initialValues);
    dispatch(changeSkuPage(1));
    dispatch(setFilters(null));
  };

  ///////////////////////////////////////////////////////////////////////////////

  ///////////////////////////////////////

  return (
    <Form className="project_form">
      <div className="fc nowrap jcfs autolabel sku-filters_container">
        <div className="flex_container nowrap" style={{justifyContent: "space-between"}}>
          <FilterButton onFilter={onFilter} delFilter={delFilters} questionTitle={[t("Filter info")]} />
          <NS
            label="SKU:"
            name="article"
            type="text"
            options={skuOptions}
            width="10em"
            multi={true}
            paginatiable={true}
          />
        </div>
        {/* <Select
            label="Країна*:"
            name="country" 
            options={countries}
            multi={true}
            errorField="countryId"
        /> */}
        <Select label={t("Brand")} name="brand" options={brandList} multi={true} width="100%" errorField="brandId" />
        <Select
          label={t("Group")}
          name="productGroup"
          options={productGroups}
          multi={true}
          width="100%"
          errorField="productGroupId"
        />
        <Select
          label={t("Type")}
          name="productType"
          options={productTypes}
          width="100%"
          multi={true}
          errorField="productTypeId"
        />
        <Select label={t("Finishing")} name="material" options={materialsList} width="100%" multi={true} />
        <Select label={t("Color")} name="color" options={colorsList} width="100%" multi={true} />
      </div>
    </Form>
  );
};

export const FilterButton = ({
  onFilter,
  delFilter,
  questionTitle = "",
  type1 = BUTTON_TYPES.BUTTON,
  type2 = BUTTON_TYPES.BUTTON,
}) => {
  const {t} = useTranslation();
  return (
    <div className="flex_container nowrap">
      <ImageButton
        src={filterImg}
        alt="filter"
        tooltipMessage={questionTitle}
        onClick={onFilter}
        size={2}
        type={type1}
      />

      <ImageButton src={filterOffImg} alt="filter off" onClick={delFilter} size={2} type={type2} />
    </div>
  );
};

FilterButton.propTypes = {
  onFilter: PropTypes.func,
  delFilter: PropTypes.func,
  // questionTitle: PropTypes.string,
  type1: PropTypes.oneOf(Object.values(BUTTON_TYPES)),
  type2: PropTypes.oneOf(Object.values(BUTTON_TYPES)),
};

export default SkuFilters;
