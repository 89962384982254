import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useFormikContext} from "formik";
import translit from "ua-en-translit";

import ImageButton from "../../../../../utilities/Buttons/ImageBtn";
import ImgBox from "../../../../Inputs/fileSelect/ImgBox/ImgBox";
import FilePreview from "../../../../Inputs/fileSelect/preview/FilePreview";
import {
  addColorImages,
  addDecorImages,
  addDrawingImages,
  addSkuImages,
  delColorImages,
  delDecorImages,
  delSkuImages,
  getCurrentSku,
} from "../../../../SKU/SkuSlice";

import plus from "./plus.png";

const ImageBlock = ({label, selectorType, imageType}) => {
  const {t} = useTranslation();

  const dispatch = useDispatch();

  const currentSku = useSelector(getCurrentSku);
  const imagesFromSku = useSelector(selectorType);
  const {values, setFieldValue} = useFormikContext();
  const [currentImgIndex, setCurrentImgIndex] = useState(0);

  const onActiveImgChange = index => {
    setCurrentImgIndex(index);
  };
  const addedImagesKey =
    imageType === "CommonImages" || imageType === "Drawings" ? "addedImages" : `added${imageType}Images`;

  const updateImages = index => {

    if (imagesFromSku !== null) {
      const match = imagesFromSku.find((item, id) => item[id] === item[index]);
      console.log("match", match);

      if (values[addedImagesKey]) {
        if (!match || values[addedImagesKey].includes(match)) {
          return;
        }

        setFieldValue(addedImagesKey, [...values[addedImagesKey], match]);
      } else {
        setFieldValue(addedImagesKey, [match]);
      }
    }
  };
  const addImg = (arrFiles, type) => {
    var formdata = new FormData();
    var i = 0,
      file,
      filename;

    for (; i < arrFiles.length; i++) {
      file = arrFiles[i];
      filename = translit(file.name);
      formdata.append("image", file, filename);
    }

    const dataObj = {skuId: currentSku.skuId, formdata};

    switch (type) {
      case "CommonImages":
        dispatch(addSkuImages(dataObj));
        break;
      case "Decor":
        dispatch(addDecorImages(dataObj));
        break;
      case "Color":
        dispatch(addColorImages(dataObj));
        break;
      case "Drawings":
        dispatch(addDrawingImages(dataObj));
        break;
      default:
        break;
    }
  };
  function onDell(imgUrl, type) {
    const dataObj = {skuId: currentSku.skuId, imgUrl};
    switch (type) {
      case "CommonImages":
        dispatch(delSkuImages(dataObj));
        break;
      case "Decor":
        dispatch(delDecorImages(dataObj));
        break;
      case "Color":
        dispatch(delColorImages(dataObj));
        break;
      default:
        break;
    }
  }

  const plusRender = type => {
    if (type === "CommonImages" || type === "Drawings") {
      return values[addedImagesKey]?.length >= 2 ? null : plus;
    } else {
      return plus;
    }
  };

  return (
    <div style={{display: "flex", flexDirection: "column"}}>
      <label className="label" style={{marginBottom: "12px"}}>
        {t(label)}
      </label>
      <div className="kpf_img_box" style={{position: "relative"}}>
        <ImageButton
          src={plusRender(imageType)}
          alt="plus"
          width={1.5}
          height={1.4}
          onClick={() => {
            updateImages(currentImgIndex);
          }}
          className="button-add"
        />
        <div style={{position: "absolute", height: "100%"}}>
          <ImgBox
            imgs={imagesFromSku || []}
            width={"10em"}
            height={"10em"}
            onActiveImgChange={onActiveImgChange}
          ></ImgBox>
          <FilePreview
            imgs={imagesFromSku || []}
            delFunc={file => {
              onDell(file, imageType);
            }}
            accept={"image/*"}
            addFunc={file => {
              addImg(file, imageType);
            }}
            disable={!currentSku}
            cropNeed={true}
          />
        </div>
      </div>
    </div>
  );
};

export default ImageBlock;
