import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {fetchRequests, getFourthStatusRequests} from "../../../../RequestsSlice";

const useStatus4Requests = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    //!
    dispatch(fetchRequests({statusId: 4, currentUser: true}));
    // dispatch(fetchRequests({ statusId: 4 }));
  }, []);

  const status4Requests = useSelector(getFourthStatusRequests);
  return status4Requests;
};

export default useStatus4Requests;
