import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useFormikContext} from "formik";
import moment from "moment";
import Swal from "sweetalert2";

import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import ReactTable from "../../../conteiners/ReactTable";
import {DateInput, TextInput} from "../../../Inputs";
import useColumns from "../../OrderProcessing/utils/hooks/useColumns";
import {
  addReadinessDate,
  confirmReadiness,
  getRequest,
  getRequestInfo,
  selectdatesWithPostponed,
} from "../../RequestsSlice";

import {READINESS_TABLE_COLUMNS} from "./utils/constants";

import styles from "./ReceiptGoodsModal.module.scss";

const ReadinessDatesBlock = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const currentRequest = useSelector(getRequestInfo),
    allDatesWithPostponed = useSelector(selectdatesWithPostponed);
  const readinessTableColumns = useColumns(READINESS_TABLE_COLUMNS);

  const {values, resetForm} = useFormikContext();

  const {readinessDate, readinessControlDate, readinessNote} = values;

  const onAddDataClick = () => {
    const formattedReadinessDate = moment(readinessDate, "DD-MM-YYYY");
    const formattedReadinessControlDate = moment(readinessControlDate, "DD-MM-YYYY");

    if (formattedReadinessControlDate.isBefore(formattedReadinessDate)) {
      dispatch(
        addReadinessDate({
          requestId: currentRequest.requestId,
          data: {
            plannedDate: new Date(readinessDate).toJSON(),
            controlDate: new Date(readinessControlDate).toJSON(),
            note: readinessNote,
          },
        }),
      ).then(res => {
        if (res.meta.requestStatus === "fulfilled") {
          resetForm();
        }
      });
    } else {
      Swal.fire({
        title: "",
        text: t("Readiness info"),
        icon: "error",
      });
      return;
    }
  };

  const onProductReadyClick = () => {
    dispatch(confirmReadiness(currentRequest.requestId)).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        dispatch(getRequest(currentRequest.requestId));
      }
    });
  };

  const addDataButtonDisabled =
    allDatesWithPostponed.length === 0
      ? readinessControlDate === "" || readinessDate === ""
      : readinessNote === "" ||
        readinessDate === "" ||
        readinessControlDate === "" ||
        allDatesWithPostponed.length === 3 ||
        !!currentRequest?.requestDates[7]?.actualDate;

  return (
    <section className={styles.readinessSection}>
      <div className={styles.readinessColumn}>
        <DateInput
          name="readinessDate"
          question="fea fab"
          flatpikrConfig={{
            minDate: moment(currentRequest.requestDates[6].actualDate, "YYYY-MM-DD")
              .add(2, "days")
              .format("DD-MM-YYYY"),
          }}
          label={t("Date: Readiness") + "(n)"}
          readOnly={allDatesWithPostponed.length === 3 || currentRequest?.requestDates[7]?.actualDate}
        />
        <DateInput
          name="readinessControlDate"
          question="fea func"
          flatpikrConfig={{
            minDate: moment(currentRequest.requestDates[6].actualDate, "YYYY-MM-DD")
              .add(1, "days")
              .format("DD-MM-YYYY"),
          }}
          label={t("Date: Readiness control")}
          readOnly={allDatesWithPostponed.length === 3 || currentRequest?.requestDates[7]?.actualDate}
        />

        <TextInput
          name="readinessNote"
          placeholder={t("Add Note to 'Product availability information' in this field")}
        />
      </div>
      <div className={styles.readinessColumn}>
        <ReactTable
          className={styles.readinessTableWrapper}
          tableClassName={styles.readinessTable}
          columns={readinessTableColumns}
          defaultData={allDatesWithPostponed}
        />
        <FunctionButton
          className={styles.addDataBtn}
          name="Add data"
          autoWidth
          disabled={addDataButtonDisabled}
          onClick={() => {
            onAddDataClick();
          }}
        />
      </div>
      <div className={styles.readinessColumnT}>
        <TextInput
          name="readinessActualDate"
          label={t("Date: Readiness (f)")}
          width="10em"
          readOnly
          generalClassName={styles.readinessDateInput}
        />

        <FunctionButton
          type="button"
          className={styles.productReadyBtn}
          name="The product is ready"
          autoWidth
          onClick={() => {
            onProductReadyClick();
          }}
          disabled={allDatesWithPostponed?.length === 0 || !!currentRequest?.requestDates[7]?.actualDate}
        />
      </div>
    </section>
  );
};

export default ReadinessDatesBlock;
