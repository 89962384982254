import React from "react";
import {Route, Routes} from "react-router-dom";

import ErrorBoundary from "../Components/errorBoundary/ErrorBoundary";
import OrderProcessing from "../Components/Requests/OrderProcessing/OrderProcessing";
import FactoryRequestModificate from "../Components/Requests/RequestCreation/FactoryRequest/FactoryRequestModificate";
import RequestCreation from "../Components/Requests/RequestCreation/RequestCreation";
import RequestRegister from "../Components/Requests/RequestRegister/RequestRegister";
import RequestsInProgress from "../Components/Requests/RequestsInProgress/RequestsInProgress";
import SkuVed from "../Components/SKU/VED/SkuVed";

import SkuVed2 from "../Components/SKU/VED/SkuVed2";

import FactoryCC from "../Components/VED/settings/conditions&contactsFactory/FactoryCC";
import VedSettingsGeneral from "../Components/VED/VedSetiingsGeneral/VedSettingsGeneral";


const SkuVedPage = () => {
  return (
    <Routes>
      <Route
        path="/sku"
        element={
          <ErrorBoundary>
            <SkuVed />
          </ErrorBoundary>
        }
      />
      <Route
        path="/sku2"
        element={
          <ErrorBoundary>
            <SkuVed2 />
          </ErrorBoundary>
        }
      />
      <Route
        path="/reqRegistr"
        element={
          // <ErrorBoundary>
          <RequestRegister />
          //  {/* <Ordering/> check */}
          // </ErrorBoundary>
        }
      />
      <Route
        path="/orderProcess"
        element={
          // <ErrorBoundary>
          <OrderProcessing />
          // </ErrorBoundary>
        }
      />
      <Route
        path="/reqCreate"
        element={
          // <ErrorBoundary>
          <RequestCreation />
          // </ErrorBoundary>
        }
      />
      <Route path="/requestToFactoryMod" element={<FactoryRequestModificate />} />
      <Route
        path="/reqInProgress"
        element={
          // <ErrorBoundary>
          <RequestsInProgress />
          // </ErrorBoundary>
        }
      />
      <Route
        path="/factorySettings"
        element={
          // <ErrorBoundary>
          <FactoryCC />
          // </ErrorBoundary>
        }
      />
      <Route
        path="/vedSettingsGeneral"
        element={
          // <ErrorBoundary>
          <VedSettingsGeneral />
          // </ErrorBoundary>
        }
      />
    </Routes>
  );
};

export default SkuVedPage;
