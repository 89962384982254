import {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Tooltip} from "@mui/material";
import {useFormikContext} from "formik";

import {withFormikForm} from "../../../../hook/formikFormHOC";
import AvailabilityStatusCIrcule from "../../../../utilities/AvailabilityStatusCIrcule";
import {checkEmptyFields} from "../../../../utilities/checkEmptyFields";
import {TextInput} from "../../../Inputs";
import {Select} from "../../../Inputs/SelectField";
import {FilterButton} from "../../../SKU/VED/Filters/SkuFilters";
import {fetchRequests, getAllStatusRequest, getRequestRegistrFilters} from "../../RequestsSlice";

const OrderingFilterForm = () => {
  // const {values} = useFormikContext()
  const {t, i18n} = useTranslation();

  const dispatch = useDispatch();

  const filters = useSelector(getRequestRegistrFilters),
    allReq = useSelector(getAllStatusRequest);

  const {values, resetForm} = useFormikContext();

  const brandsOptions = useMemo(() => {
    return (
      filters?.brands.map(item => {
        // return { title: item[i18n.language === "en" ? "engName" : "ukrName"], value: item.brandId };
        return {title: item.name, value: item.brandId};
      }) || []
    );
  }, [filters]);

  const coManagersOptions = useMemo(() => {
    return (
      filters?.coManagers.map(item => {
        const name =
          i18n.language === "en"
            ? item.persona.engFirstName + " " + item.persona.engLastName
            : item.persona.firstName + " " + item.persona.lastName;
        return {
          title: name,
          value: item.managerCoId,
        };
      }) || []
    );
  }, [filters, i18n.language]);

  const onFilter = () => {
    const {requestNumber, managerCo, factory} = values;
    var params = checkEmptyFields({
      statusId: 1,
      requestNumber,
      managerCoId: managerCo,
      brandId: factory,
    });
    dispatch(fetchRequests(params));
  };

  const delFilters = () => {
    dispatch(fetchRequests({statusId: 1})).then(res => {
      if (res.meta.requestStatus === "fulfilled") resetForm();
    });
  };

  return (
    <div className="fc jcfs autolabel" style={{gap: "2em"}}>
      <AvailabilityStatusCIrcule tooltipMessage="indicator" />

      <FilterButton onFilter={onFilter} delFilter={delFilters} questionTitle={[t("Filter")]} />

      <TextInput label={t("Order number") + ":"} name="requestNumber" />
      <Select label={t("CO Manager") + ":"} options={coManagersOptions} name="managerCo" />
      <Select label={t("Factory") + ":"} options={brandsOptions} name="factory" />
    </div>
  );
};

const OrderingFilterFormComp = withFormikForm(OrderingFilterForm);

const OrderingFilter = () => {
  const initialValues = {
    managerCo: "",
    factory: "",
    requestNumber: "",
  };

  const onSubmit = values => {};

  const validationObject = {};

  return (
    <div style={{height: "3em"}}>
      <OrderingFilterFormComp initialValues={initialValues} onSubmit={onSubmit} validationObject={validationObject} />
    </div>
  );
};

export default OrderingFilter;
