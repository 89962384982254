import {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import ReactTable from "../../../conteiners/ReactTableUniSelect";
import {getAppointRequests} from "../../RequestsSlice";

import styles from "./ordering.module.scss";

const AppointedRequestTable = ({setSelectedForCancel}) => {
  const {t, i18n} = useTranslation();

  const [currentReq, setCurrentReq] = useState(null);
  const appointedRequests = useSelector(getAppointRequests);

  useEffect(() => {
    if (currentReq) setSelectedForCancel([currentReq]);
  }, [currentReq]);

  const transformForTable = (item, i) => {
    const {requestId, status, brand, managerFea, managerCo, ...rest} = item;
    const statusName = status?.engName ? status[i18n.language === "en" ? "engName" : "ukrName"] : "";
    return {
      ...rest,
      number: i + 1,
      status: statusName,
      managerCo: managerCo?.name || "",
      managerFea: managerFea?.name || "",
      factory: brand?.name || "",
      id: requestId,
      noname: "",
    };
  };
  const defaultData = useMemo(() => {
    return appointedRequests.map(transformForTable);
  }, [appointedRequests, i18n.language]);

  useEffect(() => {
    console.log();
  }, [setCurrentReq]);

  const columns = useMemo(
    () => [
      {
        header: () => "№",
        accessorKey: "number",
        size: 15,
      },
      {
        header: () => [t("Order")],
        accessorKey: "requestNumber",
        // size: 100,
      },
      {
        header: () => [t("FEA Manager")],
        accessorKey: "managerFea",
        // size: 100,
      },
      {
        header: () => [t("Factory")],
        accessorKey: "factory",
        // size: 100,
      },
      {
        header: () => [t("Request amount")],
        accessorKey: "requestSum",
        // size: 100,
      },
      {
        header: () => [t("Request date")],
        accessorKey: "createdAt",
        // size: 100,
      },
      {
        header: () => [t("Notes")],
        accessorKey: "note",
        // size: 100,
      },
      {
        header: () => [t("Status*")],
        accessorKey: "status",
        // size: 100,
      },
      // {
      //   header: () => [t("DD to factory")],
      //   accessorKey: "article",
      //   // size: 100,
      // },
      // {
      //   header: () => [t("DD proform")],
      //   accessorKey: "article",
      //   // size: 100,
      // },
      // {
      //   header: () => [t("DD control")],
      //   accessorKey: "article",
      //   // size: 100,
      // },
      // {
      //   header: () => [t("DD for payment")],
      //   accessorKey: "article",
      //   // size: 100,
      // },
      // {
      //   header: () => [t("DD paid")],
      //   accessorKey: "article",
      //   // size: 100,
      // }
      // ,
      // {
      //   header: () => [t("DD preReady")],
      //   accessorKey: "article",
      //   // size: 100,
      // }
      // ,
      // {
      //   header: () => [t("DD ReadyProduct")],
      //   accessorKey: "article",
      //   // size: 100,
      // }
    ],
    [],
  );
  return (
    <div
      // style={{height: "100%", width: "100%", display: "flex"}}
      className={styles.table + " table_xscroll__container "}
    >
      <ReactTable defaultData={defaultData} columns={columns} onSelect={setCurrentReq} current={currentReq} />
    </div>
  );
};

export default AppointedRequestTable;
