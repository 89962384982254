import React, {useCallback, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {useFormikContext} from "formik";

import ImageButton from "../../../../../utilities/Buttons/ImageBtn";
import {Select} from "../../../../Inputs/SelectField";
import {fetchAllConditions} from "../../../redux/operations/FactoryOperations";
import useCountryIdOptionsService from "../../../settings/conditions&contactsFactory/utils/hooks/getCountryIdOptionsService";
import useFactoryIdOptionsService from "../../../settings/conditions&contactsFactory/utils/hooks/getFactoryIdOptionsService";

import filterOffImg from "./8679938_filter_off_line_icon.png";

import styles from "../../common/styles/VedSettingsGeneral.module.scss";

const FactoryListTableFilter = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const {
    values: {factoryId, countryId},
  } = useFormikContext();

  const countryIdOptions = useCountryIdOptionsService();
  const factoryIdOptions = useFactoryIdOptionsService();

  const filter = () => {
    if ((!factoryId || factoryId === "") && (!countryId || countryId === "")) {
      dispatch(fetchAllConditions());
      return;
    }
    console.log("filtr");
    var filters = {};
    if (factoryId && factoryId !== "") {
      filters["factoryId"] = factoryId;
    }
    if (countryId && countryId !== "") {
      filters["countryId"] = countryId;
    }
    if (Object.hasOwn(filters, "countryId") || Object.hasOwn(filters, "factoryId")) {
      dispatch(fetchAllConditions({...filters}));
    }
  };

  const delFilter = useCallback(() => {
    dispatch(fetchAllConditions());
  }, []);

  useEffect(() => {
    filter();
  }, [factoryId, countryId]);

  return (
    <div className={`${styles.sectionRow} ${styles.factoryListTableFilter}`}>
      <ImageButton src={filterOffImg} alt="filter off" onClick={delFilter} size={2} />
      <Select label={t("Factory") + ":"} name="factoryId" options={factoryIdOptions} />{" "}
      <Select label={t("Country") + ":"} name="countryId" options={countryIdOptions} />{" "}
      <Select label={t("Factory type") + ":"} name="selectedFactoryType" options={[]} multi={false} />
    </div>
  );
};

export default FactoryListTableFilter;
