import {apiUrl} from "../../../../../api/baseURL";
import ImageButton from "../../../../../utilities/Buttons/ImageBtn";
import cancel from "../cancel.png";

const ImagesRow = ({setFieldValue, values, data, columns, chengeable, type}) => {
  const handleDeleteImage = (image, type) => {
    switch (type) {
      case "decor":
        setFieldValue(
          "addedDecorImages",
          values.addedDecorImages.filter(item => item !== image),
        );
        break;
      case "color":
        setFieldValue(
          "addedColorImages",
          values.addedColorImages.filter(item => item !== image),
        );
        break;
      default:
        break;
    }
  };

  return (
    <>
      {(data.text === "" || data.text === null) && data.images.length === 0 ? null : (
        <tr>
          <td
            style={{
              border: "0",
            }}
          ></td>
          <td
            style={{
              border: "0",
            }}
          ></td>
          <td
            style={{
              border: "0.02px solid rgba(88, 89, 93, 0.2941176471)",
            }}
          >
            {data.text ? (
              <div
                style={{
                  overflowWrap: "break-word",
                  whiteSpace: "pre-wrap",
                  overflow: "hidden",
                }}
              >
                {data.text}
              </div>
            ) : null}
          </td>

          <td
            colSpan={columns.length}
            style={{
              border: "0.02px solid rgba(88, 89, 93, 0.2941176471)",
            }}
          >
            {data.images.length > 0 ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "left",
                  marginLeft: "21px",
                }}
              >
                {data.images.map((image, index) => (
                  <div key={index} style={{position: "relative", marginLeft: "5px"}}>
                    <img src={apiUrl + "/" + image} style={{width: "100px", height: "100px"}} />
                    {chengeable && (
                      <ImageButton
                        src={cancel}
                        alt="cancel"
                        width={1}
                        height={1}
                        onClick={() => handleDeleteImage(image, type)}
                        style={{
                          position: "absolute",
                          border: "0",
                          background: "transparent",
                          cursor: "pointer",
                          right: "-5px",
                        }}
                      />
                    )}
                  </div>
                ))}
              </div>
            ) : null}
          </td>
        </tr>
      )}
    </>
  );
};

export default ImagesRow;
