import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import useColumns from "../../../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {fetchFactoryContactConditions} from "../../../../redux/operations/FactoryOperations";
import {
  selectCurrentConditionId,
  selectCurrentContactId,
  selectCurrentFactoryId,
  selectFactoryContactConditions,
} from "../../../../redux/selectors/FactorySelectors";
import {setCurrentConditionId} from "../../../../redux/slices/FactorySlice";
import {CONDITIONS_DELIVERY_COLUMNS} from "../../../conditions&contactsFactory/utils/constants";

import useTermsStatusOptionsService from "./getTermsStatusOptionsService";
import useTimeUnitsService from "./getTimeUnitsService";

const useFactoryConditionFormService = () => {
  const dispatch = useDispatch();

  const columns = useColumns(CONDITIONS_DELIVERY_COLUMNS);
  const termsStatusOptions = useTermsStatusOptionsService();

  const currentConditionId = useSelector(selectCurrentConditionId);
  const currentFactoryId = useSelector(selectCurrentFactoryId);
  const currentContactId = useSelector(selectCurrentContactId);
  const factoryContactConditions = useSelector(selectFactoryContactConditions);

  useEffect(() => {
    if (!currentFactoryId || !currentContactId) {
      return;
    }

    dispatch(fetchFactoryContactConditions({factoryId: currentFactoryId, contactId: currentContactId}));
  }, [currentFactoryId, currentContactId]);

  const current = currentConditionId;

  const termsOptions = useTimeUnitsService();

  const factoryConditionTableData = factoryContactConditions
    ? factoryContactConditions.map(
        ({conditionId, discount, discountNote, termsFrom, termsTo, termsNote, termsCondition}, index) => ({
          index: ++index,
          id: conditionId,
          discount,
          discountNote,
          terms: termsFrom && termsTo ? `from ${Math.round(termsFrom)} to ${Math.round(termsTo)}` : "",
          termsNote,
          termsCondition,
        }),
      )
    : [];

  function onConditionTableRowSelect(id) {
    if (id === currentConditionId) {
      dispatch(setCurrentConditionId(null));
      return;
    }
    dispatch(setCurrentConditionId(id));
  }

  return {columns, termsStatusOptions, factoryConditionTableData, onConditionTableRowSelect, current, termsOptions};
};

export default useFactoryConditionFormService;
