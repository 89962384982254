import React from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useFormikContext} from "formik";

import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import ReactTable from "../../../conteiners/ReactTable";
import useColumns from "../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {selectConditions, selectCurrentConditionId} from "../../redux/selectors/FactorySelectors";
import {setCurrentConditionId, setCurrentFactoryId, setCurrentFactoryName} from "../../redux/slices/FactorySlice";
import useFactoryCCService from "../../settings/conditions&contactsFactory/utils/hooks/getFactoryCCService";
import {FACTORY_LIST_TABLE_COLUMNS} from "../common/utils/constants";

import FactoryListTableFilter from "./FactoryListTableFilter/FactoryListTableFilter";

import styles from "../common/styles/VedSettingsGeneral.module.scss";

const FactoryList = () => {
  const {t} = useTranslation();

  const {values} = useFormikContext();
  const dispatch = useDispatch();

  //data
  const fabricListColumns = useColumns(FACTORY_LIST_TABLE_COLUMNS);
  const allConditions = useSelector(selectConditions);
  const currentCondition = useSelector(selectCurrentConditionId);
  const fabricListData = [];

  //event handlers
  const onfabricListTableSelect = id => {
    const conditionId = Number(id.split("*")[0]),
      factoryId = Number(id.split("*")[1]);

    if (currentCondition === conditionId) {
      dispatch(setCurrentConditionId(null));
      dispatch(setCurrentFactoryId(null));
      dispatch(setCurrentFactoryName(null));
      return;
    }
    dispatch(setCurrentConditionId(conditionId));
    dispatch(setCurrentFactoryId(factoryId));

    const factory = allConditions.find(cond => cond.conditionId === conditionId)?.factory;
    dispatch(setCurrentFactoryName(factory?.name || null));
  };

  const onSaveSettingsBtnClick = () => {};
  const onCancelBtnClick = () => {};

  const {
    // initialValues,
    // columns,
    factoryCCTableData,
    // currentTableSelectedFactory,
    onFactoryConditionTableRowSelect,
    // onNewFactoryModalClose,
    // onEditCondFactoryModalClose,
    // onCreateCondModalClose,
    // areFactoryCCBtnsDisabled,
  } = useFactoryCCService();

  return (
    <section className={styles.factoryListSection}>
      <h2 className={styles.sectionHeader}>
        4. <span className={styles.underLined}>{t("Factories List with conditions and contacts")}</span>
      </h2>
      <FactoryListTableFilter />
      <ReactTable
        className={styles.factoryListTableWrapper}
        columns={fabricListColumns}
        defaultData={factoryCCTableData}
        onSelect={onfabricListTableSelect}
        current={currentCondition}
      />
      <div className={styles.factoryListButtonRow}>
        <FunctionButton name="Save settings" disabled onClick={onSaveSettingsBtnClick} autoWidth />
        <FunctionButton name="Cancel" disabled onClick={onCancelBtnClick} autoWidth />
      </div>
    </section>
  );
};

export default FactoryList;
