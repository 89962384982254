import React from "react";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";

import FunctionButton from "./FunctionButton";

const KpfControlSaveBtns = ({seveAll}) => {
  const {t} = useTranslation();

  return (
    <div style={{height: "15%"}}>
      <div className="fixed_button__container save_btns">
        <FunctionButton
          name={t("Save CO(f)")}
          onClick={seveAll}
          //  disabled={!props.isEdited}
        />

        <FunctionButton name={t("Cancel")} style={{width: "12em", padding: "1px 0"}} />
      </div>
    </div>
  );
};

KpfControlSaveBtns.propTypes = {
  seveAll: PropTypes.func,
};

export default KpfControlSaveBtns;
