import {useEffect, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {Field, Form, Formik, useFormikContext} from "formik";
import PropTypes from "prop-types";
import Swal from "sweetalert2";
import * as Yup from "yup";

import {getCurrentKp, getCurrentSubKp} from "../../../../selectors/generalSelectors";
import {createKpA, createKpF, getCoaList, getCofList, selectSubKp, updateSubKpNote} from "../../../../slices/KPSlice";
import {BUTTON_COLORS} from "../../../../utilities/Buttons/constants";
import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import {NoteInput, Select} from "../../../Inputs";

import TypedKpList from "./TypedKpList";

const TypedKpAddForm = ({forcedUpdate}) => {
  const currentSubKp = useSelector(getCurrentSubKp);

  return (
    <div style={{height: "60%"}}>
      <Formik
        initialValues={
          currentSubKp
            ? {...currentSubKp, kp: ""}
            : {
                type: "",
                id: "",
                note: "",
                kp: "",
              }
        }
        validateOnChange
        validationSchema={Yup.object({})}
        enableReinitialize
        // onSubmit={values => {}}
      >
        {() => (
          // { values, setFieldValue, handleChange, initialValues }
          <Form style={{height: "100%"}}>
            <FormContainer forcedUpdate={forcedUpdate} />
          </Form>
        )}
      </Formik>
    </div>
  );
};

const FormContainer = ({forcedUpdate}) => {
  const {t} = useTranslation();

  const {values, initialValues} = useFormikContext();
  const currentKp = useSelector(getCurrentKp);
  const currentSubKp = useSelector(getCurrentSubKp);
  const subKpList = useSelector(state => state.kp.subKpList);

  const kpFList = useMemo(() => {
    var kpf = [];
    var kpa = [];
    subKpList.forEach(kp => {
      if (kp.type === "f") {
        kpf.push({value: kp.cofNumber, id: kp.cofId});
      } else {
        kpa.push({value: kp.coaNumber, id: kp.coaId});
      }
    });

    return {kpf, kpa};
  }, [subKpList]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentKp) onNoteRed(values.note);
  }, [forcedUpdate]);

  const makeEffect = (kp, type) => {
    if (values.note !== initialValues.note) {
      Swal.fire({
        title: "",
        text: `${t["CO changes"]} ${currentSubKp?.cofNumber || currentSubKp?.coaNumber} ?`,
        icon: "question",
        confirmButtonText: [t("Yes")],
        showCancelButton: true,
        cancelButtonText: [t("No")],
      })
        .then(answ => {
          if (answ.isConfirmed) {
            onNoteRed(values.note);
          }
        })
        .then(() => {
          dispatch(selectSubKp({kp, type}));
        });
    } else {
      dispatch(selectSubKp({kp, type}));
    }
  };

  const onNoteRed = note => {
    if (currentSubKp && values.note !== initialValues.note) {
      dispatch(
        updateSubKpNote({
          id: currentSubKp.type === "f" ? currentSubKp.cofId : currentSubKp.coaId,
          type: currentSubKp.type,
          newNote: note,
        }),
      );
    }
  };

  return (
    <div className="form_wrapper">
      <div>
        <div style={{display: "flex", margin: "5px 0"}}>
          <p style={{width: "15%"}}>{t("CO type")}</p>
          <div role="group ">
            <div className="fin_block__container typeKp" style={{marginBottom: "5%", paddingTop: "2%"}}>
              <label className="checkBox_label">
                <Field type="radio" name="type" value="f" />
                financial (f):
                <br />
                <div style={{display: "flex", marginLeft: "4%"}}>
                  <Select
                    label={t("CO(f) additional")}
                    name={currentSubKp && currentSubKp.type === "f" ? "cofNumber" : "co"}
                    options={kpFList.kpf}
                    disabled={values.type !== "f"}
                    makeEffect={option => {
                      makeEffect(option.id);
                    }}
                  />

                  <FunctionButton
                    name={t("Create CO(f)")}
                    onClick={() => {
                      dispatch(createKpF({coId: currentKp.coId, newKp: {note: values.note}}));
                    }}
                    disabled={values.type !== "f"}
                  />

                  <FunctionButton name={t("Delete CO(f)")} onClick={null} disabled={true} color={BUTTON_COLORS.RED} />
                </div>
              </label>
            </div>
            <div className="alb_block__container typeKp" style={{marginBottom: "5%"}}>
              <label className="checkBox_label ">
                <Field type="radio" name="type" value="a" />
                albumial (a):
                <div style={{display: "flex", marginLeft: "4%"}}>
                  <Select
                    label={t("CO(a) additional")}
                    name={currentSubKp && currentSubKp.type === "a" ? "coaNumber" : "kp"}
                    options={kpFList.kpa}
                    disabled={values.type !== "a"}
                    makeEffect={option => {
                      makeEffect(option.id);
                    }}
                  />

                  <FunctionButton
                    name={t("Create CO(a)")}
                    onClick={() => {
                      dispatch(createKpA({coId: currentKp.coId, newKp: {note: values.note}}));
                    }}
                    disabled={values.type !== "a"}
                  />
                  <FunctionButton name={t("Delete CO(a)")} onClick={null} disabled={true} color={BUTTON_COLORS.RED} />
                </div>
              </label>
            </div>
          </div>
        </div>
        <NoteInput label={t("About")} action={onNoteRed} name="note" type="text" width="100%" />
      </div>
      <div className="project_list " style={{height: "90%", paddingBottom: "2%"}}>
        <TypedKpList onSelect={makeEffect} />
      </div>
      <div style={{position: "relative"}}>
        <FunctionButton
          name={t("Open and edit")}
          disabled={!currentSubKp || currentSubKp?.type === "a" || currentSubKp?.isMain}
          onClick={() => {
            navigate("/ba3a/kp/kpfin", {state: {currentKpId: currentKp.coId, currentSubKpId: currentSubKp.cofId}});
          }}
          style={{
            width: "auto",
            right: "0",
            position: "absolute",
            bottom: "-1.6em",
          }}
        />
      </div>
    </div>
  );
};
TypedKpAddForm.propTypes = {forcedUpdate: PropTypes.bool};
export default TypedKpAddForm;
