import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useFormikContext} from "formik";
import translit from "ua-en-translit";

import ImageButton from "../../../../../utilities/Buttons/ImageBtn";
import ImgBox from "../../../../Inputs/fileSelect/ImgBox/ImgBox";
import FilePreview from "../../../../Inputs/fileSelect/preview/FilePreview";
import {delOverallImage, getCurrentSkuPlan, uploadOverallImage} from "../../KpFinSlice";

import plus from "./plus.png";

const OverallView = () => {
  const {t} = useTranslation();

  const dispatch = useDispatch();

  const currentSkuPlan = useSelector(getCurrentSkuPlan);

  const {values, setFieldValue} = useFormikContext();

  const [currentImgViewIndex, setCurrentImgViewIndex] = useState(0);

  const onActiveImgViewChange = index => {
    setCurrentImgViewIndex(index);
  };

  const updatePlanImage = index => {
    if (currentSkuPlan.uploadedOverallView !== null) {
      const match = currentSkuPlan.uploadedOverallView?.find((item, id) => item[id] === item[index]);

      if (values.addedOverallView) {
        if (!match || values.addedOverallView.includes(match)) {
          return;
        }

        setFieldValue("addedOverallView", [...values.addedOverallView, match]);
      } else {
        setFieldValue("addedOverallView", [match]);
      }
    }
  };

  const addOvImg = arrFiles => {
    var formdata = new FormData();
    var i = 0,
      file,
      filename;

    for (; i < arrFiles.length; i++) {
      file = arrFiles[i];
      filename = translit(file.name);
      formdata.append("image", file, filename);
    }

    dispatch(uploadOverallImage({skuPlanId: currentSkuPlan.skuPlanId, formdata}));
  };

  function onDell(imgUrl) {
    dispatch(delOverallImage({skuPlanId: currentSkuPlan.skuPlanId, imgUrl}));
  }

  useEffect(() => {
    setFieldValue("uploadedOverallView", currentSkuPlan?.uploadedOverallView);
  }, [currentSkuPlan?.uploadedOverallView]);

  return (
    <div style={{display: "flex", flexDirection: "column"}}>
      <label className="label" style={{marginBottom: "10px"}}>
        {t("Overall view")}
      </label>
      <div className="kpf_img_box" style={{position: "relative"}}>
        <ImageButton
          src={plus}
          alt="plus"
          width={1.5}
          height={1.4}
          onClick={() => {
            updatePlanImage(currentImgViewIndex);
          }}
          className="button-add"
        />
        <div style={{position: "absolute", height: "100%"}}>
          <ImgBox
            imgs={currentSkuPlan?.uploadedOverallView || []}
            width={"10em"}
            height={"10em"}
            onActiveImgChange={onActiveImgViewChange}
          ></ImgBox>
          <FilePreview
            imgs={currentSkuPlan?.uploadedOverallView || []}
            delFunc={file => {
              onDell(file);
            }}
            accept={"image/*"}
            addFunc={file => {
              addOvImg(file);
            }}
            disable={!currentSkuPlan}
            cropNeed={true}
          />
        </div>
      </div>
    </div>
  );
};
export default OverallView;
