import React, {Children, useCallback, useEffect, useMemo, useState} from "react";
import {useField, useFormikContext} from "formik";
import PropTypes from "prop-types";

import {numberFormat} from "../../utilities/moneyFormat";

const InputWithMeasureUnits = ({label, name, width = "75%", measureUnitsList, labelWrap, children}) => {
  const [viewSelect, setViewSelect] = useState(false);
  const [measureUnit, setMeasureUnit] = useState(null);
  const [prevMeasureUnit, setPrevMeasureUnit] = useState(null);

  const [field] = useField(name);

  const {setFieldValue, values} = useFormikContext();

  const arrayChildren = Children.toArray(children);

  // const staticUnit = useMemo(()=>{
  //   return Object.keys(measureUnitsList)[0]
  // },[measureUnitsList])

  const initialValues = useMemo(() => {
    return Children.map(arrayChildren, child => {
      for (var prop in values) {
        if (prop === child.props.name) {
          return values[prop];
        }
      }
    });
  }, [values]);

  const converterFunc = () => {
    if (prevMeasureUnit && measureUnit) {
      // setFieldValue(coef, measureUnit.multiplier);
      setFieldValue(name, measureUnit.value);
      // Children.map(arrayChildren, (child, index) => {
      //   if (values[child.props.name] && values[child.props.name] !== "") {
      //     var result = (+initialValues[index] / prevMeasureUnit.multiplier) * measureUnit.multiplier;
      //     setFieldValue(child.props.name, result);
      //   }
      // });

      setPrevMeasureUnit(measureUnit);
    }
  };

  useEffect(converterFunc, [measureUnit]);

  useEffect(() => {
    setMeasureUnit(measureUnitsList[0]);
    setPrevMeasureUnit(measureUnitsList[0]);
  }, [measureUnitsList]);

  useEffect(() => {
    if (field?.value !== measureUnit?.value) {
      const unit = measureUnitsList.find(unit => unit.value === field.value);
      if (unit) setMeasureUnit(unit);
    }
  }, [field.value]);

  const setOpen = useCallback(() => {
    if (measureUnitsList.length !== 0) {
      setViewSelect(true);
    }
  }, [measureUnitsList]);

  const close = useCallback(() => {
    setViewSelect(false);
  }, [measureUnitsList]);

  const showOptions = useCallback(e => {
    if (
      e.target.className === "select__list_container" ||
      e.target.className === "select_option" ||
      e.target.className === "select__list_button"
    ) {
      close();
    }
  }, []);

  const generateSelect = useCallback(
    options => {
      if (options) {
        var liList = [];
        options.forEach((option, i) => {
          liList.push(
            <li
              className="select_option"
              key={i + 1}
              onClick={() => {
                setMeasureUnit(option);
              }}
            >
              {option.title}
            </li>,
          );
        });
      }
      return liList;
    },
    [measureUnitsList],
  );

  const optionsLi = useMemo(() => generateSelect(measureUnitsList), [measureUnitsList]);

  return (
    <div className="inputWithMeasureUnits " style={{display: "flex", width: "100%"}}>
      <div className={`project_input ${labelWrap ? "" : "autolabel"}`}>
        {label && <label>{label}</label>}
        <div className="" style={{display: "flex", width: width + "%"}}>
          <input
            type="text"
            style={{width: "55px"}}
            onClick={setOpen}
            value={measureUnit?.title || ""}
            readOnly={true}
          />
          <div className="select__list_button" onClick={setOpen}>
            ▼
          </div>
          {viewSelect ? (
            <div
              className="select__list_container"
              style={{width: "80px", paddingTop: "-20px"}}
              onClick={e => showOptions(e)}
              onMouseLeave={close}
            >
              <div className="select__list disable " style={{width: "100%"}}>
                <ul>{optionsLi}</ul>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="additional_inputs">
        {
          // React.Children.map( children, child => {
          //   return React. cloneElement ( child, {
          //     readOnly: props.readOnly
          //   } )
          // } )
          // children

          React.Children.map(children, child => {
            return <MeasureUInput coef={measureUnit?.multiplier} {...child.props} />;
          })
        }
      </div>
    </div>
  );
};

InputWithMeasureUnits.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  name: PropTypes.string,
  width: PropTypes.string,
  measureUnitsList: PropTypes.array,
  children: PropTypes.node,
};
export default InputWithMeasureUnits;

const MeasureUInput = ({label, name, width, coef, ...props}) => {
  const {initialValues, setFieldValue, values} = useFormikContext();
  const [rawValue, setRawValue] = useState("");

  const onChange = e => {
    setRawValue(e.target.value);
  };

  const validate = () => {
    // setRawValue(numberFormat(rawValue))
    setFieldValue(name, (Number(rawValue) / coef).toFixed(5));
  };

  useEffect(() => {
    if (coef) setRawValue(numberFormat(values[name] * coef));
  }, [coef, values[name]]);

  return (
    <div className={"project_input "}>
      {label && (
        <label htmlFor={props.name} className={props.autolabel ? "autolabel" : ""}>
          {label}
        </label>
      )}
      <div className="" style={{width: width, position: "relative"}}>
        <div className={props?.className}>
          <input
            value={rawValue}
            onChange={onChange}
            // style={{width:width}}
            onBlur={validate}
            readOnly={props.readOnly}
          />
        </div>
        {/* {meta.touched && meta.error ? <div className="error">{meta.error}</div> : null} */}
      </div>
    </div>
  );
};
