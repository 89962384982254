import {useEffect, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {fetchFilters, getRequestRegistrFilters} from "./../RequestsSlice";

const useRequestFilters = () => {
  const {t, i18n} = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchFilters());
  }, []);

  const filters = useSelector(getRequestRegistrFilters);

  const brandsOptions = useMemo(() => {
    return (
      filters?.brands.map(item => {
        return {title: item.name, value: item.brandId};
      }) || []
    );
  }, [filters]);

  const requestStatusOptions = useMemo(() => {
    return (
      filters?.statuses.map(item => {
        return {title: item[i18n.language === "en" ? "engName" : "ukrName"], value: item.statusId};
      }) || []
    );
  }, [filters, i18n.language]);

  const coManagersOptions = useMemo(() => {
    return (
      filters?.coManagers.map(item => {
        const name =
          i18n.language === "en"
            ? item.persona.engFirstName + " " + item.persona.engLastName
            : item.persona.firstName + " " + item.persona.lastName;
        return {
          title: name,
          value: item.managerCoId,
        };
      }) || []
    );
  }, [filters, i18n.language]);

  const feaManagersOptions = useMemo(() => {
    return (
      filters?.feaManagers.map(item => {
        const name =
          i18n.language === "en"
            ? item.persona.engFirstName + " " + item.persona.engLastName
            : item.persona.firstName + " " + item.persona.lastName;
        return {
          title: name,
          value: item.managerFeaId,
        };
      }) || []
    );
  }, [filters, i18n.language]);

  return {brandsOptions, feaManagersOptions, coManagersOptions, requestStatusOptions};
};

export default useRequestFilters;
