import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import PropTypes from "prop-types";
import translit from "ua-en-translit";

import {apiUrl} from "../../../../../api/baseURL";
import {addFacsimile, createFacsimile, selectFacsimile} from "../../../../../slices/UserSlice";
import {BUTTON_TYPES} from "../../../../../utilities/Buttons/constants";
import FunctionButton from "../../../../../utilities/Buttons/FunctionButton";
import ToolTip from "../../../../../utilities/ToolTip";
import {TextInput} from "../../../../Inputs";
import FilePreview from "../../../../Inputs/fileSelect/preview/FilePreview";
import SingleFilePicker from "../../../../Inputs/SingleFilePicker";

import useFacsimilePreview from "./utils/hooks/FacsimileFileService";

import styles from "../KpPfControl.module.scss";

const Facsimile = ({currentApproval, values, resetForm}) => {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const facsimile = useSelector(selectFacsimile);

  const [isMismatch, setIsMismatch] = useState(false);
  const [fileToUpload, setFileToUpload] = useState(null);
  const [currentFacsimile, setCurrentFacsimile] = useState(null);

  const {createdPin, repeatedPin} = values;

  const pdfFileUrl = currentApproval?.managerCo?.facsimile?.imageUrl ?? null;
  const pdfFileUrlArr = pdfFileUrl ? [pdfFileUrl] : [];
  const disabled = currentFacsimile || facsimile;
  const btnDisabled = !values.createdPin || !values.repeatedPin;

  const {onFileAdding, onFileDel} = useFacsimilePreview(currentApproval);

  useEffect(() => {
    if (currentApproval || facsimile) setCurrentFacsimile(currentApproval?.managerCo?.facsimile || facsimile);
  }, [currentApproval, facsimile]);

  const onFacsimileSaveBtnClick = () => {
    if (createdPin !== repeatedPin) {
      setIsMismatch(true);
      return;
    }
    if (!fileToUpload) {
      toast.error(t("Please add your facsimile file!"));
      return;
    }
    const formData = new FormData();
    let file, filename;
    file = fileToUpload;
    filename = translit(file.name);
    formData.append("image", file, filename);
    formData.append("password", createdPin);

    setIsMismatch(false);
    dispatch(createFacsimile(formData));
    resetForm({...values, createdPin: "", repeatedPin: ""});
  };

  const facsimileRender = () => {
    if (currentFacsimile) {
      return (
        <div className={styles.facsimileView}>
          <img src={`${apiUrl}/${currentFacsimile.imageUrl}`} alt="facsimile" />
          {/* <div>{facsimile.imageUrl}</div> */}
        </div>
      );
    }
    if (fileToUpload)
      return (
        <div className={styles.facsimileView}>
          <p className={styles.fileName}>{fileToUpload.name}</p>
          <div className={styles.facsimileDelete} onClick={facsimileFileDelete}>
            <ToolTip title={t("Delete file")}>
              <span>&#215;</span>
            </ToolTip>
          </div>
        </div>
      );
    else return <span className={styles.lightText}>{t("No facsimile provided")}</span>;
  };

  const facsimileFileDelete = () => {};

  return (
    <section className={styles.facsimileSection + " " + (disabled ? styles.dis : null)}>
      <h2 className={styles.sectionHeader}>
        3. <span className={styles.underLined}>{t("Facsimile") + ":"}</span>
      </h2>
      <div className={`${styles.sectionRow} ${styles.facsimileSectionRow}`}>
        <div>
          <TextInput
            label={t("Create pin code (obligatorily)") + ":"}
            name="createdPin"
            width="100%"
            generalClassName={styles.pinInput}
            disabled={!!disabled}
          />
          {isMismatch && <div className={styles.passwordMismatch}>{t("Passwords do not match!")}</div>}
          <TextInput
            label={t("Repeat pin code") + ":"}
            name="repeatedPin"
            width="100%"
            generalClassName={styles.pinInput}
            disabled={!!disabled}
          />
        </div>
        <span>{t("Download from file") + ":"}</span>
        <ToolTip title={t("Add file")}>
          {/* <FilePreview
            imgs={pdfFileUrlArr}
            delFunc={onFileDel}
            addFunc={arrFiles => onFileAdding(arrFiles, values)}
            disable={disabled}
          /> */}
          <SingleFilePicker disabled={disabled} addFunc={file => setFileToUpload(file)} />
        </ToolTip>
        {facsimileRender()}
        <FunctionButton
          className={styles.saveFacsimileBtn}
          type={BUTTON_TYPES.BUTTON}
          name="Save"
          autoWidth
          disabled={btnDisabled}
          onClick={onFacsimileSaveBtnClick}
        />
      </div>
    </section>
  );
};

Facsimile.propTypes = {
  currentApproval: PropTypes.oneOfType([PropTypes.object, PropTypes.instanceOf(null)]),
  values: PropTypes.object.isRequired,
};

export default Facsimile;
