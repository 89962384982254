import React from "react";
import {useSelector} from "react-redux";

import {selectFactories} from "../../../redux/selectors/FactorySelectors";

const useFactoryAppointTableData = () => {
  const factories = useSelector(selectFactories);

  return (
    factories?.map((factory, i) => ({
      number: i + 1,
      factory: factory.name,
      note: "?",
      id: factory.factoryId,
    })) || []
  );
};

export default useFactoryAppointTableData;
