import {useCallback, useEffect, useState} from "react";
import {flexRender, getCoreRowModel, useReactTable} from "@tanstack/react-table";
import PropTypes from "prop-types";

import Spinner from "../spinner/Spinner";

import styles from "./ReactTable.module.scss";

const ReactTable = ({
  defaultData,
  defaultValue,
  columns,
  onSelect,
  current,
  className = "",
  onRowDoubleClick = () => {},
  tableClassName = "",
  loading = false,
  ...props
}) => {
  const [data, setData] = useState([]);

  // console.log(data);

  const [selectedRow, setSelectedRow] = useState(null);

  useEffect(() => {
    setData(defaultData);
  }, [defaultData]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    enableRowSelection: true,
  });

  const handleRowClick = row => {
    // console.log(row.original.id);
    if (onSelect) onSelect(row.original.id);

    if (selectedRow?.original?.id === row.original.id) {
      setSelectedRow(null);
    } else {
      setSelectedRow(row);
    }
  };

  const handleRowDoubleClick = row => {
    onRowDoubleClick(row.original);
  };

  useEffect(() => {
    if (!current) setSelectedRow(null);
  }, [current]);

  useEffect(() => {
    if (defaultValue) {
      var selected = table.getRowModel().rows.find(row => row.original.id === current);
      if (selected) setSelectedRow(selected);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (current) {
      var selected = table.getRowModel().rows.find(row => row.original.id === current);
      if (selected) setSelectedRow(selected);
    }
  }, [defaultData]);

  const tHeadRender = useCallback(() => {
    return table.getHeaderGroups().map(headerGroup => (
      <tr key={headerGroup.id}>
        {headerGroup.headers.map(header => (
          <th
            key={header.id}
            style={{
              position: "relative",
              width: header.getSize(),
            }}
          >
            {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
          </th>
        ))}
      </tr>
    ));
  });

  const tbodyRender = () => {
    return table.getRowModel().rows.map(row => {
      return (
        <tr
          key={row.original.id}
          onClick={() => handleRowClick(row)}
          onDoubleClick={() => handleRowDoubleClick(row)}
          className={styles.reactTableRow}
        >
          {row.getVisibleCells().map(cell => {
            return (
              <td
                key={cell.id}
                title={cell.getValue()}
                style={{
                  minWidth: cell.column.minWidth,
                  backgroundColor: row?.original?.id === selectedRow?.original?.id ? "#bfcde2aa" : "transparent",
                }}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            );
          })}
        </tr>
      );
    });
  };

  const body = loading ? (
    <tr>
      <td colSpan="5" rowSpan={5}>
        <Spinner />
      </td>
    </tr>
  ) : (
    tbodyRender()
  );

  return (
    <div className={`project_list ${className}`} style={props.style}>
      <table className={`project_table ${tableClassName}`}>
        <thead>{tHeadRender()}</thead>

        <tbody>{body}</tbody>
      </table>
    </div>
  );
};

ReactTable.propTypes = {
  defaultData: PropTypes.array,
  columns: PropTypes.arrayOf(PropTypes.object),
  onSelect: PropTypes.func,
  current: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.oneOf([null])]),
  className: PropTypes.string,
  onRowDoubleClick: PropTypes.func,
  props: PropTypes.array,
};

export default ReactTable;
