import {useSelector} from "react-redux";
import PropTypes from "prop-types";

import {fetchData} from "../../../hook/axios.hook";
import {getCurrentProject, getProjectLoading} from "../../../selectors/generalSelectors";
import ReminderHoc from "../../reminder/ReminderHoc";

const ProjectReminder = ({forcedUpdate, setGlobalSave}) => {
  const project = useSelector(getCurrentProject);
  const loading = useSelector(getProjectLoading);

  const addRemind = newRemind => {
    return fetchData(`/todo/add/${project.projectId}`, "post", {...newRemind});
  };

  const updateRemind = newRemind => {
    return fetchData(`/todo/update/${newRemind.todoId}`, "patch", newRemind.data);
  };

  return (
    <ReminderHoc
      height="100%"
      forcedUpdate={forcedUpdate}
      setGlobalSave={setGlobalSave}
      create={addRemind}
      update={updateRemind}
      loading={loading}
      disabled={!project}
    />
  );
};

ProjectReminder.propTypes = {forcedUpdate: PropTypes.bool, setGlobalSave: PropTypes.func};

export default ProjectReminder;
