import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";

import ToolTip from "../../../../utilities/ToolTip";
import ReactTable from "../../../conteiners/ReactTable";
import {InputWithMeasureUnits, NumberInput, TextInput} from "../../../Inputs";
import {Select} from "../../../Inputs/SelectField";

import useFactoryConditionFormService from "./utils/hooks/getFactoryConditionFormService";

//similar for 3 modals
const FactoryConditionForm = ({disabled = false, children}) => {
  const {t} = useTranslation();

  const {columns, termsStatusOptions, factoryConditionTableData, onConditionTableRowSelect, current, termsOptions} =
    useFactoryConditionFormService();

  const disabledTooltipTitle = disabled ? t("Complete Factory Address and Contacts section first!") : "";

  return (
    <ToolTip title={disabledTooltipTitle}>
      <>
        <div className="cond_row">
          <div>3. {t("D terms")}</div>
          <Select
            name="termsStatus"
            label={t("Delivery terms Status") + ":"}
            options={termsStatusOptions ?? []}
            multi={false}
            widthLabel="20em"
            width="100%"
            disabled={disabled}
          />
        </div>

        <div className="cond_wrapper">
          <div className="cond_block">
            <TextInput
              label={t("Discount")}
              name="discount"
              type="number"
              width="6em"
              className="procent"
              min={0}
              max={100}
              labelStyles={{width: "11em"}}
              disabled={disabled}
            />
            <TextInput
              label={t("d note")}
              name="discountNote"
              type="text"
              labelStyles={{width: "16em"}}
              width="100%"
              generalClassName=""
              disabled={disabled}
            />
          </div>
          <div className="cond_block">
            <div className="terms_wrapper">
              <InputWithMeasureUnits
                name={"unitId"}
                measureUnitsList={termsOptions}
                label={t("terms")}
                coef={"dimensionCoef"}
              >
                <NumberInput label={t("from")} name="termsFrom" width="5em" />
                <NumberInput label={t("to")} name="termsTo" width="5em" />
              </InputWithMeasureUnits>
            </div>
            <TextInput
              label={t("t note")}
              name="termsNote"
              type="text"
              labelStyles={{width: "16em"}}
              width="100%"
              disabled={disabled}
            />
          </div>
          <div className="f_b_wrapper cond_block_m">
            <TextInput
              label={t("t cond")}
              name="termsCondition"
              type="text"
              labelStyles={{width: "12em"}}
              width="100%"
              generalClassName="w100"
              disabled={disabled}
            />
          </div>
        </div>

        <ReactTable
          defaultData={factoryConditionTableData}
          columns={columns}
          className="factory_edit_table_cond"
          current={current}
          onSelect={onConditionTableRowSelect}
        />
        {children}
        <div className="border border_gap"></div>
      </>
    </ToolTip>
  );
};

FactoryConditionForm.propTypes = {
  disabled: PropTypes.bool,
  children: PropTypes.node,
};

export default FactoryConditionForm;
