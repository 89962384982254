import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import {getMeasurementUnits} from "../../../../selectors/skuSelectors";
import {InputWithMeasureUnits, TextInput} from "../../../Inputs";
import {Select} from "../../../Inputs/SelectField";

const VolumeWeightCharacteristics = () => {
  const {t} = useTranslation();

  const units = useSelector(getMeasurementUnits);
  const measurementUnitsList = useMemo(
    () =>
      units.map(item => {
        return {value: item.name, unitId: item.unitId};
      }),
    [units],
  );

  return (
    <div>
      <div className="fc jcsb">
        <label htmlFor="" className="label">
          {t("VWC header")}
        </label>
        <Select
          label={t("Measurement units")}
          name="unitName"
          width="25"
          options={measurementUnitsList}
          readOnly={true}
        />{" "}
      </div>
      <div className="VolumeWeight_characteristics">
        <div className="" style={{width: "100%"}}>
          <InputWithMeasureUnits
            label={t("Dimension")}
            measureUnitsList={{
              [t("mm")]: 1,
              [t("cm")]: 0.1,
              [t("m")]: 0.001,
            }}
            fieldsName={[]}
            // width="20em"
          >
            {/* <ReadOnlyFields> */}
            <TextInput label={t("Length")} name="longitude" type="text" width="9em" readOnly={true} />
            <TextInput label={t("Width")} name="width" type="text" width="9em" readOnly={true} />
            <TextInput label={t("Height")} name="height" type="text" width="9em" readOnly={true} />
            {/* </ReadOnlyFields> */}
          </InputWithMeasureUnits>
          <InputWithMeasureUnits
            label={t("Area")}
            measureUnitsList={{
              [t("mm²")]: 1,
              [t("cm²")]: 0.001,
              [t("m²")]: 0.000006,
            }}
          >
            <TextInput label="" name="square" type="text" width="15em" readOnly={true} />
          </InputWithMeasureUnits>

          <InputWithMeasureUnits label={t("Volume")} measureUnitsList={{[t("m³")]: 1, [t("Liter")]: 1000}}>
            <TextInput label="" name="volume" type="text" width="15em" readOnly={true} />
          </InputWithMeasureUnits>

          <InputWithMeasureUnits label={t("Weight")} measureUnitsList={{[t("Kilogram")]: 1}}>
            <TextInput label="" name="weight" type="text" width="15em" readOnly={true} />
          </InputWithMeasureUnits>

          <div className="fc nowrap">
            <InputWithMeasureUnits label={t("Pallet")} measureUnitsList={{[t("Euro 1")]: 1, [t("Euro 2")]: 1}}>
              <TextInput label={t("Length")} name="palletL" type="text" width="5em" readOnly={true} />
              <TextInput label={t("Width")} name="palletW" type="text" width="5em" readOnly={true} />
            </InputWithMeasureUnits>
            <div className="fc nowrap autolabel" style={{gap: "2em"}}>
              <TextInput label={t("From")} name="palletL" type="text" width="6em" readOnly={true} />
              <TextInput label={t("Control")} name="palletL" type="text" width="10em" readOnly={true} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VolumeWeightCharacteristics;
