import prevIcon from "../../../../../images/icons/Caret_Circle_Left.png";
import nextIcon from "../../../../../images/icons/Caret_Circle_Right.png";
import ImageButton from "../../../../../utilities/Buttons/ImageBtn";

import styles from "../styles/VedSettingsGeneral.module.scss";

const LeftRightBtnGroup = ({onAddBtnClick, onRemoveBtnClick, tooltipL, tooltipR, disabledL, disabledR}) => {
  return (
    <div className={styles.leftRightBtnGroup}>
      <ImageButton src={prevIcon} onClick={onAddBtnClick} tooltipMessage={tooltipL} disabled={disabledL} />
      <ImageButton src={nextIcon} onClick={onRemoveBtnClick} tooltipMessage={tooltipR} disabled={disabledR} />
    </div>
  );
};

export default LeftRightBtnGroup;
