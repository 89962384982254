import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useFormikContext} from "formik";

import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import SkuPriceInput from "../../SkuPriceInput";

const Prices = () => {
  const {t} = useTranslation();

  const [procents, setProcents] = useState([]);

  const {values} = useFormikContext();
  const {price, minPrice, settlementPrice} = values;

  useEffect(() => {
    var proc = [100];
    proc.unshift((settlementPrice * 100) / minPrice);
    proc.unshift((price * 100) / minPrice);
    setProcents(proc);
  }, [price, minPrice, settlementPrice]);

  return (
    <div className="sku_price" style={{position: "relative"}}>
      <FunctionButton
        name={t("SKU checkup")}
        disabled={true}
        style={{
          position: "absolute",
          top: "0.2em",
          right: "0",
          zIndex: "100",
          width: "200px",
        }}
      />

      <table className="sku_price__table">
        <tbody>
          <tr>
            <th style={{width: "15%", textAlign: "left"}}>{t("SKU price")}</th>
            <th style={{width: "20%"}}></th>
            <th style={{width: "10%"}}>%%</th>
            <th style={{width: "2%"}}>{t("Verify")}</th>
            <th style={{width: "50%"}}></th>
          </tr>
          <SkuPriceInput
            id="1"
            label={t("Price")}
            mayEdit={false}
            price={"price"}
            priceProc={minPrice ? (price * 100) / minPrice : ""}
            verify={false}
            control={"priceCheckUser"}
          />
          <SkuPriceInput
            id="2"
            label={t("RR price")}
            price={"settlementPrice"}
            priceProc={minPrice ? (settlementPrice * 100) / minPrice : ""}
            verify={false}
            control={"settlementPriceCheckUser"}
          />
          <SkuPriceInput
            id="3"
            label={t("Min price")}
            price={"minPrice"}
            priceProc={minPrice ? 100 : ""}
            verify={false}
            control={""}
          />
        </tbody>
      </table>
    </div>
  );
};

export default Prices;
