import {useTranslation} from "react-i18next";

import QuestionItem from "../../../utilities/QuestionItem";
import PageContainer from "../../conteiners/PageContainer";

import OrderBlock from "./OrderBlock/OrderBlock";
import RequestSkuBlock from "./RequestSkuBlock/RequestSkuBlock";
import TableBlock from "./TableBlock/TableBlock";

import "./order.scss";
import styles from "./OrderProcessing.module.scss";

const OrderProcessing = () => {
  const {t} = useTranslation();

  return (
    <PageContainer title={t("Order processing")}>
      <div className="form_wrapper">
        <section className={styles.orderAndSkuBlocksSection}>
          <div className={styles.orderBlockWrapper}>
            <OrderBlock />
          </div>
          <div className={styles.requestSkuBlockWrapper}>
            <RequestSkuBlock />
          </div>
        </section>
        <section className={styles.reqBlocksSection}>
          <h2 className={styles.reqBlockHeader + " label"}>
            2.
            {t("Requests")}
            <QuestionItem title="only1" />
          </h2>

          <TableBlock />
        </section>
      </div>
    </PageContainer>
  );
};

export default OrderProcessing;
