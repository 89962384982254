import {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Form, Formik} from "formik";

import ReadOnlyFields from "../../../hoc/ReadOnlyFields";
import {TextAreaInput, TextInput} from "../../../Inputs";
import ControlCheck from "../../../Inputs/controlCheck/ControlCheck";
import {getCurrentSku} from "../../SkuSlice";
import Prices from "../SkuVedForm/SkuPrices";

import Decoration from "./Decoration";
import VolumeWeightCharacteristics from "./vwCharacteristics";

import "../skuVed.scss";

const SkuPreviewForm = () => {
  const {t} = useTranslation();

  // const dispatch = useDispatch();

  const currentSku = useSelector(getCurrentSku);

  const initialValues = useMemo(() => {
    return {
      article: "",
      countryId: "",
      brandId: "",
      productGroupId: "",
      productTypeId: "",
      materialId: "",
      colorId: "",
      unitId: "",
      palletId: "",
      productSeries: "",
      complectDesc: "",
      objectId: "",

      colorType: "",
      materialType: "",

      settlementPrice: "",
      minimumPrice: "",
      costPrice: "",
      discountPrice: "",
      listPrice: "",
      retailPrice: "",

      name: "",
      nameEng: "",
      skuUnkit: "",

      batch: "",
      volume: "",
      volumeWeight: "",
      weight: "",
      width: "",
      height: "",
      length: "",
      area: "",

      // dimensionCoef: '',
      // areaCoef:'',
      // volumeCoef:'',
      // volumeWeightCoef:'',

      overallSizeCheck: false,
      volumeCheck: false,
      areaCheck: false,
      weightCheck: false,
      palletCheck: false,
    };
  }, []);

  return (
    <Formik
      initialValues={currentSku instanceof Object && currentSku ? currentSku : initialValues}
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize
      onSubmit={values => {}}
    >
      <Form className="sku_preview_form">
        <ReadOnlyFields>
          <div className="fc jcsb autolabel">
            <div className="fc">
              <TextInput label="UnKit" name="skuUnkit" type="text" readOnly={true} />
              <TextInput label={t("UnKit type")} id="unKitType" name="unKitType" readOnly={true} />
            </div>
            <div className="fc">
              <TextInput label={t("Series")} name="productSeries" />
              <TextInput label={t("Batch")} name="batch" />
            </div>
          </div>
          <div className="fc jcsb autolabel sku_preview_texterea">
            <TextAreaInput label={t("Nomenclature")} id="name" name="name" width="30%" />
            <TextAreaInput label="SKU name (eng)" id="nameEng" name="nameEng" width="30%" />
            <TextAreaInput label={t("Set description")} id="description" name="complectDesc" width="30%" />
          </div>
        </ReadOnlyFields>

        <div
          className="fc nowrap sku-preview_decoration_container"
          style={{gap: "1em", width: "100%", alignItems: "start"}}
        >
          <Decoration />

          <div className="priceCharacter__container">
            <Prices mayEdit={false} />
            <div style={{position: "absolute", bottom: "0.5em", right: "1em"}}>
              <ControlCheck name="dd" date="ddd" initialChecked={false} readOnly={true} />
            </div>
          </div>
          <div className="vwCharacter__container">
            <VolumeWeightCharacteristics />
            <div style={{position: "absolute", bottom: "0.5em", right: "10px", left: "1em"}}>
              <ControlCheck name="dd" date="ddd" initialChecked={false} readOnly={true} />
            </div>
          </div>
        </div>
      </Form>
    </Formik>
  );
};

export default SkuPreviewForm;
