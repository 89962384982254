import {useCallback, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";

import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import ReactTableWithSelect from "../../../conteiners/ReactTableUniSelect";
import ModalForm from "../../../Modal/FormModal";
import RequestBlock from "../../RequestBlock/RequestBlock";
import {
  clearNote,
  deleteRequest,
  deselectCurrentOrder,
  fakeMerge,
  fetchRequests,
  getCheckedRequestIds,
  getCurrentFourthStatusRequestId,
  getCurrentNote,
  getCurrentRequestId,
  getRequest,
  getThirdStatusRequests,
  mergeRequests,
  setCheckedRequestIds,
  setCurrent,
  splitRequests,
  turnIntoRequest,
} from "../../RequestsSlice";
import SimilarSkuBlock from "../../SimilarSkuBlock/SimilarSkuBlock";
import SplitOrderSku from "../splitOrderModal/splitOrderSku";
import useColumns from "../utils/hooks/useColumns";

import {ORDER_BLOCK_COLUMNES} from "./utils/constants";

import styles from "./OrderBlock.module.scss";

const OrderBlock = () => {
  const {t, i18n} = useTranslation();
  const dispatch = useDispatch();

  const [splitModalActive, setSplitModalActive] = useState(false);

  const [defaultCheck, setDefaultCheck] = useState(null);

  const currentRequestIds = useSelector(getCheckedRequestIds);
  const areRequestsChecked = currentRequestIds?.length > 1;
  const currentOrder = useSelector(getCurrentRequestId);

  let note = useSelector(getCurrentNote);
  note = note?.replaceAll("\n", " / ");

  const status3Requests = useSelector(getThirdStatusRequests) ?? [];

  const status3TableData = useMemo(() => {
    return status3Requests?.map(
      ({requestNumber, managerCo, requestSum, brand, createdAt, note, status, requestId}, index) => ({
        id: requestId,
        number: index + 1,
        requestNumber,
        managerCo: managerCo?.name,
        factory: brand?.name,
        requestSum,
        createdAt,
        note,
        status: i18n.language === "en" ? status.engName : status.ukrName,
      }),
    );
  }, [status3Requests]);

  useEffect(() => {
    dispatch(fetchRequests({statusId: 3, currentUser: true, showidenticalSku: true}));

    return () => {
      dispatch(deselectCurrentOrder());
    };
  }, []);

  const status3Columns = useColumns(ORDER_BLOCK_COLUMNES);

  const updateAndCleanAfterRequest = () => {
    dispatch(setCheckedRequestIds([]));
    dispatch(fetchRequests({statusId: 3, currentUser: true}));
  };

  //event handlers
  //for tables
  const onStatus3TableRowSelect = useCallback(
    requestId => {
      if (requestId === currentOrder) {
        dispatch(deselectCurrentOrder());

        return;
      }

      dispatch(getRequest(requestId));
      dispatch(setCurrent(requestId));
      //cleaning redux state currentNote from previous requests
      dispatch(clearNote());
    },
    [currentOrder],
  );

  const onStatus3TableCheckboxChange = checked => {
    if (currentRequestIds && checked.join("") === currentRequestIds.join("")) return;
    dispatch(setCheckedRequestIds(checked));
  };

  //for buttons
  const onCreateRequestBtnClick = () => {
    if (currentRequestIds.length === 0) {
      toast.error(t("Please select at least one request"));
      return;
    }
    dispatch(turnIntoRequest({requestIds: currentRequestIds, note})).then(({meta}) => {
      if (meta.requestStatus === "fulfilled") {
        toast.success(t("Request was created successfully"));
      }
    });
    updateAndCleanAfterRequest();
  };

  useEffect(() => {
    // console.log("currentRequestIds", currentRequestIds);
  }, [currentRequestIds]);

  const onCombineRequestsBtnClick = () => {
    // onStatus3TableCheckboxChange([129]);
    // dispatch(fakeMerge());
    // setDefaultCheck([129]);

    dispatch(mergeRequests(currentRequestIds)).then(({meta, payload}) => {
      if (meta.requestStatus === "fulfilled") {
        toast.success(t("Requests were merged successfully"));
        updateAndCleanAfterRequest();
        setDefaultCheck([payload.requestId]);
        onStatus3TableRowSelect(payload.requestId);
      }
    });
  };

  const onSplitRequestBtnClick = () => {
    if (!currentOrder) {
      toast.error(t("You must select one request!"));

      return;
    }
    dispatch(splitRequests(currentOrder)).then(({meta}) => {
      if (meta.requestStatus === "fulfilled") {
        toast.success(t("Request before split"));
        setSplitModalActive(true);
        updateAndCleanAfterRequest();
      }
    });
  };

  const onDel = useCallback(() => {
    dispatch(deleteRequest(currentOrder));
  }, [currentOrder]);

  return (
    <>
      <div className={styles.orderBlock}>
        <div className={styles.blockHeader}>
          <div className={styles.blockHeaderLeft}>
            <label>{`1. ${t("Orders")}:`}</label>
            <FunctionButton
              name={t("Create special order")}
              tooltipMessage={"specOrd"}
              autoWidth
              className={styles.specialOrderBtn}
              disabled={true}
            />
          </div>
          <SimilarSkuBlock />
        </div>
        <ReactTableWithSelect
          defaultData={status3TableData}
          columns={status3Columns}
          onSelect={onStatus3TableRowSelect}
          current={currentOrder ?? null}
          defaultSelection={defaultCheck}
          setSelectedItems={onStatus3TableCheckboxChange}
          className={styles.status3Table}
        />
        <RequestBlock />
        <div className="button-block ">
          <FunctionButton
            name={t("Create request")}
            onClick={onCreateRequestBtnClick}
            disabled={!currentRequestIds?.length > 0}
          />
          {/* <FunctionButton name={t("Combine orders")} onClick={onCombineRequestsBtnClick} style={{width: "200px"}} />
          <FunctionButton
            name={t("Split orders")}
            onClick={onCreateRequestBtnClick}
            disabled={!areRequestsChecked}
            autoWidth
          /> */}
          <FunctionButton
            name={t("Combine orders")}
            onClick={onCombineRequestsBtnClick}
            disabled={!areRequestsChecked}
            autoWidth
          />
          <FunctionButton
            name={t("Split orders")}
            onClick={onSplitRequestBtnClick}
            style={{width: "200px"}}
            disabled={!currentOrder}
            autoWidth
          />
          {/* кнопка удаления (на всякий случай) */}
          {/* <FunctionButton
            name="Cancel"
            onClick={() => {
              console.log("del", currentOrder);
              onDel();
            }}
          /> */}
        </div>
      </div>
      <ModalForm
        closable={true}
        Component={SplitOrderSku}
        label={t("Order separation")}
        active={splitModalActive}
        setModalActive={setSplitModalActive}
      />
    </>
  );
};

export default OrderBlock;
