export const REQUEST_SKU_BLOCK_COLUMNS = Object.freeze([
  {
    header: "Is",
    accessorKey: "indicator",
    size: 15,
  },
  {
    header: "SKU",
    accessorKey: "article",
    // size: 100,
  },
  {
    header: "Brand c",
    accessorKey: "brandName",
    // size: 100,
  },
  {
    header: "Group c",
    accessorKey: "productGroup",
    // size: 100,
  },
  {
    header: "Type c",
    accessorKey: "productType",
    // size: 100,
  },
  {
    header: "Nomenclature",
    accessorKey: "name",
    // size: 100,
  },

  {
    header: "Finishing",
    accessorKey: "material",
    // size: 100,
  },
  {
    header: "Color",
    accessorKey: "color",
    // size: 15,
  },
  {
    header: "Price",
    accessorKey: "listPrice",
    // size: 15,
  },
  {
    header: "RR price",
    accessorKey: "retailPrice",
    // size: 100,
  },
  {
    header: "Length mm",
    accessorKey: "length",
    // size: 100,
  },
  {
    header: "Width mm",
    accessorKey: "width",
    // size: 100,
  },
  {
    header: "Height mm",
    accessorKey: "height",
    // size: 100,
  },
  {
    header: "Weight kg",
    accessorKey: "weight",
    // size: 100,
  },

  {
    header: "Quantity short",
    accessorKey: "amount",
    // size: 100,
  },
  {
    header: "Area m2",
    accessorKey: "area",
    // size: 100,
  },
  {
    header: "Volume m3",
    accessorKey: "volumeWeight",
    // size: 100,
  },

  {
    header: "On stock",
    accessorKey: "onStock",
    // size: 100,
  },
  {
    header: "Available",
    accessorKey: "available",
    // size: 15,
  },
  {
    header: "In order",
    accessorKey: "inOrder",
    // size: 15,
  },
  {
    header: "In request",
    accessorKey: "inRequest",
    // size: 100,
  },
  {
    header: "Proform",
    accessorKey: "proform",
    // size: 100,
  },
  {
    header: "TP №",
    accessorKey: "tpNumber",
    // size: 100,
  },
  {
    header: "Reservation",
    accessorKey: "reservation",
    // size: 100,
  },
  {
    header: "CO reservation",
    accessorKey: "CO_Reservation",
    // size: 100,
  },
]);
