import {useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import FunctionButton from "../../../../../utilities/Buttons/FunctionButton";
import QuestionItem from "../../../../../utilities/QuestionItem";
import ReactTable from "../../../../conteiners/ReactTableUniSelect";
// import ReactTable from "../../../../conteiners/ReactTable";
import useColumns from "../../../OrderProcessing/utils/hooks/useColumns";

import {sendForPayment} from "./redux/PaymentOperationsSlice";
import {getDocsForPayment, getPayment} from "./redux/PaymentSelectors";
import usePaymentsData from "./utils/hook/PaymentDataService";
import {finSchemeColumns} from "./columnsForPaymentTables";

const FinScheme = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [schemaIds, setSchemaIds] = useState([]);

  const columns = useColumns(finSchemeColumns);

  const {paymentSchemas} = usePaymentsData();
  const payment = useSelector(getPayment);
  const docsForPayment = useSelector(getDocsForPayment);

  const onSendForPayment = () => {
    dispatch(sendForPayment({paymentId: payment.paymentId, data: {schemaIds, note: docsForPayment[0].note}})).then(
      resp => {
        // if (resp.meta.requestStatus === "fulfilled")
      },
    );
  };

  return (
    <>
      <div className="header-blocks q_wrapper">
        <QuestionItem title="select scheme" />
        {t("Financial payment scheme")}
      </div>
      <ReactTable
        defaultData={paymentSchemas}
        columns={columns}
        onSelect={() => {}}
        current={null}
        className={"project_table"}
        style={{height: "5em"}}
        setSelectedItems={setSchemaIds}
      />
      <div className="button-pay">
        <FunctionButton
          name={t("Send for payment")}
          autoWidth
          tooltipMessage="send doc"
          // disabled={true}
          onClick={onSendForPayment}
        />
      </div>
    </>
  );
};

export default FinScheme;
