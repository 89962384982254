import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";

import ReactTable from "../../../../../conteiners/ReactTable";
import useColumns from "../../../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {finSchemeColumns} from "../../../../../Requests/RequestsInProgress/modalsInProgress/modalPaymentDocParts/columnsForPaymentTables";
import usePaymentsData from "../../../../../Requests/RequestsInProgress/modalsInProgress/modalPaymentDocParts/utils/hook/PaymentDataService";
import {FINSCHEME_TO_FACTORY_MODAL_COLUMNS} from "../../utils/constants";

const FinSchemeToFactory = () => {
  const {t} = useTranslation();

  const columns = useColumns(finSchemeColumns);
  const {paymentSchemas} = usePaymentsData();
  return (
    <>
      <div className="header-blocks q_wrapper">{t("Financial payment scheme")}</div>
      <ReactTable
        defaultData={paymentSchemas}
        columns={columns}
        onSelect={() => {}}
        current={null}
        className={"project_table"}
        style={{height: "5em"}}
      />
    </>
  );
};

export default FinSchemeToFactory;
