import {useTranslation} from "react-i18next";

import {TextAreaInput, TextInput} from "../../../Inputs";
import {Select} from "../../../Inputs/SelectField";
import useSkuCatalogsService from "../../SkuCatalogsService";

const MainCharacteristics = () => {
  const {t} = useTranslation();
  const {brandList, colorsList, materialsList, productGroups, productTypes} = useSkuCatalogsService();

  return (
    <div className=" " style={{width: "100%"}}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          gap: "1em",
          paddingBottom: "1em",
        }}
        className="autolabel sku-modal-form__mainChar"
      >
        <TextInput label="SKU:*" name="article" type="text" width={"12em"} />
        <Select label={t("Brand") + "*"} name="brandId" options={brandList} multi={true} width={"12em"} />
        <Select label={t("Group") + "*"} name="productGroupId" options={productGroups} multi={true} width={"12em"} />
        <Select label={t("Type") + "*"} name="productTypeId" options={productTypes} width="12em" multi={true} />
        <Select label={t("Finishing*")} name="materialId" options={materialsList} width="12em" multi={true} />
        <Select label={t("Color*")} name="colorId" options={colorsList} width="12em" multi={true} />
      </div>
      <div className="fc jcsb autolabel">
        <div className="fc">
          <TextInput label="UnKit" name="skuUnkit" type="text" readOnly={true} />
          <Select label={t("UnKit type")} id="unKitType" name="unKitType" type="text" options={[]} readOnly={true} />
        </div>
        <div className="fc">
          <TextInput label={t("Series")} name="productSeries" />
          <TextInput label={t("Batch")} name="batch" />
        </div>
      </div>
      <div className="fc jcsb autolabel">
        <TextAreaInput label={t("Nomenclature")} id="name" name="name" width="30%" />
        <TextAreaInput label="SKU name (eng)" id="nameEng" name="nameEng" width="30%" />
        <TextAreaInput label={t("Set description")} id="description" name="complectDesc" width="30%" />
      </div>
    </div>
  );
};

export default MainCharacteristics;
