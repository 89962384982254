export const columnsForPayment = [
  {
    header: "№",
    accessorKey: "number",
    size: 15,
  },

  {
    header: "Doc. for payment",
    accessorKey: "doc",
    // size: 100,
  },
  {
    header: "Document type",
    accessorKey: "docType",
    // size: 100,
  },
  {
    header: "Notes",
    accessorKey: "notes",
    // size: 100,
  },
];

export const paymentColumns = [
  {
    header: "№",
    accessorKey: "number",
    size: 15,
  },

  {
    header: "Doc. for payment",
    accessorKey: "doc",
    // size: 100,
  },
  {
    header: "Document type",
    accessorKey: "docType",
    // size: 100,
  },
  {
    header: "Note for factory manager",
    accessorKey: "notes",
    // size: 100,
  },
];

export const finSchemeColumns = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 10,
  },

  {
    header: "Payment scheme",
    accessorKey: "name",
    // size: 100,
  },
  {
    header: "Description",
    accessorKey: "desc",
    // size: 100,
  },
]);

export const onPaymentColumns = [
  {
    header: "№",
    accessorKey: "number",
    size: 15,
  },

  {
    header: "Paid doc",
    accessorKey: "doc",
    // size: 100,
  },
  {
    header: "Document type",
    accessorKey: "docType",
    // size: 100,
  },
  {
    header: "Note for FEA manager",
    accessorKey: "notes",
    // size: 100,
  },
];
