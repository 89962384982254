import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {Route, Routes} from "react-router-dom";
import PropTypes from "prop-types";

import {API_ENDPOINTS} from "../api/endpoints";
import AccessBoundary from "../Components/AccessBoundary/AccessBoundary";
import AdminNav from "../Components/adminForms/AdminNav";
import AddClient from "../Components/adminForms/clientForm/AddClient";
import AddPersonModal from "../Components/adminForms/Persona/AddPersonModal";
import SmallWindowForm from "../Components/conteiners/SmallWindowForm";
import {personsFetched, userPositionsFetched} from "../slices/PersonSlice";

import RegistrDemo from "./../Components/adminForms/Persona/RegistrDemo";

const AdminPage = ({fetchData}) => {
  const {t} = useTranslation();

  const dispatch = useDispatch();

  useEffect(() => {
    fetchData("/persona/getAll", "get").then(res => {
      dispatch(personsFetched(res));
    });
    fetchData("position/getAll", "get").then(res => {
      dispatch(userPositionsFetched(res));
    });
  }, []);

  return (
    <Routes>
      <Route path="/" element={<AdminNav />} />
      <Route
        path={API_ENDPOINTS.ADMIN_PANEL.USER}
        element={
          <AccessBoundary>
            {/* <User /> */}
            <SmallWindowForm label={[t("User registration")]} navigatePath={"/ba3a/admin"} Component={RegistrDemo} />
          </AccessBoundary>
        }
      />
      <Route
        path={API_ENDPOINTS.ADMIN_PANEL.PERSON}
        // element={<AddPerson />}
        element={
          <SmallWindowForm label={[t("Create person")]} navigatePath={"/ba3a/admin"} Component={AddPersonModal} />
        }
      >
        {/* <Route
          path=":name"
          element={<SmallWindowForm label={[t("Create person")]} navigatePath={"/ba3a/admin"} Component={AddPerson} />}
        /> */}
      </Route>
      <Route
        path={API_ENDPOINTS.ADMIN_PANEL.CLIENT}
        element={
          <SmallWindowForm label={[t("Client registration")]} navigatePath={"/ba3a/admin"} Component={AddClient} />
        }
      />
    </Routes>
  );
};

AdminPage.propTypes = {
  fetchData: PropTypes.func.isRequired,
};

export default AdminPage;
