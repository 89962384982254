// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-form__container {
  width: 100%;
  height: 100vh;
  position: relative;
}

.login_form {
  max-width: 500px;
  padding: 20px 40px;
  padding-bottom: 4em;
  position: absolute;
  top: 40%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  border: 6px solid rgba(136, 166, 207, 0.2823529412);
  border-top: 1.9em solid rgba(136, 166, 207, 0.2823529412);
  border-radius: 15px 15px 1px 1px;
  position: relative;
  box-sizing: content-box;
}
.login_form_label {
  position: absolute;
  top: -1.4em;
  left: 0;
  font-size: 1.1em;
  font-weight: 400;
  background-color: #f5f5f5;
  border-radius: 8px 25px 1px 0px;
  padding: 0 0.8em;
  line-height: 1.5em;
}
.login_form .project_input {
  justify-content: space-between;
  margin-bottom: 1.5em;
}
.login_form .fixed_button {
  position: absolute;
  right: 2em;
  bottom: 1em;
}
.login_form .close_btn {
  top: -0.9em;
  right: 0.2em;
}`, "",{"version":3,"sources":["webpack://./src/Components/authorization/authForm.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,kBAAA;AACF;;AACA;EACE,gBAAA;EAEA,kBAAA;EACA,mBAAA;EACA,kBAAA;EACA,QAAA;EACA,SAAA;EACA,kBAAA;EACA,gCAAA;EACA,mDAAA;EACA,yDAAA;EACA,gCAAA;EACA,kBAAA;EACA,uBAAA;AACF;AAAE;EACE,kBAAA;EACA,WAAA;EACA,OAAA;EACA,gBAAA;EACA,gBAAA;EACA,yBAAA;EACA,+BAAA;EACA,gBAAA;EACA,kBAAA;AAEJ;AACE;EACE,8BAAA;EACA,oBAAA;AACJ;AACE;EACE,kBAAA;EACA,UAAA;EACA,WAAA;AACJ;AAEE;EACE,WAAA;EACA,YAAA;AAAJ","sourcesContent":[".login-form__container {\n  width: 100%;\n  height: 100vh;\n  position: relative;\n}\n.login_form {\n  max-width: 500px;\n  // height: 10em;\n  padding: 20px 40px;\n  padding-bottom: 4em;\n  position: absolute;\n  top: 40%;\n  left: 50%;\n  margin-right: -50%;\n  transform: translate(-50%, -50%);\n  border: 6px solid #88a6cf48;\n  border-top: 1.9em solid #88a6cf48;\n  border-radius: 15px 15px 1px 1px;\n  position: relative;\n  box-sizing: content-box;\n  &_label {\n    position: absolute;\n    top: -1.4em;\n    left: 0;\n    font-size: 1.1em;\n    font-weight: 400;\n    background-color: #f5f5f5;\n    border-radius: 8px 25px 1px 0px;\n    padding: 0 0.8em;\n    line-height: 1.5em;\n  }\n\n  .project_input {\n    justify-content: space-between;\n    margin-bottom: 1.5em;\n  }\n  .fixed_button {\n    position: absolute;\n    right: 2em;\n    bottom: 1em;\n  }\n\n  .close_btn {\n    top: -0.9em;\n    right: 0.2em;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
