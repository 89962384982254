import {useEffect, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useFormikContext} from "formik";

import {getMeasurementQuantites} from "../../../../selectors/CatalogSelectors";
import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import {getError, getSuccess} from "../../../../utilities/toasts";
import ReactTable from "../../../conteiners/ReactTable";
import {NumberInput, TextInput} from "../../../Inputs";
import {Select} from "../../../Inputs/SelectField";
import {fetchMeasurementFields, fetchMeasurementQuantities} from "../../../SKU/SkuSlice";
import useVWCharInputsService from "../../../SKU/viewWidthCharInputs/vwCharInputsService";
import useColumns from "../../OrderProcessing/utils/hooks/useColumns";
import {addPackingSpace, getRequestInfo, selectPackingSpace, updatePackingSpace} from "../../RequestsSlice";

import {PROPORTIONS_TABLE_COLUMNS} from "./utils/constants";

import styles from "./ReceiptGoodsModal.module.scss";

const MeasurementBlock = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const currentRequest = useSelector(getRequestInfo),
    packingSpaces = useSelector(selectPackingSpace);

  const {values, setValues, setFieldValue} = useFormikContext();

  const proportionsTableColumns = useColumns(PROPORTIONS_TABLE_COLUMNS);
  const proportionsTableData = useMemo(
    () =>
      packingSpaces?.map(space => {
        const {spaceNumber, volume, weight, note, length, width, height, spaceId} = space;
        const proportions = length + "×" + width + "×" + height;
        return {
          id: spaceId,
          placeNumber: spaceNumber,
          proportions,
          weight,
          volume,
          note,
        };
      }) || [],
    [packingSpaces],
  );
  const packingSpaceOptions = useMemo(
    () =>
      packingSpaces?.map(space => ({value: space.spaceId, title: `${t("Place number")} ${space.spaceNumber}`})) || [],
    [packingSpaces],
  );

  const {dimension: dimensionField, volume: volumeField, weight: weightField} = useVWCharInputsService();

  /////utils
  const fetchMU = field => {
    dispatch(fetchMeasurementQuantities({field}));
  };
  const getPlaceObj = valuesObj => {
    const {length, width, height, volume, weight, note, quantity, volumeUnitId, weightUnitId} = valuesObj;
    return {length, width, height, volume, weight, note, quantity, volumeUnitId, weightUnitId};
  };

  const reset = () => {
    setValues({
      ...values,
      length: "",
      width: "",
      height: "",
      volume: "",
      weight: "",
      note: "",
      quantity: "",
      overallSizeUnitId: "",
      volumeUnitId: "",
      weightUnitId: "",
    });
  };

  /////// uefs

  useEffect(() => {
    var fieldsName = ["length", "volume", "weight"];
    fieldsName.forEach(fetchMU);
  }, []);

  useEffect(() => {
    if (values.place && values.place !== "") {
      const currentPlace = packingSpaces.find(space => space.spaceId == values.place);
      if (currentPlace) {
        const currentValues = getPlaceObj(currentPlace);
        setValues({
          ...values,
          ...currentValues,
          overallSizeUnitId: currentValues.overSizeUnitId,
        });
      }
    } else {
      reset();
    }
  }, [values.place]);

  /////operations
  const addPlace = () => {
    const dataV = getPlaceObj(values);
    const data = {
      ...dataV,
      overSizeUnitId: values.overallSizeUnitId,
    };

    const dataToAdd = {l: dataV.length, w: dataV.width, h: dataV.height, v: dataV.volume, weigth: dataV.weight};

    if (Object.values(dataToAdd).every(item => item === "")) {
      getError(t("place error"));
      return;
    } else {
      // console.log(data);
      dispatch(addPackingSpace({requestId: currentRequest.requestId, data})).then(res => {
        if (res.meta.requestStatus === "fulfilled") {
          setFieldValue("place", res.payload.spaceId);
          getSuccess(t("place info"));
        }
      });
    }
  };

  const update = () => {
    const data = getPlaceObj(values);
    dispatch(
      updatePackingSpace({spaceId: values.place, data: {...data, overSizeUnitId: values.overallSizeUnitId}}),
    ).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        getSuccess(t("place upd"));
      }
    });
  };

  const onSelectSpace = id => {
    if (id === values.place) {
      setFieldValue("place", "");
    } else setFieldValue("place", id || "");
  };

  return (
    <>
      <h2 className={styles.sectionHeader}>
        3. <span className={styles.underLined}>{t("Proportions") + ":"}</span>
      </h2>
      <Select label={t("Place") + ":"} name="place" options={packingSpaceOptions} multi={false} />

      {dimensionField}
      {volumeField}
      {weightField}

      <TextInput name="note" placeholder={t("Add a Location Note in this field")} />
      <div className={styles.sectionRow}>
        <FunctionButton
          type="button"
          className={styles.addPfToControlBtn}
          name="Add place"
          autoWidth
          // disabled={dataV}
          onClick={addPlace}
        />
        <NumberInput label={t("Places quantity") + ":"} name="quantity" />
        <FunctionButton
          type="button"
          className={styles.addPfToControlBtn}
          name="Edit place"
          autoWidth
          disabled={!values.place || values.place === ""}
          onClick={update}
        />
      </div>
      <ReactTable
        className={styles.proportionsTableWrapper}
        tableClassName={styles.proportionsTable}
        columns={proportionsTableColumns}
        defaultData={proportionsTableData}
        current={values?.place || null}
        defaultValue={values?.place || null}
        onSelect={onSelectSpace}
      />
    </>
  );
};

export default MeasurementBlock;
