import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import PropTypes from "prop-types";

import {delSkuPlan, deselect, getCurrentSkuPlan, updateSkuPlan} from "../KpFinSlice";

import {withKpForm} from "./KpFinFormHOC";

const KpCreateForm = ({setModalActive}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const currentSkuPlan = useSelector(getCurrentSkuPlan);

  const onSubmit = (values, {resetForm}) => {
    dispatch(
      updateSkuPlan({
        skuPlanId: values.skuPlanId,
        skuPlan: values,
      }),
    ).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        resetForm();
        setModalActive(false);
        dispatch(deselect());
      }
    });
  };

  const onCancel = () => {
    dispatch(delSkuPlan(currentSkuPlan.skuPlanId));

    setModalActive(false);
  };

  const KpForm = withKpForm(t("Add to CO(f)"), onSubmit, onCancel, true, "save and return kp upd create");
  return <KpForm />;
};

KpCreateForm.propTypes = {setModalActive: PropTypes.func};

export default KpCreateForm;
