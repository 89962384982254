import {useEffect, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";

import {fetchFilters} from "../../../../redux/operations/FactoryOperations";
import {selectFactoriesRefs} from "../../../../redux/selectors/FactorySelectors";

const useFactoryIdOptionsService = () => {
  const dispatch = useDispatch();

  const factories = useSelector(selectFactoriesRefs);

  useEffect(() => {
    if (!factories) {
      dispatch(fetchFilters());
    }
  }, []);

  const factoriesOptions = useMemo(
    () => factories?.map(({factoryId, name}) => ({title: name, value: `${factoryId}`})) || [],
    [factories],
  );

  return factoriesOptions;
};

export default useFactoryIdOptionsService;
