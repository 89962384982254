import {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import PropTypes from "prop-types";

import {getCoaList, getCofList, getSubKps, selectSubKp} from "../../../../slices/KPSlice";
import TableWithSelect from "../../../conteiners/TableWithSelect";

const TypedKpList = ({onSelect}) => {
  const {t} = useTranslation();

  const {kpLoadingStatus, currentSubKp} = useSelector(state => state.kp);
  const subKpList = useSelector(getSubKps);

  const dispatch = useDispatch();

  const ths = useMemo(() => {
    return {
      "№": 5,
      [t("CO №")]: 25,
      [t("Notes")]: 65,
      [t("Status")]: 5,
    };
  }, []);

  const onChange = (id, type) => {
    if (currentSubKp && (currentSubKp.cofId === id || currentSubKp.coaId === id)) {
      dispatch(selectSubKp(null));
    } else {
      onSelect(id, type);
    }
  };

  return (
    <TableWithSelect
      thsName={ths}
      items={subKpList ? subKpList.map(transformKpForTable) : []}
      current={currentSubKp?.cofId || currentSubKp?.coaId}
      onSelect={onChange}
      loadingStatus={kpLoadingStatus}
      formatStatus={formatStatus}
    />
  );
};

const formatStatus = status => {
  var statusColor = "gray";
  var textColor = "#A3B9D9";
  switch (status) {
    case "tempSaved":
      statusColor = "green";
      textColor = "#426BA6";
      break;
    case "unsaved":
      statusColor = "rgb(246, 248, 96)";
      textColor = "#426BA6";
      break;
    default:
      break;
  }
  return [
    <label key="status-label" className="radio_container">
      <div className="circules_box">
        <div className="circule1"></div>
        <div className="circule2" style={{backgroundColor: statusColor}}></div>
      </div>
    </label>,
    <span key="status-text" style={{color: textColor}}></span>,
  ];
};

function transformKpForTable(kp, i) {
  return {
    nmber: i + 1,
    coName: kp.type === "f" ? kp.cofNumber : kp.coaNumber,
    notes: kp.note || "",
    status: "",
    id: kp.type === "f" ? kp.cofId : kp.coaId,
    type: kp.type,
  };
}

TypedKpList.propTypes = {onSelect: PropTypes.func};

export default TypedKpList;
