import React, {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";

import {BUTTON_COLORS} from "../../../../utilities/Buttons/constants";
import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import {distributeRequests} from "../../RequestsSlice";

import AppointedRequestTable from "./AppointedRequestTable";
import ManagerKpRequestTable from "./ManagerKpRequestTable";
import OrderingFilter from "./OrderingFilter";
import OrderingForm from "./OrderingForm";

const OrderingModal = ({onModalClose}) => {
  //onModalClose содержит в себе функцию которая срабатывает при закрытии окна в данном случае совпадает с функцией которая должна быть вызвана при нажатии кнопки "відмінити"
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const [selectedCreatedReq, setSelectedCreatedReq] = useState([]);
  const [selectedForCancel, setSelectedForCancel] = useState([]);

  const addToProcessing = useCallback(() => {
    dispatch(distributeRequests());
  }, []);

  return (
    <div style={{height: "100%"}}>
      <OrderingFilter />
      <ManagerKpRequestTable onRequestSelect={setSelectedCreatedReq} />

      <OrderingForm selectedReq={selectedCreatedReq} selectedForCancel={selectedForCancel} />
      <AppointedRequestTable setSelectedForCancel={setSelectedForCancel} />

      <div className="fc jcsb" style={{marginTop: "1em "}}>
        <FunctionButton
          name={t("Auto distribution of Orders")}
          autoWidth
          tooltipMessage={"distrib settings"}
          color={BUTTON_COLORS.TRANSPARENT}
        />
        <div className="fc" style={{gap: "1em"}}>
          <FunctionButton
            name={t("Add Order to processing")}
            onClick={addToProcessing}
            autoWidth
            tooltipMessage={"transfer to feaM"}
            color={BUTTON_COLORS.BLUE}
          />
          <FunctionButton
            name={t("Cancel")}
            tooltipMessage={"cancel tip"}
            color={BUTTON_COLORS.BLUE}
            onClick={onModalClose}
          />
        </div>
      </div>
    </div>
  );
};

export default OrderingModal;
