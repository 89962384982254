import {useEffect, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";

import {fetchFilters} from "../../../../redux/operations/FactoryOperations";
import {selectCountries} from "../../../../redux/selectors/FactorySelectors";

const useCountryIdOptionsService = () => {
  const dispatch = useDispatch();

  const countries = useSelector(selectCountries);

  useEffect(() => {
    if (countries?.length < 1) {
      dispatch(fetchFilters());
    }
  }, []);

  const countriesOptions = useMemo(
    () => countries?.map(({countryId, shortName}) => ({title: shortName, value: `${countryId}`})) || [],
    [countries],
  );

  return countriesOptions;
};

export default useCountryIdOptionsService;
