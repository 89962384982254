import {useCallback, useState} from "react";
import {useTranslation} from "react-i18next";
import {useFormikContext} from "formik";
import PropTypes from "prop-types";

import ImageButton from "../../../utilities/Buttons/ImageBtn";
import Modal from "../../Modal/Modal";

import reestr from "./reestr.png";

export const RegistrButton = ({label, list, idFieldName}) => {
  const {t} = useTranslation();

  const {setFieldValue} = useFormikContext();

  const [modal, setModal] = useState(false);

  const generateSelect = useCallback(
    options => {
      if (options)
        return options.map((option, i) => {
          var tds = [],
            y = 0;
          for (var prop in option) {
            if (prop !== "id") tds.push(<td key={y}>{option[prop]}</td>);
            y++;
          }

          return (
            <tr
              className="select_option"
              key={i}
              onClick={() => {
                setModal(false);
                setFieldValue(idFieldName, option.id);
              }}
            >
              {tds}
            </tr>
          );
        });
    },
    [list],
  );

  const optionsLi = generateSelect(list && list.length !== 0 ? list : list);

  return (
    <>
      <ImageButton
        src={reestr}
        alt="registr"
        width={1.2}
        height={1.2}
        className="reestr_btn"
        onClick={() => {
          if (list.length !== 0) setModal(true);
        }}
        disabled={list.length === 0}
      >
        <p className="label label_thin">
          {t("Registr")} {label}
        </p>
      </ImageButton>
      <Modal active={modal} setActive={setModal}>
        <div className="disable select_option_registr">
          <table>
            <tbody>{optionsLi}</tbody>
          </table>
        </div>
      </Modal>
    </>
  );
};

RegistrButton.propTypes = {
  label: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  list: PropTypes.array,
  idFieldName: PropTypes.string,
};
