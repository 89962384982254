import {useEffect, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {fetchRequests, getAllStatusRequest} from "../../../RequestsSlice";

const useInProgressRequests = () => {
  const dispatch = useDispatch();
  const {i18n} = useTranslation();

  useEffect(() => {
    dispatch(fetchRequests({currentUser: true}));
  }, []);

  const allStatusRequests = useSelector(getAllStatusRequest);

  const result = useMemo(() => {
    return allStatusRequests?.map(
      ({requestId, status, requestNumber, managerCo, managerFea, brand, requestSum, createdAt, note}, index) => ({
        number: index + 1,
        requestStatus: i18n === "english" ? status.engName : status.ukrName,
        requestNumber,
        managerCo: managerCo?.name,
        managerFea: managerFea?.name,
        factory: brand?.name,
        requestSum,
        createdAt,
        note,
        id: requestId,
      }),
    );
  }, [allStatusRequests]);
  return result;
};

export default useInProgressRequests;
