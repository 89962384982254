import React from "react";
import {useTranslation} from "react-i18next";
import {useFormikContext} from "formik";

import ReadOnlyFields from "../../../hoc/ReadOnlyFields";
import useVWCharInputsService from "../../viewWidthCharInputs/vwCharInputsService";

const VolumeWeightCharacteristics = () => {
  const {t} = useTranslation();

  const {area, dimension, volume, weight, quantity, skuMeasurementUnit, pallet} = useVWCharInputsService();

  const {values} = useFormikContext();
  const {overallSizeCheck, volumeCheck, areaCheck, weightCheck, palletCheck} = values;

  return (
    <div className="vwCharacter ">
      <div className=" autolabel ">
        <label htmlFor="" className="label underline">
          {t("VWC header")}
        </label>
        <ReadOnlyFields>
          {skuMeasurementUnit}

          {quantity}

          {overallSizeCheck && dimension}

          {areaCheck && area}

          {volumeCheck && volume}

          {weightCheck && weight}

          {palletCheck && pallet}
        </ReadOnlyFields>
      </div>
    </div>
  );
};

export default VolumeWeightCharacteristics;
