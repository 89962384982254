import React from "react";
import PropTypes from "prop-types";

import {DATE_STATUS_CIRCLE_COLORS} from "./DateStatusCircle";
import ToolTip from "./ToolTip";

export const AVAILABILITY_CIRCLE_COLORS = Object.freeze({
  RED: "#B72025",
  GREEN: "#69A042",
  GREY: "#C8C8C8",
});

const AvailabilityStatusCIrcule = ({ifAvailable, tooltipMessage = "", color}) => {
  let statusColor = color ? color : AVAILABILITY_CIRCLE_COLORS.RED;

  if (ifAvailable) {
    statusColor = AVAILABILITY_CIRCLE_COLORS.GREEN;
  }

  return (
    <ToolTip title={tooltipMessage}>
      <div className="availability_circule_box">
        <div className="outer_circle">
          <div className="inner_circle" style={{backgroundColor: statusColor}}></div>
        </div>
      </div>
    </ToolTip>
  );
};

AvailabilityStatusCIrcule.propTypes = {
  ifAvailable: PropTypes.bool,
  tooltipMessage: PropTypes.string,
  color: PropTypes.oneOf([...Object.values(AVAILABILITY_CIRCLE_COLORS), ...Object.values(DATE_STATUS_CIRCLE_COLORS)]),
};

export default AvailabilityStatusCIrcule;
