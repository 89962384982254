import React from "react";
import {Field, Form, Formik, useFormikContext} from "formik";

import PhoneInput from "./PhoneInput";
import TextInput from "./TextInput";

const DemoForm = () => {
  console.log("render");
  return (
    <div style={{width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
      <Formik
        initialValues={{
          phone: "",
        }}
        // validateOnChange
        onSubmit={values => console.log(values)}
      >
        {formProps => (
          <Form className="all-acc-form" style={{width: "30em"}}>
            <Input />
            <button type="submit">submit</button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const Input = () => {
  // const {setFieldValue} = useFormikContext();
  console.log("render cgild");
  return (
    <>
      {/* <PhoneInput name={"phone"} /> */}
      {/* <TextInput name={"phone"} /> */}
      <Field type="text" name="phone" />
      <button
        type="button"
        onClick={() => {
          // setFieldValue("phone", "11111111");
        }}
      >
        changeV
      </button>
    </>
  );
};

export default DemoForm;
