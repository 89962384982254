import {useCallback, useMemo} from "react";

import {numberFormat} from "../../utilities/moneyFormat";
import useColumns from "../Requests/OrderProcessing/utils/hooks/useColumns";

const useSkuTable = (skus, idName = "skuId") => {
  const columns = useMemo(
    () => [
      {
        header: "Is",
        accessorKey: "indicator",
        size: 15,
      },
      {
        header: "SKU",
        accessorKey: "coId",
        // size: 100,
      },
      {
        header: "Brand c",
        accessorKey: "brandName",
        // size: 100,
      },
      {
        header: "Group c",
        accessorKey: "productGroup",
        // size: 100,
      },
      {
        header: "Type c",
        accessorKey: "productType",
        // size: 100,
      },
      {
        header: "Nomenclature",
        accessorKey: "name",
        // size: 100,
      },

      {
        header: "Finishing",
        accessorKey: "material",
        // size: 100,
      },
      {
        header: "Color",
        accessorKey: "color",
        // size: 15,
      },
      {
        header: "Price",
        accessorKey: "listPrice",
        // size: 15,
      },
      {
        header: "RR price",
        accessorKey: "retailPrice",
        // size: 100,
      },
      {
        header: "Length mm",
        accessorKey: "length",
        // size: 100,
      },
      {
        header: "Width mm",
        accessorKey: "width",
        // size: 100,
      },
      {
        header: "Height mm",
        accessorKey: "height",
        // size: 100,
      },
      {
        header: "Weight kg",
        accessorKey: "weight",
        // size: 100,
      },

      {
        header: "Quantity short",
        accessorKey: "amount",
        // size: 100,
      },
      {
        header: "Area m2",
        accessorKey: "area",
        // size: 100,
      },
      {
        header: "Volume m3",
        accessorKey: "volumeWeight",
        // size: 100,
      },

      {
        header: "On stock",
        accessorKey: "onStock",
        // size: 100,
      },
      {
        header: "Available",
        accessorKey: "available",
        // size: 15,
      },
      {
        header: "In order",
        accessorKey: "inOrder",
        // size: 15,
      },
      {
        header: "In request",
        accessorKey: "inRequest",
        // size: 100,
      },
      {
        header: "Proform",
        accessorKey: "proform",
        // size: 100,
      },
      {
        header: "TP №",
        accessorKey: "tpNumber",
        // size: 100,
      },
      {
        header: "Reservation",
        accessorKey: "reservation",
        // size: 100,
      },
      {
        header: "CO reservation",
        accessorKey: "CO_Reservation",
        // size: 100,
      },
    ],
    [],
  );

  const skuTablecolumns = useColumns(columns);

  const transformForTable = useCallback(sku => {
    const {
      listPrice,
      retailPrice,
      minimumPrice,
      width,
      height,
      weight,
      amount,
      area,
      volume,
      material,
      color,
      brand,
      productGroup,
      productType,
      ...restSku
    } = sku;
    return {
      ...restSku,
      brandName: brand?.name,
      productGroup: productGroup?.name,
      productType: productType?.name,
      material: material?.name || material,
      color: color?.name || color,
      price: numberFormat(listPrice),
      retailPrice: numberFormat(retailPrice),
      minPrice: numberFormat(minimumPrice),
      length: numberFormat(sku["length"]),
      width: numberFormat(width),
      height: numberFormat(height),
      weight: numberFormat(weight),
      amount: numberFormat(amount),
      area: numberFormat(area),
      volume: numberFormat(volume),
      id: sku[idName], ////// в реквестах другой айди
    };
  }, []);

  const skuListData = useMemo(() => {
    return skus ? skus.map(transformForTable) : [];
  }, [skus]);

  return {skuTablecolumns, skuListData, transformSkuDataForTable: transformForTable};
};

export default useSkuTable;
