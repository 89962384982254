import {useSelector} from "react-redux";
import PropTypes from "prop-types";

import {fetchData} from "../../../../hook/axios.hook";
import {getCurrentKp, getKpLoading} from "../../../../selectors/generalSelectors";
import ReminderHoc from "../../../reminder/ReminderHoc";

const KpReminder = ({forcedUpdate, setGlobalSave}) => {
  const kp = useSelector(getCurrentKp),
    loading = useSelector(getKpLoading);

  const addRemind = newRemind => {
    console.log("newRemind", newRemind);
    return fetchData(`/todo/kp/add/${kp.coId}`, "post", {...newRemind});
  };
  // const addRemind = newRemind => {
  //   return fetchData(`/todo/kp/add/${coId}`, "post", {...newRemind, coId: kp.coId});
  // };

  const updateRemind = newRemind => {
    return fetchData(`/todo/kp/update/${newRemind.todoId}`, "patch", newRemind.data);
  };

  return (
    <ReminderHoc
      height="100%"
      forcedUpdate={forcedUpdate}
      setGlobalSave={setGlobalSave}
      create={addRemind}
      update={updateRemind}
      loading={loading}
      disabled={!kp}
    />
  );
};

KpReminder.propTypes = {forcedUpdate: PropTypes.bool, setGlobalSave: PropTypes.func};

export default KpReminder;
