import {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";

// import { useDispatch, useSelector } from "react-redux";
import {fetchData} from "../../hook/axios.hook";
import ViewTable from "../conteiners/ViewTable";

const AccountsList = () => {
  const {t} = useTranslation();

  const [accountsList, setAccList] = useState([]);

  useEffect(() => {
    fetchData("/finance/getAll", "get").then(res => {
      setAccList(res);
    });
  }, []);

  function transformForTable(item, i) {
    return {
      id: i + 1,
      accountNumber: item.accountNumber || "",
      accountType: item.accountType || "",
      accountName: item.accountName || "",
      owner: item.owner || "",
      status: item.status || "",
    };
  }

  const ths = useMemo(() => {
    return {
      "№": 5,
      [t("Account №")]: 20,
      [t("Type*")]: 5,
      [t("Nomenclature")]: 50,
      [t("Owner")]: 15,
      [t("Status")]: 5,
    };
  }, []);

  return (
    <div className="project_form_wrapper" style={{width: "100%", height: "100%"}}>
      <div className="project_list" style={{height: "100%", margin: "2em"}}>
        <ViewTable
          thsName={ths}
          items={accountsList.map(transformForTable)}
          //
          loadingStatus={"idle"}
        />
      </div>
    </div>
  );
};

export default AccountsList;
