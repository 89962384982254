import {useCallback, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {apiUrl} from "../../../api/baseURL";
import FunctionButton from "../../../utilities/Buttons/FunctionButton";
import useKpFinDataService from "../../KP/kp-f/kpFinTable/KpFinDataService";
import useKpFinTableService from "../../KP/kp-f/kpFinTable/KpFinTableService";
import {generatePdf} from "../../pdfGenerate/pdfMaker";
import Spinner from "../../spinner/Spinner";
import {
  deselectCurrentRequestSku,
  getCurrentRequestSKUs,
  getRequestInfo,
  getRequestSkuInfo,
  getSkuRequestInfo,
  reorderRequestSku,
  uploadPdf,
} from "../RequestsSlice";

const RequestPlanSkuTable = ({onSubmit, submitBtnLabel}) => {
  const {t} = useTranslation();

  const dispatch = useDispatch();

  const currentRequestSKUs = useSelector(getCurrentRequestSKUs);
  const currentRequest = useSelector(getRequestInfo);
  const currentRequestSku = useSelector(getRequestSkuInfo);

  const {getRequestSkuTableData} = useKpFinDataService();

  const [loading, setLoading] = useState(false);

  const skuListForT = useMemo(() => {
    return currentRequestSKUs?.map(getRequestSkuTableData) || [];
  }, [currentRequestSKUs]);

  // for numeration change, when user moves the lines in table
  const onReorder = useCallback(
    reorderedTableData => {
      if (reorderedTableData) {
        var sequenceObject = reorderedTableData.map(plan => {
          return {
            skuRequestId: plan.id,
            sequenceNumber: plan.number,
          };
        });

        dispatch(reorderRequestSku({requestId: currentRequest.requestId, data: {skuRequestOrder: sequenceObject}}));
      }
    },
    [currentRequest],
  );

  const selectSkuPlan = id => {
    console.log(currentRequestSku);
    if (id === currentRequestSku?.skuRequestId) {
      dispatch(deselectCurrentRequestSku());
      return;
    }
    if (id) dispatch(getSkuRequestInfo(id));
  };

  const exportToPdf = () => {
    const {requestNumber, requestId} = currentRequest;
    const data = generateDataForExport(`${requestNumber}`);
    setLoading(true);
    var pdf = generatePdf(data);

    pdf.getBlob(file => {
      var formData = new FormData();
      var fileName = `request_${requestNumber}_doc.pdf`;
      formData.append("doc", file, fileName);

      dispatch(uploadPdf({requestId, formData})).then(res => {
        if (res.meta.requestStatus === "fulfilled") {
          onSubmit();
        }
        setLoading(false);
      });
    });
  };

  const columns = useMemo(() => {
    const imgcellRender = value => {
      var imgs = value.getValue();
      if (imgs) {
        return (
          <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
            <div key={imgs} style={{position: "relative"}}>
              <img src={apiUrl + "/" + imgs} alt={imgs} style={{width: "5em", height: "5em", marginRight: "2px"}} />
            </div>
          </div>
        );
      }
    };
    return [
      {
        header: () => "№",
        accessorKey: "number",
        size: 15,
      },
      {
        header: () => "SKU",
        accessorKey: "article",
        toggled: true,
        // size: 200,
      },
      {
        header: () => [t("Brand w*")],
        accessorKey: "brandName",
      },
      {
        header: () => [t("Nomenclature")],
        accessorKey: "skuName",
      },
      {
        header: () => [t("Characteristics")],
        accessorKey: "characteristics",
        size: 300,
        toggled: true,
      },
      {
        header: () => [t("VWC")],
        accessorKey: "ovh",
      },
      {
        header: () => [t("Image")],
        accessorKey: "image1",
        cell: imgcellRender,
      },
      {
        header: () => [t("Image")],
        accessorKey: "image2",
        cell: imgcellRender,
      },
      {
        header: () => [t("Set description")],
        accessorKey: "complectDesc",
        toggled: true,
      },
      {
        header: () => [t("Quantity")],
        accessorKey: "amount",
      },
      {
        header: () => [t("Price pcs")],
        accessorKey: "price",
        numberFormat: true,
      },
      {
        header: () => [t("Sum €")],
        accessorKey: "paymentSumm",
        numberFormat: true,
      },
    ];
  }, []);

  const {KpFinTableRender, generateDataForExport} = useKpFinTableService(
    columns,
    skuListForT,
    onReorder,
    selectSkuPlan,
  );

  const KpFinTable = KpFinTableRender();

  return (
    <>
      {loading ? <Spinner /> : KpFinTable}

      <FunctionButton
        name={t(submitBtnLabel)}
        style={{position: "absolute", left: "40%", bottom: "0.5em", zIndex: "10"}}
        autoWidth
        disabled={loading}
        onClick={exportToPdf}
      />
    </>
  );
};

// ReequestPlanSkuTable.propTypes = {
//   onUpdate: PropTypes.func,
//   currentKpF: PropTypes.string,
//   setCurrSkuPlan: PropTypes.func,
//   active: PropTypes.object,
//   setActive: PropTypes.func
// };

export default RequestPlanSkuTable;
