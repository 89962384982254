import {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Form, Formik, useFormik} from "formik";
import * as Yup from "yup";

import {getCurrentSku} from "../SkuSlice";

import {Decoration, MainCharacteristics, Prices, VolumeWeightCharacteristics} from "./skuFormParts";

const SkuForm = () => {
  const {t} = useTranslation();

  // const { setIsDirty, onSelect } = props;

  // const dispatch = useDispatch();

  const currentSku = useSelector(getCurrentSku);

  const initialValues = useMemo(() => {
    return {
      complectDesc: "",

      color: "",
      colorType: "",

      height: "",
      longitude: "",
      material: "",
      materialType: "",
      minPrice: "",
      minPriceCheckUser: null,
      minPriceCheckUserId: null,
      minPriceDate: null,
      pallet: "",
      price: "",
      priceCheckUser: "",
      priceCheckUserId: "",
      priceDate: "",
      productSeries: "",

      settlementPrice: "",
      settlementPriceCheckUser: "",
      settlementPriceCheckUserId: "",
      settlementPriceDate: "",

      skuName: "",

      skuNameEng: "",
      skuUnkit: "",
      skuBatch: "",
      volume: "",
      volumeWeight: "",
      weight: "",
      width: "",
      currencyId: "",
      dimCheckDate: "",
      dimCheckUser: "",
      dimCheckUserId: "",
      unit: [t("pcs")],
    };
  }, []);

  // const onCreate = values => {
  //   const {
  //     article,
  //     countryId,
  //     brandId,
  //     productGroupId,
  //     productTypeId,
  //     // materialId,
  //     // colorId,
  //     // unitId
  //   } = values;
  //   dispatch(
  //     createSku({
  //       article,
  //       countryId,
  //       brandId,
  //       productGroupId,
  //       productTypeId,
  //       // materialId,
  //       // colorId,
  //       // unitId
  //     }),
  //   );
  // };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: values => {
      alert(JSON.stringify(values, null, 2));
    },
    onReset: () => {
      // Обработка сброса формы
      formik.resetForm();
    },
    // validateOnChange:false,
    // validateOnBlur:false,
    // enableReinitialize: true
  });

  return (
    <div>
      {/* <SkuAddForm /> */}
      <Formik
        initialValues={currentSku || initialValues}
        validationSchema={Yup.object().shape({
          article: Yup.string().required(),
          //    countryId :Yup.number().required(),
          brandId: Yup.number().required(),
          productGroupId: Yup.number().required(),
          productTypeId: Yup.number().required(),
          //    materialId: Yup.number().required(),
          //    colorId: Yup.number().required(),
          //    unitId: Yup.number().required(),
        })}
        validateOnChange={false}
        validateOnBlur={false}
        enableReinitialize
        // onSubmit={values => {
        //      onCreate(values)
        // }}
      >
        <Form className="sku_form" onReset={formik.handleReset} onSubmit={formik.handleSubmit}>
          {/* <DirtyControl setIsDirty={setIsDirty}/> */}

          <MainCharacteristics
          // dispatch={dispatch}
          // onSelect={onSelect}
          />
          <div className="middle_sku" style={{display: "flex", alignItems: "stretch", width: "100%"}}>
            <Decoration />
            <div className="" style={{paddingLeft: "10px", width: "50%"}}>
              <VolumeWeightCharacteristics />
              <Prices />
            </div>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

// const DirtyControl = ({ setIsDirty }) => {
//   const { dirty } = useFormikContext();

//   useEffect(() => {
//     setIsDirty(dirty);
//   }, [dirty]);

//   return null;
// };

export default SkuForm;
