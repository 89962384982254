import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useFormikContext} from "formik";

import FunctionButton from "../../../../../../utilities/Buttons/FunctionButton";
import {getSuccess} from "../../../../../../utilities/toasts";
import ReactTable from "../../../../../conteiners/ReactTable";
import {TextAreaInput, TextInput} from "../../../../../Inputs";
import {Select} from "../../../../../Inputs/SelectField";
import SingleFilePicker from "../../../../../Inputs/SingleFilePicker";
import useColumns from "../../../../../Requests/OrderProcessing/utils/hooks/useColumns";
import PaymentDates from "../../../../../Requests/RequestsInProgress/modalsInProgress/modalPaymentDocParts/PaymentDates";
import {
  addPaymentDocs,
  payUp,
} from "../../../../../Requests/RequestsInProgress/modalsInProgress/modalPaymentDocParts/redux/PaymentOperationsSlice";
import {
  getCurrentDocument,
  getPayment,
} from "../../../../../Requests/RequestsInProgress/modalsInProgress/modalPaymentDocParts/redux/PaymentSelectors";
import usePaymentsData from "../../../../../Requests/RequestsInProgress/modalsInProgress/modalPaymentDocParts/utils/hook/PaymentDataService";
import usePaymentsDoc from "../../../../../Requests/RequestsInProgress/modalsInProgress/modalPaymentDocParts/utils/hook/paymentDocService";
import {PAYMENT_TO_FACTORY_MODAL_COLUMNS} from "../../utils/constants";

import FinSchemeToFactory from "./FinSchemeToFactory";

const OnPaymentBlock = () => {
  const {t} = useTranslation();

  const columns = useColumns(PAYMENT_TO_FACTORY_MODAL_COLUMNS);

  const {docsForPayment} = usePaymentsData();
  const currentDoc = useSelector(getCurrentDocument);

  const dispatch = useDispatch();

  const {docTypes} = usePaymentsData();
  const payment = useSelector(getPayment);

  const {values, setValues} = useFormikContext(),
    {payDocument, documentType} = values;

  const disabledFilePicker = payDocument === "" ;

  const {onDocSelect, current} = usePaymentsDoc(2);

  const onFileAdding = file => {
    const formdata = new FormData();
    let filename = file.name;
    formdata.append("doc", file, filename);
    formdata.append("docNumber", values.payDocument);
    // formdata.append("docTypeId", values.documentType);
    // formdata.append("note", values.docNote);

    if (formdata.has("doc")) {
      dispatch(addPaymentDocs({paymentId: payment.paymentId, formData: formdata})).then(resp => {
        if (resp.meta.requestStatus === "fulfilled") {
          getSuccess(t("Payment document has been created"));
          setValues({
            payDocument: "",
            docNote: "",
          });
        }
      });
    }
  };

  const onPay = () => {
    dispatch(payUp({paymentId: payment.paymentId, data: {note: values.docNote}})).then(resp => {
      if (resp.meta.requestStatus === "fulfilled") {
        getSuccess(t("The document was successfully paid"));
      }
    });
  };

  return (
    <div>
      <div className="header-blocks header-blocks_last">1.{t("For payment")} </div>
      <PaymentDates labelPlan={t("on payment (p)")} labelFact={t("on payment (f)")} name={"onPayment"} />

      <div className="header-blocks q_wrapper">{t("Doc list for payment")}</div>
      <ReactTable
        defaultData={docsForPayment}
        columns={columns}
        onSelect={onDocSelect}
        current={current}
        className={"project_table"}
        style={{height: "5em"}}
      />
      <FinSchemeToFactory />
      <div className="on-payment on-payment_download">
        <TextInput label={t("Doc. for payment")} name="doc" width="10em" labelStyles={{width: "9em"}} readOnly />

        <Select
          label={t("Document type")}
          options={docTypes}
          name="documentType"
          readOnly
          width="10em"
          widthLabel="9em"
        />
      </div>
      <div className="on-payment on-payment_download">
        <TextInput label={t("Payment doc for f")} name="payDocument" width="20em" autolabel />
        <SingleFilePicker disabled={disabledFilePicker} addFunc={onFileAdding} accept={".pdf"} />
      </div>
      <TextAreaInput name="docNote" width={"101%"} placeholder={t("enter note")} style={{marginTop: "10px"}} />
      <div className="button-pay">
        <FunctionButton name={t("paid")} autoWidth disabled={false} onClick={onPay} />
      </div>
      <div className="border"></div>
    </div>
  );
};

export default OnPaymentBlock;
