import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Form, Formik} from "formik";

import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import UpDownButtons from "../../../../utilities/Buttons/UpDownButtons";
import {getInfo, getSuccess, getWarn} from "../../../../utilities/toasts";
import ReactTable from "../../../conteiners/ReactTableUniSelect";
import {DateInput, TextInput} from "../../../Inputs";
import useSkuTable from "../../../SKU/skuTableService";
import {
  deleteRequest,
  deselectCurrentOrder,
  getCurrentRequestSKUs,
  getSplitedOrder,
  transferSkuToRequest,
} from "../../RequestsSlice";

const SplitOrderSku = ({setModalActive, active, setCouldModalClose}) => {
  const currentSkuList = useSelector(getCurrentRequestSKUs),
    orders = useSelector(getSplitedOrder);

  const dispatch = useDispatch();

  const {t} = useTranslation();

  const [firstRequestSku, setFirstRequestSku] = useState([]);
  const [secondRequestSku, setSecondRequestSku] = useState([]);

  const firstRequestSkuRef = useRef([]);
  const secondRequestSkuRef = useRef([]);

  const [firstSelected, setFirstSelected] = useState([]);
  const [secondSelected, setSecondSelected] = useState([]);

  useEffect(() => {
    if (secondSelected.length < 1) {
      setCouldModalClose(false);
    }
  }, [secondSelected]);

  const {skuTablecolumns, skuListData, transformSkuDataForTable} = useSkuTable(firstRequestSku, "skuRequestId");

  const onUnload = () => {
    // console.log("firstRequestSku", firstRequestSku);
    // console.log("secondRequestSku", secondRequestSkuRef.current);
    if (firstRequestSkuRef.current.length < 1 || secondRequestSkuRef.current.length < 1) {
      console.log("delete");
      dispatch(deleteRequest(orders.second.requestId)).then(({meta}) => {
        if (meta.requestStatus === "fulfilled") {
          getWarn(t("Order splitting was cancelled"));
        }
      });
    }
  };

  useEffect(() => {
    firstRequestSkuRef.current = firstRequestSku; // Обновляем значение в useRef при изменении state
  }, [firstRequestSku]);

  useEffect(() => {
    secondRequestSkuRef.current = secondRequestSku; // Обновляем значение в useRef при изменении state
  }, [secondRequestSku]);

  useEffect(() => {
    window.addEventListener("beforeunload", onUnload);
    return () => {
      onUnload();
      window.removeEventListener("beforeunload", onUnload);
      //
    };
  }, []);

  useEffect(() => {
    setFirstRequestSku(currentSkuList);
  }, [currentSkuList]);

  const directions = Object.freeze({
    UP: "up",
    DOWN: "down",
  });

  const changeRequest = (idList, direction) => {
    const switchedSkus = currentSkuList.filter(sku => idList.includes(sku.skuRequestId));

    switch (direction) {
      case directions.UP: {
        setFirstRequestSku(prev => [...prev, ...switchedSkus]);
        const filteredSecond = secondRequestSku.filter(sku => idList.includes(sku.skuRequestId));
        console.log(filteredSecond);
        setSecondRequestSku(filteredSecond);
        break;
      }
      case directions.DOWN: {
        const filteredFirst = firstRequestSku.filter(sku => !idList.includes(sku.skuRequestId));
        setFirstRequestSku(filteredFirst);
        setSecondRequestSku(prev => {
          return [...prev, ...switchedSkus];
        });

        break;
      }
    }
  };

  useEffect(() => {
    console.log(orders?.second?.requestDates[0].plannedDate);
  }, [orders]);

  const initialValues = useMemo(() => {
    return {
      requestNumber: orders?.first || "",
      managerCo: orders?.second?.managerCo.name || "",
      secondRequestNumber: orders?.second.requestNumber || "",
      dateD: "2023-08-17" || new Date(orders?.second?.requestDates[0].plannedDate) || "",
    };
  }, [orders]);

  const onCancel = () => {
    setSecondRequestSku([]);
    setFirstRequestSku(currentSkuList);
  };

  const onSeparate = () => {
    dispatch(
      transferSkuToRequest({
        requestId: orders.second.requestId,
        data: {
          skuRequestIds: secondRequestSku.map(req => {
            return req.skuRequestId;
          }),
        },
      }),
    ).then(({meta}) => {
      if (meta.requestStatus === "fulfilled") {
        getSuccess(t("Request was splitted successfully"));
        setModalActive(false);
        dispatch(deselectCurrentOrder());
      }
    });
  };

  const secondOrderTableData = useMemo(() => {
    return secondRequestSku.map(transformSkuDataForTable);
  }, [secondRequestSku]);

  return (
    <div style={{height: "100%"}}>
      <Formik initialValues={initialValues} enableReinitialize>
        <Form className="autolabel flex_form g15">
          <div className="fc nowrap jcfs g2">
            <TextInput label={"Order number"} name="requestNumber" />
            <TextInput label={"CO Manager"} name="managerCo" />
          </div>
          <div style={{height: "270px"}}>
            <ReactTable
              // defaultData={firstRequestSku.map(transformForTable)}
              defaultData={skuListData}
              columns={skuTablecolumns}
              setSelectedItems={setFirstSelected}
              // current={null}
              style={{height: "100%", width: "100%"}}
              className="scrollX"
            />
          </div>
          <div className="fc jcsb">
            <div className="fc nowrap jcfs g2">
              <TextInput label={"Order number"} name="secondRequestNumber" />
              <DateInput name="dateD" label={"DD per Factory(p):"} readOnly />
            </div>
            <UpDownButtons
              onUpClick={() => {
                changeRequest(secondSelected, directions.UP);
              }}
              onDownClick={() => {
                changeRequest(firstSelected, directions.DOWN);
              }}
            />
          </div>
          <div style={{height: "270px"}}>
            <ReactTable
              defaultData={secondOrderTableData}
              // defaultData={}
              columns={skuTablecolumns}
              setSelectedItems={setSecondSelected}
              current={null}
              className="scrollX"
              // style={{ height: "100%", width: "100%" }}
            />
          </div>
          <div className="btn_container left">
            <FunctionButton name="Split orders" onClick={onSeparate} autoWidth />
            <FunctionButton name="Cancel" onClick={onCancel} />
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default SplitOrderSku;
