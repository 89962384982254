import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Formik} from "formik";

import PageContainer from "../../conteiners/PageContainer";
import {selectCurrentFactoryName} from "../redux/selectors/FactorySelectors";

import RequestAutodistribution from "./Autodistribution/FactoryDistribution";
import FactoryDistribution from "./Autodistribution/FactoryDistribution";
import ControlTermsSettings from "./ControlTermsSettings/ControlTermsSettings";
import RequestText from "./EmailTemplates/EmailTemplates";
import EmailTemplates from "./EmailTemplates/EmailTemplates";
import FactoryList from "./FactoryList/FactoryList";

import styles from "./common/styles/VedSettingsGeneral.module.scss";

const VedSettingsGeneral = () => {
  const {t} = useTranslation();

  const factory = useSelector(selectCurrentFactoryName);

  const initialValues = useMemo(
    () => ({
      reqAutoNote: "",
      factory: factory || "",
      variant: "",
      toWhom: "",
      copy: "",
      theme: "",
      reqText: "",
      toFactoryDate: "",
      pfReceivingDate: "",
      pfControlDate: "",
      paymentDate: "",
      paymentReadyDate: "",
      readyControlDate: "",
      logistDate: "",
    }),
    [factory],
  );

  return (
    <Formik initialValues={initialValues} enableReinitialize onSubmit={() => {}}>
      <PageContainer title={t("Main settings")}>
        <div className={styles.pageContainer}>
          <div className={styles.leftColumn}>
            <FactoryDistribution />
            <EmailTemplates />
            <ControlTermsSettings />
          </div>
          <div className={styles.rightColumn}>
            <FactoryList />
          </div>
        </div>
      </PageContainer>
    </Formik>
  );
};

export default VedSettingsGeneral;
