import {useTranslation} from "react-i18next";

import FunctionButton from "../../../../../../utilities/Buttons/FunctionButton";
import ReactTable from "../../../../../conteiners/ReactTable";
import {TextAreaInput, TextInput} from "../../../../../Inputs";
import {Select} from "../../../../../Inputs/SelectField";
import SingleFilePicker from "../../../../../Inputs/SingleFilePicker";
import useColumns from "../../../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {onPaymentColumns} from "../../../../../Requests/RequestsInProgress/modalsInProgress/modalPaymentDocParts/columnsForPaymentTables";
import PaymentDates from "../../../../../Requests/RequestsInProgress/modalsInProgress/modalPaymentDocParts/PaymentDates";
import usePaymentsData from "../../../../../Requests/RequestsInProgress/modalsInProgress/modalPaymentDocParts/utils/hook/PaymentDataService";
import usePaymentsDoc from "../../../../../Requests/RequestsInProgress/modalsInProgress/modalPaymentDocParts/utils/hook/paymentDocService";
import {PAID_MODAL_COLUMNS, PAYMENT_TO_FACTORY_MODAL_COLUMNS} from "../../utils/constants";

import FinSchemeToFactory from "./FinSchemeToFactory";

const PaidBlock = () => {
  const {t} = useTranslation();

  const columns = useColumns(onPaymentColumns);
  const {onDocSelect, current} = usePaymentsDoc(3);
  const {docsByPayment} = usePaymentsData();

  return (
    <div>
      <div className="header-blocks header-blocks_last">2.{t("paid")} </div>

      <div className="header-blocks q_wrapper">{t("Paid doc list")}</div>
      <ReactTable
        defaultData={docsByPayment}
        columns={columns}
        onSelect={onDocSelect}
        current={current}
        className={"project_table"}
        style={{height: "10em"}}
      />

      <div className="button-pay">
        <FunctionButton name={t("send to")} autoWidth disabled={true} />
      </div>
    </div>
  );
};

export default PaidBlock;
