import AvailabilityStatusCIrcule from "../../../../../utilities/AvailabilityStatusCIrcule";

export const PAYMENT_TO_FACTORY_COLUMNS = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 25,
  },
  {
    header: "Date",
    accessorKey: "date",
    // size: 100,
  },
  {
    header: "Дт.",
    accessorKey: "dt",
    // size: 100,
  },
  {
    header: "Кт.",
    accessorKey: "kt",
    // size: 100,
  },
  {
    header: "Sum",
    accessorKey: "summ",
    // size: 100,
  },
  {
    header: "paid",
    accessorKey: "isPaid",
    size: 60,
    cell: circleColor => (
      <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}>
        <AvailabilityStatusCIrcule color={circleColor.getValue()} />
      </div>
    ),
  },
  {
    header: "Payment purpose",
    accessorKey: "paymentPurpose",
    // size: 100,
  },
  {
    header: "Payment document",
    accessorKey: "paymentDocument",
    // size: 100,
  },
  {
    header: "Proforma",
    accessorKey: "proforma",
    // size: 100,
  },
  {
    header: "Factory",
    accessorKey: "factory",
    // size: 100,
  },
]);

export const PAYMENT_TO_FACTORY_MODAL_COLUMNS = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 25,
  },
  {
    header: "doc for pay",
    accessorKey: "doc",
    // size: 100,
  },
  {
    header: "Document type",
    accessorKey: "docType",
    // size: 100,
  },
  {
    header: "Note for factory manager",
    accessorKey: "managerNote",
    // size: 100,
  },
]);

export const FINSCHEME_TO_FACTORY_MODAL_COLUMNS = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 10,
  },

  {
    header: "Payment scheme",
    accessorKey: "scheme",
    // size: 100,
  },
  {
    header: "Description",
    accessorKey: "desc",
    // size: 100,
  },
]);

export const PAID_MODAL_COLUMNS = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 25,
  },
  {
    header: "paid doc",
    accessorKey: "paidDoc",
    // size: 100,
  },
  {
    header: "Payment doc",
    accessorKey: "paymentDoc",
    // size: 100,
  },
  {
    header: "Note for FEA manager",
    accessorKey: "managerFEANote",
    // size: 100,
  },
]);
