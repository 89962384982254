import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";

import {
  addControlTerms,
  deleteControlTerms,
  fetchControlTerms,
  updateTermsForCondition,
} from "../../redux/operations/FactoryOperations";
import {
  selectControlTerms,
  selectCurrentConditionId,
  selectCurrentFactoryName,
} from "../../redux/selectors/FactorySelectors";
import {cleanControlTerms} from "../../redux/slices/FactorySlice";

const useControlTermsService = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const currentConditionId = useSelector(selectCurrentConditionId);
  const controlTermsForCurrentCondition = useSelector(selectControlTerms);
  const currentFactoryName = useSelector(selectCurrentFactoryName);

  const currentConditionValues = {
    toFactoryDate: "",
    pfReceivingDate: "",
    pfControlDate: "",
    paymentDate: "",
    paymentReadyDate: "",
    readyControlDate: "",
    logistDate: "",
  };

  controlTermsForCurrentCondition?.map(term => {
    const calculatedOption = term.calculatedOption;

    switch (term.actualDateType.dateTypeId) {
      case 3:
        currentConditionValues.toFactoryDate = calculatedOption;
        break;
      case 4:
        currentConditionValues.pfReceivingDate = calculatedOption;
        break;
      case 5:
        currentConditionValues.pfControlDate = calculatedOption;
        break;
      case 6:
        currentConditionValues.paymentDate = calculatedOption;
        break;
      case 7:
        currentConditionValues.paymentReadyDate = calculatedOption;
        break;
      case 8:
        currentConditionValues.readyControlDate = calculatedOption;
        break;
      case 9:
        currentConditionValues.logistDate = calculatedOption;
        break;
    }
  });

  useEffect(() => {
    if (!currentConditionId) {
      dispatch(cleanControlTerms());
      return;
    }
    dispatch(fetchControlTerms(currentConditionId));
  }, [currentConditionId]);

  const areControlTerms = controlTermsForCurrentCondition && controlTermsForCurrentCondition.length !== 0;
  const isAddControlTermsDisabled =
    !currentConditionId || !controlTermsForCurrentCondition || controlTermsForCurrentCondition.length > 0;
  const areUpdateAndDeleteControlTermsDisabled = !currentConditionId || !areControlTerms;

  //initial formik values
  const initialValues = {
    factoryName: currentFactoryName || "",
    toFactoryDate: currentConditionValues.toFactoryDate,
    pfReceivingDate: currentConditionValues.pfReceivingDate,
    pfControlDate: currentConditionValues.pfControlDate,
    paymentDate: currentConditionValues.paymentDate,
    paymentReadyDate: currentConditionValues.paymentReadyDate,
    readyControlDate: currentConditionValues.readyControlDate,
    logistDate: currentConditionValues.logistDate,
  };

  //event handlers
  function onSaveControlTermsBtnClick(values) {
    const valuesArr = Object.values(values);
    const data = controlTermsForCurrentCondition.map((term, index) => ({
      termId: term.termId,
      calculatedOption: valuesArr[index + 1],
    }));
    dispatch(updateTermsForCondition({conditionId: currentConditionId, data})).then(response => {
      if (response.meta.requestStatus === "fulfilled") {
        toast.success(t("Control terms saved successfully"));
      }
    });
  }
  function onAddControlTermsBtnClick() {
    dispatch(addControlTerms(currentConditionId)).then(response => {
      if (response.meta.requestStatus === "fulfilled") {
        toast.success(t("Control terms added successfully"));
      }
    });
  }
  function onRemoveControlTermsBtnClick() {
    dispatch(deleteControlTerms(currentConditionId)).then(response => {
      if (response.meta.requestStatus === "fulfilled") {
        toast.success(t("Control terms deleted successfully"));
      }
    });
  }

  return {
    initialValues,
    onSaveControlTermsBtnClick,
    onAddControlTermsBtnClick,
    onRemoveControlTermsBtnClick,
    isAddControlTermsDisabled,
    areUpdateAndDeleteControlTermsDisabled,
  };
};

export default useControlTermsService;
