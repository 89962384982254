import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import QuestionItem from "../../../../utilities/QuestionItem";
import ReactTable from "../../../conteiners/ReactTable";
import ModalForm from "../../../Modal/FormModal";
import {fetchSkuCatalogs} from "../../../SKU/SkuSlice";
import {getRequestInfo, getRequestSkuInfo, getSkuRequestInfo, getThirdStatusRequests} from "../../RequestsSlice";
import useColumns from "../utils/hooks/useColumns";

import {REQUEST_SKU_BLOCK_COLUMNS} from "./utils/constants";
import useCurrentRequest from "./utils/hooks/useCurrentRequest";
import ViewSkuFromTable from "./ViewSkuFromTable";

import styles from "./RequestSkuBlock.module.scss";

const RequestSkuBlock = () => {
  const {t, i18n} = useTranslation();
  const dispatch = useDispatch();

  const currentRequest = useSelector(getRequestInfo);
  const currentSkuInRequest = useSelector(getRequestSkuInfo);

  useEffect(() => {
    dispatch(fetchSkuCatalogs());
  }, []);

  const [modalActive, setModalActive] = useState(false);

  const columns = useColumns(REQUEST_SKU_BLOCK_COLUMNS);
  const currentRequestSKUs = useCurrentRequest();

  const RequestSkuBlockTableData = currentRequestSKUs.map(
    ({
      skuRequestId,
      article,
      brand,
      productGroup,
      productType,
      name,
      nameEng,
      material,
      color,
      listPrice,
      retailPrice,
      length,
      width,
      height,
      weight,
      amount,
      area,
      volumeWeight,
    }) => ({
      id: skuRequestId,
      article,
      brandName: brand.name,
      productGroup: productGroup?.name,
      productType: productType?.name,
      name: i18n === "eng" ? nameEng : name,
      material,
      color,
      listPrice,
      retailPrice,
      length,
      width,
      height,
      weight,
      amount,
      area,
      volumeWeight,
    }),
  );

  const handleRowDoubleClick = rowData => {
    dispatch(getSkuRequestInfo(rowData.id)).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        setModalActive(true);
      }
    });
  };

  return (
    <div className="table_xscroll__container">
      <div className="q_wrapper">
        <QuestionItem title="double click" />
        SKU {t("In order small")} {currentRequest?.requestNumber}
      </div>
      <ReactTable
        defaultData={RequestSkuBlockTableData ?? []}
        columns={columns}
        onSelect={() => {}}
        current={null}
        onRowDoubleClick={handleRowDoubleClick}
        className={styles.skuTableWrapper}
        tableClassName={styles.skuTable}
      />
      <ModalForm
        closable={true}
        Component={ViewSkuFromTable}
        element={currentSkuInRequest}
        label={t(`${t("View SKU")} ${currentSkuInRequest?.article} ${t("in order")} ${currentRequest?.requestNumber}`)}
        active={modalActive}
        setModalActive={setModalActive}
      />
    </div>
  );
};

export default RequestSkuBlock;
