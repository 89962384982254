import {STATUS_COLORS} from "../../RequestRegister/colorConstants";

export const STATUSES = {
  NOT_ACTIVE: STATUS_COLORS.GREY,
  IN_TIME: STATUS_COLORS.GREEN,
  RANGE: STATUS_COLORS.YELLOW,
  EXPIRED: STATUS_COLORS.RED,
};

export const PROGRESS_CARD_TYPES = Object.freeze({
  FACTORY: "toFactory",
  PF: "pf",
  PAYMENT: "payment",
  RECEIPT: "receipt",
  LOGISTICIAN: "logist",
});

export const PROGRESS_CARDS = Object.freeze([
  {
    statusButton: STATUSES.NOT_ACTIVE,
    header: "To factory (p/f)",
    dateStartName: "sendToFactoryDateS",
    dateEndName: "sendToFactoryDateE",
    buttonName: "Request to factory",
    frame: STATUS_COLORS.TRANSPARENT,
    type: PROGRESS_CARD_TYPES.FACTORY,
    label: "edit req header",
    descText: "factory request",
  },
  {
    statusButton: STATUSES.IN_TIME,
    header: "Pf receipt (p/f)",
    dateStartName: "gettingProformaDateS",
    dateEndName: "gettingProformaDateE",
    buttonName: "Pf receipt",
    frame: STATUS_COLORS.TRANSPARENT,
    type: PROGRESS_CARD_TYPES.PF,
    label: "Pf receipt and control of the request №",
    descText: "pf req",
  },
  {
    statusButton: STATUSES.RANGE,
    header: "Pf control (p/f)",
    dateStartName: "proformaControlDateS",
    dateEndName: "proformaControlDateE",
    buttonName: "Pf control",
    frame: STATUS_COLORS.TRANSPARENT,
    type: PROGRESS_CARD_TYPES.PF,
    label: "Pf receipt and control of the request №",
    descText: "pf req",
  },
  {
    statusButton: STATUSES.NOT_ACTIVE,
    header: "For payment (p/f)",
    dateStartName: "forPaymentDateS",
    dateEndName: "forPaymentDateE",
    buttonName: "Document for payment",
    frame: STATUS_COLORS.TRANSPARENT,
    type: PROGRESS_CARD_TYPES.PAYMENT,
    label: "Payment on request №",
    descText: "payment req",
  },
  {
    statusButton: STATUSES.NOT_ACTIVE,
    header: "On payment (p/f)",
    dateStartName: "onPaymentDateS",
    dateEndName: "onPaymentDateE",
    buttonName: "Document on payment",
    frame: STATUS_COLORS.TRANSPARENT,
    type: PROGRESS_CARD_TYPES.PAYMENT,
    label: "Payment on request №",
    descText: "payment req",
  },
  {
    statusButton: STATUSES.NOT_ACTIVE,
    header: "Readiness",
    dateStartName: "plannedReadinessDate",
    dateEndName: "actualReadinessDate",
    buttonName: "Product readiness",
    frame: STATUS_COLORS.TRANSPARENT,
    type: PROGRESS_CARD_TYPES.RECEIPT,
    label: "Product readiness according to the request №",
    descText: "readiness req",
  },
  {
    statusButton: STATUSES.NOT_ACTIVE,
    header: "Product readiness control",
    dateStartLabel: "",
    dateStartName: "readinessControlDate",
    buttonName: "Readiness control",
    frame: STATUS_COLORS.TRANSPARENT,
    type: PROGRESS_CARD_TYPES.RECEIPT,
    label: "Product readiness according to the request №",
    descText: "readiness req",
  },
  {
    statusButton: STATUSES.NOT_ACTIVE,
    header: "Logistician (s/r)",
    dateStartLabel: "sent",
    dateEndLabel: "received",
    dateStartName: "logist",
    dateEndName: "logist",
    buttonName: "Logistician request transfer info",
    frame: STATUS_COLORS.TRANSPARENT,
    type: PROGRESS_CARD_TYPES.LOGISTICIAN,
    label: "",
    descText: "logist req",
  },
]);
