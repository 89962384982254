// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.RequestSkuBlock_skuTableWrapper__ADc1p {
  height: 60vh;
  max-height: 580px;
  margin-bottom: 0;
}

.RequestSkuBlock_skuTable__CY1l\\+ tr {
  height: 17px;
}
.RequestSkuBlock_skuTable__CY1l\\+ td {
  padding: 5px;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/Components/Requests/OrderProcessing/RequestSkuBlock/RequestSkuBlock.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,iBAAA;EACA,gBAAA;AACF;;AAGE;EACE,YAAA;AAAJ;AAEE;EACE,YAAA;EACA,kBAAA;AAAJ","sourcesContent":["div.skuTableWrapper {\n  height: 60vh;\n  max-height: 580px;\n  margin-bottom: 0;\n}\n\n.skuTable {\n  tr {\n    height: 17px;\n  }\n  td {\n    padding: 5px;\n    text-align: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"skuTableWrapper": `RequestSkuBlock_skuTableWrapper__ADc1p`,
	"skuTable": `RequestSkuBlock_skuTable__CY1l+`
};
export default ___CSS_LOADER_EXPORT___;
