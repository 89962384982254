import React, {useState} from "react";
import {useTranslation} from "react-i18next";

import TextInput from "../TextInput";

import checkImg from "./buttonx.png";

const ControlCheck = ({name, date, initialChecked = false, readOnly = false}) => {
  const [checked, setChecked] = useState(initialChecked);
  const {t} = useTranslation();

  return (
    <div className="fc nowrap autolabel" style={{gap: "0.3em"}}>
      {!readOnly && (
        <input
          type="checkbox"
          checked={checked}
          // onChange={()=>{setChecked(i=>!i)}}
          onChange={() => {}}
          readOnly={true}
        />
      )}
      <TextInput label={t("Control")} name={date} width="100%" readOnly={readOnly} />
      <TextInput label={t("From")} name={name} width="60%" readOnly={readOnly} />
      {!readOnly && <img src={checkImg} alt="" style={{height: "1.6em"}} draggable="false" />}
    </div>
  );
};

export default ControlCheck;
