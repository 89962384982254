import {useEffect, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Form, Formik} from "formik";

import PageContainer from "../../../conteiners/PageContainer";
import ReactTable from "../../../conteiners/ReactTable";
import {DateInput, TextAreaInput, TextInput} from "../../../Inputs";
import NewPdfViewer from "../../../Project/Documents/DocumentView/NewPdfViewer";
import useColumns from "../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {fetchForCurrentApproval} from "../../../Requests/Proform/redux/operations/ProformOperations";
import {
  selectApprovals,
  selectCurrentApproval,
  selectDisapprovalReasons,
} from "../../../Requests/Proform/redux/selectors/ProformSelectors";
import {setCurrentApproval} from "../../../Requests/Proform/redux/slices/ProformSlice";

import Facsimile from "./Facsimile/Facsimile";
import {PF_CONTROL_BTN_TYPES, PfControlBtn} from "./PfControlBtn/PfControlBtn";
import {ReasonAddingForm} from "./ReasonAddingForm/ReasonAddingForm";
import {DISAPPROVE_DETAILS_TABLE_COLUMNS, PF_CONTROL_TABLE_COLUMNS} from "./utils/constants";

import styles from "./KpPfControl.module.scss";

const KpPfControl = () => {
  const dispatch = useDispatch();
  const {t, i18n} = useTranslation();

  useEffect(() => {
    dispatch(fetchForCurrentApproval());
  }, []);

  //redux state
  const approvals = useSelector(selectApprovals);
  const currentApproval = useSelector(selectCurrentApproval);
  const approvalId = currentApproval?.approvalId ?? null;

  const nameType = i18n.language === "en" ? "engFirstName" : "firstName";
  const nameType2 = i18n.language === "en" ? "engLastName" : "lastName";

  //data
  const pdfFileUrlArr = currentApproval?.proforma?.pdfFileUrl ? [currentApproval.proforma.pdfFileUrl] : [];
  const controlPfTableColumns = useColumns(PF_CONTROL_TABLE_COLUMNS);

  const approvalsData = useMemo(() => {
    const approvalsMas = [];
    approvals?.forEach((appr, i) => {
      if (appr?.proforma?.request) {
        var managerFea = appr?.proforma?.request.managerFea;
        approvalsMas.push({
          id: appr.approvalId,
          number: i + 1,
          pfNumber: appr.proforma?.proformaNumber || "",
          coNumber: "",
          requestNumber: appr?.proforma?.request?.requestNumber || "",
          managerFEA: managerFea ? managerFea.persona[nameType] + " " + managerFea.persona[nameType2] : "",
        });
      }
    });
    return approvalsMas;
  }, [approvals]);
  const disapprovTableColumns = useColumns(DISAPPROVE_DETAILS_TABLE_COLUMNS);
  const disapprovalReasons = useSelector(selectDisapprovalReasons);
  const disapproveTableData = currentApproval
    ? disapprovalReasons.map((el, index) => ({...el, number: index + 1, id: el.reasonId}))
    : [];

  //initiial values
  const initialValues = {
    pfNumber: currentApproval ? currentApproval.proforma.proformaNumber : "",
    controlPfP: currentApproval ? currentApproval.proforma.request.requestDates[0]?.plannedDate : "",
    controlPfF: currentApproval ? currentApproval.proforma.request.requestDates[0]?.actualDate : "",
    skuNumber: "",
    disapprovReason: "",
    disapprovDescription: "",
    noteForManagerFEA: currentApproval ? currentApproval.proforma.note : "",
    createdPin: "",
    repeatedPin: "",
    noteFromManagerFEA: currentApproval
      ? currentApproval.note
        ? currentApproval.note
        : t("No note from manager FEA")
      : "",
  };

  //FilePreview functions
  // const {onFileAdding, onFileDel} = useFilePreview(currentApproval);

  const onApprovalsTableRowSelect = approvalId => {
    if (currentApproval && approvalId === currentApproval.approvalId) {
      dispatch(setCurrentApproval(null));
      return;
    }
    const current = approvals?.find(approval => approval.approvalId === approvalId);
    dispatch(setCurrentApproval(current));
  };

  return (
    <PageContainer title={t("Control PF")}>
      <Formik initialValues={initialValues} enableReinitialize onSubmit={() => {}}>
        {({values, resetForm}) => (
          <Form className={`${styles.pageContainer} autolabel`}>
            <div className={styles.leftColumn}>
              <section className={styles.controlSection}>
                <div className={styles.sectionRow}>
                  <h2 className={styles.sectionHeader}>
                    1. <span className={styles.underLined}>{t("Pf Control") + ":"}</span>
                  </h2>
                  <div className={styles.sectionRow}>
                    <TextInput label={t("Pf №") + ":"} name="pfNumber" disabled />
                    {
                      //! ? what logic, what file, where added
                      //   <ToolTip title={t("Add file")}>
                      //   <FilePreview
                      //     imgs={[]}
                      //     delFunc={onFileDel}
                      //     // accept={}
                      //     addFunc={arrFiles => onFileAdding(arrFiles, values)}
                      //     disable={true}
                      //   />
                      // </ToolTip>
                    }
                  </div>
                </div>
                <div className={styles.sectionRow}>
                  <DateInput name="controlPfP" label={t("Date: Pf control (p):")} disabled readOnly />
                  <DateInput name="controlPfF" label={t("Date: Pf control (f):")} disabled readOnly />
                </div>
                <ReactTable
                  className={styles.controlPFTableWrapper}
                  tableClassName={styles.controlPFTable}
                  columns={controlPfTableColumns}
                  defaultData={approvalsData}
                  onSelect={onApprovalsTableRowSelect}
                  current={null}
                />
                <PfControlBtn
                  type={PF_CONTROL_BTN_TYPES.APPROVE}
                  disabled={currentApproval?.isApproved}
                  approvalId={approvalId}
                />
              </section>
              <section className={styles.controlSection}>
                <h2 className={styles.sectionHeader}>
                  2. <span className={styles.underLined}>{t("NOT approved") + ":"}</span>
                </h2>
                <ReasonAddingForm values={values} approvalId={approvalId} resetForm={resetForm} />
                <ReactTable
                  className={styles.disapproveTableWrapper}
                  tableClassName={styles.disapproveTable}
                  columns={disapprovTableColumns}
                  current={null}
                  defaultData={disapproveTableData}
                />
                <TextAreaInput
                  name="noteForManagerFEA"
                  className={styles.noteForManagerFEA}
                  width="100%"
                  height="50px"
                  placeholder={t("Enter your note for the manager FEA")}
                />
                <PfControlBtn
                  type={PF_CONTROL_BTN_TYPES.DISAPPROVE}
                  disabled={currentApproval?.isDeclined || currentApproval?.isApproved}
                  approvalId={approvalId}
                  note={values.noteForManagerFEA}
                />
              </section>
              <Facsimile values={values} currentApproval={currentApproval} resetForm={resetForm} />
            </div>
            <div className={styles.rightColumn}>
              <TextAreaInput
                className={styles.noteFromManagerFEA}
                name="noteFromManagerFEA"
                width="100%"
                height="50px"
                readOnly
              />
              <div className={styles.pdfWrapper}>
                {currentApproval && <NewPdfViewer hiding={false} fileArray={pdfFileUrlArr} />}
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </PageContainer>
  );
};

export default KpPfControl;
