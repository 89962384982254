import {AVAILABILITY_CIRCLE_COLORS} from "../../../../../../../utilities/AvailabilityStatusCIrcule";

const getStatusControlTableData = proforma => {
  if (proforma) {
    const circleColor =
      proforma?.approvals?.length !== 0 ? AVAILABILITY_CIRCLE_COLORS.GREEN : AVAILABILITY_CIRCLE_COLORS.RED;
    const {proformaId, proformaNumber} = proforma;
    return [{number: 1, id: proformaId, pfNumb: proformaNumber, statControl: circleColor}];
  } else {
    return [];
  }
};

export default getStatusControlTableData;
