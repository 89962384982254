import {useTranslation} from "react-i18next";
import {Formik} from "formik";
import PropTypes from "prop-types";

import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import {DateInput, TextInput} from "../../../Inputs";
import FactoryAdress from "../factoryModalParts/FactoryAdress";
import FactoryCommonTable from "../factoryModalParts/FactoryCommonTable";
import FactoryConditionForm from "../factoryModalParts/FactoryConditionForm";
import FactoryContactsEdit from "../factoryModalParts/FactoryContactsEdit";

import {FACTORY_MODAL_TYPES} from "./utils/constants";
import useFactoryService from "./utils/hooks/getFactoryService";

const EditFactoryConditions = ({setModalActive}) => {
  const {t} = useTranslation();

  const modalType = FACTORY_MODAL_TYPES.EDIT_FACTORY;
  const {
    onUpdateFactoryBtnClick,
    onUpdateContactBtnClick,
    onDeleteContactBtnClick,
    onUpdateConditionBtnClick,
    onDeleteConditionBtnClick,
    onUpdateFactoryModalChangesBtnClick,
    initialValues,
    setCurrentCommonCondition,
    onCancelBtnClick,
    calcIfUpdateConditionsButtonDisabled,
    areConditionsButtonsDisabled,
    calcIfUpdateFactoryAddressBtnDisabled,
  } = useFactoryService({modalType, setModalActive});

  const noChangeDisabledBtnTooltipMessage = t("Make some changes first");
  const noSelectedConditionTooltipMessage = t("Select a condition first");

  return (
    <Formik
      initialValues={initialValues}
      // validationSchema={Yup.object().shape({})}
      validateOnChange={true}
      validateOnBlur={true}
      enableReinitialize
    >
      {({values}) => (
        <>
          <div className="factory_modal_wrapper">
            <div className="factory_address_block">
              <FactoryAdress modalType={modalType}>
                <div className="fcc jcfe" style={{marginTop: "0.5em"}}>
                  <FunctionButton
                    name="s factory ch"
                    autoWidth
                    onClick={() => onUpdateFactoryBtnClick(values)}
                    disabled={calcIfUpdateFactoryAddressBtnDisabled(values)}
                    tooltipMessage={
                      calcIfUpdateFactoryAddressBtnDisabled(values) ? noChangeDisabledBtnTooltipMessage : ""
                    }
                  />
                </div>
              </FactoryAdress>
            </div>

            <div className="factory_address_block">
              <FactoryContactsEdit
                onUpdateContactBtnClick={() => onUpdateContactBtnClick(values)}
                onDeleteContactBtnClick={() => onDeleteContactBtnClick(values)}
              />
            </div>
          </div>
          <div className="border border_gap"></div>
          <FactoryConditionForm>
            <div className="fcc jcsb" style={{marginTop: "0.5em"}}>
              <FunctionButton
                name="Delete Supply terms"
                autoWidth
                onClick={() => onDeleteConditionBtnClick(values)}
                disabled={areConditionsButtonsDisabled}
                tooltipMessage={areConditionsButtonsDisabled ? noSelectedConditionTooltipMessage : ""}
              />
              <FunctionButton
                name="Save Supply terms` changes"
                autoWidth
                onClick={() => onUpdateConditionBtnClick(values)}
                disabled={calcIfUpdateConditionsButtonDisabled(values)}
                tooltipMessage={calcIfUpdateFactoryAddressBtnDisabled(values) ? noChangeDisabledBtnTooltipMessage : ""}
              />
            </div>
          </FactoryConditionForm>
          <FactoryCommonTable
            setCurrentCommonCondition={setCurrentCommonCondition}
            onCancelBtnClick={onCancelBtnClick}
            modalType={modalType}
          >
            <>
              <DateInput label={t("Creaction date") + ":"} name="createdAt" type="date" readOnly />
              <TextInput
                label={t("Created by") + ":"}
                name="creator"
                width="12em"
                labelStyles={{width: "100px", wordWrap: "nowrap"}}
                readOnly
                hasTitle
              />
              <DateInput label={t("Edit date") + ":"} name="updatedAt" type="date" readOnly />
              <TextInput
                label={t("Eddited by") + ":"}
                name="modifier"
                width="12em"
                labelStyles={{width: "100px", wordWrap: "nowrap"}}
                generalClassName="factory_common_last_input"
                readOnly
                hasTitle
              />
              <FunctionButton name="Save ch" onClick={() => onUpdateFactoryModalChangesBtnClick()} />
            </>
          </FactoryCommonTable>
        </>
      )}
    </Formik>
  );
};

EditFactoryConditions.propTypes = {setModalActive: PropTypes.func};

export default EditFactoryConditions;
