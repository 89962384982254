// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ReactTableUniSelect_reactTableRow__0NTZO {
  cursor: pointer;
}
.ReactTableUniSelect_reactTableRow__0NTZO:hover, .ReactTableUniSelect_reactTableRow__0NTZO:focus, .ReactTableUniSelect_reactTableRow__0NTZO:active {
  background-color: rgba(191, 205, 226, 0.6666666667);
}`, "",{"version":3,"sources":["webpack://./src/Components/conteiners/ReactTableUniSelect.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;AACE;EAGE,mDAAA;AADJ","sourcesContent":[".reactTableRow {\n  cursor: pointer;\n\n  &:hover,\n  &:focus,\n  &:active {\n    background-color: #bfcde2aa;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"reactTableRow": `ReactTableUniSelect_reactTableRow__0NTZO`
};
export default ___CSS_LOADER_EXPORT___;
