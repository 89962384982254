import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useFormikContext} from "formik";
import {translit} from "ua-en-translit";

import cloud from "../../../../../images/icons/upload-two.png";
import FunctionButton from "../../../../../utilities/Buttons/FunctionButton";
import ImageButton from "../../../../../utilities/Buttons/ImageBtn";
import {getInfo, getSuccess} from "../../../../../utilities/toasts";
import {TextInput} from "../../../../Inputs";
import FilePreview from "../../../../Inputs/fileSelect/preview/FilePreview";
import {Select} from "../../../../Inputs/SelectField";
import SingleFilePicker from "../../../../Inputs/SingleFilePicker";
import {getRequestInfo} from "../../../RequestsSlice";

import {
  addPaymentDocs,
  addRequestPaymentDocs,
  attachFromRequest,
  getPaymentsForRequest,
  removePaymentDocs,
  sendForPayment,
} from "./redux/PaymentOperationsSlice";
import {getCurrentDocument, getDocTypes, getPayment} from "./redux/PaymentSelectors";
import usePaymentsData from "./utils/hook/PaymentDataService";
import DocumentsForPayment from "./DocumentsForPayment";
import FinScheme from "./FinScheme";
import PaymentDates from "./PaymentDates";
import PaymentDocs from "./PaymentDocs";

const ForPaymentPart = () => {
  const {t} = useTranslation();

  const dispatch = useDispatch();

  const {docTypes} = usePaymentsData();
  const payment = useSelector(getPayment);
  const currentRequest = useSelector(getRequestInfo);
  const currentDoc = useSelector(getCurrentDocument);

  const {values, setValues} = useFormikContext(),
    {doc, documentType} = values;

  const disabledFilePicker = doc === "" || documentType === "";

  const onFileAdding = file => {
    const formdata = new FormData();
    let filename = file.name;
    formdata.append("doc", file, filename);
    formdata.append("docNumber", values.doc);
    formdata.append("docTypeId", values.documentType);
    formdata.append("note", values.docNote);

    if (formdata.has("doc")) {
      dispatch(addRequestPaymentDocs({requestId: currentRequest.requestId, formData: formdata})).then(resp => {
        if (resp.meta.requestStatus === "fulfilled") {
          getSuccess(t("Payment document has been created"));
          setValues({
            doc: "",
            documentType: "",
            docNote: "",
          });
        }
      });
    }
  };

  const attachDoc = () => {
    dispatch(attachFromRequest({paymentId: payment.paymentId, data: {docId: currentDoc.doc.docId}})).then(resp => {
      if (resp.meta.requestStatus === "fulfilled") {
        getSuccess(t("The document has been added for payment"));
      }
    });
  };

  return (
    <div>
      <div>1.{t("For payment")} </div>
      <div>
        <PaymentDates
          labelPlan={t("for payment (p)")}
          labelFact={t("for payment (f)")}
          ttType="load doc assign"
          name={"onPayment"}
        />

        <div className="on-payment on-payment_download">
          <TextInput
            label={t("Doc. for payment")}
            question="num doc"
            name="doc"
            width="10em"
            labelStyles={{width: "9em"}}
          />

          <Select
            label={t("Document type")}
            question="doc type"
            options={docTypes}
            name="documentType"
            width="10em"
            widthLabel="9em"
          />
          <SingleFilePicker disabled={disabledFilePicker} addFunc={onFileAdding} accept={".pdf"} />
        </div>

        <DocumentsForPayment />
        <div className="buttons-table">
          <FunctionButton
            name={t("Delete document for payment")}
            autoWidth
            tooltipMessage="Delete selected"
            disabled={!(currentDoc.number === 2)}
            onClick={() => {
              dispatch(removePaymentDocs(currentDoc.doc.docId));
            }}
          />
          <FunctionButton
            name={t("Add document for payment")}
            autoWidth
            tooltipMessage="list docs"
            disabled={!(currentDoc.number === 1)}
            onClick={attachDoc}
          />
        </div>
        <PaymentDocs />
        <FinScheme />

        <div className="border"></div>
      </div>
    </div>
  );
};

export default ForPaymentPart;
