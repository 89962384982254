import {useTranslation} from "react-i18next";
import {useFormikContext} from "formik";
import PropTypes from "prop-types";

import AvailabilityStatusCIrcule, {AVAILABILITY_CIRCLE_COLORS} from "../../../utilities/AvailabilityStatusCIrcule";
import FunctionButton from "../../../utilities/Buttons/FunctionButton";
import DateStatusCircle from "../../../utilities/DateStatusCircle";
import QuestionItem from "../../../utilities/QuestionItem";
import ReadOnlyFields from "../../hoc/ReadOnlyFields";
import {DateInput} from "../../Inputs";
import {STATUS_COLORS} from "../RequestRegister/colorConstants";

const InProgressCard = ({...props}) => {
  const {t} = useTranslation();
  const {header, dateStartLabel, dateEndLabel, buttonName, dateStartName, dateEndName, frame, onClick, descText, type} =
    props;

  const {values} = useFormikContext();
  const plannedDate = values[dateStartName];

  const btnDisabled =
    (!values[dateStartName] || values[dateStartName] === "") && (!values[dateEndName] || values[dateEndName] === "");

  const redinessButtonDisabled =
    dateStartLabel === "" ? values.plannedReadinessDate === "" : values.onPaymentDateE === "";

  return (
    <div className="card" style={{border: `1px solid ${frame}`}}>
      <div className="header-wrapper">
        <div className="header-card">
          <DateStatusCircle plannedDate={plannedDate} />

          <div className="names q_wrapper">
            {t("Date")}
            <QuestionItem title={"date tip"} />
          </div>
        </div>
        <p className="names">{header}</p>
      </div>
      <ReadOnlyFields>
        <div className="dates">
          {dateStartName && <DateInput label={dateStartLabel} name={dateStartName} />}
          {dateEndName && <DateInput label={dateEndLabel} name={dateEndName} />}
        </div>
      </ReadOnlyFields>
      <FunctionButton
        name={buttonName}
        className="card_btn"
        tooltipMessage={descText}
        onClick={onClick}
        disabled={type === "receipt" ? redinessButtonDisabled : btnDisabled}
      />
    </div>
  );
};

InProgressCard.propTypes = {
  statusButton: PropTypes.string,
  header: PropTypes.string,
  dateStartLabel: PropTypes.string,
  dateEndLabel: PropTypes.string,
  buttonName: PropTypes.string,
  dateStartName: PropTypes.string,
  dateEndName: PropTypes.string,
  frame: PropTypes.oneOf(Object.values(STATUS_COLORS)),
  onInProgressCardBtnClick: PropTypes.func,
};

export default InProgressCard;
