import {useEffect, useMemo, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {Form, Formik, useFormikContext} from "formik";
import PropTypes from "prop-types";
import Swal from "sweetalert2";
import translit from "ua-en-translit";

import {apiUrl} from "../../../../api/baseURL";
import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import QuestionItem from "../../../../utilities/QuestionItem";
import ToolTip from "../../../../utilities/ToolTip";
import {TextAreaInput, TextInput} from "../../../Inputs";
import FilePreview from "../../../Inputs/fileSelect/preview/FilePreview";
import {Select} from "../../../Inputs/SelectField";
import {deselectCurrentRequest, getRequest, getRequestInfo, requestsAddFile, sendToFactory} from "../../RequestsSlice";

import styles from "./FactoryRqstsModal.module.scss";

const FactoryRqstsModal = ({setModalActive, submitBtnName, children}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const downloadLinkRef = useRef(null);
  const [downloadUrl, setDownloadUrl] = useState(null);

  const currentRequest = useSelector(getRequestInfo);

  useEffect(() => {
    if (downloadUrl) {
      downloadLinkRef.current.click();
      setTimeout(() => {
        navigate("/ba3a/ved/reqInProgress");
      }, 1500);
    }

    return () => {
      // dispatch(deselectCurrentRequest());
      // dispatch(getRequest(currentRequest.requestId));
    };
  }, [downloadUrl]);

  const initialValues = {
    requestNumber: "",
    requestSum: "",
    selectedContact: "",
    emailCopy: "copyemail@gmail.com",
    addedFiles: null,
    email: "testemail@gmail.com",
    carbonCopy: "copyemail@gmail.com",
    subject: "Request for manufacturing products",
    content: "",
  };

  const currentValues = useMemo(() => {
    if (currentRequest) {
      const {requestNumber, brand, requestSum, requestContact} = currentRequest;
      console.log(requestContact);
      return {
        ...initialValues,
        // selectedContact: requestContact?.requestContactId || "",
        requestNumber,
        selectedFabric: brand?.name || "",
        requestSum,
      };
    }
    return null;
  }, [currentRequest]);

  //event handlers

  const onFileDel = url => {
    Swal.fire({
      title: "",
      text: [t("Sure")],
      icon: "question",
      confirmButtonText: [t("Yes")],
      showCancelButton: true,
      cancelButtonText: [t("No")],
      customClas: {
        popup: "zindex",
        container: "zindex",
        htmlContainer: "zindex",
      },
    }).then(value => {
      if (value.isConfirmed) {
        // dispatch(delProjectContractFile({ contractId: current.contractId, url })).then(res => {
        //   if (res.meta.requestStatus === "fulfilled") {
        //     setFile(res.payload.docsArray);
        //     setCurrent(item => {
        //       return { ...item, docsArray: res.payload.docsArray };
        //     });
        //   }
        // });
      }
    });
  };

  const onFileAdding = arrFiles => {
    var formdata = new FormData();
    var i = 0,
      file;

    for (; i < arrFiles.length; i++) {
      file = arrFiles[i];
      if (currentRequest && currentRequest?.requestFiles && currentRequest?.requestFiles.length !== 0) {
        var exist = currentRequest?.requestFiles.includes(
          `requests/6-1-2-NOK-16-ARC${currentRequest.requestNumber}/${file.name}`,
        );
        if (exist) {
          Swal.fire({
            title: "",
            text: `${[t("File name")]} ${file.name} ${[t("Already exist")]}`,
            icon: "warning",
            confirmButtonText: "Ок",
            customClas: {
              popup: "zindex",
              container: "zindex",
              htmlContainer: "zindex",
            },
          });
          continue;
        }
      }
      const filename = translit(file.name);
      formdata.append("file", file, filename);
    }
    if (formdata.has("file")) {
      dispatch(requestsAddFile({requestId: currentRequest.requestId, formData: formdata}));
    }
  };

  const onFabricRqstFormSubmit = values => {
    try {
      const {email, emailCopy, subject, content} = values;
      const data = {
        email,
        cc: emailCopy,
        subject,
        content,
      };
      dispatch(sendToFactory({requestId: currentRequest.requestId, data})).then(res => {
        if (res.meta.requestStatus === "fulfilled") {
          setDownloadUrl(apiUrl + "/" + res.payload);
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Formik initialValues={currentValues || initialValues} enableReinitialize onSubmit={onFabricRqstFormSubmit}>
      <Form className={`${styles.modalContainer} autolabel`}>
        <div className={styles.upperRow}>
          <TextInput
            label={t("Order №") + ":"}
            name="requestNumber"
            generalClassName={styles.gapInput}
            readOnly={true}
          />
          <TextInput
            label={t("Order Sum") + ":"}
            name="requestSum"
            generalClassName={styles.gapInput}
            readOnly={true}
          />
          {/* <Select
            label={t("Select a Factory") + ":"}
            name="selectedFabric"
            width="100%"
            multi={true}
            minFilterLength={1}
            options={factories}
            generalClassName={styles.gapInput}
          /> */}
          <TextInput label={t("Select a Factory") + ":"} name="selectedFabric" width="100%" readOnly={true} />
        </div>
        <Contacts>
          <div className={styles.addFileSubRow}>
            <div className={styles.questionLabel}>
              <QuestionItem title="attached f" />
              <label className="label">{t("Added Files") + ":"}</label>
            </div>

            <ToolTip title={t("Add file")}>
              <FilePreview
                imgs={
                  currentRequest?.requestFiles?.map(file => {
                    return file.fileUrl;
                  }) || []
                }
                delFunc={onFileDel}
                // accept={}
                addFunc={onFileAdding}
                disable={!currentRequest}
                // loading={docsLoading}
              />
            </ToolTip>
          </div>
          <a href={downloadUrl} ref={downloadLinkRef} />
        </Contacts>
        {children}
      </Form>
    </Formik>
  );
};

const Contacts = ({children}) => {
  const {t} = useTranslation();
  const {values, setValues, initialValues} = useFormikContext();

  const currentRequest = useSelector(getRequestInfo);

  const {selectedContact} = values;

  const factoryContacts = currentRequest?.brand?.factoryContacts;

  const factoryContactsData = useMemo(() => {
    return (
      currentRequest?.brand?.factoryContacts?.map(cont => {
        return {title: cont.name, value: cont.contactId};
      }) || []
    );
  }, [currentRequest]);

  useEffect(() => {
    console.log(selectedContact);
    if (selectedContact && selectedContact !== "") {
      try {
        const current = factoryContacts.find(cont => cont.contactId === selectedContact);
        const {email, carbonCopy, subject, content} = current;
        setValues({
          ...values,
          email,
          carbonCopy,
          subject,
          content,
        });
      } catch {
        setValues(initialValues);
      }
    }
    if (!selectedContact) {
      if (currentRequest) {
        const {requestContact} = currentRequest;
        setValues({
          ...values,
          content: requestContact?.content || "",
          email: requestContact?.email || "",
          carbonCopy: requestContact?.carbonCopy || "",
          subject: requestContact?.subject || "",
        });
      } else {
        setValues({
          ...values,
          email: "",
          carbonCopy: "",
          subject: "",
          content: "",
        });
      }
    }
  }, [selectedContact, currentRequest]);

  return (
    <div className={styles.middleRow}>
      <TextAreaInput
        label={t("Add text to the Factory Request") + ":"}
        placeholder={t("req create note")}
        name="content"
        width="55%"
        height="105px"
      />
      <div className={styles.middleRowRightColumn}>
        <Select
          label={t("Select a Contact") + ":"}
          name="selectedContact"
          width="100%"
          multi={true}
          minFilterLength={1}
          options={factoryContactsData}
          question="contacts"
        />

        <TextInput
          label={t("For") + ":"}
          name="email"
          width="100%"
          generalClassName={styles.borderedInputWithLabel}
          inputClassName={styles.borderedInputInner}
        />
        <TextInput
          label={t("Copy") + ":"}
          name="carbonCopy"
          width="100%"
          generalClassName={styles.borderedInputWithLabel}
          inputClassName={styles.borderedInputInner}
        />
        <TextInput
          label={t("Theme") + ":"}
          name="subject"
          width="100%"
          generalClassName={styles.borderedInputWithLabel}
          inputClassName={styles.borderedInputInner}
        />
        {children}
      </div>
    </div>
  );
};

FactoryRqstsModal.propTypes = {
  setModalActive: PropTypes.func,
  // setCouldModalClose: PropTypes.func
};

export default FactoryRqstsModal;
