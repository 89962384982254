import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import useColumns from "../../../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {
  cancelFactoryCreation,
  cancelFactoryUpdate,
  fetchAllConditions,
  fetchAllContacts,
} from "../../../../redux/operations/FactoryOperations";
import {
  selectConditions,
  selectCurrentFactory,
  selectCurrentFactoryId,
} from "../../../../redux/selectors/FactorySelectors";
import {factoryCleanCurrent, setCurrentFactoryId} from "../../../../redux/slices/FactorySlice";
import {
  cleanFactoryIdSessionStorage,
  getFactoryIdSessionStorage,
} from "../../../factoryModalParts/utils/functions/factoryIdSessionStorage";
import {FACTORY_MODAL_TYPES} from "../../../factoryModals/utils/constants";
import {CLEAN_FILTERS, FACTORY_TABLE_COLUMNS, FILTER_STORAGE_NAME} from "../constants";

const useFactoryCCService = () => {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const storedFilters = JSON.parse(sessionStorage.getItem(FILTER_STORAGE_NAME));

  const [currentCondition, setCurrentCondition] = useState(null);
  const [filters, setFilters] = useState(storedFilters ?? CLEAN_FILTERS);

  useEffect(() => {
    if (filters !== CLEAN_FILTERS) {
      dispatch(fetchAllConditions(filters));
    }
    if (filters === CLEAN_FILTERS) {
      dispatch(fetchAllConditions());
    }

    dispatch(fetchAllContacts());
  }, [filters]);

  //clean backend data after reload after unfinished factory creation / update
  useEffect(() => {
    const closedModalTypeActions = {
      [FACTORY_MODAL_TYPES.CREATE_FACTORY]: cancelFactoryCreation,
      [FACTORY_MODAL_TYPES.CREATE_CONDITIONS]: cancelFactoryUpdate,
    };

    const {factoryId: savedFactoryId, modalType: closedModalType} = getFactoryIdSessionStorage();
    if (savedFactoryId) {
      dispatch(closedModalTypeActions[closedModalType](savedFactoryId));
      cleanFactoryIdSessionStorage();
    }
  }, []);

  //values from redux state
  const allConditions = useSelector(selectConditions);
  const newFactory = useSelector(selectCurrentFactory);
  const currentFactoryId = useSelector(selectCurrentFactoryId);
  const users = useSelector(state => state.person.users);

  const isCurrentFactory = Boolean(currentFactoryId);
  const areFactoryCCBtnsDisabled = !currentFactoryId;

  //initial values
  const creator = currentCondition ? users?.find(user => user.userId === currentCondition.creatorId)?.name : "";
  const modifier = currentCondition ? users?.find(user => user.userId === currentCondition.modifierId)?.name : "";

  const initialValues = {
    createdAt: currentCondition ? currentCondition.createdAt : "",
    creator,
    updatedAt: currentCondition ? currentCondition.updatedAt : "",
    modifier,
  };

  const noDataFieldText = t("No data");

  // for factories table
  const columns = useColumns(FACTORY_TABLE_COLUMNS);
  const factoryCCTableData = allConditions
    ? allConditions.map(
        (
          {
            discount,
            termsFrom,
            termsTo,
            discountNote,
            termsNote,
            termsCondition,
            factory,
            representativeContact,
            conditionId,
          },
          index,
        ) => {
          const {name, address, factoryId, country} = factory ?? {};
          const {phone, email, name: contactPerson} = representativeContact ?? {};

          return {
            index: ++index,
            id: conditionId + "*" + factoryId,
            name: name || noDataFieldText,
            country: country?.shortName || noDataFieldText,
            discount: discount || noDataFieldText,
            contactPerson: contactPerson || noDataFieldText,
            email: email || noDataFieldText,
            phone: phone || noDataFieldText,
            terms: `from ${termsFrom} to ${termsTo}`,
            conditions: termsCondition || noDataFieldText,
            notes: `${discountNote} ${termsNote}`,
            address: address || noDataFieldText,
          };
        },
      )
    : [];

  const currentTableSelectedFactory = currentFactoryId;

  const onFactoryConditionTableRowSelect = complexId => {
    const factoryId = Number(complexId.split("*")[1]);
    const conditionId = Number(complexId.split("*")[0]);

    //deselect
    if (isCurrentFactory && currentFactoryId === factoryId && currentCondition.conditionId === conditionId) {
      dispatch(setCurrentFactoryId(null));
      setCurrentCondition(null);
      return;
    }
    dispatch(setCurrentFactoryId(factoryId));
    setCurrentCondition(
      allConditions?.find(condition => condition.factoryId === factoryId && condition.conditionId === conditionId) ??
        null,
    );
  };

  //modal close functions
  const onModalClose = () => {
    dispatch(factoryCleanCurrent());
  };

  const onNewFactoryModalClose = () => {
    const savedFilters = JSON.parse(sessionStorage.getItem(FILTER_STORAGE_NAME));
    dispatch(fetchAllConditions(savedFilters));
    if (!newFactory) return;
    dispatch(cancelFactoryCreation(newFactory.factoryId));

    onModalClose();
  };
  const onEditCondFactoryModalClose = () => {
    onModalClose();
  };
  const onCreateCondModalClose = () => {
    onModalClose();
    dispatch(cancelFactoryUpdate(newFactory.factoryId));
  };

  return {
    initialValues,
    columns,
    factoryCCTableData,
    currentTableSelectedFactory,
    onFactoryConditionTableRowSelect,
    onNewFactoryModalClose,
    onEditCondFactoryModalClose,
    onCreateCondModalClose,
    areFactoryCCBtnsDisabled,
    filters,
    setFilters,
    currentCondition,
  };
};

export default useFactoryCCService;
