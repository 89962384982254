import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Moment from "react-moment";
import {useDispatch, useSelector} from "react-redux";
import {NavLink, useLocation} from "react-router-dom";

import {MODE} from "../../gitIgnore/appMode";
// import {MODE} from "../../gitIgnore/appMode";
import logoutPng from "../../images/icons/logout.png";
import {logout} from "../../slices/UserSlice";
import AccessBoundary from "../AccessBoundary/AccessBoundary";

import logo from "./src/Logo.png";

import "./src/NavBar.scss";

const NavBar = () => {
  const {t} = useTranslation();

  let activeStyle = {
    fontWeight: 600,
  };
  // const { token } = useSelector(state => state.user);

  const location = useLocation();

  const {token, user} = useSelector(state => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = location.pathname.split("/").pop() + " / " + MODE;
  }, []);

  const {i18n} = useTranslation();
  return (
    <div className="navbar">
      <div className="navbar__container">
        <NavLink end to="/ba3a/main" style={({isActive}) => (isActive ? activeStyle : undefined)}>
          <div className="navbar__logo">
            <img alt="" src={logo} draggable="false" />
            <h1>ERP ’ba3a’ - {MODE}</h1>
          </div>
        </NavLink>
        {token && (
          <nav className="navbar__top_navigation">
            <ul>
              <NavItem name="ba3a">
                <MyLink to="/ba3a/admin/addPerson" name={t("Person")} />
                <AccessBoundary>
                  <MyLink to="/ba3a/admin/registr" name={t("User")} />
                </AccessBoundary>
                <MyLink to="/xx" name={t("Designer")} />
                <MyLink to="/ba3a/admin/addClient" name={t("Client")} />
              </NavItem>

              <NavItem name={t("PROJECT")}>
                <MyLink to="/ba3a/project" name={t("Project")} />
              </NavItem>

              <NavItem name={t("CO")}>
                <MyLink to="/ba3a/kp" name={t("CO")} />
                {/* <MyLink to='/ba3a/kp/kpfin' name="КП финансы"/> */}
                <MyLink to="/vdvd" name={t("CO album")} />
                <MyLink to="/vdvd" name={t("CO templates")} />
                <MyLink to="/vdvd" name={t("CO register")} />
                <MyLink to="/ba3a/kp/control" name={t("Control PF")} />
                {/* <MyLink to="/ba3a/kp/archive" name={t("Archive PF")} /> */}
                <MyLink to="/vdvd" name={t("Archive PF")} />
              </NavItem>
              <NavItem name="SKU">
                <MyLink to="/ba3a/sku" name="SKU" />
              </NavItem>
              <NavItem name={t("FEA")}>
                <MyLink to="/ba3a/ved/sku" name="SKU" />
                <MyLink to="/ba3a/ved/reqRegistr" name={t("Request register")} />
                <MyLink to="/ba3a/ved/orderProcess" name={t("Order processing")} />
                <MyLink to="/ba3a/ved/reqInProgress" name={t("Requests in progress")} />
                <MyLink to="/ba3a/ved/factorySettings" name={t("factory cc")} />
                <MyLink to="/ba3a/ved/vedSettingsGeneral" name={t("General VED settings")} />
              </NavItem>
              <NavItem name={t("LOGISTICIAN")}></NavItem>
              <NavItem name={t("FIN UNIT")}>
                <MyLink to="/ba3a/finance/fin-block/paymentsregistr" name={t("Payments register for Pf")} />
              </NavItem>
              <NavItem name={t("FIN ACCOUNTING")}>
                <MyLink to="/ba3a/finance/fin-accounting" name={t("FIN ACCOUNTING")} />
                <MyLink to="/ba3a/finance/fin-accounting/accountsdir" name={t("Account handbook")} />
              </NavItem>
              <NavItem name={t("SERVICE")}></NavItem>
              <NavItem name={t("WAREHOUSE")}></NavItem>
              <NavItem name={t("HELP")}>
                <MyLink to="/ba3a/settings/translate" name={t("Translation")} />
              </NavItem>
            </ul>
          </nav>
        )}
        <div className="navbar__datetime">
          <div className="navbar__datetime_wrapper">
            <h1>
              <Moment format="DD.MM.YYYY" />
            </h1>
            <h1>
              <Moment format="HH:mm" interval={1000 * 60} />
            </h1>
          </div>
          {user && Object.prototype.hasOwnProperty.call(user, "login") && (
            <div className="user_label__container">
              <label htmlFor="" className="label user_label">
                {i18n.language === "en" ? (
                  <span>
                    {user.engName} {user.engLastName}
                  </span>
                ) : (
                  <span>
                    {user.firstName} {user.lastName}
                  </span>
                )}{" "}
              </label>
              <img
                src={logoutPng}
                alt=""
                title={t("LogOut")}
                onClick={() => {
                  dispatch(logout());
                }}
                draggable="false"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const NavItem = props => {
  const [open, setOpen] = useState();

  return (
    <div className="nav-item" onClick={() => setOpen(true)} onMouseLeave={() => setOpen(false)}>
      {props.name}
      {open && <div className="dropdown">{props.children}</div>}
    </div>
  );
};

const MyLink = props => {
  let activeStyle = {
    fontWeight: 600,
  };

  const location = useLocation();

  useEffect(() => {
    if (location.pathname === props.to) {
      document.title = props.name + " / " + MODE;
    }
  }, [location]);

  return (
    <li className="nav-item">
      <NavLink end to={props.to} style={({isActive}) => (isActive ? props.style || activeStyle : undefined)}>
        {props.name}
      </NavLink>
    </li>
  );
};

export default NavBar;
