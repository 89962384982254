import React from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import PropTypes from "prop-types";
import Swal from "sweetalert2";

import {selectFacsimile} from "../../../../../slices/UserSlice";
import {BUTTON_TYPES} from "../../../../../utilities/Buttons/constants";
import FunctionButton from "../../../../../utilities/Buttons/FunctionButton";
import {getInfo} from "../../../../../utilities/toasts";
import {
  addProformApproval,
  declineProformApproval,
} from "../../../../Requests/Proform/redux/operations/ProformOperations";
import {selectCurrentApproval} from "../../../../Requests/Proform/redux/selectors/ProformSelectors";

import styles from "../KpPfControl.module.scss";

export const PF_CONTROL_BTN_TYPES = Object.freeze({APPROVE: "approve", DISAPPROVE: "disapprove"});

const FPfControlBtn = ({type, disabled = false, approvalId, note = ""}) => {
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const facsimile = useSelector(selectFacsimile);
  const currentApproval = useSelector(selectCurrentApproval);

  const btnClassName = type === PF_CONTROL_BTN_TYPES.APPROVE ? styles.approveBtn : styles.disApproveBtn;
  const btnName = type === PF_CONTROL_BTN_TYPES.APPROVE ? "Pf approved" : "Pf not approved";

  const onPfControlBtnClick = () => {
    if (!approvalId) return;
    console.log(currentApproval?.managerCo?.facsimile);
    if (!(currentApproval?.managerCo?.facsimile || facsimile)) {
      toast.error(t("You need to add your facsimile first!"));
      return;
    }

    Swal.fire({
      title: t("Submit your facsimile password"),
      input: "text",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      confirmButtonText: t("Submit"),
      cancelButtonText: t("Cancel"),
      showLoaderOnConfirm: true,
      preConfirm: password =>
        type === PF_CONTROL_BTN_TYPES.APPROVE
          ? dispatch(addProformApproval({approvalId, data: {password}}))
          : dispatch(declineProformApproval({approvalId, data: {note}})),
      allowOutsideClick: () => !Swal.isLoading(),
    }).then(result => {
      if (result.isConfirmed) {
        getInfo(type === PF_CONTROL_BTN_TYPES.APPROVE ? t("Pf agreed") : t("Pf not agreed"));
      }
    });
  };

  return (
    <FunctionButton
      className={btnClassName}
      type={BUTTON_TYPES.BUTTON}
      name={btnName}
      width={"11em"}
      disabled={disabled}
      onClick={onPfControlBtnClick}
    />
  );
};

FPfControlBtn.propTypes = {
  type: PropTypes.oneOf(Object.values(PF_CONTROL_BTN_TYPES)).isRequired,
  disabled: PropTypes.bool,
  approvalId: PropTypes.oneOfType([PropTypes.number, PropTypes.instanceOf(null)]),
  note: PropTypes.string,
};

export const PfControlBtn = React.memo(FPfControlBtn);
