import {useSelector} from "react-redux";

import ReactTable from "../../../conteiners/ReactTable";
import useColumns from "../../OrderProcessing/utils/hooks/useColumns";
import {getRequestInfo} from "../../RequestsSlice";

import {REQUEST_TABLE_COLUMNS} from "./utils/constants";

const RequestTable = ({defaultData, orderingModalActive, requestLoading, onSelect}) => {
  const columns = useColumns(REQUEST_TABLE_COLUMNS);

  const currentRequest = useSelector(getRequestInfo);

  return (
    <ReactTable
      defaultData={defaultData}
      columns={columns}
      onSelect={onSelect}
      current={currentRequest?.requestId}
      className={"scrollX"}
      tableClassName={"request-table"}
      loading={orderingModalActive ? false : requestLoading}
    />
  );
};

export default RequestTable;
