import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useFormikContext} from "formik";

import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import {getInfo, getSuccess} from "../../../../utilities/toasts";
import {ListSelect, Select, TextAreaInput, TextInput} from "../../../Inputs";
import {
  addEmailTemplates,
  deleteEmailTemplates,
  fetchEmailTemplates,
  updateEmailTemplates,
} from "../../redux/operations/FactoryOperations";
import {selectCurrentConditionId, selectEmailTamplates} from "../../redux/selectors/FactorySelectors";
import {setEmailTemplates} from "../../redux/slices/FactorySlice";
import LeftRightBtnGroup from "../common/components/LeftRightBtnGroup";

import styles from "../common/styles/VedSettingsGeneral.module.scss";

const EmailTemplates = () => {
  const {t} = useTranslation();

  const {values, setValues, setFieldValue} = useFormikContext();

  const {variant} = values;
  const dispatch = useDispatch();

  const [currentTempl, setCurrentTempl] = useState(null);
  const currentCondition = useSelector(selectCurrentConditionId),
    templatesList = useSelector(selectEmailTamplates);

  const fieldsDisabled = !currentTempl;

  useEffect(() => {
    if (variant && variant !== "") {
      var templ = templatesList.find(templ => templ.variant === variant);
      setCurrentTempl(templ);
    }
  }, [variant]);

  useEffect(() => {
    if (currentTempl) {
      const {email, carbonCopy, subject, content} = currentTempl;
      setValues({
        ...values,
        toWhom: email,
        copy: carbonCopy,
        theme: subject,
        reqText: content,
      });
    }
    // else{}
  }, [currentTempl]);

  const templatesVariantOptions = useMemo(() => {
    return templatesList?.map(templ => templ.variant) || [];
  }, [templatesList]);

  useEffect(() => {
    if (templatesList?.length > 0) {
      var curr = templatesList[templatesList.length - 1];
      setCurrentTempl(curr);
      setFieldValue("variant", curr.variant);
    } else {
      setCurrentTempl(null);
      setCurrentTempl(null);
    }
  }, [templatesList]);

  //event handlers
  useEffect(() => {
    if (currentCondition) dispatch(fetchEmailTemplates({conditionId: currentCondition}));
    else {
      dispatch(setEmailTemplates([]));
    }
  }, [currentCondition]);

  const onAddTemplate = () => {
    dispatch(addEmailTemplates(currentCondition)).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        getInfo(t("email template add success"));
      }
    });
  };

  const onDellTemplate = () => {
    dispatch(deleteEmailTemplates(currentTempl.templateId)).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        getInfo(t("email template del success"));
      }
    });
  };

  const onUpdate = () => {
    const data = {
      email: values.toWhom,
      carbonCopy: values.copy,
      subject: values.theme,
      content: values.reqText,
    };
    dispatch(updateEmailTemplates({templateId: currentTempl.templateId, data})).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        getInfo(t("email template del success"));
      }
    });
  };

  return (
    <section className={styles.reqTextSection}>
      <h2 className={styles.sectionHeader}>
        2. <span className={styles.underLined}>{t("Fabric Request Text")}</span>
      </h2>
      <div className={`${styles.sectionRow} ${styles.reqTextFabricRow}`}>
        <TextInput label={t("Factory") + ":"} name="factory" disabled />
        <ListSelect label={t("Option") + ":"} name="variant" options={templatesVariantOptions} />
      </div>
      <div className={styles.sectionRow}>
        <div className={styles.reqTextInputs}>
          <TextInput
            label={t("To whom") + ":"}
            name="toWhom"
            generalClassName={styles.reqTextField}
            inputClassName={styles.reqTextInputInner}
            width="100%"
            disabled={fieldsDisabled}
          />
          <TextInput
            label={t("Copy") + ":"}
            name="copy"
            generalClassName={styles.reqTextField}
            inputClassName={styles.reqTextInputInner}
            width="100%"
            disabled={fieldsDisabled}
          />
          <TextInput
            label={t("Theme") + ":"}
            name="theme"
            generalClassName={styles.reqTextField}
            inputClassName={styles.reqTextInputInner}
            width="100%"
            disabled={fieldsDisabled}
          />
          <TextAreaInput
            name="reqText"
            className={styles.reqTextArea}
            width="100%"
            height="80px"
            disabled={fieldsDisabled}
          />
        </div>
        <LeftRightBtnGroup
          onAddBtnClick={onAddTemplate}
          onRemoveBtnClick={onDellTemplate}
          tooltipL={"Add a new email template"}
          tooltipR={"Delete email template"}
          disabledL={!currentCondition}
          disabledR={fieldsDisabled}
        />
      </div>
      <FunctionButton
        name="Save"
        disabled={fieldsDisabled}
        className={styles.reqTextSaveBtn}
        onClick={onUpdate}
        autoWidth
      />
    </section>
  );
};

export default EmailTemplates;
